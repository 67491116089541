import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "radr-subscriber-reingest-modal",
  templateUrl: "./subscriber-reingest-modal.component.html",
  styleUrls: ["./subscriber-reingest-modal.component.scss"]
})
export class SubscriberReingestModalComponent {
  constructor(public dialogRef: MatDialogRef<SubscriberReingestModalComponent>) {}

  okClicked(): void {
    this.dialogRef.close(true);
  }

  cancelClicked(): void {
    this.dialogRef.close(false);
  }
}
