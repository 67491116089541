import { EventEmitter, Pipe, PipeTransform } from "@angular/core";
import { of, Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { AudiencesService } from "@shared/services/audience.service";
import { AudienceViewModel, CampaignViewModel, DataSourceIdTypes, QualifierViewModel } from "radr-shared";
import { ProfilesService } from "@shared/services/profiles.service";
import { AudienceBuilderService } from "@shared/services/audience-builder.service";
import { QualifierDataTypes } from "radr-shared/src/enums";

@Pipe({
  name: "setAudienceBuilderConfig"
})
export class SetAudienceBuilderConfigPipe implements PipeTransform {
  constructor(
    private qualifiersService: QualifiersService,
    private profilesService: ProfilesService,
    private audienceBuilderService: AudienceBuilderService,
    private audiencesService: AudiencesService
  ) {}

  setConfigFromAudience(a: AudienceViewModel, config: any, fields: any, rule: any, operatorMap: any): void {
    config.fields[a.id] = {
      name: a.audienceName,
      value: a.id.toString(),
      type: a.qualifier.dataType,
      segmentId: a.segmentId,
      segmentName: a.segmentName
    };

    Object.keys(config.fields).forEach(value => {
      const field: any = config.fields[value];
      field.value = field.value || value;

      if (fields.indexOf(field) === -1) {
        fields.push(field);
      }
    });
    rule.segmentId = a.segmentId;
    rule.segmentName = a.segmentName;
    // set operator to includes for Tune-In qualifiers
    if (!rule.operator && a.audienceType === 2) {
      rule.operator = "includes";
    } else if (!rule.operator && operatorMap[a.qualifier?.dataType]) {
      rule.operator = operatorMap[a.qualifier?.dataType][0];
    }
  }

  transform(rule: any, config: any, fields: any, operatorMap: any, dataChange: EventEmitter<any>): Observable<any[]> {
    if (!rule.field) {
      return of(null);
    }
    if (!config?.fields[rule.field]) {
      const audience: AudienceViewModel = this.audienceBuilderService.getSelectedItem();
      const audienceFromAudience: AudienceViewModel = audience?.audiences?.find(
        fromAudience => fromAudience.id === +rule.field
      );
      if (audienceFromAudience) {
        this.setConfigFromAudience(audienceFromAudience, config, fields, rule, operatorMap);
        return of(rule);
      } else {
        rule.loading = true;
        return this.audiencesService.getAudience(rule.field).pipe(
          map((a: AudienceViewModel) => {
            if (audience?.audiences?.length >= 0) {
              audience?.audiences.push(a);
            } else {
              if (audience && audience.audiences) {
                audience.audiences = [a];
              }
            }
            this.setConfigFromAudience(a, config, fields, rule, operatorMap);
            dataChange.emit(rule);
            return rule;
          }),
          tap(() => (rule.loading = false)),
          catchError(err => {
            const audienceNotFound: AudienceViewModel = new AudienceViewModel();
            audienceNotFound.id = rule.field;
            audienceNotFound.name = `AUDIENCE NOT FOUND FOR ID ${rule.field}`;
            audienceNotFound.dataType = "none";
            audienceNotFound.segmentId = null;
            audienceNotFound.segmentName = null;
            this.setConfigFromAudience(audienceNotFound, config, fields, rule, operatorMap);
            rule.loading = false;
            return of(rule);
          })
        );
      }
    } else {
      if (!rule.operator && operatorMap[config.fields[rule.field].type]) {
        rule.operator = operatorMap[config.fields[rule.field].type][0];
      }
      rule.segmentId = config.fields[rule.field].segmentId;
      rule.segmentName = config.fields[rule.field].segmentName;
      if (!rule.value) {
        rule.value = ["All"];
        // this.audienceBuilderService.loadCounts();
        dataChange.emit(rule);
      }
      return of(rule);
    }
  }
}
