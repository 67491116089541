import { Component } from "@angular/core";
import { LoadingService } from "@shared/services/loading.service";

@Component({
  selector: "radr-loading-overlay",
  templateUrl: "./loading-overlay.component.html",
  styleUrls: ["./loading-overlay.component.scss"]
})
export class LoadingOverlayComponent {
  constructor(public loadingService: LoadingService) {}
}
