import { APP_BASE_HREF, CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { RouterModule } from "@angular/router";
import { SatPopoverModule } from "@ncstate/sat-popover";
import { SelectAutocompleteComponent } from "@shared/components/select-autocomplete/select-autocomplete.component";
import { TableComponent } from "@shared/components/table/table.component";
import { FilePreviewComponent } from "@shared/components/file-preview/file-preview.component";
import { EditPopupComponent } from "@shared/components/edit-popup/edit-popup.component";
import { RadrModalComponent } from "@shared/components/radr-modal/radr-modal.component";
import { FormComponent } from "@shared/components/form/form.component";
import { FormErrorComponent } from "@shared/components/form-error/form-error.component";
import { LoadingOverlayComponent } from "@shared/components/loading-overlay/loading-overlay.component";
import { SnackbarContentComponent } from "@shared/components/snackbar-content/snackbar-content.component";
import { DisableControlDirective } from "@shared/directives/disable-control.directive";
import { Guid } from "@shared/lib/guid.service";
import { TableHeaderComponent } from "./table/table-header/table-header.component";
import { TablePageContainerComponent } from "./table/table-page-container/table-page-container.component";
import { TableRowExpanderComponent } from "./table/table-row-expander/table-row-expander.component";
import { SubscriberReingestModalComponent } from "@shared/components/subscriber-reingest-modal/subscriber-reingest-modal.component";
import { DashifyPipe } from "@shared/pipes/dashify.pipe";
import { FormatArrayPipe } from "@shared/pipes/format-array.pipe";
import { SetProfileBuilderConfigPipe } from "@shared/pipes/set-profile-builder-config.pipe";
import { SetAudienceBuilderConfigPipe } from "@shared/pipes/set-audience-builder-config.pipe";
import { TextOverflowComponent } from "./text-overflow/text-overflow.component";
import { ComponentLoaderComponent } from "./component-loader/component-loader.component";
import { RadrDetailRowDirective } from "./table/radr-detail-row.directive";
import { DialogModalComponent } from "./dialog-modal/dialog-modal.component";
import { BatchValuesSelectorModule } from "./batch-values-selector/batch-values-selector.module";
import { BatchValuesSelectorComponent } from "./batch-values-selector/batch-values-selector.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { ContextualMessageIconComponent } from "./contextual-message-icon/contextual-message-icon.component";
import { MatSortModule } from "@angular/material/sort";
import { RootProfileModalComponent } from "../../builder/components/root-profile-modal/root-profile-modal.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SessionTimeoutComponent } from "./session-timeout/session-timeout.component";
import { ActionButtonModule } from "./action-button/action-button.module";
import { LoadingOverlayModule } from "./loading-overlay/loading-overlay.module";
import { AdvancedFiltersComponent } from "./advanced-filters/advanced-filters.component";
import { AdvancedFiltersItemComponent } from "./advanced-filters/advanced-filters-item/advanced-filters-item.component";
import { FileImportModalComponent } from "./file-import-modal/file-import-modal.component";
import { MatListModule } from "@angular/material/list";
import { TuneinScheduleModalComponent } from "./tunein-schedule-modal/tunein-schedule-modal.component";
import { TuneInScheduleItemComponent } from "./tune-in-schedule-item/tune-in-schedule-item.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgxMaskModule, IConfig, MaskPipe } from "ngx-mask";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AudienceDetailsModalComponent } from "./audience-details-modal/audience-details-modal.component";

const maskConfig: Partial<IConfig> = null;

@NgModule({
  imports: [
    RouterModule,
    ActionButtonModule,
    LoadingOverlayModule,
    ScrollingModule,
    CommonModule,
    FormsModule,
    DragDropModule,
    MatButtonModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTooltipModule,
    MatTableModule,
    MatToolbarModule,
    MatListModule,
    ReactiveFormsModule,
    SatPopoverModule,
    BatchValuesSelectorModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  declarations: [
    AdvancedFiltersComponent,
    AdvancedFiltersItemComponent,
    SelectAutocompleteComponent,
    DisableControlDirective,
    TableComponent,
    FilePreviewComponent,
    EditPopupComponent,
    RadrModalComponent,
    FormComponent,
    FormErrorComponent,
    ComponentLoaderComponent,
    SnackbarContentComponent,
    TableHeaderComponent,
    TablePageContainerComponent,
    TableRowExpanderComponent,
    SubscriberReingestModalComponent,
    DashifyPipe,
    FormatArrayPipe,
    SetProfileBuilderConfigPipe,
    SetAudienceBuilderConfigPipe,
    TextOverflowComponent,
    RadrDetailRowDirective,
    DialogModalComponent,
    ContextualMessageIconComponent,
    FileUploadComponent,
    FileImportModalComponent,
    RootProfileModalComponent,
    SessionTimeoutComponent,
    TuneInScheduleItemComponent,
    TuneinScheduleModalComponent,
    AudienceDetailsModalComponent
  ],
  exports: [
    AdvancedFiltersComponent,
    AdvancedFiltersItemComponent,
    ScrollingModule,
    ActionButtonModule,
    LoadingOverlayModule,
    SelectAutocompleteComponent,
    RouterModule,
    CommonModule,
    FormsModule,
    DisableControlDirective,
    DragDropModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTooltipModule,
    MatToolbarModule,
    MatListModule,
    ReactiveFormsModule,
    EditPopupComponent,
    TableComponent,
    FilePreviewComponent,
    SatPopoverModule,
    RadrModalComponent,
    FormComponent,
    FormErrorComponent,
    ComponentLoaderComponent,
    LoadingOverlayComponent,
    TableHeaderComponent,
    TablePageContainerComponent,
    TableRowExpanderComponent,
    TextOverflowComponent,
    SubscriberReingestModalComponent,
    DashifyPipe,
    FormatArrayPipe,
    SetProfileBuilderConfigPipe,
    SetAudienceBuilderConfigPipe,
    DialogModalComponent,
    BatchValuesSelectorComponent,
    ContextualMessageIconComponent,
    FileUploadComponent,
    FileImportModalComponent,
    RootProfileModalComponent,
    TuneInScheduleItemComponent,
    TuneinScheduleModalComponent,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    AudienceDetailsModalComponent
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    MaskPipe,
    Guid,
    { provide: APP_BASE_HREF, useValue: "/" },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3825, verticalPosition: "top" }
    }
  ],
  entryComponents: [RadrModalComponent, SnackbarContentComponent, SubscriberReingestModalComponent]
})
export class SharedModule {}
