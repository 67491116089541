import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

export interface CustomSnackbarConfigData {
  action?: string;
  message: string;
  dismiss: () => void;
}

@Component({
  selector: "radr-snackbar-content",
  templateUrl: "snackbar-content.component.html",
  styleUrls: ["./snackbar-content.component.scss"]
})
export class SnackbarContentComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarConfigData) {}
}
