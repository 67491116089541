import { Inject } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import BaseService from "./base.service";
import { tap } from "rxjs/operators";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";

export default abstract class MasterDetailService<T> extends BaseService {
  protected masterListSource$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected selectedItemSource$: BehaviorSubject<T> = new BehaviorSubject<T>(null);
  private originalItem: T;
  public selectedItemPropertyChanged$: Subject<void> = new Subject();

  constructor(
    @Inject("route") protected route: string,
    @Inject("loadListOnStart") loadListOnStart: boolean = true,
    protected viewModelClass?: any
  ) {
    super(viewModelClass);
    if (loadListOnStart) {
      this.updateMasterList().subscribe();
    }
  }

  hasChanges(): boolean {
    return isEqual(this.originalItem, this.getSelectedItem());
  }

  onSelectedItemPropertyChanged(): void {
    this.selectedItemPropertyChanged$.next();
  }

  getSelectedItem(): T {
    return this.selectedItemSource$.getValue();
  }

  selectItem(item: T): void {
    this.originalItem = cloneDeep(item);
    this.selectedItemSource$.next(item);
    this.onSelectedItemPropertyChanged();
  }

  updateMasterList(): Observable<T[]> {
    return super.getMany<T>(this.route).pipe(
      tap(list => {
        this.masterListSource$.next(list);
      })
    );
  }

  protected save(route: string, body: any, options?: object): Observable<T> {
    return super.post<T>(route, body, options).pipe(tap(item => this.updateMasterList().subscribe()));
  }

  protected update(route: string, body: any, options?: object): Observable<T> {
    return super.patch<T>(route, body, options).pipe(tap(item => this.updateMasterList().subscribe()));
  }

  protected upsert(route: string, body: any, options?: object): Observable<T> {
    return super.put<T>(route, body, options).pipe(tap(item => this.updateMasterList().subscribe()));
  }
}
