import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { ActionButtonComponent } from "./action-button.component";

@NgModule({
  imports: [MatButtonModule],
  declarations: [ActionButtonComponent],
  exports: [ActionButtonComponent, MatButtonModule]
})
export class ActionButtonModule {}
