import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarContentComponent } from "@shared/components/snackbar-content/snackbar-content.component";

export enum SnackBarStatus {
  Unknown = "",
  Primary = "primary",
  Warning = "warn",
  Success = "success",
  Error = "error"
}

export interface SnackBarNotifyOptions {
  snackBarType?: SnackBarStatus;
  duration?: number | null;
  showDismissButton?: boolean;
}

const notify: (snackBar: MatSnackBar, alertMessage: string, options?: SnackBarNotifyOptions) => void = (
  snackBar,
  alertMessage,
  options = {}
) => {
  const { snackBarType = SnackBarStatus.Primary, duration = 3825, showDismissButton = false } = options;

  snackBar.openFromComponent(SnackbarContentComponent, {
    data: {
      dismiss: () => snackBar.dismiss(),
      message: alertMessage,
      action: showDismissButton ? "Close" : undefined
    },
    duration,
    panelClass: `mat-snack-bar-container--${snackBarType}`
  });
};

export default notify;
