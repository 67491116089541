<div class="execution-platform-group-close-button">
  <button
    mat-icon-button
    [disabled]="formGroup?.disabled"
    (click)="requestRemoveExecutionPlatform()"
    id="execution-platform-close-{{ groupNumber }}"
    [attr.data-test]="'execution-platform-close-' + groupNumber"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="execution-platform-group-header" data-test="execution-platform-group-header">
  <h2 id="execution-platform-group-{{ groupNumber }}-name">Execution Platform Group {{ groupNumber + 1 }}</h2>
  <div class="execution-platform-group-status-and-options">
    <div class="execution-platform-group-status">
      <div class="execution-platform-group-status-label">
        DESTINATION:
        <strong
          id="execution-platform-group-{{ groupNumber }}-destination"
          class="execution-platform-group-status-value"
          >{{ destination }}<span *ngIf="networkId && destination !== 'N/A'"> - {{ networkId }}</span></strong
        >
      </div>
      <div class="execution-platform-group-status-divider">|</div>
      <div class="execution-platform-group-status-label">
        TOTAL DEVICE COUNT {{ grossOrDedupe }}:
        <mat-progress-spinner
          id="execution-platform-group-{{ groupNumber }}-device-count-spinner"
          *ngIf="campaignBuilderService.isCampaignCountsLoading; else deviceCountTemplate"
          mode="indeterminate"
          diameter="15"
        ></mat-progress-spinner>
        <ng-template #deviceCountTemplate>
          <strong
            id="execution-platform-group-{{ groupNumber }}-device-count"
            class="execution-platform-group-status-value"
            >{{ campaignBuilderService.getTotalDeviceCount(universe) | number: "1.0" : "en-US" }}</strong
          >
        </ng-template>
      </div>
      <div class="execution-platform-group-status-divider">|</div>
      <div class="execution-platform-group-status-label">
        TOTAL HOUSEHOLD COUNT {{ grossOrDedupe }}:
        <mat-progress-spinner
          id="execution-platform-group-{{ groupNumber }}-household-count-spinner"
          *ngIf="campaignBuilderService.isCampaignCountsLoading; else householdCountTemplate"
          mode="indeterminate"
          diameter="15"
        ></mat-progress-spinner>
        <ng-template #householdCountTemplate>
          <strong
            id="execution-platform-group-{{ groupNumber }}-household-count"
            class="execution-platform-group-status-value"
            >{{ this.campaignBuilderService.getTotalTargetCount(universe) | number: "1.0" : "en-US" }}</strong
          >
        </ng-template>
      </div>
    </div>
  </div>
</div>
