import { Injectable } from "@angular/core";
import { Operators } from "radr-shared/src/models/operators.model";
import { Observable, of } from "rxjs";
import BaseService from "@shared/lib/base.service";

@Injectable({
  providedIn: "root"
})
export class OperatorsService extends BaseService {
  private readonly route: string = "operators";

  constructor() {
    super();
  }

  getOperatorOptions(key: string): Observable<Operators> {
    return this.getOne(`${this.route}?key=${key}`);
  }
}
