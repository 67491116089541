import { Audience } from "./audience.model";
import { SimplifiAttribute } from "./simplifi-attribute.model";
import { SimplifiCategory } from "./simplifi-category.model";
import { SimplifiSubcategory } from "./simplifi-subcategory.model";
/**
 * Represents the model for a profile in RADR
 *
 * @class Profile
 */
export class Profile {
  /**
   * Identifier for the profile
   *
   * @type {number}
   * @memberof Profile
   */
  id: number;

  /**
   * Identifier for the campaign id of the profile
   *
   * @type {number}
   * @memberof Profile
   */
  campaignId: number;

  /**
   * The definition of the profile
   * @type {string}
   * @memberof Profile
   */
  definition: any = { condition: "and", rules: [] };

  /**
   * The priority of the profile, relative to the other profiles in the campaign
   * @type {number}
   * @memberof Profile
   */
  priority: number;

  /**
   * The name of the profile
   * @type {string}
   * @memberof Profile
   */
  name: string;

  /**
   * The description of the profile
   * @type {string}
   * @memberof Profile
   */
  description: string;

  /**
   * The export key of the profile
   * @type {string}
   * @memberof Profile
   */
  exportKey: string;

  /**
   * Control percentage of profile
   * @type {number | null}
   * @memberof Profile
   */
  controlPercentage: number | null;

  /**
   * User that created the profile
   * @type {string}
   * @memberof Profile
   */
  createdBy: string;

  /**
   * The date that the profile was created
   * @type {string}
   * @memberof Profile
   */
  createdDateTime: string;

  /**
   * User that altered the profile
   * @type {string}
   * @memberof Profile
   */
  lastModifiedBy: string;

  /**
   * The date that the profile was last modified
   * @type {string}
   * @memberof Profile
   */
  lastModifiedDateTime: string;

  /**
   * The control count of the profile
   * @type {number}
   * @memberof Profile
   */
  controlCount: number;

  /**
   * The test count of the profile
   * @type {number}
   * @memberof Profile
   */
  testCount: number;

  /**
   * The the total household count of the profile
   * @type {number}
   * @memberof Profile
   */
  householdCount: number;

  /**
   * The capped subscriber count for the campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  capFixed: number;

  /**
   * The capped subscriber count for the campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  capPercentage: number;

  /**
   * The subscriber cap for the profile
   *
   * @type {number}
   * @memberof Campaign
   */
  subscriberCap: number;

  /**
   * Segment id
   * @type {string}
   * @memberof Profile
   */
  segmentId: string;

  /**
   * Segment name
   * @type {string}
   * @memberof Profile
   */
  segmentName: string;

  /**
   * Boolean if the profile is a root profile or not
   * @type {boolean}
   * @memberof Profile
   */
  isRoot: boolean;

  /**
   * The status id of the profile
   * @type {number}
   * @memberof Profile
   */
  status: number;

  /**
   * The list of test mac addresses for the profile
   * @type {string}
   * @memberof Profile
   */
  testMacAddresses: string;

  /**
   * The profile type id
   * @type {number}
   * @memberof Profile
   */
  profileType: number;

  /**
   * The simplifi category id of the profile
   * @type {SimplifiCategory}
   * @memberof Profile
   */
  simplifiCategory: SimplifiCategory;

  /**
   * The simplifi subcategory id of the profile
   * @type {SimplifiSubcategory}
   * @memberof Profile
   */
  simplifiSubcategory: SimplifiSubcategory;

  /**
   * The simplifi attribute of the profile
   * @type {SimplifiAttribute}
   * @memberof Profile
   */
  simplifiAttribute: SimplifiAttribute;

  /**
   * Boolean if the profile is a data catalog profile or not
   * @type {boolean}
   * @memberof Profile
   */
  inDataCatalog: boolean;

  /**
   * Boolean if the profile is canoe tune in profile or not
   * @type {boolean}
   * @memberof Profile
   */
  isCanoeTuneIn: boolean;

  /**
   * The business_category of the profile
   * @type {string}
   * @memberof Profile
   */
  businessCategory: string;

  /**
   * The simplifi_profile_id of the profile
   * @type {number}
   * @memberof Profile
   */
  simplifiProfileId: number;

  /**
   * The target_audience_id of the profile
   * @type {number}
   * @memberof Profile
   */
  targetAudienceId: number;

  /**
   * The control_audience_id of the profile
   * @type {number}
   * @memberof Profile
   */
  controlAudienceId: number;

  /**
   * The target audience of the profile
   * @type {Audience}
   * @memberof Profile
   */
  targetAudience: Audience;

  /**
   * The control audience of the profile
   * @type {Audience}
   * @memberof Profile
   */
  controlAudience: Audience;

  /**
   * The mrm_id of the profile
   * @type {number}
   * @memberof Profile
   */
  mrmId: number;

  /**
   * The aos_targeting_option_id of the profile
   * @type {number}
   * @memberof Profile
   */
  aosTargetingOptionId: number;
}
