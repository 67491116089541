import {
  Component,
  EventEmitter,
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  Output,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ElementRef
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import flatten from "lodash.flatten";
import { Observable, of, Subject } from "rxjs";
import { debounceTime, map, take, takeUntil } from "rxjs/operators";
import { getTriggerData } from "./util";
import isEqual from "lodash.isequal";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { QueryBuilderService } from "@shared/services/query-builder.service";
import { WorkBook, read as xlsxRead, write as xlsxWrite, utils as xlsxUtils } from "xlsx/dist/xlsx.core.min";
import uniq from "lodash.uniq";
import { SnackbarService } from "@shared/services/snackbar.service";
import { SnackBarStatus } from "@shared/utils/notify";
import { QualifiersService } from "@shared/services/qualifiers.service";

@Component({
  selector: "radr-select-autocomplete",
  templateUrl: "./select-autocomplete.component.html",
  styleUrls: ["./select-autocomplete.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true
    }
  ]
})
export class SelectAutocompleteComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  public filterInputFormControl: FormControl = new FormControl();
  public selectAllTuneInFormControl: FormControl = new FormControl();
  private filters: string[] = [""];
  public nonMatchingTuneInOptions: string[] = [];
  public matchingTuneInOptions: string[] = [];
  public filteredOptions: any[] = [];
  public triggerText: string = "";
  public remainingTriggerText: string = "";
  public triggerTitleText: string = "";
  private _selectedValues: any[] = [];
  public isLoadingOptions: boolean = false;
  public optionsLoaded: boolean = false;
  public optionsDisabled: boolean = false;
  public currentFile: File = null;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() control: FormControl = new FormControl();
  @Input() searchBackend: boolean = false;
  @Input() searchEndpoint: string = null;
  @Input() optionsCache: any;
  @Input() disabled: boolean;
  @Input() customErrorField: string;
  @Input() label: string;
  @Input() multiple: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() placeholder: string = "";
  @Input() required: boolean = false;
  @Input() showActions: boolean = true;
  @Input() multiSelectDisplayMaxLen: number = 10;
  @Input() maxSelectedValuesCount: number = 500;
  @Input() options: any[] = [];
  @Input() loadOptionsOnOpen: boolean = false;
  @Input() getOptionsFunction: () => Observable<string[]>;
  @Input() formFieldAppearance: string;
  @Input() tuneInType: string = null;
  @Input() fileUploaded: boolean = false;
  @ViewChild("selectAutocomplete", { static: true }) selectAutocomplete: MatSelect;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChild("fileInput", { static: false }) fileUploadInput: ElementRef;
  private cleanUp$: Subject<void> = new Subject<void>();
  public matToolTipText: string = `You cannot select more than ${this.maxSelectedValuesCount} items at a time`;
  private _lastClickedIndex: number = 0;

  constructor(
    private cdRef: ChangeDetectorRef,
    private httpClient: HttpClient,
    private queryBuilderService: QueryBuilderService,
    private snackBarService: SnackbarService,
    private qualifierService: QualifiersService
  ) {}

  @Input()
  get value(): any {
    return this.control.value || [];
  }
  set value(val: any) {
    this.control.setValue(val);
    this.selectedValues = val;
  }

  @Input()
  get selectedValues(): any {
    return this._selectedValues || [];
  }
  set selectedValues(val: any) {
    this._selectedValues = val;
    this.control.setValue(this._selectedValues);
    if (this.tuneInType === "Scheduling") {
      if (this.control?.parent?.controls) {
        Object.keys(this.control?.parent?.controls).forEach(control => {
          this.control.parent.get(control).updateValueAndValidity();
        });
      }
    }
    this.triggerText = this.getTriggerText(this._selectedValues, this.multiSelectDisplayMaxLen);
    this.remainingTriggerText = this.getTriggerRemainingText(this._selectedValues, this.multiSelectDisplayMaxLen);
    this.triggerTitleText = this.getTriggerTitleText(this._selectedValues);
  }

  ngOnInit(): void {
    this.getFilteredOptions().subscribe(options => {
      this.filteredOptions = options;
    });
    this.filterInputFormControl.valueChanges
      .pipe(debounceTime(this.searchBackend ? 1800 : 500), takeUntil(this.cleanUp$))
      .subscribe(this.handleFilterChange.bind(this));
    this.selectAllTuneInFormControl.valueChanges
      .pipe(takeUntil(this.cleanUp$))
      .subscribe(this.handleSelectAllTuneInChange.bind(this));
    if (this.selectedValues[0] === "Select All") {
      this.optionsDisabled = true;
      this.selectAllTuneInFormControl.setValue(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && !isEqual(changes.options.currentValue, changes.options.previousValue)) {
      this.getFilteredOptions().subscribe(options => {
        this.filteredOptions = options;
      });
    }
  }

  ngOnDestroy(): void {
    this.cleanUp$.next();
    this.cleanUp$.complete();
  }

  get selectAllTuneIn(): boolean {
    return !!this.tuneInType && this.tuneInType !== "Scheduling";
  }

  async parseFileAndUpdateInputs(event: any): Promise<void> {
    this.currentFile = event.target.files[0];
    if (
      !this.currentFile.name.endsWith("csv") &&
      !this.currentFile.name.endsWith("xlsx") &&
      !this.currentFile.name.endsWith("xls")
    ) {
      this.snackBarService.open("Invalid file format, must be csv, xlsx, or xls", null, 5000, SnackBarStatus.Error);
      return;
    }
    const file: ArrayBuffer = await this.currentFile.arrayBuffer();
    const parsedWorkbook: WorkBook = xlsxRead(file, {
      type: "array",
      raw: true,
      cellNF: true,
      cellDates: true,
      cellFormula: true,
      cellHTML: true,
      cellStyles: true,
      cellText: true
    });
    const allValues: string[] = [];
    const sheetName: string = parsedWorkbook.SheetNames[0];
    Object.keys(parsedWorkbook.Sheets[sheetName]).forEach(w => {
      if (w.includes("A")) {
        if (this.tuneInType === "Program") {
          allValues.push(parsedWorkbook.Sheets[sheetName][w].v);
        } else {
          allValues.push(parsedWorkbook.Sheets[sheetName][w].v.toUpperCase());
        }
      }
    });

    this.checkForMissingValues(allValues);
  }

  checkForMissingValues(fileValues: string[]): void {
    this.qualifierService
      .verifyTuneInValues(fileValues, this.tuneInType)
      .pipe(take(1))
      .subscribe((response: { nonMatchingTuneInOptions: string[]; matchingTuneInOptions: string[] }) => {
        this.selectedValues = uniq([...this.selectedValues, ...response.matchingTuneInOptions]);
        if (response.nonMatchingTuneInOptions.length) {
          this.snackBarService.open(
            "One or more errors has been detected. Unable to find " + response.nonMatchingTuneInOptions.join(","),
            null,
            10000,
            SnackBarStatus.Error
          );
        } else {
          this.snackBarService.open("Upload Successful", null, 5000, SnackBarStatus.Success);
        }
      });
  }

  openFilePicker(): void {
    this.fileUploadInput.nativeElement.value = null;
    this.fileUploadInput.nativeElement.click();
  }

  openedChange(opened: boolean): void {
    if (!opened) {
      this.selectedValues = this.selectedValues.slice(0, this.maxSelectedValuesCount);
      this.value = this.selectedValues;
      this.selectionChange.emit(this.selectedValues);
      this.clearFilterText();
      this.scrollViewport.setRenderedContentOffset(0);
      this.scrollViewport.setRenderedRange({ start: 0, end: 31 });
      this.cdRef.detectChanges();
    } else {
      if (this.loadOptionsOnOpen && !this.optionsLoaded) {
        this.isLoadingOptions = true;
        this.getOptionsFunction().subscribe(options => {
          this.options = options;
          this.handleFilterChange(this.filterInputFormControl.value || "");
          this.isLoadingOptions = false;
          this.optionsLoaded = true;
        });
      }
    }
  }

  optionClicked(event: any): void {
    if (event.isUserInput) {
      this.selectedValues = event.source.selected
        ? [...this.selectedValues, event.source.value]
        : this.selectedValues.filter(value => value !== event.source.value);
    }
  }

  shiftClicked(event: any, matOption: any): void {
    if (event.shiftKey && this._lastClickedIndex !== null) {
      const selectedIndex: number = this.filteredOptions.indexOf(matOption.value) + 1;
      if (this._lastClickedIndex < selectedIndex) {
        this._addItemsToSelected(this._lastClickedIndex, selectedIndex);
      } else {
        this._addItemsToSelected(selectedIndex, this._lastClickedIndex);
      }
    } else if (matOption.selected) {
      this._lastClickedIndex = this.filteredOptions.indexOf(matOption.value);
    } else {
      this._lastClickedIndex = null;
    }
  }

  private _addItemsToSelected(indexStart: number, indexEnd: number): void {
    this.selectedValues = uniq([...this.selectedValues, ...this.filteredOptions.slice(indexStart, indexEnd)]);
  }

  getFilteredOptions(): Observable<any[]> {
    if (this.searchBackend) {
      this.isLoadingOptions = true;

      if (this.queryBuilderService.getCachedOptions(this.optionsCache)?.length && !this.filters.join("").length) {
        this.isLoadingOptions = false;
        return of([
          ...this.selectedValues.filter(option =>
            !!this.selectAllTuneInFormControl.value ? option : option !== "Select All"
          ),
          ...this.queryBuilderService
            .getCachedOptions(this.optionsCache)
            .filter(item => !this.selectedValues.includes(item))
        ]);
      } else {
        const getUrl: string = this.searchEndpoint.includes("searchFilter")
          ? `${this.searchEndpoint}${this.filters[0]}`
          : `${this.searchEndpoint}&searchFilter=${this.filters[0]}`;
        return this.httpClient.get<any[]>(`${environment.api_domain}/${getUrl}`).pipe(
          map(options => {
            this.isLoadingOptions = false;
            // if (!this.filters.join("").length) {
            //   this.queryBuilderService.setCachedOptions(this.optionsCache, options);
            // }
            if (options?.length) {
              if (this.fileUploaded) {
                this.checkForMissingValues(this.selectedValues);
              }
              return [
                ...this.selectedValues.filter(option =>
                  !!this.selectAllTuneInFormControl.value ? option : option !== "Select All"
                ),
                ...options.filter(item => !this.selectedValues.includes(item))
              ];
            } else {
              return [
                ...this.selectedValues.filter(option =>
                  !!this.selectAllTuneInFormControl.value ? option : option !== "Select All"
                )
              ];
            }
          })
        );
      }
    } else {
      const filteredOptions: any[] = [
        ...this.selectedValues,
        ...this.options.filter(item => !this.selectedValues.includes(item))
      ].filter(option => this.filters.some(filter => option.toString().toLowerCase().includes(filter.toLowerCase())));
      return of(filteredOptions);
    }
  }

  handleFilterChange(filterText: string): void {
    let filters: string[] = filterText
      .split(",")
      .filter(filter => !!filter)
      .map(filter => filter.trim());
    if (filters.length === 0) {
      filters = [""];
    }
    this.filters = filters;
    this.getFilteredOptions().subscribe(options => {
      this.filteredOptions = options;
    });
  }

  handleSelectAllTuneInChange(selectAll: boolean): void {
    if (selectAll) {
      this.selectedValues = ["Select All"];
      this.filteredOptions = ["Select All", ...this.filteredOptions];
      this.optionsDisabled = true;
    } else {
      this.handleClearClick();
      this.filteredOptions = this.filteredOptions.filter(option => option !== "Select All");
      this.optionsDisabled = false;
    }
  }

  handleApplyClick(): void {
    this.selectAutocomplete.close();
  }

  public isClearEnabled(): boolean {
    if (
      this.selectAllTuneInFormControl.value ||
      (Array.isArray(this.selectedValues) && this.selectedValues.length === 0)
    ) {
      return false;
    }
    return !!this.selectedValues?.length ?? false;
  }

  clearFilterText(): void {
    this.filterInputFormControl.setValue("");
  }

  handleClearClick(): void {
    this.getFilteredOptions().subscribe(options => {
      this.selectedValues = this.selectedValues.filter(value => !this.filteredOptions.includes(value));
    });
  }

  public isSelectAllDisabled(): boolean {
    return (this.filteredOptions || []).length > this.maxSelectedValuesCount;
  }

  handleSelectAllClick(): void {
    this.selectedValues = [
      ...this.selectedValues,
      ...this.filteredOptions.filter(option => !this.selectedValues.includes(option))
    ];
  }

  handleInputKey(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  getTriggerText(selectedValues: any[], multiSelectDisplayMaxLen: number): string {
    return getTriggerData(selectedValues, multiSelectDisplayMaxLen).text;
  }

  getTriggerRemainingText(selectedValues: any[], multiSelectDisplayMaxLen: number): string {
    const { remaining } = getTriggerData(selectedValues, multiSelectDisplayMaxLen);
    return remaining ? ` (+${remaining} item${remaining > 1 ? "s" : ""})` : "";
  }

  getTriggerTitleText(selectedValues: any[]): string {
    if (selectedValues) {
      return flatten([selectedValues]).join(", ");
    }
    return "";
  }

  getPlaceholderText(): string {
    return this.isLoadingOptions ? "Loading..." : this.placeholder;
  }

  public onChange(value: any): void {}

  public onTouched(): void {}

  public writeValue(value: any[]): void {
    this.onChange(value);
  }

  public registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
