import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, UrlSegment } from "@angular/router";
import flatMap from "lodash.flatmap";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LoadingService implements OnDestroy {
  private urlSource$: BehaviorSubject<UrlSegment[]> = new BehaviorSubject<UrlSegment[]>(null);
  private isLoadingMap: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();
  private cleanUp$: Subject<void> = new Subject<void>();

  constructor(private route?: ActivatedRoute) {
    if (route) {
      route.url.pipe(takeUntil(this.cleanUp$)).subscribe(url => {
        this.urlSource$.next(url);
        this.isLoadingMap.set(this.getUrlKey(), new BehaviorSubject<boolean>(false));
      });
    }
  }

  ngOnDestroy(): void {
    this.cleanUp$.next();
    this.cleanUp$.complete();
  }

  private getUrlKey(): string {
    return flatMap(this.urlSource$.getValue(), (urlSegment: UrlSegment) => urlSegment.toString()).join("");
  }

  public setLoading(val?: boolean): void {
    this.isLoadingMap.get(this.getUrlKey()).next(typeof val === "undefined" ? true : val);
  }

  public get isLoading$(): Observable<boolean> {
    const isLoadingBehaviorSubject: BehaviorSubject<boolean> = this.isLoadingMap.get(this.getUrlKey());
    if (isLoadingBehaviorSubject) {
      return isLoadingBehaviorSubject.asObservable();
    }
    return of(false);
  }
}
