import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import notify, { SnackBarStatus, SnackBarNotifyOptions } from "./notify";

export const closeDialogAndNotify = (
  dialog: MatDialog,
  notifyFn?: (snackBar: MatSnackBar, alertMessage: string, options?: SnackBarNotifyOptions) => void,
  snackBar?: MatSnackBar,
  alertMessage?: string,
  snackBarType?: SnackBarStatus
): void => {
  dialog.closeAll();
  if (notifyFn && snackBar && alertMessage) {
    notifyFn(snackBar, alertMessage, { snackBarType });
  }
};

const bootstrappedCloseDialogAndNotify: (
  dialog: MatDialog,
  snackBar?: MatSnackBar,
  alertMessage?: string,
  snackBarType?: SnackBarStatus
) => void = (dialog, snackBar, alertMessage, snackBarType) => {
  closeDialogAndNotify(dialog, notify, snackBar, alertMessage, snackBarType);
};

export default bootstrappedCloseDialogAndNotify;
