<div [class]="isProfileBuilder || isSimplifi || isAudienceBuilder ? 'scrollable-form' : ''">
  <div [class]="class + '-form' + ' pop-up-container'">
    <div><ng-content select="[popupContent]"></ng-content></div>
  </div>
  <div *ngIf="!compact; else compactActions" class="actions">
    <button mat-flat-button class="action-button edit-popup-cancel" data-test="edit-popup-cancel" (click)="onCancel()">
      Cancel
    </button>
    <button
      mat-flat-button
      class="action-button edit-popup-submit"
      data-test="edit-popup-submit"
      [disabled]="!isSubmitEnabled"
      (click)="onSubmit()"
    >
      Apply
    </button>
  </div>

  <ng-template #compactActions class="compact-actions">
    <button
      mat-icon-button
      class="edit-popup-submit"
      [disabled]="!isSubmitEnabled"
      (click)="onSubmit()"
      id="edit-popup-submit-button"
    >
      <mat-icon>check</mat-icon>
    </button>
    <button mat-icon-button class="edit-popup-cancel" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </ng-template>
</div>
