/**
 * Represents the view model for a file in RADR
 *
 * @export
 * @interface DataSource
 */
import { FileStatus } from "./file-status.model";
import { Qualifier } from "./qualifier.model";
import { DataProvider } from "./data-provider.model";

export class DataSource {
  /**
   * The id of the data source
   *
   * @type {number}
   * @memberof DataSource
   */
  id: number;
  /**
   * The name of the data source
   *
   * @type {string}
   * @memberof DataSource
   */
  name: string;

  /**
   * The description of the data source
   *
   * @type {string}
   * @memberof DataSource
   */
  description: string;
  /**
   * The number of rows in the data source
   *
   * @type {number}
   * @memberof DataSource
   */
  rowCount: number;
  /**
   * The number of columns in the data source
   *
   * @type {number}
   * @memberof DataSource
   */
  columnCount: number;
  /**
   * The current status of the data source
   *
   * @type {FileStatusModel}
   * @memberof DataSource
   */
  status: FileStatus;
  /**
   * The number of subscribers in the data source
   *
   * @type {number}
   * @memberof DataSource
   */
  subscriberCount: number;
  /**
   * What column the hhid is
   * @type {string}
   * @memberof DataSource
   */
  householdIdColumn: string;
  /**
   * What type of identifier the file uses
   *
   * @type {DataSourceIdTypes}
   * @memberof DataSource
   */
  idType: string;
  /**
   * What row the data begins on (if file has a header row, start row would be 2)
   *
   * @type {number}
   * @memberof DataSource
   */
  dataStartRow: number;
  /**
   * The user who uploaded the data source
   *
   * @type {string}
   * @memberof DataSource
   */
  uploadedBy: string;
  /**
   * The date that the data source was ingested
   *
   * @type {string}
   * @memberof DataSource
   */
  ingestedDateTime: string;
  /**
   * The user who last modified the data source
   *
   * @type {string}
   * @memberof DataSource
   */
  lastModifiedBy: string;
  /**
   * The date that the data source was last modified
   *
   * @type {string}
   * @memberof DataSource
   */
  lastModifiedDateTime: string;
  /**
   * The id of the data provider for the data source
   *
   * @type {number}
   * @memberof DataSource
   */
  dataProvider: DataProvider;
  /**
   * The qualifiers associated with the datasource
   *
   * @type {Qualifier}
   * @memberof DataSource
   */
  qualifiers: Qualifier[];

  /**
   * The data source is currently hidden from general view.
   * @type {boolean}
   * @memberof DataSource
   */
  isHidden: boolean;

  /**
   * Represents column that stores segment id.
   * @type {number}
   * @memberof DataSource
   */
  segmentIdColumn: number;

  /**
   * Represents column that stores segment name.
   * @type {number}
   * @memberof DataSource
   */
  segmentNameColumn: number;

  /**
   * Canoe Campaign Name, if Canoe Data Source
   *
   * @type {string}
   * @memberof DataSource
   */
  canoeCampaignName: string;

  /**
   * Canoe Campaign Id, if Canoe Data Source
   *
   * @type {string}
   * @memberof DataSource
   */
  canoeCampaignId: string;
}
