import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ProfileNotFoundError } from "@shared/models";
import { ProfileViewModel } from "radr-shared";

@Component({
  selector: "radr-qualifier-profile-list-modal",
  templateUrl: "./qualifier-profile-list-modal.component.html",
  styleUrls: ["./qualifier-profile-list-modal.component.scss"]
})
export class QualfierProfileListModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { profiles: ProfileViewModel[]; type: string },
    private router: Router,
    private dialogRef: MatDialogRef<QualfierProfileListModalComponent>
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  goToProfile(profile: ProfileViewModel): void {
    if (!profile) {
      throw new ProfileNotFoundError(
        "Attempted to edit a non-existent profile. That isn't going to work very well.",
        "QualfierProfileListModalComponent.goToProfile"
      );
    }
    if (profile?.campaignId) {
      this.router.navigate(["campaigns/builder"], { queryParams: { campaignId: profile.campaignId } });
    } else {
      this.router.navigate(["profiles/builder"], { queryParams: { profileId: profile.id } });
    }

    this.closeModal();
  }
}
