import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  AudienceQueryBuilderComponent,
  QueryInputDirective,
  QueryFieldDirective,
  QueryEntityDirective,
  QueryOperatorDirective,
  QueryButtonGroupDirective,
  QuerySwitchGroupDirective,
  QueryRemoveButtonDirective,
  QueryEmptyWarningDirective,
  QueryArrowIconDirective,
  QueryHeaderDirective
} from "./components";
import { SharedModule } from "../shared.module";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatDatepickerModule
  ],
  declarations: [
    QueryInputDirective,
    QueryOperatorDirective,
    QueryFieldDirective,
    QueryEntityDirective,
    QueryButtonGroupDirective,
    QuerySwitchGroupDirective,
    QueryRemoveButtonDirective,
    QueryEmptyWarningDirective,
    QueryArrowIconDirective,
    QueryHeaderDirective,
    AudienceQueryBuilderComponent
  ],
  exports: [
    QueryInputDirective,
    QueryOperatorDirective,
    QueryFieldDirective,
    QueryEntityDirective,
    QueryButtonGroupDirective,
    QuerySwitchGroupDirective,
    QueryRemoveButtonDirective,
    QueryEmptyWarningDirective,
    QueryArrowIconDirective,
    QueryHeaderDirective,
    MatDatepickerModule,
    AudienceQueryBuilderComponent
  ]
})
export class AudienceQueryBuilderModule {}
