import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { RoleAccessService } from "@shared/services/role-access.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private roleAccessService: RoleAccessService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAccess();
  }

  checkAccess(): boolean {
    if (this.roleAccessService.isLimitedAccess()) {
      this.router.navigate(["/profiles"]);
      return false;
    }
    return true;
  }
}
