<ng-container *ngIf="items?.length > 0; else noItems">
  <radr-data-provider-list
    [tree]="items"
    [type]="type"
    [disabled]="!roleAccessService.canEditEntity(campaign)"
  ></radr-data-provider-list>
</ng-container>
<ng-template #noItems>
  <ul class="no-record-text">
    Sorry, we couldn't find any records for "{{
      filterText
    }}"
  </ul>
</ng-template>
