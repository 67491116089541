<radr-modal toolbarColor="warn" [warningIcon]="true" [closeDisabled]="true">
  <ng-container body>
    <div mat-dialog-content>
      <div class="mat-dialog-content-text">
        <div *ngIf="timedOut; else sessionCountdown">
          <p class="context-copy">You have been logged out due to inactivity</p>
          <p class="context-copy">Any unsaved changes may have been lost</p>
          <p class="context-copy"><strong>Please refresh the page to login again</strong></p>
        </div>
      </div>
      <ng-template #sessionCountdown>
        <mat-icon class="session-timeout-icon">timer</mat-icon>
        <p *ngIf="remainingTime$ | async as remainingTime" class="context-copy">You will be logged out in</p>
        <p *ngIf="remainingTime$ | async as remainingTime" class="remaining-time">
          {{ remainingTime }}
        </p>
        <p class="context-copy">Any unsaved changes may be lost</p>
      </ng-template>
    </div>
  </ng-container>

  <ng-container actions>
    <button
      id="session-timeout-refresh"
      mat-button
      mat-flat-button
      (click)="okClicked()"
      color="warn"
      [disabled]="timedOut"
    >
      {{ timedOut ? "Please refresh to login" : "I'm still here!" }}
    </button>
  </ng-container>
</radr-modal>
