import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { Audience, ProfileViewModel } from "radr-shared";
import MasterDetailService from "../lib/master-detail.service";
import { RuleSet } from "@shared/components/angular-query-builder";
import { CampaignBuilderModes } from "@shared/enums/campaign-builder-mode.enum";
import { QualifiersService } from "./qualifiers.service";
import { Qualifier } from "radr-shared/src/models";
import { TopicsViewModel } from "radr-shared/src/view-models/topics.viewmodel";
import { SnackBarStatus } from "@shared/utils/notify";
import { saveAs } from "file-saver";
import { ClientError, EmptyTuneInQuery } from "@shared/models";

@Injectable({
  providedIn: "root"
})
export class ProfilesService extends MasterDetailService<ProfileViewModel> {
  readonly profileList$: Observable<ProfileViewModel[]> = this.masterListSource$.asObservable();
  readonly selectedProfile$: Observable<ProfileViewModel> = this.selectedItemSource$.asObservable();
  public isLoadingAllProfiles: boolean = false;
  public addQualifierToProfile: Subject<{ qualifier: Qualifier; profiles: any[] }> = new Subject();
  public addAudienceToProfile: Subject<{ audience: Audience; profiles: any[] }> = new Subject();

  constructor(private qualifierService: QualifiersService) {
    super("profiles", false, ProfileViewModel);
  }

  getProfiles(): Observable<ProfileViewModel[]> {
    this.isLoadingAllProfiles = true;
    return super.updateMasterList().pipe(
      tap(() => {
        this.isLoadingAllProfiles = false;
      })
    );
  }

  getProfilesByQualifierId(
    qualifierId: string,
    includeDefaultProfile: boolean = false
  ): Observable<ProfileViewModel[]> {
    return this.getMany<ProfileViewModel>(
      `${this.route}?qualifierId=${qualifierId}&includeDefaultProfile=${includeDefaultProfile}`
    );
  }

  saveProfiles(profiles: ProfileViewModel[]): Observable<ProfileViewModel[]> {
    return this.put(`${this.route}`, profiles).pipe(
      map((savedProfiles: ProfileViewModel[]) => {
        return savedProfiles.map(savedProfile => new ProfileViewModel(savedProfile));
      })
    );
  }

  deleteProfile(profileId: number): Observable<any> {
    return this.delete(`${this.route}/${profileId}`);
  }

  patchProfile(profileId: number, profile: Partial<ProfileViewModel>): Observable<any> {
    return this.patch(`${this.route}/${profileId}`, { ...profile, id: profileId });
  }

  getProfile(id: number): Observable<ProfileViewModel> {
    return this.getOne<ProfileViewModel>(`${this.route}/${id}`);
  }

  getProfileBuilderViewModel(id: number): Observable<ProfileViewModel> {
    return this.getOne<ProfileViewModel>(`${this.route}/builder-viewmodel/${id}`);
  }

  isProfileNameUnique(name: string): Observable<boolean> {
    return this.getOne(`${this.route}/name/${encodeURIComponent(name.trim())}`, undefined, false).pipe(
      map(profile => !profile)
    );
  }

  areAllProfileNamesUnique(profileNames: string[]): Observable<string[]> {
    return this.post(`${this.route}/unique-names`, profileNames);
  }

  isProfileExportKeyUnique(exportKey: string): Observable<boolean> {
    return this.getOne(`${this.route}/exportKey/${encodeURIComponent(exportKey.trim())}`, undefined, false).pipe(
      map(profile => !profile)
    );
  }

  getProfileExportKeyByGroupNameAndPriority(groupName: string, priority: number): string {
    return groupName + String(priority).padStart(2, "0");
  }

  getCurrentProfileErrors(
    p: ProfileViewModel,
    mode: CampaignBuilderModes = CampaignBuilderModes.CampaignBuilder
  ): string {
    let errorMessage: string = "";
    if (mode === CampaignBuilderModes.CampaignBuilder) {
      errorMessage = !p.name ? "Profiles must have a name before a campaign can be saved." : "";
      if (!this.hasValidSegmentId(p)) {
        errorMessage = "Profiles with Canoe qualifiers must have Segment Id set in Details";
      }
    } else {
      if (!p.name) {
        errorMessage = "Profiles cannot be saved unless each profile has a unique name.";
      }
      if (!this.tuneInQualifierDatesAreValid(p)) {
        errorMessage = "At least one Tune-In qualifier has invalid date value(s)";
      }
      if (!this.everyQualifierInProfileIsComplete(p)) {
        errorMessage = "Every qualifier must have a complete qualifier expression";
      }
      if (!p.hasMinimumQualifiers) {
        errorMessage = "Profiles cannot be saved unless all profiles have at least 1 qualifier";
      }
      // This error should never happen in profile builder mode.
      // It should ALWAYS be last in this list.
      if (p.isDefault) {
        errorMessage = "Default profiles are prohibited in the profile builder.";
      }
    }

    return errorMessage;
  }

  everyQualifierInProfileIsComplete(profile: ProfileViewModel): boolean {
    const definition: RuleSet = profile?.definition as RuleSet;
    if (profile.priority > 0) {
      return this.qualifierService.isRuleSetComplete(definition);
    } else {
      return this.qualifierService.isRuleSetComplete(definition, true);
    }
  }

  tuneInQualifierDatesAreValid(profile: ProfileViewModel): boolean {
    const definition: RuleSet = profile?.definition as RuleSet;
    return !this.qualifierService.isTuneInRuleSetDateValid(definition);
  }

  hasValidSegmentId(profile: ProfileViewModel): boolean {
    const hasSegmentIds: boolean = this.qualifierService.getUniqueSegmentIdsFromRuleSet(profile.definition).length > 0;
    if (!hasSegmentIds) {
      return true;
    } else {
      return !!profile.segmentId;
    }
  }

  getTopicsNewProfile(): Observable<TopicsViewModel[]> {
    return this.getMany<TopicsViewModel>(`${this.route}/topics`);
  }

  getProfileEntityTags(profileId: number): Observable<string[]> {
    return this.getOne(`${this.route}/entity-tags/${profileId}`).pipe(
      map((target: any) => {
        const entity = target[0];
        if (!entity) {
          return [];
        }
        return entity.tags;
      })
    );
  }

  generateProfileReport(profiles: ProfileViewModel[], fromBuilder: boolean): void {
    this.loadingService.setLoading(true);
    this.post(`${this.route}/report?fromBuilder=${fromBuilder}`, profiles)
      .pipe(take(1))
      .subscribe(
        async ({ b64Array }) => {
          for (const b of b64Array) {
            const blob: Blob = this.b64toBlob(
              b.b64,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
            const currentDateTimeForFileName: string = new Date()
              .toLocaleString()
              .replace(/[^0-9]/gi, "")
              .toLowerCase();

            const filename: string = `${b.name} ${currentDateTimeForFileName}.xlsx`;

            saveAs(blob, filename);
          }
          this.snackbarService.open(
            `Reports Successfully Generated for Profiles ${b64Array.map(b => b.name).join(",")}`,
            null,
            5000,
            SnackBarStatus.Success
          );
        },
        () => {
          this.snackbarService.open(
            `Reports Failed to Generate for Profiles ${profiles.map(p => p.name).join(",")}`,
            "OK",
            10000,
            SnackBarStatus.Error
          );
          this.loadingService.setLoading(false);
        },
        () => {
          this.loadingService.setLoading(false);
        }
      );
  }

  generateQueryReport(profiles: ProfileViewModel[]): void {
    this.loadingService.setLoading(true);
    this.post(`${this.route}/queryReport`, profiles)
      .pipe(take(1))
      .subscribe(
        async (b64Array: any[]) => {
          for (const b of b64Array) {
            const blob: Blob = this.b64toBlob(
              b.b64,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
            const currentDateTimeForFileName: string = new Date()
              .toLocaleString()
              .replace(/[^0-9]/gi, "")
              .toLowerCase();

            const filename: string = `${b.name} ${currentDateTimeForFileName}.xlsx`;

            saveAs(blob, filename);
          }
          this.snackbarService.open(
            `Reports Successfully Generated for ${b64Array.map(b => b.name).join(",")}`,
            null,
            5000,
            SnackBarStatus.Success
          );
        },
        (error: ClientError) => {
          if (error instanceof EmptyTuneInQuery) {
            this.snackbarService.open(
              `No matching results found for your Tune-In report query. Please revise your query parameters and try again.`,
              "OK",
              10000,
              SnackBarStatus.Error
            );
          } else {
            this.snackbarService.open(
              `Reports Failed to Generate for Profiles ${profiles.map(p => p.name).join(",")}`,
              "OK",
              10000,
              SnackBarStatus.Error
            );
          }
        }
      );
    this.loadingService.setLoading(false);
  }

  b64toBlob(b64Data: string, contentType: string = "", sliceSize: number = 512): Blob {
    const byteCharacters: string = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice: string = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers: any[] = new Array(slice.length);
      for (let i: number = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray: Uint8Array = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob: Blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
