import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dashify"
})
export class DashifyPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (value && value.toLowerCase()) {
      return value.toLowerCase().replace(/\s/g, "-");
    }
    return value;
  }
}
