<radr-modal toolbarColor="primary">
  <ng-container body>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-progress-spinner class="spinner"></mat-progress-spinner>
    </div>
    <div class="audience-count-body" *ngIf="audience">
      <div class="audience-count-header">
        <span class="audience-header-field" *ngIf="audience.audienceName">{{ audience.audienceName }}</span>
        <span class="divider" *ngIf="audience.description"> | </span>
        <radr-text-overflow
          class="audience-header-field"
          [displayText]="audience.description"
          *ngIf="audience.description"
        >
        </radr-text-overflow>
        <!-- <div *ngIf="audience.matchedCount"> -->
        <!-- TODO: Update once we have counts populating in postgres table -->
        <div>
          <span class="divider"> | </span>
          <span class="audience-header-field">MATCHED HOUSEHOLD COUNT Target:</span>
        </div>
      </div>
      <div class="audience-count-header">
        <span class="audience-header-field">Date Created: </span>
        <span>{{ audience.createdDateTime | date: "shortDate" }}</span>
        <!-- <span class="audience-header-field">Date Updated: </span>
        <span>{{ audience.lastModifiedDateTime | date: "shortDate" }}</span> -->
        <!-- TODO: Update once we have counts populating in postgres table -->
      </div>

      <!-- <ng-container *ngIf="audienceValueCounts"> -->
      <!-- TODO: Update once we have counts populating in postgres table -->
      <ng-container>
        <cdk-virtual-scroll-viewport
          [itemSize]="70"
          [minBufferPx]="70 * 1"
          [maxBufferPx]="70 * 20"
          [ngStyle]="{ 'height.px': audienceValueCounts?.length < 10 ? (audienceValueCounts?.length + 1) * 70 : 250 }"
        >
          <div class="table">
            <div class="table-row header">
              <div class="text">
                <span>Audience Details</span>
              </div>
              <div class="text">
                <span>Counts</span>
              </div>
            </div>
            <div
              class="table-row content"
              *cdkVirtualFor="let audienceValueCount of audienceValueCounts; templateCacheSize: 100"
            >
              <div class="text">
                <span>{{ audienceValueCount.value }}</span>
              </div>
              <div class="text">
                <span>{{ audienceValueCount.count }}</span>
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </div>
  </ng-container>
  <ng-container actions *ngIf="!!audience && !isLoading">
    <div class="add-audience-field">
      <span class="add-text">Add to New Profile/Profiles:</span>
      <div>
        <radr-select-autocomplete
          [options]="buildSelectAutocompleteOptions()"
          [showActions]="false"
          [control]="audienceSelection"
          placeholder="Select"
        >
        </radr-select-autocomplete>
        <button
          color="primary"
          [disabled]="!canAddAudience()"
          mat-flat-button
          (click)="addAudienceToProfiles(audienceSelection.value)"
        >
          Add
        </button>
      </div>
    </div>
    <!-- TODO: Do we need this button if we aren't looking at audience status? -->
    <!-- TODO: Qualifier Details Modal shows button if status !== processed -->
    <!-- <button color="primary" mat-flat-button (click)="close()">Close</button> -->
  </ng-container>
</radr-modal>
