import { Component } from "@angular/core";

@Component({
  selector: "radr-component-loader",
  templateUrl: "./component-loader.component.html",
  styleUrls: ["./component-loader.component.scss"]
})
export class ComponentLoaderComponent {
  constructor() {}
}
