<ng-container>
  <mat-error id="radr-form-error-name-required-error" *ngIf="control.hasError('required')">
    You must specify a value.
  </mat-error>
  <mat-error *ngIf="control.hasError('startsWithAlphaNumeric')"> Must start with an alphanumeric character. </mat-error>
  <mat-error *ngIf="control.hasError('alphanumeric')"> Must contain only alphanumeric characters. </mat-error>
  <mat-error id="radr-form-error-forbiddenName-error" *ngIf="control.hasError('forbiddenName')">
    This name is already in use. Please use another.
  </mat-error>
  <mat-error id="radr-form-error-max-value-error" *ngIf="control.hasError('max')">
    Value cannot be greater than {{ control.getError("max").max }}
  </mat-error>
  <mat-error id="radr-form-error-min-value-error" *ngIf="control.hasError('min')">
    Value cannot be less than {{ control.getError("min").min }}
  </mat-error>
  <mat-error id="radr-form-error-max-length-error" *ngIf="control.hasError('maxlength')">
    This field must be {{ control.getError("maxlength").requiredLength }} characters or fewer.
  </mat-error>
  <mat-error id="radr-form-error-forbidden-spaces" *ngIf="control.hasError('forbiddenSpaces')">
    Value cannot contain spaces.
  </mat-error>
  <mat-error id="radr-form-error-date-format" *ngIf="control.hasError('matDatepickerParse')">
    Date Invalid: mm/dd/yyyy
  </mat-error>
  <mat-error id="radr-form-error-date-format" *ngIf="control.hasError('matDatepickerMax')">
    Start date must be before end date
  </mat-error>
  <mat-error id="radr-form-error-date-format" *ngIf="control.hasError('matDatepickerMin')">
    End date must be after start date
  </mat-error>
  <mat-error id="radr-form-error-email" *ngIf="control.hasError('multipleEmails')">
    Please enter a valid list of email addresses separated by a comma.
  </mat-error>
</ng-container>
