import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { TableConfigModel } from "@shared/models";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ProfilesService } from "@shared/services/profiles.service";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { RoleAccessService } from "@shared/services/role-access.service";
import { CampaignViewModel } from "radr-shared";
import { Qualifier, QualifierValueCounts, QualifierStatusEnum } from "radr-shared";

@Component({
  selector: "radr-qualifier-details-modal",
  templateUrl: "./qualifier-details-modal.component.html",
  styleUrls: ["./qualifier-details-modal.component.scss"]
})
export class QualifierDetailsModalComponent implements OnInit {
  public isLoading: boolean = false;
  public qualifier: Qualifier;
  public qualifierValueCounts: QualifierValueCounts[];
  public qualifierDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public qualifierDataSourceTableConfig: TableConfigModel;
  public campaign: CampaignViewModel;
  public qualifierSelection: FormControl = new FormControl();
  public availableProfiles: any[] = [];
  public QualifierStatusEnum: typeof QualifierStatusEnum = QualifierStatusEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<QualifierDetailsModalComponent>,
    private qualifiersService: QualifiersService,
    private campaignBuilderService: CampaignBuilderService,
    private profilesService: ProfilesService,
    private roleAccessService: RoleAccessService
  ) {}

  ngOnInit(): void {
    this.setTableConfig();
    this.getQualifierDetailsAndValueCounts(this.data.qualifier?.id);
    this.loadAvailableProfiles();
  }

  canAddQualifier(): boolean {
    const hasValue: boolean = this.qualifierSelection.value && this.qualifierSelection.value.length !== 0;
    if (this.roleAccessService.isAdmin()) {
      return this.campaign.canEdit && hasValue;
    } else {
      return this.campaign.canEdit && hasValue && this.roleAccessService.isEditor(this.campaign);
    }
  }

  setTableConfig(): void {
    this.qualifierDataSourceTableConfig = {
      headerTopBorder: true,
      isSortable: true,
      displayColumns: ["value", "count"],
      columns: [
        {
          name: "value",
          title: "Qualifier Details",
          field: "value",
          width: "50%"
        },
        {
          name: "count",
          title: "Counts",
          field: "count",
          width: "50%"
        }
      ]
    };
  }

  getQualifierDetailsAndValueCounts(id: number): void {
    this.isLoading = true;
    this.qualifiersService.getQualifier(id).subscribe(qualifier => {
      this.qualifier = qualifier;
    });
    this.qualifiersService.getQualifierValueCounts(id).subscribe(qualifierValueCounts => {
      if (qualifierValueCounts.length) {
        this.qualifierValueCounts = qualifierValueCounts;
        this.setDataSource();
      }
      this.isLoading = false;
    });
  }

  setDataSource(): void {
    this.qualifierDataSource.data = this.qualifierValueCounts;
  }

  loadAvailableProfiles(): void {
    this.campaign = this.campaignBuilderService.getSelectedItem();
    this.availableProfiles = this.campaign.profiles.slice();
    this.availableProfiles.push({ name: "New Profile" });
    if (this.data.qualifier.qualifierTypeId === 2) {
      this.availableProfiles = this.availableProfiles.filter(profile => {
        return profile.profileType === 2;
      });
    }
  }

  buildSelectAutocompleteOptions(): string[] {
    return this.availableProfiles.map((profile, i) => profile.name || `Unnamed Profile ${i + 1}`);
  }

  addQualifierToProfiles(selectedProfiles: string[]): void {
    if (selectedProfiles.includes("New Profile")) {
      this.campaignBuilderService.addNewProfile(null, null, true);
    }

    const profilesToAlter: any[] = selectedProfiles.map(selection => {
      if (selection === "New Profile") {
        return this.campaign.profiles[this.campaign.profiles.length - 1];
      }

      return this.campaign.profiles.find((profile, i) => {
        return profile.name === selection || selection === `Unnamed Profile ${i + 1}`;
      });
    });

    if (this.qualifier.dataSource?.canoeCampaignName && this.qualifier.dataSource?.canoeCampaignId) {
      this.campaignBuilderService.setCanoeCampaignControlValues(
        this.qualifier.dataSource?.canoeCampaignName,
        this.qualifier.dataSource?.canoeCampaignId
      );
    }

    setTimeout(() => this.pushNewQualifierToProfiles(profilesToAlter), 1);
    this.close();
  }

  pushNewQualifierToProfiles(profilesToAlter: string[]): void {
    this.profilesService.addQualifierToProfile.next({ qualifier: this.qualifier, profiles: profilesToAlter });
  }

  close(): void {
    this.dialogRef.close();
  }
}
