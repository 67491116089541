import { Campaign, QualifierCountRequest, Universe } from "../models";

export class QualifierCountRequestFactory {
  public static create(rules: any, campaign: Campaign, universe?: Universe): QualifierCountRequest {
    const qualifierCountRequest: QualifierCountRequest = {
      rules,
      countOptions: {
        universe:
          universe ||
          ({
            id: 1,
            name: "All Subscribers",
            sourceView: "universe_all_subscribers"
          } as Universe)
      }
    };

    return qualifierCountRequest;
  }
}
