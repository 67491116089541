import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "radr-edit-popup",
  templateUrl: "./edit-popup.component.html",
  styleUrls: ["edit-popup.component.scss"]
})
export class EditPopupComponent {
  @Input() class: string = "radr-edit-popup";
  @Input() isSubmitEnabled: boolean = true;
  @Input() compact: boolean = false;
  @Input() isProfileBuilder: boolean = false;
  @Input() isAudienceBuilder: boolean = false;
  @Input() isSimplifi: boolean = false;
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();
  @Output() formCancel: EventEmitter<any> = new EventEmitter();

  public onSubmit(): void {
    if (this.isSubmitEnabled) {
      this.formSubmit.emit();
    }
  }

  public onCancel(): void {
    this.formCancel.emit();
  }
}
