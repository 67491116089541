<div class="profile-definition-wrapper" cdkDrag [cdkDragDisabled]="!canDrag" (cdkDragMoved)="onDragMove($event)">
  <div *cdkDragPreview matchSize class="drag-profile-preview" [id]="this.dropListGuid + '-preview'">
    Move {{ profile.name }}
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div *cdkDragPlaceholder class="drop-profile-placeholder"></div>
  <div *ngIf="!audienceEnabled; else profileAudienceDefinition" class="profile-definition-row">
    <radr-query-builder
      [(ngModel)]="definition"
      #queryBuilder
      class="mat-elevation-z3 query-builder"
      [class.profile-builder-mode]="mode === modes.ProfileBuilder"
      [config]="config"
      [classNames]="customClassNames"
      [operatorMap]="customOperatorMap"
      [disabled]="isDisabled"
      [profile]="profile"
      (updateSegmentIds)="updateSegmentIdAndSegmentName()"
      [isPreview]="isPreview"
    >
      <ng-container *radrQueryHeader>
        <div *ngIf="!hideHeader" class="query-builder-header">
          <div class="left-header-section">
            <mat-icon
              *ngIf="profileWarning"
              class="empty-profile-name-warning-icon"
              [matTooltip]="profileWarning"
              matTooltipPosition="above"
            >
              error
            </mat-icon>
            <span *ngIf="profile.priority === 0" id="default-profile-indicator">D</span>
            <mat-select
              matNativeControl
              class="priority-dropdown query-builder-switch-group-select query-builder-mat-select-with-border"
              [value]="profile.priority"
              (selectionChange)="priorityChanged({ newPriority: $event.value })"
              [disabled]="isDisabled"
            >
              <ng-container *ngFor="let priority of priorities">
                <mat-option *ngIf="priority !== 0 || allowDefaultProfiles" [value]="priority">
                  P{{ priority | number: "2.0-0" }}
                </mat-option>
              </ng-container>
            </mat-select>
            <span class="profile-header-field" *ngIf="profile.name; else elseName">
              {{ profile.name }}
            </span>
            <ng-template class="empty-field" #elseName>
              <span id="profile-priority-{{ profile?.priority }}-name-empty" class="empty-field"> No name added </span>
            </ng-template>

            <span class="\field-divider">&nbsp;|&nbsp;</span>

            <ng-container [ngSwitch]="mode">
              <ng-container *ngSwitchCase="modes.ProfileBuilder">
                <span class="profile-header-field" *ngIf="profile.description; else elseDescription">{{
                  profile.description
                }}</span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span class="profile-header-field" *ngIf="profile.exportKey; else elseExportKey">{{
                  profile.exportKey
                }}</span>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="mode === modes.CampaignBuilder">
              <ng-container>
                <span class="field-divider">&nbsp;|&nbsp;</span>
                <span class="profile-header-field">% of Campaign:</span>&#160;
                <mat-progress-spinner
                  *ngIf="campaignBuilderService.isCampaignCountsLoading; else subscriberPercentageTemplate"
                  mode="indeterminate"
                  diameter="15"
                ></mat-progress-spinner>
                <ng-template #subscriberPercentageTemplate>
                  <span
                    >{{
                      campaignBuilderService.getPercentageOfCampaign(profile.priority) | number: "1.0-2" : "en"
                    }}%</span
                  >
                  <ng-container *ngIf="isCappingLevelProfileFixed()">
                    <span class="field-divider">&nbsp;|&nbsp;</span>
                    <span class="profile-header-field">Fixed Cap:</span>
                    <span id="profile-capping-size">{{ profile.capFixed || 0 | number: "1.0-0" : "en" }}</span>
                  </ng-container>
                  <ng-container *ngIf="isCappingLevelProfilePercentage()">
                    <span class="field-divider">&nbsp;|&nbsp;</span>
                    <span class="profile-header-field">Percentage Cap:</span>
                    <span id="profile-capping-size">{{ profile.capPercentage || 0 | number: "1.0-2" : "en" }}%</span>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-container>

            <ng-template #elseExportKey>
              <span id="profile-priority-{{ profile?.priority }}-export-key-empty" class="empty-field">
                No export key added
              </span>
            </ng-template>

            <ng-template #elseDescription>
              <span id="profile-priority-{{ profile?.priority }}-description-empty" class="empty-field">
                No description added
              </span>
            </ng-template>

            <span class="field-divider">&nbsp;|&nbsp;</span>

            <span class="profile-header-field" *ngIf="isCampaignControlSource()">
              Control:
              <span id="profile-priority-{{ profile?.priority }}-control-percentage" class="profile-control-percentage"
                >{{ profile.controlPercentage }}%</span
              >
            </span>

            <span *ngIf="isCampaignControlSource()" class="field-divider">&nbsp;|&nbsp;</span>

            <span
              *ngIf="!isDisabled"
              (click)="openMenu(anchor)"
              #anchor="satPopoverAnchor"
              [satPopoverAnchor]="popover"
              class="profile-header-edit"
              >Details</span
            >

            <span *ngIf="isDisabled" class="profile-header-edit-disable">Details</span>
            <sat-popover #popover interactiveClose xAlign="start" yAlign="start" (closed)="handleEditPopoverClose()">
              <radr-edit-profile-popup
                [mode]="mode"
                [profile]="profile"
                [segmentIds]="segmentIds"
              ></radr-edit-profile-popup>
            </sat-popover>
          </div>

          <a (click)="toggleCollapse()" class="profile-expander-button">
            <mat-icon *ngIf="!queryBuilder.data.collapsed">expand_less</mat-icon>
            <mat-icon *ngIf="queryBuilder.data.collapsed">expand_more</mat-icon>
          </a>

          <div class="right-header-section">
            <radr-profile-count [mode]="mode" [profile]="profile" class="header-count"></radr-profile-count>
            <button
              mat-icon-button
              id="profile-options-menu-btn"
              [matMenuTriggerFor]="profileSaveOptions"
              [disabled]="campaign?.status.id !== 1 && campaign?.status.id !== 0"
              [id]="'profile-ellipsis-btn-' + profile.priority"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #profileSaveOptions class="profile-options-menu">
              <button mat-menu-item (click)="cloneProfile(profile)" [disabled]="isDisabled">
                <mat-icon>file_copy</mat-icon>
                Clone
              </button>
              <button
                mat-menu-item
                (click)="addTestMacs()"
                [hidden]="!showTestMacsButton"
                [id]="'profile-ellipis-btn-test-macs'"
              >
                <mat-icon>add</mat-icon>
                Add Test MAC Addresses
              </button>
              <button
                mat-menu-item
                (click)="removeProfile()"
                [disabled]="isDisabled"
                [id]="'remove-profile-btn-' + profile.priority"
              >
                <mat-icon>clear</mat-icon>
                Remove
              </button>
              <button
                mat-menu-item
                (click)="deleteProfile()"
                [disabled]="isDisabled || !profile.id"
                [id]="'delete-profile-btn-' + profile.priority"
              >
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
      <ng-container *radrQuerySwitchGroup="let ruleset; let onChange = onChange" #radrQuerySwitchGroup>
        <mat-select
          *ngIf="ruleset"
          matNativeControl
          class="query-builder-switch-group-select query-builder-mat-select-with-border"
          [(value)]="ruleset.condition"
          (selectionChange)="onChange($event.value)"
          [disabled]="isDisabled"
        >
          <mat-option value="and">And</mat-option>
          <mat-option value="or">Or</mat-option>
        </mat-select>
      </ng-container>
      <ng-container
        *radrQueryButtonGroup="
          let ruleset;
          let addRule = addRule;
          let addRuleSet = addRuleSet;
          let removeRuleSet = removeRuleSet
        "
      >
        <button
          type="button"
          mat-button
          (click)="addRuleSet()"
          class="query-builder-add-group-button"
          *ngIf="!isDisabled"
        >
          <mat-icon color="primary" class="query-builder-add-group-button-circle-icon">add_circle</mat-icon>
          Add Group
        </button>
        <span [hidden]="isDisabled" class="mat-body-2 query-button-group-text drag-drop-text"
          >- Drag and drop qualifiers -</span
        >
        <div class="query-builder-right-row-container">
          <span *ngIf="removeRuleSet; then nestedGroupButton"></span>
          <ng-template #nestedGroupButton>
            <button mat-icon-button (click)="removeRuleSet()" [hidden]="isDisabled" [disabled]="isDisabled">
              <mat-icon class="profile-group-trash-icon profile-group-trash-icon-header">delete</mat-icon>
            </button>
          </ng-template>
        </div>
      </ng-container>
      <ng-container *radrQueryRemoveButton="let rule; let removeRule = removeRule; let handleTouched = handleTouched">
        <mat-checkbox
          *ngIf="mode === modes.CampaignBuilder"
          class="control-checkbox"
          [(ngModel)]="rule.isControl"
          (change)="handleTouched(true, rule); campaignBuilderService.loadCounts()"
          [disabled]="isDisabled"
          >{{ isDisabled ? "Control" : "Set as Control" }}</mat-checkbox
        >
        <div class="count-and-delete-wrapper">
          <radr-qualifier-count
            *ngIf="!hideCounts"
            [field]="rule.field"
            [isControl]="rule.isControl"
            [operator]="rule.operator"
            [value]="rule.value"
            [tercile]="rule.tercile"
            [qualifierName]="rule.qualifierName"
            [originalAirDateStart]="rule.originalAirDateStart"
            [originalAirDateEnd]="rule.originalAirDateEnd"
            [viewershipLevels]="rule.viewershipLevels"
            class="header-count"
          ></radr-qualifier-count>
          <div class="query-builder-remove-icon-group" [hidden]="isDisabled">
            <button mat-icon-button class="q-remove-button" (click)="removeRule(rule)" [disabled]="isDisabled">
              <mat-icon class="profile-group-trash-icon">delete</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *radrQueryOperator="let rule; let operators = operators; let onChange = onChange">
        <mat-form-field class="query-select">
          <mat-select [(value)]="rule.operator" (selectionChange)="onChange(rule)" [disabled]="isDisabled">
            <mat-option *ngFor="let operator of operators" [value]="operator">
              {{ operator }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; let field = field; type: ''">
        <mat-form-field class="query-input" floatLabel="never" appearance="legacy">
          <mat-label class="placeholder-label">Enter value</mat-label>
          <input
            #emptyInput
            matInput
            [(ngModel)]="rule.value"
            (focus)="emptyInput.setAttribute('data-value', rule.value)"
            (blur)="emptyInput.getAttribute('data-value') !== rule.value ? onChange(rule) : ''"
            type="text"
            [disabled]="isDisabled"
          />
        </mat-form-field>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; let field = field; type: 'string'">
        <mat-form-field class="query-input" floatLabel="never" appearance="legacy">
          <mat-label class="placeholder-label">Enter value</mat-label>
          <input
            #stringInput
            matInput
            [(ngModel)]="rule.value"
            (focus)="stringInput.setAttribute('data-value', rule.value)"
            (blur)="stringInput.getAttribute('data-value') !== rule.value ? onChange(rule) : ''"
            [disabled]="isDisabled"
            type="text"
          />
        </mat-form-field>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; let field = field; type: 'number'">
        <mat-form-field class="query-input" floatLabel="never" appearance="legacy">
          <mat-label class="placeholder-label">Enter value</mat-label>
          <input
            #numberInput
            matInput
            [(ngModel)]="rule.value"
            (focus)="numberInput.setAttribute('data-value', rule.value)"
            (blur)="numberInput.getAttribute('data-value') !== rule.value ? onChange(rule) : ''"
            [disabled]="isDisabled"
            type="number"
          />
        </mat-form-field>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; type: 'multiselect'">
        <div class="query-select">
          <radr-select-autocomplete
            [(value)]="rule.value"
            (selectionChange)="onChange(rule, $event)"
            [loadOptionsOnOpen]="!isBackendSearch(rule)"
            [getOptionsFunction]="getOptionsForStringQualifier.bind(this, rule.field)"
            [searchBackend]="isBackendSearch(rule)"
            [searchEndpoint]="getQualifierSearchEndpoint(rule)"
            [disabled]="isDisabled"
          ></radr-select-autocomplete>
        </div>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; type: 'activeProfile'">
        <div class="query-select">
          <radr-select-autocomplete
            [(value)]="rule.value"
            (selectionChange)="onChange(rule, $event)"
            [options]="['Target', 'Control']"
            [disabled]="isDisabled"
          ></radr-select-autocomplete>
        </div>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; type: 'rootProfile'">
        <div class="query-input">
          <input matInput [(value)]="rule.value[0]" [disabled]="true" class="root-profile-input" />
        </div>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; type: 'category'">
        <mat-form-field class="query-select-boolean query-select" floatLabel="never" appearance="legacy">
          <mat-label class="placeholder-label">Select value</mat-label>
          <mat-select [(value)]="rule.value" (selectionChange)="onChange(rule)" [disabled]="isDisabled">
            <mat-option
              *ngFor="
                let option of [
                  { name: 'true', value: true },
                  { name: 'false', value: false }
                ]
              "
              [value]="option.value"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *radrQueryInput="let rule; let onChange = onChange; let options = options; type: 'batch'">
        <div class="batch-select">
          <radr-batch-values-selector
            (batchIdChange)="rule.value = $event; onChange(rule)"
            [batchId]="rule.value"
            [qualifierId]="rule.field"
            [profile]="profile"
          ></radr-batch-values-selector>
        </div>
      </ng-container>
    </radr-query-builder>
    <i
      cdkDragHandle
      *ngIf="showDragHandleOnHover && hovering; else dragPlaceholder"
      class="material-icons drag-profile-icon"
      >drag_indicator</i
    >
    <ng-template #dragPlaceholder><div style="width: 23px"></div></ng-template>
  </div>
  <ng-template #profileAudienceDefinition>
    <div class="profile-audience-definition-row">
      <div *ngIf="!hideHeader" class="profile-audience-builder-header">
        <div class="left-profile-audience-header-section">
          <mat-icon
            *ngIf="profileWarning"
            class="empty-profile-name-warning-icon"
            [matTooltip]="profileWarning"
            matTooltipPosition="above"
          >
            error
          </mat-icon>
          <span *ngIf="profile.priority === 0" id="default-profile-indicator">D</span>
          <mat-select
            matNativeControl
            class="priority-dropdown query-builder-switch-group-select query-builder-mat-select-with-border"
            [value]="profile.priority"
            (selectionChange)="priorityChanged({ newPriority: $event.value })"
            [disabled]="isDisabled"
          >
            <ng-container *ngFor="let priority of priorities">
              <mat-option *ngIf="priority !== 0 || allowDefaultProfiles" [value]="priority">
                P{{ priority | number: "2.0-0" }}
              </mat-option>
            </ng-container>
          </mat-select>
          <span class="profile-audience-header-field" *ngIf="profile.name; else elseName">
            {{ profile.name }}
          </span>
          <ng-template class="empty-field" #elseName>
            <span id="profile-priority-{{ profile?.priority }}-name-empty" class="empty-field"> No name added </span>
          </ng-template>

          <span class="\field-divider">&nbsp;|&nbsp;</span>

          <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="modes.ProfileBuilder">
              <span class="profile-audience-header-field" *ngIf="profile.description; else elseDescription">{{
                profile.description
              }}</span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="profile-audience-header-field" *ngIf="profile.exportKey; else elseExportKey">{{
                profile.exportKey
              }}</span>
            </ng-container>
          </ng-container>

          <!-- <ng-container *ngIf="mode === modes.CampaignBuilder">
            <ng-container>
              <span class="field-divider">&nbsp;|&nbsp;</span>
              <span class="profile-header-field">% of Campaign:</span>&#160;
              <mat-progress-spinner
                *ngIf="campaignBuilderService.isCampaignCountsLoading; else subscriberPercentageTemplate"
                mode="indeterminate"
                diameter="15"
              ></mat-progress-spinner>
              <ng-template #subscriberPercentageTemplate>
                <span
                  >{{ campaignBuilderService.getPercentageOfCampaign(profile.priority) | number: "1.0-2":"en" }}%</span
                >
                <ng-container *ngIf="isCappingLevelProfileFixed()">
                  <span class="field-divider">&nbsp;|&nbsp;</span>
                  <span class="profile-header-field">Fixed Cap:</span>
                  <span id="profile-capping-size">{{ profile.capFixed || 0 | number: "1.0-0":"en" }}</span>
                </ng-container>
                <ng-container *ngIf="isCappingLevelProfilePercentage()">
                  <span class="field-divider">&nbsp;|&nbsp;</span>
                  <span class="profile-header-field">Percentage Cap:</span>
                  <span id="profile-capping-size">{{ profile.capPercentage || 0 | number: "1.0-2":"en" }}%</span>
                </ng-container>
              </ng-template>
            </ng-container>
          </ng-container> -->

          <ng-template #elseExportKey>
            <span id="profile-priority-{{ profile?.priority }}-export-key-empty" class="empty-field">
              No export key added
            </span>
          </ng-template>

          <ng-template #elseDescription>
            <span id="profile-priority-{{ profile?.priority }}-description-empty" class="empty-field">
              No description added
            </span>
          </ng-template>

          <span class="field-divider">&nbsp;|&nbsp;</span>
          <!--
          <span class="profile-header-field" *ngIf="isCampaignControlSource()">
            Control:
            <span id="profile-priority-{{ profile?.priority }}-control-percentage" class="profile-control-percentage"
              >{{ profile.controlPercentage }}%</span
            >
          </span>

          <span *ngIf="isCampaignControlSource()" class="field-divider">&nbsp;|&nbsp;</span> -->

          <span
            *ngIf="!isDisabled"
            (click)="openMenu(anchor)"
            #anchor="satPopoverAnchor"
            [satPopoverAnchor]="popover"
            class="profile-audience-header-edit"
            >Details</span
          >

          <span *ngIf="isDisabled" class="profile-audience-header-edit-disable">Details</span>
          <sat-popover #popover interactiveClose xAlign="start" yAlign="start" (closed)="handleEditPopoverClose()">
            <radr-edit-profile-popup
              [mode]="mode"
              [profile]="profile"
              [segmentIds]="segmentIds"
            ></radr-edit-profile-popup>
          </sat-popover>
        </div>

        <!-- <a (click)="toggleCollapse()" class="profile-expander-button">
          <mat-icon *ngIf="!queryBuilder.data.collapsed">expand_less</mat-icon>
          <mat-icon *ngIf="queryBuilder.data.collapsed">expand_more</mat-icon>
        </a> -->

        <div class="right-audience-header-section">
          <radr-profile-count [mode]="mode" [profile]="profile" class="header-count"></radr-profile-count>
          <button
            mat-icon-button
            id="profile-options-menu-btn"
            [matMenuTriggerFor]="profileSaveOptions"
            [disabled]="campaign?.status.id !== 1 && campaign?.status.id !== 0"
            [id]="'profile-ellipsis-btn-' + profile.priority"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #profileSaveOptions class="profile-options-menu">
            <button mat-menu-item (click)="cloneProfile(profile)" [disabled]="isDisabled">
              <mat-icon>file_copy</mat-icon>
              Clone
            </button>
            <button
              mat-menu-item
              (click)="addTestMacs()"
              [hidden]="!showTestMacsButton"
              [id]="'profile-ellipis-btn-test-macs'"
            >
              <mat-icon>add</mat-icon>
              Add Test MAC Addresses
            </button>
            <button
              mat-menu-item
              (click)="removeProfile()"
              [disabled]="isDisabled"
              [id]="'remove-profile-btn-' + profile.priority"
            >
              <mat-icon>clear</mat-icon>
              Remove
            </button>
            <button
              mat-menu-item
              (click)="deleteProfile()"
              [disabled]="isDisabled || !profile.id"
              [id]="'delete-profile-btn-' + profile.priority"
            >
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="profile-definition-audience">
        <radr-profile-audience-definition
          [audienceForm]="audienceFormGroup"
          [(profile)]="profile"
        ></radr-profile-audience-definition>
      </div>
    </div>
  </ng-template>
</div>
