<radr-modal toolbarColor="primary" class="tunein-schedule-modal">
  <input #fileInput type="file" id="file" hidden="true" (change)="parseFileAndUpdateInputs($event)" />
  <ng-container body>
    <div class="modal-body">
      <div *ngFor="let scheduleForm of scheduleForms; index as i" class="radr-tunein-schedules">
        <radr-tune-in-schedule-item
          [scheduleForm]="scheduleForm"
          [fileUploaded]="fileUploaded"
        ></radr-tune-in-schedule-item>
        <ng-container id="schedule-buttons">
          <button id="add-filter-item-btn" mat-button mat-icon-button (click)="addScheduleItem(i)">
            <mat-icon id="add-item">add_circle_outline</mat-icon>
          </button>
          <button id="add-filter-item-btn" mat-button mat-icon-button (click)="removeScheduleItem(i)">
            <mat-icon id="remove-item">remove_circle_outline</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container actions>
    <button mat-button (click)="cancelModal()">CANCEL</button>
    <button
      mat-flat-button
      color="primary"
      id="form-modal-save"
      [disabled]="checkScheduleValidity()"
      (click)="submit()"
    >
      SUBMIT
    </button>
    <button class="sa-actions__clear" mat-button color="primary" (click)="openFilePicker()">UPLOAD FILE</button>
  </ng-container>
</radr-modal>
