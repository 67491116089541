<input
  #fileInput
  type="file"
  id="file"
  hidden="true"
  [accept]="acceptedFileTypes"
  (change)="filePicked($event.target.files)"
/>
<button mat-flat-button class="file-upload-button" (click)="uploadFile()" [disabled]="file || importedFile">
  {{ uploadText }}
  <mat-icon class="file-upload-button-icon">cloud_upload</mat-icon>
</button>
<div class="file-upload-text-container">
  {{ file?.name }}
  <div *ngIf="errorMessage" class="file-upload-error-message">{{ errorMessage }}</div>
</div>
<button *ngIf="!!file" mat-flat-button class="file-upload-clear-button" (click)="clearFile()">CLEAR</button>
