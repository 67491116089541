<mat-toolbar mat-dialog-title [color]="toolbarColor" class="toolbar">
  <mat-toolbar-row class="radr-modal-header-row">
    <mat-icon *ngIf="warningIcon" class="radr-modal-icon">warning</mat-icon>
    <span class="radr-modal-title" tabindex="1">
      {{ title }}
      <span class="radr-modal-subtitle">{{ subtitle }}</span>
    </span>

    <button *ngIf="!closeDisabled" mat-icon-button matDialogClose class="radr-close-modal-icon">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<ng-content select="[body]"></ng-content>

<div mat-dialog-actions class="radr-modal-footer-actions">
  <ng-content select="[actions]"></ng-content>
</div>
