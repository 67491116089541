export enum CampaignStatusEnum {
  Error = -1, // Terminal state
  Unknown = 0, // Terminal state
  Inactive = 1, // Terminal state
  Exporting = 2, // Transition state, set by backend
  Active = 3, // Terminal state
  PendingActivation = 4, // Transition state, set by frontend
  Processing = 5, // Transition state, set by backend
  PendingProcessing = 6 // Transition state, set by frontend
}
