import { OnDestroy } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { DashifyPipe } from "@shared/pipes/dashify.pipe";
import { EnhancedFormGroup } from "@shared/components/form/form.component";
import { ExecutionPlatform, ProductGroup, RequesterGroup } from "radr-shared";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { GroupInputCategory } from "../execution-platform-group.component";

interface StepNumberClasses {
  stepNumber: boolean;
  complete: boolean;
  disabled: boolean;
}

@Component({
  selector: "radr-execution-platform-group-stepped-input",
  templateUrl: "./execution-platform-group-stepped-input.component.html",
  styleUrls: ["./execution-platform-group-stepped-input.component.scss"],
  providers: [DashifyPipe]
})
export class ExecutionPlatformGroupSteppedInputComponent implements OnDestroy, OnInit {
  @Input() groupNumber: number;
  @Input() stepNumber: number;
  @Input() inputCategory: GroupInputCategory;
  @Input() formGroup: EnhancedFormGroup | FormGroup;
  @Input() controlName: string;
  @Input() options$: Observable<RequesterGroup | ProductGroup | ExecutionPlatform>;
  @Input() disabled: boolean = false;
  @Input() universeLabel: boolean = false;

  public inputCategoryId: string;
  public placeholder: string;
  public stepNumberClasses: StepNumberClasses = {
    stepNumber: true,
    complete: false,
    disabled: true
  };

  private get formControl(): AbstractControl {
    if (this.formGroup && this.controlName) {
      return this.formGroup.get(this.controlName);
    }
  }

  private cleanUp: Subject<void> = new Subject<void>();

  constructor(private dashify: DashifyPipe) {}

  ngOnInit(): void {
    if (this.inputCategory) {
      this.inputCategoryId = this.dashify.transform(this.inputCategory);
      this.placeholder = `Select ${this.inputCategory}`;
    }
    if (this.formGroup?.get(this.controlName)) {
      this.handleControlStatus(this.formControl.status);
      this.handleControlValue(this.formControl.value);
      const control: AbstractControl = this.formControl;
      control.statusChanges
        .pipe(takeUntil(this.cleanUp), distinctUntilChanged())
        .subscribe((status: string) => this.handleControlStatus(status));
      control.valueChanges
        .pipe(takeUntil(this.cleanUp), distinctUntilChanged())
        .subscribe((value: string | number) => this.handleControlValue(value));
    }
  }

  ngOnDestroy(): void {
    this.cleanUp.next();
    this.cleanUp.complete();
  }

  private handleControlStatus(status: string): void {
    if (status.toLowerCase() === "disabled") {
      this.stepNumberClasses.disabled = true;
    } else {
      this.stepNumberClasses.disabled = false;
    }
  }

  private handleControlValue(value: string | number): void {
    if (value && +value >= 0) {
      this.stepNumberClasses.complete = true;
    } else {
      this.stepNumberClasses.complete = false;
    }
  }
}
