import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { BehaviorSubject, interval, of, Subscription } from "rxjs";
import { AuthService } from "@shared/services/auth.service";
import { HistoricalRoutingState } from "@shared/lib/historical-routing-state.service";
import { SubscriberService } from "@shared/services/subscriber.service";
import { SubscriberReingestModalComponent } from "@shared/components/subscriber-reingest-modal/subscriber-reingest-modal.component";
import { catchError, take, tap } from "rxjs/operators";
import { formatDate } from "@angular/common";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "../environments/environment";
import * as Sentry from "@sentry/angular";
import { RadrNotificationsService } from "@shared/services/radr-notifications.service";
import { TasksService } from "@shared/services/tasks.service";
import { ExportAuditService } from "@shared/services/export-audit.service";
import { KeycloakEvent, KeycloakEventType, KeycloakService } from "keycloak-angular";
import { SessionTimeoutComponent } from "@shared/components/session-timeout/session-timeout.component";
import { CheckForAppUpdateService } from "@shared/services/check-for-app-update.service";
import { PromptAppUpdateServiceService } from "@shared/services/prompt-app-update-service.service";
import { ClientError, SessionTimeoutError } from "@shared/models";
import { RoleAccessService } from "@shared/services/role-access.service";
import { ApplicationSetting } from "radr-shared";
import { KeycloakProfileModel } from "@shared/models/keycloak-profile.model";
import { ApplicationSettingsService } from "@shared/services/application-settings.service";

declare global {
  interface Window {
    Appcues: any;
  }
}

window.Appcues = window.Appcues || {};

@Component({
  selector: "radr-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public title: string = "RADR";
  public subscribersLastUpdatedTooltip: string;
  public devicesLastUpdatedTooltip: string;
  public freewheelThresholdAlert: string;
  public freewheelWarningThresholdExceeded: boolean;
  public recentlyUpdated: boolean = true;
  public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private sentryDsn: string = environment.sentry_dsn;
  private appEnv: string = environment.app_env;
  private sessionTimeoutTracker: any = null;

  public get notificationsErrorCountTooltip(): string {
    return `There are ${this.notificationsService.errorCount$.value ?? 0} error notifications in the last 7 days`;
  }

  public get exportErrorCountTooltip(): string {
    return `There are ${this.exportAuditService.errorCount$.value ?? 0} export errors in the last 7 days`;
  }

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    public subscriberService: SubscriberService,
    routerState: HistoricalRoutingState,
    public notificationsService: RadrNotificationsService,
    public exportAuditService: ExportAuditService,
    public roleAccessService: RoleAccessService,
    private taskService: TasksService,
    private keycloak: KeycloakService,
    private checkForAppUpdateService: CheckForAppUpdateService, // Required for Service Worker
    private promptAppUpdateService: PromptAppUpdateServiceService, // Required for Service Worker
    private applicationSettingsService: ApplicationSettingsService
  ) {
    if (this.appEnv !== "local") {
      Sentry.init({
        dsn: this.sentryDsn,
        environment: this.appEnv,
        release: environment.version,
        sendClientReports: false,
        autoSessionTracking: false,
        beforeSend: (event, hint) => {
          const exception: string | Error = hint.originalException;

          // Ignore SessionTimeoutErrors because they are noisy and unavoidable
          if (exception instanceof SessionTimeoutError) {
            return null;
          }

          if (exception instanceof ClientError) {
            event.fingerprint = [exception.fingerprint, String(exception.functionName)];
          } else {
            event.fingerprint = ["client-uncategorized-error", "needs-specific-grouping"];
          }

          return event;
        }
      });
    }

    routerState.initializeHistoryListener();
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.isAuthenticated$.next(true);
      this.subscriberService.getSubscriberSummary();
      this.subscriberService.subscriberSummary.pipe(take(1)).subscribe(result => {
        this.setLastUpdatedDate(result.subscribersLastUpdated, result.devicesLastUpdated);
      });

      interface Settings {
        highestHhidProfileCount: number;
        freewheelProfileLimit: number;
        freewheelWarningThreshold: number;
      }

      type SettingKeys = keyof Settings;

      this.applicationSettingsService
        .getApplicationSettings(["highestHhidProfileCount", "freewheelProfileLimit", "freewheelWarningThreshold"])
        .subscribe((results: ApplicationSetting[]) => {
          const settings: Settings = results.reduce((acc, { key, value }) => {
            acc[key as SettingKeys] = Number(value);
            return acc;
          }, {} as Settings);

          this.freewheelThresholdAlert = `The highest profile count for a FW HHID is ${settings.highestHhidProfileCount}.`;
          this.freewheelWarningThresholdExceeded =
            settings.freewheelProfileLimit * settings.freewheelWarningThreshold < settings.highestHhidProfileCount;
        });

      this.notificationsService.getErrorCount().pipe(take(1)).subscribe();
      this.exportAuditService.getErrorCount().pipe(take(1)).subscribe();

      // Check notification and export audit event counts every 60 seconds
      // but stops polling if we receive 3 errors in a row for either service
      let numNotifErrors: number = 0;
      let numExportErrors: number = 0;
      const maxErrors: number = 3;
      const pollingErrorCounts: Subscription = interval(60000).subscribe(() => {
        this.notificationsService
          .getErrorCount()
          .pipe(
            take(1),
            tap(() => (numNotifErrors = 0)),
            catchError((err, caught) => {
              numNotifErrors++;
              if (numNotifErrors >= maxErrors) {
                pollingErrorCounts.unsubscribe();
              }
              return of();
            })
          )
          .subscribe();
        this.exportAuditService
          .getErrorCount()
          .pipe(
            take(1),
            tap(() => (numExportErrors = 0)),
            catchError((err, caught) => {
              numExportErrors++;
              if (numExportErrors >= maxErrors) {
                pollingErrorCounts.unsubscribe();
              }
              return of();
            })
          )
          .subscribe();
      });
    } else {
      this.isAuthenticated$.next(false);
    }
    this.setSessionTimeout(this.authService.getRemainingExpirySeconds());
    this.authService.keycloakEvents$.subscribe((event: KeycloakEvent) => {
      if (event.type === KeycloakEventType.OnAuthRefreshSuccess) {
        this.setSessionTimeout(this.authService.getRemainingExpirySeconds());
      }
    });

    const localStorageTaskKeys: string[] = Object.keys(localStorage).filter(key => key.includes("task_id"));
    if (localStorageTaskKeys.length > 0) {
      localStorageTaskKeys.forEach(key => {
        const taskData: any = JSON.parse(localStorage.getItem(key));
        this.taskService.pollForTaskCompletion(
          taskData.taskId,
          `Refresh completed for ${taskData.qualifierName}`,
          `Refresh of ${taskData.qualifierName} failed`,
          5000,
          720
        );
      });
    }

    // We use this info for appcues to identify the current user and decide what content they need to see
    this.keycloak.loadUserProfile().then((profile: KeycloakProfileModel) => {
      const userRoles: string[] = this.keycloak.getUserRoles();
      const radrRole: string = userRoles.find(role => role.toLowerCase().indexOf("radr") > -1);
      window.Appcues.identify(profile.username, {
        role: radrRole,
        accountId: profile.attributes.Username[0],
        firstName: profile.firstName,
        lastName: profile.lastName
      });
    });
  }

  setSessionTimeout(secondsRemaining: number): void {
    if (this.sessionTimeoutTracker) {
      clearTimeout(this.sessionTimeoutTracker);
    }
    this.sessionTimeoutTracker = setTimeout(
      () => {
        this.openSessionTimeoutDialog();
      },
      (secondsRemaining - 60) * 1000
    );
  }

  openSessionTimeoutDialog(): void {
    const dialogRef: MatDialogRef<SessionTimeoutComponent> = this.dialog.open(SessionTimeoutComponent, {
      data: {
        title: "Are you still there?",
        initialTime: this.authService.getRemainingExpirySeconds()
      }
    });
    dialogRef.afterClosed().subscribe(notIdle => {
      if (notIdle) {
        this.authService.tryRefresh().then(success => {
          if (!success) {
            this.authService.logout();
          }
        });
      }
    });
  }

  setLastUpdatedDate(subscribersLastUpdated: Date, devicesLastUpdated: Date): void {
    const now: Date = new Date();
    const oneDay: number = 24 * 60 * 60 * 1000;

    this.subscribersLastUpdatedTooltip = `Subscribers last updated ${formatDate(
      subscribersLastUpdated,
      "MM.dd.yyyy 'at' hh:mm a ",
      "en-US"
    )}`;

    this.devicesLastUpdatedTooltip = `Devices last updated ${formatDate(
      devicesLastUpdated,
      "MM.dd.yyyy 'at' hh:mm a ",
      "en-US"
    )}`;

    this.recentlyUpdated =
      now.getTime() - subscribersLastUpdated.getTime() < oneDay &&
      now.getTime() - devicesLastUpdated.getTime() < oneDay;
  }

  openConfirmation(): void {
    const modal: MatDialogRef<SubscriberReingestModalComponent> = this.dialog.open(SubscriberReingestModalComponent, {
      data: {
        title: "Subscriber List Reingest"
      }
    });

    modal
      .afterClosed()
      .pipe(take(1))
      .subscribe(okClicked => {
        if (okClicked) {
          this.subscriberService.overrideSubscriberDeviceImport();
        }
      });
  }
}
