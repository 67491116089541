<div
  class="execution-platform-group-input-step"
  [formGroup]="formGroup"
  [ngClass]="{ 'under-universe-label': universeLabel }"
>
  <div [ngClass]="stepNumberClasses">{{ stepNumber }}</div>
  <div class="execution-platform-group-input">
    <mat-form-field floatLabel="always" class="mat-form-field">
      <mat-label>{{ inputCategory }}</mat-label>
      <mat-select
        id="execution-platform-group-{{ groupNumber }}-{{ inputCategoryId }}-select"
        [placeholder]="placeholder"
        [formControlName]="controlName"
        [disabled]="disabled"
      >
        <mat-option
          *ngFor="let option of options$ | async"
          id="{{ inputCategoryId }}-{{ option?.id }}"
          [value]="option.id"
          [disabled]="option.isDisabled"
        >
          {{ option?.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
