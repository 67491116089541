import { EventEmitter, Pipe, PipeTransform } from "@angular/core";
import { of, Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { CampaignViewModel, DataSourceIdTypes, QualifierViewModel } from "radr-shared";
import { ProfilesService } from "@shared/services/profiles.service";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { QualifierDataTypes } from "radr-shared/src/enums";

@Pipe({
  name: "setProfileBuilderConfig"
})
export class SetProfileBuilderConfigPipe implements PipeTransform {
  constructor(
    private qualifiersService: QualifiersService,
    private profilesService: ProfilesService,
    private campaignBuilderService: CampaignBuilderService
  ) {}

  setConfigFromQualifier(q: QualifierViewModel, config: any, fields: any, rule: any, operatorMap: any): void {
    config.fields[q.id] = {
      name: q.name,
      value: q.id.toString(),
      type: q.dataType,
      segmentId: q.segmentId,
      segmentName: q.segmentName
    };

    Object.keys(config.fields).forEach(value => {
      const field: any = config.fields[value];
      field.value = field.value || value;

      if (fields.indexOf(field) === -1) {
        fields.push(field);
      }
    });
    rule.segmentId = q.segmentId;
    rule.segmentName = q.segmentName;
    // set operator to includes for Tune-In qualifiers
    if (!rule.operator && q.qualifierTypeId === 2) {
      rule.operator = "includes";
    } else if (!rule.operator && operatorMap[q.dataType]) {
      rule.operator = operatorMap[q.dataType][0];
    }
  }

  transform(rule: any, config: any, fields: any, operatorMap: any, dataChange: EventEmitter<any>): Observable<any[]> {
    if (!rule.field) {
      return of(null);
    }
    if (!config?.fields[rule.field]) {
      if (rule.field.indexOf("p") === 0) {
        const profileId: number = rule.field.split("p")[1];
        rule.loading = true;
        return this.profilesService.getProfile(profileId).pipe(
          map(profile => {
            config.fields[profile.profileQualifierId] = {
              name: profile.name,
              value: profile.profileQualifierId,
              type: profile.isRoot ? "rootProfile" : "activeProfile"
            };

            Object.keys(config.fields).forEach(value => {
              const field: any = config.fields[value];
              field.value = field.value || value;

              if (fields.indexOf(field) === -1) {
                fields.push(field);
              }
            });

            if (!rule.operator && operatorMap[config.fields[`p${profile.id}`].type]) {
              rule.operator = operatorMap[config.fields[`p${profile.id}`].type][0];
            }

            if (!rule.value && profile.isRoot) {
              rule.value = ["All"];
              this.campaignBuilderService.loadCounts();
              dataChange.emit(rule);
            }
            return rule;
          }),
          tap(() => (rule.loading = false))
        );
      } else {
        const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
        const qualifierFromCampaign: QualifierViewModel = campaign?.qualifiers?.find(
          qualifier => qualifier.id === +rule.field
        );
        if (qualifierFromCampaign) {
          this.setConfigFromQualifier(qualifierFromCampaign, config, fields, rule, operatorMap);
          return of(rule);
        } else {
          rule.loading = true;
          return this.qualifiersService.getQualifier(rule.field).pipe(
            map((q: QualifierViewModel) => {
              if (campaign?.qualifiers?.length >= 0) {
                campaign?.qualifiers.push(q);
              } else {
                if (campaign && campaign.qualifiers) {
                  campaign.qualifiers = [q];
                }
              }
              this.setConfigFromQualifier(q, config, fields, rule, operatorMap);
              dataChange.emit(rule);
              return rule;
            }),
            tap(() => (rule.loading = false)),
            catchError(err => {
              const qualifierNotFound: QualifierViewModel = new QualifierViewModel();
              qualifierNotFound.id = rule.field;
              qualifierNotFound.name = `QUALIFIER NOT FOUND FOR ID ${rule.field}`;
              qualifierNotFound.dataType = "none";
              qualifierNotFound.segmentId = null;
              qualifierNotFound.segmentName = null;
              this.setConfigFromQualifier(qualifierNotFound, config, fields, rule, operatorMap);
              rule.loading = false;
              return of(rule);
            })
          );
        }
      }
    } else {
      if (!rule.operator && operatorMap[config.fields[rule.field].type]) {
        rule.operator = operatorMap[config.fields[rule.field].type][0];
      }
      rule.segmentId = config.fields[rule.field].segmentId;
      rule.segmentName = config.fields[rule.field].segmentName;
      if (config?.fields[rule.field]?.type === "rootProfile" && !rule.value) {
        rule.value = ["All"];
        this.campaignBuilderService.loadCounts();
        dataChange.emit(rule);
      }
      return of(rule);
    }
  }
}
