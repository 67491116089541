import { Component, Input } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
@Component({
  selector: "radr-tune-in-schedule-item",
  templateUrl: "./tune-in-schedule-item.component.html",
  styleUrls: ["./tune-in-schedule-item.component.scss"]
})
export class TuneInScheduleItemComponent {
  constructor(private fb: FormBuilder) {}
  @Input() scheduleForm: FormGroup;
  @Input() fileUploaded: boolean;

  get fromDateControl(): AbstractControl {
    return this.scheduleForm.get("fromDate");
  }
  get toDateControl(): AbstractControl {
    return this.scheduleForm.get("toDate");
  }
  get programsControl(): AbstractControl {
    return this.scheduleForm.get("program");
  }
  get stationsControl(): AbstractControl {
    return this.scheduleForm.get("station");
  }

  datePickerOnClosed(formControl: string): void {
    this.scheduleForm.get(formControl).markAsTouched();
  }

  getMaxFromDate(): string {
    return this.toDateControl.value;
  }

  getMinToDate(): string {
    return this.fromDateControl.value;
  }
}
