import { Campaign, ResearchPlatform, Universe } from "../models";
import { ProfileViewModel } from "../view-models/profile.viewmodel";
import { ExecutionPlatformGroup } from "../models/execution-platform-group.model";
import { ControlLevel, CampaignStatusEnum, CappingLevel } from "../enums";
import isEqual from "lodash.isequal";
import { QualifierViewModel } from "./qualifier.viewmodel";
import { ViewModel } from "./view-model.viewmodel";
import { Subject } from "rxjs";
import { TopicsViewModel } from "./topics.viewmodel";
import { PlatformViewModel } from "./platform.viewmodel";
import { AddressableTypeViewModel } from "./adressable-type.viewmodel";

/**
 * Represents a campaign in RADR
 *
 * @export
 * @class CampaignViewModel
 */
export class CampaignViewModel extends Campaign implements ViewModel {
  /* tslint:disable-next-line */
  private _isDirty: boolean = false;
  public selectedUniverses: Set<string>;

  get isDirty(): boolean {
    return this._isDirty || this.profiles.some(profile => profile.isDirty);
  }
  set isDirty(dirty: boolean) {
    this._isDirty = dirty;
  }
  private fieldsToMarkAsDirty: string[] = [
    "name",
    "description",
    "isGross",
    "groupName",
    "isAddressable",
    "controlLevel",
    "controlPercentage",
    "capFixed",
    "capPercentage",
    "canoeVideoCampaignName",
    "canoeVideoCampaignId",
    "canoeATVCampaignName",
    "canoeATVCampaignId",
    "executionPlatformGroups",
    "isActive",
    "startDate",
    "endDate",
    "addressableTypeId",
    "businessLine"
  ];

  isLoadingCounts: boolean = false;
  /**
   * The list of qualifiers for the campaign
   *
   * @type {QualifierViewModel[]}
   * @memberof CampaignViewModel
   */
  qualifiers?: QualifierViewModel[];

  /**
   * The list of profiles for the campaign
   *
   * @type {ProfileViewModel[]}
   * @memberof CampaignViewModel
   */
  profiles: ProfileViewModel[] = [];
  /**
   * The list of names of profiles that are being used
   * as qualifiers by other profiles
   *
   * @type {string[]}
   * @memberof CampaignViewModel
   */
  destinationProfiles: ProfileViewModel[] = [];
  topics?: TopicsViewModel[] = [];
  platforms?: PlatformViewModel[] = [];
  addressableTypes?: AddressableTypeViewModel[] = [];

  public onPropertyChange$: Subject<string>;

  constructor(campaign: Partial<CampaignViewModel | Campaign> = {}) {
    super();
    Object.assign(this, campaign);
    this.selectedUniverses = new Set();

    this.profiles = new Proxy(
      (campaign?.profiles || []).map((profile: any) => new ProfileViewModel(profile)),
      { deleteProperty: this.deletePropertyFromNestedArray, get: this.addPropertyToNestedArray }
    );
    this.researchPlatforms = new Proxy(campaign?.researchPlatforms || [], {
      deleteProperty: this.deletePropertyFromNestedArray,
      get: this.addPropertyToNestedArray
    });
    this.executionPlatformGroups = new Proxy(campaign?.executionPlatformGroups || [], {
      deleteProperty: this.deletePropertyFromNestedArray,
      get: this.addPropertyToNestedArray
    });

    if (campaign?.status?.id === CampaignStatusEnum.Active && this.completedExecutionPlatformGroups.length > 0) {
      campaign?.executionPlatformGroups?.forEach((epg: ExecutionPlatformGroup) => {
        this.selectedUniverses.add(epg?.universe?.name);
      });
    } else {
      campaign?.universes?.forEach((universe: Universe) => this.selectedUniverses.add(universe?.name));
    }

    this.isDirty = false;

    this.onPropertyChange$ = new Subject<string>();

    return new Proxy(this, {
      set: (target: any, property: string, value: any): boolean => {
        if (this.fieldsToMarkAsDirty.indexOf(property) !== -1 && !isEqual(target[property], value)) {
          this.isDirty = true;
        }
        target[property] = value;
        this.onPropertyChange$.next(property);
        return true;
      }
    });
  }

  get isCappingActive(): boolean {
    return this.cappingLevel !== CappingLevel.Off;
  }
  set isCappingActive(isCappingActive: boolean) {
    if (isCappingActive && !this.isCappingActive) {
      this.cappingLevel = CappingLevel.CampaignPercentage;
      this.capPercentage = 100;
    }
    if (!isCappingActive) {
      this.cappingLevel = CappingLevel.Off;
      this.capPercentage = 0;
      this.capFixed = 0;
      this.profiles?.forEach(profile => {
        profile.capPercentage = 0;
        profile.capFixed = 0;
      });
    }
  }

  get isControlActive(): boolean {
    return this.controlLevel !== ControlLevel.Off;
  }
  set isControlActive(isControlActive: boolean) {
    if (isControlActive && !this.isControlActive) {
      this.controlLevel = ControlLevel.Campaign;
      this.controlPercentage = 10;
    }
    if (!isControlActive) {
      this.controlLevel = ControlLevel.Off;
      this.controlPercentage = 0;
      this.profiles?.forEach(profile => (profile.controlPercentage = 0));
    }
  }

  get isActive(): boolean {
    switch (this.status?.id) {
      case CampaignStatusEnum.Exporting:
      case CampaignStatusEnum.Active:
        return true;
      default:
        return false;
    }
  }

  set isActive(value: boolean) {
    if (value && !this.isActive) {
      this.status = { id: CampaignStatusEnum.PendingActivation };
      this.autoCalculateCounts = false;
    } else {
      this.status = { id: CampaignStatusEnum.Inactive };
    }
  }

  get isError(): boolean {
    return this.status?.id === CampaignStatusEnum.Error;
  }

  public get isWaitingForEngine(): boolean {
    return (
      this.status?.id === CampaignStatusEnum.PendingProcessing ||
      this.status?.id === CampaignStatusEnum.Processing ||
      this.status?.id === CampaignStatusEnum.Exporting ||
      this.isProcessingProfileIncluded
    );
  }

  get isAllProfilesInactive(): boolean {
    return this.profiles?.every(p => p.status === 1);
  }

  get isAllProfilesActive(): boolean {
    return this.profiles?.every(p => p.status === 3);
  }

  get isProcessingProfileIncluded(): boolean {
    return !!this.profiles?.find(p => [2, 5, 6].includes(p.status));
  }

  get isProcessingRootProfileIncluded(): boolean {
    return !!this.profiles?.find(p => p.status === 5);
  }

  get isProcessingAudienceIncluded(): boolean {
    // TODO: IMPLEMENT
    // return !!this.audiences?.find(p => p.status === 5);
    return false;
  }

  get isErrorProfileIncluded(): boolean {
    return !!this.profiles?.find(p => p.status === -1);
  }

  public get hasDefaultProfile(): boolean {
    return this.profiles?.some(p => p.isDefault);
  }

  public get isControlLevelCampaign(): boolean {
    return this.controlLevel === ControlLevel.Campaign;
  }

  public get isControlLevelProfile(): boolean {
    return this.controlLevel === ControlLevel.Profile;
  }

  public get isCampaignActivated(): boolean {
    return this.isActive;
  }

  public get isCampaignActivatedOrActivating(): boolean {
    return this.isActive || this.status?.id === CampaignStatusEnum.PendingActivation;
  }

  public get canEdit(): boolean {
    return !this.isCampaignActivated && !this.isLoadingCounts && !this.isError;
  }

  public get isAudienceFinderEnabled(): boolean {
    return (
      (!this.executionPlatformGroups.filter(epg => !!epg.universeId).length && this.selectedUniverses.has("Video")) ||
      !!this.executionPlatformGroups.find(ep => ep.universeId === 2)
    );
  }

  public get isAudienceFinderATVEnabled(): boolean {
    return (
      (!this.executionPlatformGroups.filter(epg => !!epg.universeId).length &&
        this.selectedUniverses.has("ATV Enabled")) ||
      !!this.executionPlatformGroups.find(ep => ep.universeId === 3)
    );
  }

  public get isAudienceFinderToggledOn(): boolean {
    return !!this.researchPlatforms.find(rp => rp.id === 2);
  }

  public get isAudienceFinderATVToggledOn(): boolean {
    return !!this.researchPlatforms.find(rp => rp.id === 3);
  }

  public get completedExecutionPlatformGroups(): ExecutionPlatformGroup[] {
    return this.executionPlatformGroups.filter(
      (epg: ExecutionPlatformGroup) =>
        epg.requesterGroupId && epg.productGroupId && epg.executionPlatformId && epg.universeId
    );
  }

  public removeResearchPlatform(id: number): void {
    this.researchPlatforms = this.researchPlatforms.filter(rp => rp.id !== id);
  }

  public toggleOffRestrictedFields(researchPlatform: Partial<ResearchPlatform>): void {
    if (researchPlatform.id === 3) {
      this.isRestrictedAudienceFinderAtv = false;
    } else if (researchPlatform.id === 2) {
      this.isRestrictedAudienceFinder = false;
    }
  }

  public get qualifierIds(): number[] {
    const qualifierIds: Set<number> = new Set();
    this.profiles.forEach(profile => {
      profile.qualifierIds.forEach(qualifierId => {
        if (!qualifierId.toString().startsWith("p")) {
          qualifierIds.add(qualifierId);
        }
      });
    });

    return Array.from(qualifierIds.values());
  }

  public get batchIds(): number[] {
    const batchIds: Set<number> = new Set();
    this.profiles.forEach(profile => {
      profile.batchIds.forEach(batchId => {
        batchIds.add(batchId);
      });
    });

    return Array.from(batchIds.values());
  }

  private deletePropertyFromNestedArray = (target: any, name: string) => {
    this.isDirty = true;
    return target[name];
    /* tslint:disable-next-line */
  };

  /* tslint:disable-next-line */
  private addPropertyToNestedArray = (target: any, property: string) => {
    const value: any = target[property];
    if (typeof value === "function") {
      if (["push", "unshift"].includes(property)) {
        this.isDirty = true;
      }
      if (["pop"].includes(property)) {
        this.isDirty = true;
      }
    }
    return target[property];
    /* tslint:disable-next-line */
  };

  public isQualifierPresentInAllNonDefaultProfiles(): boolean {
    if (this.profiles?.length > 0) {
      return this.filterOutDefaultProfile(this.profiles).every(
        (profile: ProfileViewModel) => profile?.definition?.rules?.length > 0
      );
    }
    return false;
  }

  public filterOutDefaultProfile(profiles: ProfileViewModel[]): ProfileViewModel[] {
    if (!profiles) {
      return [];
    }
    return profiles.filter((p: ProfileViewModel) => !p.isDefault || (p.isDefault && p.definition?.rules?.length > 0));
  }
}
