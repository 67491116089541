import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({ selector: "[radrQueryInput]" })
export class QueryInputDirective {
  /** Unique name for query input type. */
  @Input("radrQueryInputType")
  get queryInputType(): string {
    return this.type;
  }
  set queryInputType(value: string) {
    // If the directive is set without a type (updated programatically), then this setter will
    // trigger with an empty string and should not overwrite the programatically set value.
    if (!value) {
      return;
    }
    this.type = value;
  }
  private type: string;

  constructor(public template: TemplateRef<any>) {}
}
