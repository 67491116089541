<radr-modal class="radr-save-modal">
  <ng-container body>
    <div class="add-test-macs-modal-body">
      <form [formGroup]="formGroup" class="radr-default-form">
        <mat-form-field floatLabel="always" class="radr-default-form__mat-form-field">
          <mat-label>Test MAC Addresses</mat-label>
          <textarea
            matInput
            formControlName="testMacAddresses"
            id="radr-form__input-test-macs"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          ></textarea>
        </mat-form-field>
      </form>
    </div>
  </ng-container>

  <ng-container actions>
    <button mat-button id="form-modal-cancel" matDialogClose [disabled]="isLoading">CANCEL</button>
    <button
      mat-flat-button
      color="primary"
      id="form-modal-save"
      [disabled]="!formGroup.dirty || formGroup.pending || !formGroup.valid || !canEdit || isLoading"
      [class.spinner]="acceptButtonLoading"
      (click)="submit()"
    >
      APPLY
    </button>
  </ng-container>
</radr-modal>
