import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CheckForAppUpdateService {
  constructor(router: Router, updates: SwUpdate) {
    // Check for updates on each navigation event
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      if (updates.isEnabled) {
        // This requests the ngsw.json file and looks for differences.
        // If a difference is found, it will change an underlying
        // Subject and any subscriptions to it will fire.
        // In our case, it will pop up the modal informing the users
        // of a new version being available.
        updates.checkForUpdate();
      }
    });
  }
}
