import { Component, Input, OnInit } from "@angular/core";

export enum MessageIconType {
  UNKNOWN = "unknown",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error"
}

export enum MessageIconSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}

export enum MessageVerticalAlignment {
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom"
}

@Component({
  selector: "radr-contextual-message-icon",
  templateUrl: "./contextual-message-icon.component.html",
  styleUrls: ["./contextual-message-icon.component.scss"]
})
export class ContextualMessageIconComponent implements OnInit {
  @Input() type: MessageIconType = MessageIconType.UNKNOWN;
  @Input() size: MessageIconSize = MessageIconSize.MEDIUM;
  @Input() verticalAlign: MessageVerticalAlignment = MessageVerticalAlignment.MIDDLE;
  @Input() message: string = "";
  @Input() disabled: boolean = false;

  public iconMap: Map<MessageIconType, string> = new Map<MessageIconType, string>([
    [MessageIconType.UNKNOWN, "error"],
    [MessageIconType.INFO, "error"],
    [MessageIconType.WARNING, "error"],
    [MessageIconType.ERROR, "error"]
  ]);

  public UNKNOWN: string = MessageIconType.UNKNOWN;
  public INFO: string = MessageIconType.INFO;
  public WARNING: string = MessageIconType.WARNING;
  public ERROR: string = MessageIconType.ERROR;

  public SMALL: string = MessageIconSize.SMALL;
  public MEDIUM: string = MessageIconSize.MEDIUM;
  public LARGE: string = MessageIconSize.LARGE;

  public TOP: string = MessageVerticalAlignment.TOP;
  public MIDDLE: string = MessageVerticalAlignment.MIDDLE;
  public BOTTOM: string = MessageVerticalAlignment.BOTTOM;

  constructor() {}

  ngOnInit(): void {}
}
