import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TuneInScheduleModel } from "@shared/models/tune-in-schedule.model";
import moment, { Moment } from "moment-timezone";
import { WorkBook, read as xlsxRead } from "xlsx/dist/xlsx.core.min";
import { SnackbarService } from "@shared/services/snackbar.service";
import { SnackBarStatus } from "@shared/utils/notify";
import { CellObject, WorkSheet } from "xlsx";
import { DialogModalComponent } from "../dialog-modal/dialog-modal.component";

@Component({
  selector: "radr-tunein-schedule-modal",
  templateUrl: "./tunein-schedule-modal.component.html",
  styleUrls: ["./tunein-schedule-modal.component.scss"]
})
export class TuneinScheduleModalComponent implements OnInit {
  public currentFile: File = null;
  public fileUploaded: boolean = false;
  public scheduleForms: FormGroup[];
  public timezone: string;
  @ViewChild("fileInput", { static: false }) fileUploadInput: ElementRef;

  constructor(
    public tuneInScheduleDialogRef: MatDialogRef<TuneinScheduleModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; schedules: TuneInScheduleModel[] },
    private fb: FormBuilder,
    private snackBarService: SnackbarService,
    private warningDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.scheduleForms = this.data.schedules.map(scheduleItem => this.createFormRow(scheduleItem));
    this.timezone = moment.tz.guess();
  }

  createFormRow(scheduleItem?: TuneInScheduleModel): any {
    return this.fb.group({
      station: new FormControl(scheduleItem?.station, [Validators.required]),
      program: new FormControl(scheduleItem?.program),
      fromDate: new FormControl(scheduleItem?.fromDate, [Validators.required]),
      toDate: new FormControl(scheduleItem?.toDate, [Validators.required]),
      fromTime: new FormControl(this.formatExistingTime(scheduleItem?.fromTime), [
        Validators.required,
        Validators.pattern(/^(1[0-2]|[1-9]|0[0-9]):[0-5][0-9]$/)
      ]),
      toTime: new FormControl(this.formatExistingTime(scheduleItem?.toTime), [
        Validators.required,
        Validators.pattern(/^(1[0-2]|[1-9]|0[0-9]):[0-5][0-9]$/)
      ]),
      toTimeSuffix: new FormControl(scheduleItem?.toTimeSuffix, [Validators.required]),
      fromTimeSuffix: new FormControl(scheduleItem?.fromTimeSuffix, [Validators.required])
    });
  }

  validateStation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get("program").value?.length && !control?.value?.length) {
        return { required: true };
      }
      return null;
    };
  }

  validateProgram(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get("station").value?.length && !control?.value?.length) {
        return { required: true };
      }
      return null;
    };
  }

  formatExistingTime(time: string): string {
    let formattedTime: string = time;
    if (time && time.length === 4) {
      formattedTime = "0" + formattedTime;
    }

    return formattedTime;
  }

  removeScheduleItem(itemIndex: number): void {
    const deleteDialogRef: MatDialogRef<DialogModalComponent> = this.warningDialog.open(DialogModalComponent, {
      data: {
        title: "Delete Line Item",
        content: "Are you sure you want to delete this item?",
        submitButtonText: "Delete",
        cancelButtonText: "Cancel",
        dialogType: "Primary"
      }
    });
    deleteDialogRef.afterClosed().subscribe(deleteRequested => {
      if (deleteRequested) {
        this.scheduleForms.splice(itemIndex, 1);
      }
    });
  }

  cancelModal(): void {
    this.tuneInScheduleDialogRef.close({ data: this.data.schedules, action: "cancel" });
  }

  addScheduleItem(itemIndex: number): void {
    this.fileUploaded = false;
    this.scheduleForms.splice(itemIndex + 1, 0, this.createFormRow());
  }

  checkScheduleValidity(): boolean {
    return this.scheduleForms.some(schedule => {
      return schedule.status === "INVALID";
    });
  }

  async parseFileAndUpdateInputs(event: any): Promise<void> {
    this.currentFile = event.target.files[0];
    if (
      !this.currentFile.name.endsWith("csv") &&
      !this.currentFile.name.endsWith("xlsx") &&
      !this.currentFile.name.endsWith("xls")
    ) {
      this.snackBarService.open("Invalid file format, must be csv, xlsx, or xls", null, 5000, SnackBarStatus.Error);
      return;
    }
    const file: ArrayBuffer = await this.currentFile.arrayBuffer();
    const parsedWorkbook: WorkBook = xlsxRead(file, {
      type: "array",
      raw: true,
      cellNF: true,
      cellDates: true,
      cellFormula: true,
      cellHTML: true,
      cellStyles: true,
      cellText: true
    });
    this.fileUploaded = true;
    const sheetName: string = parsedWorkbook.SheetNames[0];
    const sheet: WorkSheet = parsedWorkbook.Sheets[sheetName];

    const headers: { [key: string]: string } = {
      A1: "Networks",
      B1: "Program Name",
      C1: "Date",
      D1: "Start Time",
      E1: "End Time"
    };

    for (let i: number = 2; ; i++) {
      const row: { [key: string]: string } = {};

      // Stop iterating if the first cell of the row is empty
      const firstCellAddress: string = `A${i}`;
      const firstCell: CellObject = sheet[firstCellAddress];
      if (!firstCell || !firstCell.v) {
        break;
      }

      for (const cellAddress in headers) {
        if (cellAddress) {
          const cell: CellObject = sheet[cellAddress.replace("1", i.toString())];
          const header: string = headers[cellAddress];
          row[header] = cell.v ? cell.v.toString() : "";
        }
      }

      const scheduleItem: TuneInScheduleModel = {
        station: [row["Networks"].toUpperCase()],
        program: [row["Program Name"]],
        fromDate: moment(row["Date"]).format("YYYY-MM-DD"),
        toDate: moment(row["Date"]).format("YYYY-MM-DD"),
        fromTime: row["Start Time"].slice(0, 5),
        fromTimeSuffix: row["Start Time"].slice(5, 7),
        toTime: row["End Time"].slice(0, 5),
        toTimeSuffix: row["End Time"].slice(5, 7)
      };

      this.scheduleForms.push(this.createFormRow(scheduleItem));
    }
    this.scheduleForms.splice(0, 1); // remove the first row because it's empty
    this.scheduleForms.forEach(scheduleForm => scheduleForm.markAllAsTouched());
  }

  openFilePicker(): void {
    this.fileUploadInput.nativeElement.value = null;
    this.fileUploadInput.nativeElement.click();
  }

  formatUTC(bdate: string, btime: string, bsuffix: string, btz: string): string {
    // format existing date/time for moment function
    const newDate: string = moment(bdate).format("YYYY-MM-DD");
    let a: Moment;
    // Add 12 hours if PM is selected
    if (bsuffix === "PM") {
      a = moment(newDate + " " + btime)
        .tz(btz)
        .add(12, "h");
    } else {
      a = moment(newDate + " " + btime).tz(btz);
    }
    // Format new timestamp in UTC
    return a.utc().format();
  }

  submit(): void {
    const schedules: any[] = this.scheduleForms.map(schedule => {
      const newSchedule: any = schedule.value;
      newSchedule.utcFromDateTime = this.formatUTC(
        newSchedule.fromDate,
        newSchedule.fromTime,
        newSchedule.fromTimeSuffix,
        this.timezone
      );
      newSchedule.utcToDateTime = this.formatUTC(
        newSchedule.toDate,
        newSchedule.toTime,
        newSchedule.toTimeSuffix,
        this.timezone
      );
      return newSchedule;
    });

    this.tuneInScheduleDialogRef.close({ data: schedules, action: "submit" });
  }
}
