import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ExportAudit } from "radr-shared";
import BaseService from "@shared/lib/base.service";
import { tap } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ExportAuditService extends BaseService {
  private readonly route: string = "export-audits";
  public errorCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  constructor() {
    super();
  }

  getExportAudits(showErrorsOnly: boolean = false, executionPlatformId?: number): Observable<ExportAudit[]> {
    let params: HttpParams = new HttpParams();
    params = params.set("showErrorsOnly", `${showErrorsOnly}`);
    if (executionPlatformId) params = params.set("executionPlatformId", `${executionPlatformId}`);
    return this.getMany<ExportAudit>(this.route, { params });
  }

  getErrorCount(): Observable<number> {
    return this.getOne<number>(`${this.route}/errors/count`).pipe(
      tap(count => {
        if (count) {
          this.errorCount$.next(count);
        }
      })
    );
  }
}
