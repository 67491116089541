<radr-loading-overlay></radr-loading-overlay>
<mat-toolbar *ngIf="isAuthenticated$ | async" color="primary" class="radr-header">
  <mat-toolbar-row class="radr-header-row">
    <div class="radr-header-nav-container">
      <mat-list-item>
        <img id="radr-logo" src="assets/RADR_Logo_Reverse_120x30.svg" width="95" height="22" alt="RADR logo" />
      </mat-list-item>
      <mat-list-item *ngIf="!roleAccessService.isLimitedAccess()">
        <a
          id="campaign-builder-nav-link"
          [routerLink]="['/campaigns']"
          routerLinkActive="active"
          class="router-link router-link-small"
          data-qa="campaign-builder-link"
        >
          Campaigns
        </a>
      </mat-list-item>
      <mat-list-item>
        <a
          id="profile-builder-nav-link"
          [routerLink]="['/profiles']"
          routerLinkActive="active"
          class="router-link router-link-small"
          data-qa="profile-builder-link"
        >
          Profiles
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="!roleAccessService.isLimitedAccess()">
        <a
          id="data-sources-nav-link"
          [routerLink]="['/data_sources']"
          routerLinkActive="active"
          class="router-link router-link-small"
          data-qa="data-sources-link"
        >
          Data Sources
        </a>
      </mat-list-item>
    </div>
    <div class="radr-settings-container" *ngIf="!roleAccessService.isLimitedAccess()">
      <div class="radr-total-subscriber-container" *ngIf="freewheelThresholdAlert">
        <div class="radr-update-info-container">
          <mat-icon
            class="radr-update-info-icon freewheel-info-icon"
            [class.freewheel-warning-icon]="freewheelWarningThresholdExceeded"
            [routerLink]="['/notifications']"
            [queryParams]="{ notificationType: 'Freewheel Threshold' }"
            >running_with_errors</mat-icon
          >
          <div class="radr-tooltip">
            <div class="radr-tooltip-text">{{ freewheelThresholdAlert }}</div>
          </div>
        </div>
      </div>
      <div class="radr-total-subscriber-container" *ngIf="(subscriberService.subscriberSummary | async) !== null">
        <div class="radr-update-info-container">
          <mat-icon class="radr-update-info-icon" [class.warning]="!recentlyUpdated">info</mat-icon>
          <div class="radr-icon-background"></div>
          <div class="radr-tooltip">
            <div class="radr-tooltip-text">{{ subscribersLastUpdatedTooltip }}</div>
            <div class="radr-tooltip-text">{{ devicesLastUpdatedTooltip }}</div>
            <radr-action-button
              class="radr-tooltip-button"
              (clicked)="openConfirmation()"
              [disabled]="roleAccessService.isReadOnly()"
            >
              REINGEST MOST RECENT
            </radr-action-button>
          </div>
        </div>
        <div>
          TOTAL SUBSCRIBERS: {{ (subscriberService.subscriberSummary | async)?.totalCount | number: "" : "en" }}
        </div>
      </div>
      <div class="radr-settings-separator">|</div>
      <mat-icon
        class="radr-settings-icon"
        id="export-audit-button"
        [routerLink]="['/export-audit']"
        [matBadge]="exportAuditService.errorCount$ | async"
        matBadgePosition="before"
        matBadgeColor="warn"
        matBadgeSize="small"
        [matTooltip]="exportErrorCountTooltip"
        matTooltipPosition="below"
        >import_export</mat-icon
      >
      <mat-icon
        class="radr-settings-icon notifications-icon"
        id="notifications-button"
        [routerLink]="['/notifications']"
        [matBadge]="notificationsService.errorCount$ | async"
        matBadgePosition="before"
        matBadgeColor="warn"
        matBadgeSize="small"
        [matTooltip]="notificationsErrorCountTooltip"
        matTooltipPosition="below"
        >notifications</mat-icon
      >
      <div class="radr-settings-separator">|</div>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="radr-logout">
        <mat-icon class="radr-header-menu-icon">menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="radr-header-menu">
        <a
          *ngIf="roleAccessService.isAdmin()"
          [routerLink]="roleAccessService.isAdmin() ? 'admin' : ''"
          routerLinkActive="active"
          id="admin-btn"
          [ngClass]="{ 'router-link router-link-small': true }"
        >
          Admin
        </a>
        <a routerLink="/logout" routerLinkActive="active" id="logout-btn" class="router-link router-link-small">
          Log Out
        </a>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>
