<radr-modal toolbarColor="warn" warningIcon="true" [class.spinner]="isLoading" [closeDisabled]="isLoading">
  <ng-container body>
    <div root-profile-modal *ngIf="!isLoading">
      <p class="root-profile-modal-text">
        You are adding a profile into another profile. Please select from the options below.
      </p>
    </div>
  </ng-container>
  <ng-container actions *ngIf="!isLoading">
    <button
      mat-button
      id="profile-modal-cancel"
      [ngClass]="'profile-modal-cancel-button ' + class + '-cancel-button'"
      [attr.data-qa]="class + '-cancel-button'"
      [matDialogClose]="{ cloneOption: rootProfileCloneOption.Cancel, profile: sourceProfile }"
    >
      CANCEL
    </button>
    <button
      mat-flat-button
      color="warn"
      id="profile-modal-root-button"
      [ngClass]="'profile-modal-root-button ' + class + '-root-button'"
      [attr.data-qa]="class + '-root-button'"
      matDialogClose
      [matDialogClose]="{ cloneOption: rootProfileCloneOption.Root, profile: sourceProfile }"
      [disabled]="!enableQualifierOption"
    >
      USE AS QUALIFIER
    </button>
    <button
      mat-flat-button
      color="warn"
      id="profile-modal-save"
      [disabled]="sourceProfile.profileType === 2"
      [ngClass]="'profile-modal-clone-button ' + class + '-clone-button'"
      [attr.data-qa]="class + '-clone-button'"
      [matDialogClose]="{ cloneOption: rootProfileCloneOption.Clone, profile: sourceProfile }"
      matDialogClose
    >
      CLONE PROFILE
    </button>
  </ng-container>
</radr-modal>
