import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

/* tslint:disable:component-selector */
@Component({
  selector: "[radrFormError]",
  templateUrl: "./form-error.component.html",
  styleUrls: ["./form-error.component.scss"]
})
export class FormErrorComponent {
  @Input() control: FormControl;
}
