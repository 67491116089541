import { Injectable } from "@angular/core";
import { ExecutionPlatform, ExecutionPlatformGroup, ExecutionPlatformGroupRule, Universe } from "radr-shared";
import { Observable } from "rxjs";
import BaseService from "../lib/base.service";

@Injectable({
  providedIn: "root"
})
export class ExecutionPlatformsService extends BaseService {
  private readonly route: string = "execution-platforms";

  constructor() {
    super();
  }

  getGroupRules(): Observable<ExecutionPlatformGroupRule[]> {
    return this.getMany<ExecutionPlatformGroupRule>(`${this.route}/group-rules`);
  }

  getNetworkId(executionPlatformId: number): Observable<string> {
    return this.getOne<string>(`${this.route}/network-id/${executionPlatformId}`);
  }

  getExecutionPlatforms(): Observable<ExecutionPlatform[]> {
    return this.getMany<ExecutionPlatform>(`${this.route}`);
  }

  exportExecutionPlatform(executionPlatformId: number, universeIds?: number[]): Observable<any> {
    return this.post(`${this.route}/${executionPlatformId}`, universeIds ? { universeIds } : {});
  }

  isExecutionPlatformFullAvail(executionPlatformId: number, executionPlatform?: ExecutionPlatform): boolean {
    return executionPlatformId === 4 || executionPlatform?.isFullAvail;
  }
}
