import { AudienceCountRequest, Universe } from "../models";

export class AudienceCountRequestFactory {
  public static create(rules: any, universes?: Universe[]): AudienceCountRequest {
    const audienceCountRequest: AudienceCountRequest = {
      rules,
      countOptions: {
        universes:
          universes ||
          ([
            {
              id: 1,
              name: "All Subscribers",
              sourceView: "universe_all_subscribers"
            }
          ] as Universe[])
      }
    };

    return audienceCountRequest;
  }
}
