import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import {
  EnhancedFormControl,
  enhancedFormControlFactory,
  EnhancedFormGroup
} from "@shared/components/form/form.component";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ExecutionPlatformsService } from "@shared/services/execution-platforms.service";
import {
  CampaignStatusEnum,
  CampaignViewModel,
  DataSource,
  ExecutionPlatform,
  ExecutionPlatformGroup,
  ExecutionPlatformGroupRule,
  ProductGroup,
  Profile,
  ProfileViewModel,
  QualifierViewModel,
  RequesterGroup,
  ResearchPlatform,
  Universe
} from "radr-shared";
import { Observable, of, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, pairwise, startWith, take, takeUntil } from "rxjs/operators";
import uniqueBy from "lodash.uniqby";
import {
  generateRequiredMaxLengthValidators,
  startsWithAlphaNumericValidator,
  forbiddenNameValidatorFactory
} from "@shared/components/form/form-util";
import { ProfilesService } from "@shared/services/profiles.service";
import { DialogModalComponent } from "@shared/components/dialog-modal/dialog-modal.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import cloneDeep from "lodash.clonedeep";

type GroupInput = RequesterGroup | ProductGroup | ExecutionPlatform;

export enum GroupInputCategory {
  Requester = "Requester",
  Product = "Product",
  ExecutionPlatform = "Execution Platform"
}

export enum Options {
  None = 0,
  Requesters = 1,
  Products = 2,
  ExecutionPlatforms = 3,
  All = 4
}

@Component({
  selector: "radr-execution-platform-group",
  templateUrl: "./execution-platform-group.component.html",
  styleUrls: ["./execution-platform-group.component.scss"]
})
export class ExecutionPlatformGroupComponent implements OnDestroy, OnInit {
  @Input() groupNumber: number = 1;
  @Input() epg: ExecutionPlatformGroup;

  public requesters$: Observable<RequesterGroup[]> = of([]);
  public products$: Observable<ProductGroup[]> = of([]);
  public executionPlatforms$: Observable<ExecutionPlatform[]> = of([]);
  public groupInputTypes: typeof GroupInputCategory = GroupInputCategory;
  public executionPlatformGroupForm: EnhancedFormGroup;
  public groupNameForm: EnhancedFormGroup;
  public groupNameControl: EnhancedFormControl;
  public groupNameButtonsVisible: boolean = false;
  public originalGroupName: string = "";
  public isFullAvail: boolean = false;
  public isFreewheelFullAvail: boolean = false;
  public canoeVideoDetailsForm: EnhancedFormGroup;
  public canoeVideoCampaignNameControl: EnhancedFormControl;
  public canoeVideoCampaignIdControl: EnhancedFormControl;
  public canoeVideoDetailsButtonsVisible: boolean = false;
  public originalCanoeVideoCampaignName: string = "";
  public originalCanoeVideoCampaignId: string = "";
  public isCanoeVideo: boolean = false;
  public canoeATVDetailsForm: EnhancedFormGroup;
  public canoeATVCampaignNameControl: EnhancedFormControl;
  public canoeATVCampaignIdControl: EnhancedFormControl;
  public canoeATVDetailsButtonsVisible: boolean = false;
  public originalCanoeATVCampaignName: string = "";
  public originalCanoeATVCampaignId: string = "";
  public isCanoeATV: boolean = false;
  public universeLabel: string;

  public groupRules: ExecutionPlatformGroupRule[] = [];
  public loadingGroupRules: boolean = true;
  public isGross: boolean = false;

  public get showAddNewButton(): boolean {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    return campaign.executionPlatformGroups.length - 1 === this.groupNumber;
  }

  private get executionPlatformGroupId(): AbstractControl {
    return this.executionPlatformGroupForm?.get("executionPlatformGroupId");
  }

  private get campaignId(): AbstractControl {
    return this.executionPlatformGroupForm?.get("campaignId");
  }

  private get requesterGroupId(): AbstractControl {
    return this.executionPlatformGroupForm?.get("requesterGroupId");
  }

  private get productGroupId(): AbstractControl {
    return this.executionPlatformGroupForm?.get("productGroupId");
  }

  private get executionPlatformId(): AbstractControl {
    return this.executionPlatformGroupForm?.get("executionPlatformId");
  }

  private cleanUp: Subject<void> = new Subject<void>();
  private isFormInitiallyPopulated: boolean = false;
  private epGroupsHaveUniverseId(campaign: CampaignViewModel): boolean {
    return !!campaign.executionPlatformGroups.find(ep => ep.universeId);
  }
  private isATVToggledOnWithoutVideoUniverse(
    campaign: CampaignViewModel,
    validExecutionPlatforms: ExecutionPlatform[]
  ): boolean {
    return campaign.isAudienceFinderToggledOn && validExecutionPlatforms[0].universe.id !== 2;
  }
  private isVideoToggledOnWithoutATVUniverse(
    campaign: CampaignViewModel,
    validExecutionPlatforms: ExecutionPlatform[]
  ): boolean {
    return campaign.isAudienceFinderATVToggledOn && validExecutionPlatforms[0].universe.id !== 3;
  }

  constructor(
    private campaignBuilderService: CampaignBuilderService,
    private executionPlatformService: ExecutionPlatformsService,
    private profileService: ProfilesService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.buildForms();
    this.setUpListeners();
    this.getGroupRules();
    this.campaignBuilderService
      .getSelectedItem()
      .onPropertyChange$.pipe(takeUntil(this.cleanUp))
      .subscribe((property: string) => {
        if (property === "executionPlatformGroups") {
          this.coalesceValidOptions(this.requesterGroupId.value, this.productGroupId.value);
        }
      });
  }

  ngOnDestroy(): void {
    this.cleanUp.next();
    this.cleanUp.complete();
  }

  public setGroupNameButtonVisibility(visible: boolean): void {
    this.groupNameButtonsVisible = visible;
  }

  public cancelGroupName(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    this.groupNameControl.setValue(campaign.groupName);
    this.setGroupNameButtonVisibility(false);
  }

  public submitGroupName(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    campaign.groupName = this.groupNameControl.value;
    this.setAllCampaignProfileNames(this.groupNameControl.value);
    this.setGroupNameButtonVisibility(false);
  }

  public setCanoeVideoDetailsButtonVisibility(visible: boolean): void {
    this.canoeVideoDetailsButtonsVisible = visible;
  }

  public cancelCanoeVideoDetailsForm(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    this.canoeVideoCampaignNameControl.setValue(campaign.canoeVideoCampaignName);
    this.canoeVideoCampaignIdControl.setValue(campaign.canoeVideoCampaignId);
    this.setCanoeVideoDetailsButtonVisibility(false);
  }

  public submitCanoeVideoDetailsForm(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    campaign.canoeVideoCampaignName = this.canoeVideoCampaignNameControl.value;
    campaign.canoeVideoCampaignId = this.canoeVideoCampaignIdControl.value;
    this.setCanoeVideoDetailsButtonVisibility(false);
  }

  public setCanoeATVDetailsButtonVisibility(visible: boolean): void {
    this.canoeATVDetailsButtonsVisible = visible;
  }

  public cancelCanoeATVDetailsForm(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    this.canoeATVCampaignNameControl.setValue(campaign.canoeATVCampaignName);
    this.canoeATVCampaignIdControl.setValue(campaign.canoeATVCampaignId);
    this.setCanoeATVDetailsButtonVisibility(false);
  }

  public submitCanoeATVDetailsForm(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    campaign.canoeATVCampaignName = this.canoeATVCampaignNameControl.value;
    campaign.canoeATVCampaignId = this.canoeATVCampaignIdControl.value;
    this.setCanoeATVDetailsButtonVisibility(false);
  }

  public addNewExecutionPlatformGroup(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    campaign.executionPlatformGroups.push({
      campaignId: campaign.id,
      executionPlatformGroupId: null,
      requesterGroupId: null,
      requesterGroup: null,
      productGroupId: null,
      productGroup: null,
      executionPlatformId: null,
      executionPlatform: null,
      universeId: null,
      universe: null
    });
  }

  public removeExecutionPlatformGroup(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    campaign.executionPlatformGroups.splice(this.groupNumber, 1);
    campaign.onPropertyChange$.next("executionPlatformGroups");
  }

  private getGroupRules(): void {
    this.executionPlatformService
      .getGroupRules()
      .pipe(take(1))
      .subscribe((groupRules: ExecutionPlatformGroupRule[]) => {
        this.groupRules = groupRules || [];
        this.groupRules.forEach(rule => (rule.executionPlatform.universe = rule.universe));
        this.resetOption(Options.All);
        this.populateForm();
        this.loadingGroupRules = false;
      });
  }

  private buildForms(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();

    // Execution Platform Group Form
    this.executionPlatformGroupForm = new EnhancedFormGroup({
      executionPlatformGroupId: enhancedFormControlFactory(new FormControl(undefined), {}),
      campaignId: enhancedFormControlFactory(new FormControl(null), {}),
      requesterGroupId: enhancedFormControlFactory(new FormControl(null), {}),
      productGroupId: enhancedFormControlFactory(new FormControl(null), {}),
      executionPlatformId: enhancedFormControlFactory(new FormControl(null), {})
    });
    this.executionPlatformGroupForm.disable();

    // Group Name Form
    this.groupNameForm = new EnhancedFormGroup({
      groupName: enhancedFormControlFactory(
        new FormControl(
          { value: campaign.groupName || "" },
          [...generateRequiredMaxLengthValidators(23, { isRequired: false }), startsWithAlphaNumericValidator],
          forbiddenNameValidatorFactory(this.isGroupNameKeyUnique.bind(this))
        ),
        {
          errorsMetaData: [
            {
              name: "maxlength",
              errorMessage: "The group name must be 23 characters or fewer."
            },
            {
              name: "forbiddenName",
              errorMessage: "This group name is already in use. Please use another."
            },
            {
              name: "startsWithAlphaNumeric",
              errorMessage: "Must start with an alphanumeric character."
            }
          ],
          hint: "The group name must be 23 characters or fewer."
        }
      )
    });

    if (campaign && campaign.groupName) {
      this.originalGroupName = campaign.groupName;
      this.groupNameForm.setValue({ groupName: campaign.groupName });
    } else {
      this.originalGroupName = null;
      this.groupNameForm.setValue({ groupName: null });
    }

    this.groupNameControl = this.groupNameForm.get("groupName") as EnhancedFormControl;

    this.canoeVideoDetailsForm = new EnhancedFormGroup({
      canoeVideoCampaignName: enhancedFormControlFactory(
        new FormControl({ value: campaign.canoeVideoCampaignName || "" }, [Validators.required]),
        {
          isRequired: true,
          errorsMetaData: [
            {
              name: "required",
              errorMessage: "You must specify a name for the Canoe Video Campaign."
            }
          ]
        }
      ),
      canoeVideoCampaignId: enhancedFormControlFactory(
        new FormControl({ value: campaign.canoeVideoCampaignId || "" }, [Validators.required]),
        {
          isRequired: true,
          errorsMetaData: [
            {
              name: "required",
              errorMessage: "You must specify an ID for the Canoe Video Campaign."
            }
          ]
        }
      )
    });

    this.originalCanoeVideoCampaignName = campaign.canoeVideoCampaignName ? campaign.canoeVideoCampaignName : null;
    this.originalCanoeVideoCampaignId = campaign.canoeVideoCampaignId ? campaign.canoeVideoCampaignId : null;
    this.canoeVideoDetailsForm.setValue({
      canoeVideoCampaignName: this.originalCanoeVideoCampaignName,
      canoeVideoCampaignId: this.originalCanoeVideoCampaignId
    });
    this.canoeVideoCampaignNameControl = this.canoeVideoDetailsForm.get(
      "canoeVideoCampaignName"
    ) as EnhancedFormControl;
    this.canoeVideoCampaignIdControl = this.canoeVideoDetailsForm.get("canoeVideoCampaignId") as EnhancedFormControl;

    this.canoeATVDetailsForm = new EnhancedFormGroup({
      canoeATVCampaignName: enhancedFormControlFactory(
        new FormControl({ value: campaign.canoeATVCampaignName || "" }, [Validators.required]),
        {
          isRequired: true,
          errorsMetaData: [
            {
              name: "required",
              errorMessage: "You must specify a name for the Canoe ATV Campaign."
            }
          ]
        }
      ),
      canoeATVCampaignId: enhancedFormControlFactory(
        new FormControl({ value: campaign.canoeATVCampaignId || "" }, [Validators.required]),
        {
          isRequired: true,
          errorsMetaData: [
            {
              name: "required",
              errorMessage: "You must specify an ID for the Canoe ATV Campaign."
            }
          ]
        }
      )
    });

    this.originalCanoeATVCampaignName = campaign.canoeATVCampaignName ? campaign.canoeATVCampaignName : null;
    this.originalCanoeATVCampaignId = campaign.canoeATVCampaignId ? campaign.canoeATVCampaignId : null;
    this.canoeATVDetailsForm.setValue({
      canoeATVCampaignName: this.originalCanoeATVCampaignName,
      canoeATVCampaignId: this.originalCanoeATVCampaignId
    });
    this.canoeATVCampaignNameControl = this.canoeATVDetailsForm.get("canoeATVCampaignName") as EnhancedFormControl;
    this.canoeATVCampaignIdControl = this.canoeATVDetailsForm.get("canoeATVCampaignId") as EnhancedFormControl;
  }

  private populateForm(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    const executionPlatformGroups: ExecutionPlatformGroup[] = campaign?.executionPlatformGroups ?? [];
    this.campaignId.setValue(campaign?.id ?? null);
    // TODO: handle this better when we can have more than one group:
    if (executionPlatformGroups.length >= this.groupNumber && executionPlatformGroups[+this.groupNumber]) {
      const thisGroup: ExecutionPlatformGroup = executionPlatformGroups[+this.groupNumber];
      this.resetOption(Options.All);
      this.executionPlatformGroupId.setValue(thisGroup.executionPlatformGroupId ?? undefined);
      // Need to set these values in the order that the steps build out
      // So that they are properly enabled and the dropdowns are populated
      this.requesterGroupId.setValue(thisGroup.requesterGroupId);
      this.productGroupId.setValue(thisGroup.productGroupId);
      this.executionPlatformId.setValue(thisGroup.executionPlatformId);
      this.checkAndSetFullAvail(thisGroup.executionPlatformId);
      this.setFreewheelFullAvail(thisGroup.executionPlatformId);
      this.checkAndSetCanoe(thisGroup.executionPlatformId);
      this.groupNameControl.setValue(campaign?.groupName ?? null);
      campaign.executionPlatformGroups[+this.groupNumber].universeId = this.getUniverse(thisGroup)?.id;
      this.checkAndSetUniverseLabel(thisGroup);
    } else {
      this.executionPlatformGroupForm.reset();
      this.checkAndSetFullAvail(null);
      this.setFreewheelFullAvail(null);
      this.checkAndSetCanoe(null);
      this.enableIfNeeded(this.requesterGroupId);
      // In case we save a brand new campaign, we need the executionPlatformGroupId that comes back
      const onlySubForFirstSave: Subscription = this.campaignBuilderService.selectedItemPropertyChanged$
        .pipe(takeUntil(this.cleanUp))
        .subscribe(() => {
          const c: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
          if (c?.executionPlatformGroups) {
            const thisGroup: ExecutionPlatformGroup = c?.executionPlatformGroups[this.groupNumber];
            if (this.executionPlatformGroupId.value) {
              onlySubForFirstSave.unsubscribe();
            } else if (!this.executionPlatformGroupId.value && thisGroup?.executionPlatformGroupId) {
              this.executionPlatformGroupId.patchValue(thisGroup.executionPlatformGroupId);
              onlySubForFirstSave.unsubscribe();
            }
          }
        });
    }
    if (![CampaignStatusEnum.Inactive, CampaignStatusEnum.Unknown].includes(campaign.status?.id)) {
      this.executionPlatformGroupForm.disable();
      this.canoeVideoDetailsForm.disable();
      this.canoeATVDetailsForm.disable();
      this.groupNameForm.disable();
    }
    this.setUpExecutionPlatformListener();
    this.isFormInitiallyPopulated = true;
  }

  private resetOption(options: Options): void {
    if (options === Options.Requesters || options === Options.All) {
      this.requesters$ = of(this.getValidSortedRequesterGroups() || []);
    }
    if (options === Options.Products || options === Options.All) {
      this.products$ = of(this.getValidSortedProductGroups() || []);
    }
    if (options === Options.ExecutionPlatforms || options === Options.All) {
      this.executionPlatforms$ = of(this.getValidSortedExecutionPlatforms() || []);
    }
  }

  private setUpListeners(): void {
    this.setUpRequesterGroupListener();
    this.setUpProductGroupListener();
    this.setUpCampaignChangeListener();
  }

  private setUpCampaignChangeListener(): void {
    this.campaignBuilderService.selectedItemPropertyChanged$.pipe(takeUntil(this.cleanUp)).subscribe(() => {
      const c: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
      if (c?.status?.id !== CampaignStatusEnum.Inactive && c?.status?.id !== CampaignStatusEnum.Unknown) {
        this.executionPlatformGroupForm.disable();
        this.groupNameForm.disable();
        this.canoeVideoDetailsForm.disable();
        this.canoeATVDetailsForm.disable();
      } else {
        this.restoreExecutionPlatormGroupFormStatus();
        this.groupNameForm.enable();
        this.canoeVideoDetailsForm.enable();
        this.canoeATVDetailsForm.enable();
      }
      if ((c?.executionPlatformGroups?.length ?? 0) === 0) {
        this.executionPlatformGroupForm.reset();
      }
      this.isGross = !!c?.isGross;
    });
  }

  private setUpRequesterGroupListener(): void {
    this.requesterGroupId.valueChanges
      .pipe(takeUntil(this.cleanUp), distinctUntilChanged())
      .subscribe((requesterGroupId: number) => {
        this.enableIfNeeded(this.requesterGroupId);
        const productGroupId: number | null = this.productGroupId.value;
        const { validProducts, validExecutionPlatforms } = this.coalesceValidOptions(requesterGroupId, productGroupId);
        if (this.isValidId(requesterGroupId)) {
          this.enableIfNeeded(this.productGroupId);
          if (productGroupId && !validProducts.some((pg: ProductGroup) => pg.id === productGroupId)) {
            this.productGroupId.reset();
          }
        } else {
          this.disableAndResetIfNeeded(this.productGroupId);
          this.resetOption(Options.Products);
        }
        if (validExecutionPlatforms.length === 1 && validExecutionPlatforms[0].id === 6) {
          this.isCanoeVideo = true;
          this.isCanoeATV = false;
        } else if (validExecutionPlatforms.length === 1 && validExecutionPlatforms[0].id === 7) {
          this.isCanoeATV = true;
          this.isCanoeVideo = false;
        } else {
          this.isCanoeVideo = false;
          this.isCanoeATV = false;
        }
        this.updateExecutionPlatformControl(
          this.productGroupId.value,
          this.executionPlatformId.value,
          validExecutionPlatforms
        );
        this.setCampaign();
      });
  }

  private setUpProductGroupListener(): void {
    this.productGroupId.valueChanges
      .pipe(takeUntil(this.cleanUp), distinctUntilChanged())
      .subscribe((productGroupId: number) => {
        const executionPlatformId: number | null = this.executionPlatformId.value;
        const { validExecutionPlatforms } = this.coalesceValidOptions(this.requesterGroupId.value, productGroupId);
        this.updateExecutionPlatformControl(productGroupId, executionPlatformId, validExecutionPlatforms);
        this.setCampaign();
      });
  }

  private setUpExecutionPlatformListener(): void {
    this.executionPlatformId.valueChanges
      .pipe(
        startWith(this.executionPlatformGroupForm.get("executionPlatformId").value),
        takeUntil(this.cleanUp),
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([oldExecutionPlatformId, newExecutionPlatformId]: [number, number]) => {
        const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
        const executionPlatformGroups: ExecutionPlatformGroup[] = campaign?.executionPlatformGroups ?? [];
        const thisGroup: ExecutionPlatformGroup = executionPlatformGroups[+this.groupNumber];
        const backupExecutionPlatformGroup: ExecutionPlatformGroup = cloneDeep(
          executionPlatformGroups[+this.groupNumber]
        );
        const { validExecutionPlatforms } = this.coalesceValidOptions(
          this.requesterGroupId.value,
          thisGroup?.productGroupId
        );
        if (
          (!this.dialog.openDialogs || !this.dialog.openDialogs.length) &&
          !this.epGroupsHaveUniverseId(campaign) &&
          !this.campaignBuilderService.doesCampaignOnlyHaveDefaultExecutionPlatformGroup(campaign) &&
          (this.isATVToggledOnWithoutVideoUniverse(campaign, validExecutionPlatforms) ||
            this.isVideoToggledOnWithoutATVUniverse(campaign, validExecutionPlatforms))
        ) {
          const researchPlatformToRemove: ResearchPlatform = campaign.researchPlatforms.find(
            rp => rp.id !== validExecutionPlatforms[0].universe.id && rp.id !== 1
          );
          this.openRemoveRPModal(researchPlatformToRemove, campaign, newExecutionPlatformId, thisGroup);
        } else {
          this.setExecutionPlatformGroup(newExecutionPlatformId, campaign);
        }
        if (newExecutionPlatformId === 4 && !campaign.hasDefaultProfile) {
          this.campaignBuilderService.addNewProfile(new ProfileViewModel(), 0);
        } else if (newExecutionPlatformId !== 4 && oldExecutionPlatformId === 4 && campaign.hasDefaultProfile) {
          this.openDefaultModal(backupExecutionPlatformGroup);
        }
      });
  }

  private restoreExecutionPlatormGroupFormStatus(): void {
    const requesterControl: AbstractControl = this.executionPlatformGroupForm.get("requesterGroupId");
    const productControl: AbstractControl = this.executionPlatformGroupForm.get("productGroupId");
    const executionPlatformControl: AbstractControl = this.executionPlatformGroupForm.get("executionPlatformId");

    this.executionPlatformGroupForm.enable();

    if (requesterControl.value) {
      productControl.enable();
    } else {
      productControl.disable();
    }
    if (productControl.value) {
      executionPlatformControl.enable();
    } else {
      executionPlatformControl.disable();
    }
  }

  private updateExecutionPlatformControl(
    productGroupId: number,
    executionPlatformId: number | null,
    validExecutionPlatforms: ExecutionPlatform[]
  ): void {
    if (this.isValidId(productGroupId)) {
      this.enableIfNeeded(this.executionPlatformId);
      if (
        executionPlatformId &&
        !validExecutionPlatforms.some((ep: ExecutionPlatform) => ep.id === executionPlatformId)
      ) {
        this.executionPlatformId.reset();
      }
      if (validExecutionPlatforms.length === 1 && !validExecutionPlatforms[0].isDisabled) {
        const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
        const executionPlatformGroups: ExecutionPlatformGroup[] = campaign?.executionPlatformGroups ?? [];
        if (
          (!this.dialog.openDialogs || !this.dialog.openDialogs.length) &&
          !this.epGroupsHaveUniverseId(campaign) &&
          !this.campaignBuilderService.doesCampaignOnlyHaveDefaultExecutionPlatformGroup(campaign) &&
          (this.isATVToggledOnWithoutVideoUniverse(campaign, validExecutionPlatforms) ||
            this.isVideoToggledOnWithoutATVUniverse(campaign, validExecutionPlatforms))
        ) {
          const researchPlatformToRemove: ResearchPlatform = campaign.researchPlatforms.find(
            rp => rp.id !== validExecutionPlatforms[0].universe.id && rp.id !== 1
          );
          this.openRemoveRPModal(
            researchPlatformToRemove,
            campaign,
            validExecutionPlatforms[0].id,
            executionPlatformGroups[+this.groupNumber]
          );
        } else {
          this.setExecutionPlatformGroup(validExecutionPlatforms[0].id, campaign);
        }
      } else {
        this.executionPlatformId.reset();
      }
    } else {
      this.disableAndResetIfNeeded(this.executionPlatformId);
      this.resetOption(Options.ExecutionPlatforms);
    }
  }

  private openRemoveRPModal(
    researchPlatformToRemove: ResearchPlatform,
    campaign: CampaignViewModel,
    executionPlatformId: number,
    group: ExecutionPlatformGroup
  ): void {
    const researchPlatormName: string =
      researchPlatformToRemove.id === 2 ? "Audience Finder" : ("Audience Finder - ATV" ?? "");
    const dialogRef: MatDialogRef<DialogModalComponent> = this.dialog.open(DialogModalComponent, {
      data: {
        title: "Remove Research Platform",
        content: `Are you sure you want to add this execution platform? Doing so will remove the ${researchPlatormName} research platform from the campaign.`,
        submitButtonText: "OK",
        cancelButtonText: "Cancel",
        dialogType: "Warning"
      }
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(removeRequested => {
        if (removeRequested) {
          campaign.removeResearchPlatform(researchPlatformToRemove.id);
          campaign.toggleOffRestrictedFields(researchPlatformToRemove);
          this.setExecutionPlatformGroup(executionPlatformId, campaign);
        } else {
          this.productGroupId.reset(null);
        }
      });
  }

  private openDefaultModal(executionPlatformGroup: ExecutionPlatformGroup): void {
    const dialogRef: MatDialogRef<DialogModalComponent> = this.dialog.open(DialogModalComponent, {
      data: {
        title: "Remove Default Profile",
        content: `Changing execution platform will cause the default profile to be deleted. Are you sure you want to proceed?`,
        submitButtonText: "OK",
        cancelButtonText: "Cancel",
        dialogType: "Warning"
      }
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(removeRequested => {
        if (removeRequested) {
          this.campaignBuilderService.getSelectedItem().profiles.splice(0, 1);
        } else {
          this.campaignBuilderService.getSelectedItem().executionPlatformGroups[+this.groupNumber] =
            executionPlatformGroup;
        }
      });
  }

  setExecutionPlatformGroup(executionPlatformId: number, campaign: CampaignViewModel): void {
    this.executionPlatformId.setValue(executionPlatformId);
    if (this.executionPlatformId.value) {
      this.getValidSortedExecutionPlatforms(this.requesterGroupId.value, this.productGroupId.value);
    }
    this.checkAndSetFullAvail(executionPlatformId);
    this.setFreewheelFullAvail(executionPlatformId);
    this.checkAndSetCanoe(executionPlatformId);
    const updatedGroup: ExecutionPlatformGroup = this.executionPlatformGroupForm.getRawValue();
    this.checkAndSetUniverseLabel(updatedGroup);
    const universe: Universe = this.getUniverse(updatedGroup);

    if ((!campaign.selectedUniverses || !campaign.selectedUniverses.size) && universe) {
      campaign.selectedUniverses.add(universe?.name);
    }

    this.setCampaign();
  }

  private coalesceValidOptions(
    requesterGroupId: number,
    productGroupId: number
  ): { validProducts: ProductGroup[]; validExecutionPlatforms: ExecutionPlatform[] } {
    const validProducts: ProductGroup[] = this.getValidSortedProductGroups(requesterGroupId);
    this.products$ = of(validProducts);
    const validExecutionPlatforms: ExecutionPlatform[] = this.getValidSortedExecutionPlatforms(
      requesterGroupId,
      productGroupId
    );
    this.executionPlatforms$ = of(validExecutionPlatforms);
    return { validProducts, validExecutionPlatforms };
  }

  private getValidSortedRequesterGroups(): RequesterGroup[] {
    let valid: RequesterGroup[] = uniqueBy(
      this.groupRules.map(rule => rule.requesterGroup),
      "id"
    );
    valid = valid.filter(v => !!v);
    return valid.sort(this.sortByDisplayOrder);
  }

  private getValidSortedProductGroups(requesterGroupId?: number): ProductGroup[] {
    let valid: ProductGroup[] = [];

    if (requesterGroupId && +requesterGroupId >= 0) {
      valid = uniqueBy(
        this.groupRules.filter(rule => rule.requesterGroup.id === +requesterGroupId).map(rule => rule.productGroup),
        "id"
      );
    } else {
      valid = uniqueBy(
        this.groupRules.map(rule => rule.productGroup),
        "id"
      );
    }
    valid = valid.filter(v => !!v);
    return valid.sort(this.sortByDisplayOrder);
  }

  private getValidSortedExecutionPlatforms(requesterGroupId?: number, productGroupId?: number): ExecutionPlatform[] {
    let valid: ExecutionPlatform[] = [];
    if (requesterGroupId && +requesterGroupId >= 0 && productGroupId && +productGroupId >= 0) {
      valid = uniqueBy(
        this.groupRules
          .filter(rule => rule.requesterGroup.id === +requesterGroupId)
          .filter(rule => rule.productGroup.id === +productGroupId)
          .map(rule => rule.executionPlatform),
        "id"
      );
    } else {
      valid = uniqueBy(
        this.groupRules.map(rule => rule.executionPlatform),
        "id"
      );
    }
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    const completeExecutionPlatformGroupIds: number[] = campaign.completedExecutionPlatformGroups
      // filter out ourselves
      .filter(epg => {
        return epg !== campaign.executionPlatformGroups[this.groupNumber];
      })
      .map(epg => epg.executionPlatformId);

    valid.forEach(ep => (ep.isDisabled = false));
    if (completeExecutionPlatformGroupIds.length) {
      valid.forEach(ep => {
        if (
          completeExecutionPlatformGroupIds.includes(4) ||
          completeExecutionPlatformGroupIds.includes(ep.id) ||
          ep.id === 4
        ) {
          ep.isDisabled = true;
        }
      });
    }

    valid = valid.filter(v => !!v);
    return valid.sort(this.sortByDisplayOrder);
  }

  private sortByDisplayOrder(a: GroupInput, b: GroupInput): number {
    return (a?.displayOrder ?? 0) - (b?.displayOrder ?? 0);
  }

  private isValidId(id: number | string): boolean {
    return id && +id >= 0;
  }

  private checkAndSetFullAvail(epId: number): void {
    this.isFreewheelFullAvail = this.productGroupId.value === 1 && epId === 1;

    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    if (this.executionPlatformService.isExecutionPlatformFullAvail(epId)) {
      this.isFullAvail = true;
    } else {
      this.isFullAvail = false;
      if (campaign && campaign.groupName && !this.isFreewheelFullAvail) {
        this.groupNameForm.reset();
        campaign.groupName = null;
        campaign.profiles.forEach(profile => {
          profile.exportKey = null;
        });
      }
    }
  }

  private setFreewheelFullAvail(epId: number): void {
    this.isFreewheelFullAvail = this.productGroupId.value === 1 && epId === 1;
  }

  private checkAndSetCanoe(epId: number): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    if (epId === 6) {
      this.isCanoeVideo = true;
      this.isCanoeATV = false;
      this.canoeATVDetailsForm.reset();
    } else if (epId === 7) {
      this.isCanoeVideo = false;
      this.isCanoeATV = true;
      this.canoeVideoDetailsForm.reset();
    } else {
      this.isCanoeVideo = false;
      this.isCanoeATV = false;
      this.canoeVideoDetailsForm.reset();
      this.canoeATVDetailsForm.reset();
      if (!this.campaignBuilderService.doesCampaignHaveCanoeVideo(campaign)) {
        campaign.canoeVideoCampaignName = null;
        campaign.canoeVideoCampaignId = null;
      }
      if (!this.campaignBuilderService.doesCampaignHaveCanoeATV(campaign)) {
        campaign.canoeATVCampaignName = null;
        campaign.canoeATVCampaignId = null;
      }
    }
    this.setCanoeCampaignControlValues(epId);
  }

  setCanoeCampaignControlValues(epId: number): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    if (campaign && epId && (epId === 6 || epId === 7)) {
      const campaignQualifiers: QualifierViewModel[] = campaign.qualifiers;
      const sortedCampaignProfiles: ProfileViewModel[] = campaign.profiles.sort((a, b) => a.priority - b.priority);
      let highestPriorityCanoeDataSource: DataSource;
      sortedCampaignProfiles.every(profile => {
        profile.qualifierIds.every(id => {
          const qualifier: QualifierViewModel = campaignQualifiers?.find(qual => qual.id === +id);
          if (qualifier?.dataSource?.canoeCampaignId && qualifier?.dataSource?.canoeCampaignName) {
            highestPriorityCanoeDataSource = qualifier.dataSource;
            return false;
          }
          return true;
        });
        if (highestPriorityCanoeDataSource) {
          return false;
        }
        return true;
      });

      if (epId === 6 && !campaign.canoeVideoCampaignName && !campaign.canoeVideoCampaignId) {
        campaign.canoeVideoCampaignName = highestPriorityCanoeDataSource?.canoeCampaignName ?? null;
        campaign.canoeVideoCampaignId = highestPriorityCanoeDataSource?.canoeCampaignId ?? null;
        this.canoeVideoCampaignNameControl.setValue(highestPriorityCanoeDataSource?.canoeCampaignName ?? null);
        this.canoeVideoCampaignIdControl.setValue(highestPriorityCanoeDataSource?.canoeCampaignId ?? null);
      } else if (epId === 7 && !campaign.canoeATVCampaignName && !campaign.canoeATVCampaignId) {
        campaign.canoeATVCampaignName = highestPriorityCanoeDataSource?.canoeCampaignName ?? null;
        campaign.canoeATVCampaignId = highestPriorityCanoeDataSource?.canoeCampaignId ?? null;
        this.canoeATVCampaignNameControl.setValue(highestPriorityCanoeDataSource?.canoeCampaignName ?? null);
        this.canoeATVCampaignIdControl.setValue(highestPriorityCanoeDataSource?.canoeCampaignId ?? null);
      }
    }
  }

  private checkAndSetUniverseLabel(epGroup: ExecutionPlatformGroup): void {
    const rule: ExecutionPlatformGroupRule = this.groupRules.find((gr: ExecutionPlatformGroupRule) => {
      return (
        gr.requesterGroup.id === epGroup?.requesterGroupId &&
        gr.productGroup.id === epGroup?.productGroupId &&
        gr.executionPlatform.id === epGroup?.executionPlatformId
      );
    });
    this.universeLabel = rule ? rule?.universe?.name : "";
  }

  private getUniverse(epGroup: ExecutionPlatformGroup): Universe {
    const rule: ExecutionPlatformGroupRule = this.groupRules.find((gr: ExecutionPlatformGroupRule) => {
      return (
        gr.requesterGroup.id === epGroup?.requesterGroupId &&
        gr.productGroup.id === epGroup?.productGroupId &&
        gr.executionPlatform.id === epGroup?.executionPlatformId
      );
    });
    return rule?.universe ?? null;
  }

  private isGroupNameKeyUnique(groupName: string): Observable<boolean> {
    groupName += "01";
    const lowerCasedGroupName: string = (groupName || "").toLowerCase();

    if (!groupName || lowerCasedGroupName === (this.originalGroupName || "").toLowerCase()) {
      return of(true);
    }

    return this.campaignBuilderService.isCampaignUnique({ groupName });
  }

  private setAllCampaignProfileNames(groupName: string): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    if (groupName) {
      campaign.profiles.forEach((profile: Profile, i: number) => {
        profile.exportKey = this.profileService.getProfileExportKeyByGroupNameAndPriority(groupName, profile.priority);
      });
    } else {
      campaign.profiles.forEach((profile: Profile) => {
        profile.exportKey = null;
      });
    }
  }

  private disableAndResetIfNeeded(a: AbstractControl): void {
    if (a.enabled) {
      a.disable();
    }
    if (a.value) {
      a.reset();
    }
  }

  private enableIfNeeded(a: AbstractControl): void {
    if (a.disabled && !this.campaignBuilderService.getSelectedItem().isCampaignActivated) {
      a.enable();
    }
  }

  public disableAddExecutionPlatform(): boolean {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    if (![CampaignStatusEnum.Inactive, CampaignStatusEnum.Unknown].includes(campaign?.status?.id)) {
      return true;
    }
    return campaign.completedExecutionPlatformGroups.length
      ? campaign.completedExecutionPlatformGroups[0].executionPlatformId === 4
      : false;
  }

  private setCampaign(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    if (this.isFormInitiallyPopulated && campaign) {
      const epGroup: ExecutionPlatformGroup = this.executionPlatformGroupForm.getRawValue();
      const universe: Universe = this.getUniverse(epGroup);
      if (campaign.executionPlatformGroups[this.groupNumber]) {
        campaign.executionPlatformGroups[this.groupNumber].executionPlatformGroupId = epGroup.executionPlatformGroupId;
        campaign.executionPlatformGroups[this.groupNumber].campaignId = epGroup.campaignId;
        campaign.executionPlatformGroups[this.groupNumber].requesterGroupId = epGroup.requesterGroupId;
        campaign.executionPlatformGroups[this.groupNumber].productGroupId = epGroup.productGroupId;
        campaign.executionPlatformGroups[this.groupNumber].executionPlatformId = epGroup.executionPlatformId;
        campaign.executionPlatformGroups[this.groupNumber].universeId = universe?.id;
        campaign.executionPlatformGroups[this.groupNumber].universe = universe;
        campaign.onPropertyChange$.next("executionPlatformGroups");
      }
    }
  }
}
