import { Qualifier } from "../models";
import { ViewModel } from "./view-model.viewmodel";

/**
 * Represents a qualifier view model in RADR
 *
 * @export
 * @class QualifierViewModel
 */
export class QualifierViewModel extends Qualifier implements ViewModel {
  constructor(qualifier: any = {}) {
    super();
    Object.assign(this, qualifier);
  }
  /**
   * All the distinct values for the qualifier
   * @type {string[]}
   * @memberof Qualifier
   */
  distinctValues: any[];
  /**
   * Can this qualifier be edited in it's current state
   */
  get canEdit(): boolean {
    return true;
  }
}
