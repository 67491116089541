import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "@shared/services/auth.service";
import { BehaviorSubject, interval, merge, Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "radr-session-timeout",
  templateUrl: "./session-timeout.component.html",
  styleUrls: ["./session-timeout.component.scss"]
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {
  private readonly originalTitle: string = document.title;
  public remainingTime$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public timedOut$: Subject<boolean> = new Subject<boolean>();
  public timedOut: boolean = false;
  private cleanUp$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<SessionTimeoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    const stopConditions$: Observable<boolean | void> = merge(this.cleanUp$, this.timedOut$);
    this.remainingTime$.next(this.formatTime(this.data.initialTime));
    interval(1000)
      .pipe(takeUntil(stopConditions$))
      .subscribe(() => {
        const remainingSeconds: number = this.authService.getRemainingExpirySeconds();
        if (remainingSeconds <= 0) {
          this.remainingTime$.next(this.formatTime(0));
          this.timedOut$.next(true);
          this.timedOut$.complete();
        } else {
          this.remainingTime$.next(this.formatTime(remainingSeconds));
        }
      });

    this.timedOut$.pipe(take(1)).subscribe(() => {
      document.title = this.originalTitle + " (Logged out due to inactivity)";
      this.timedOut = true;
    });

    this.remainingTime$.pipe(takeUntil(stopConditions$)).subscribe((remainingTime: string) => {
      document.title = `Log out - (${remainingTime})`;
    });
  }

  private formatTime(seconds: number): string {
    let min: string | number = Math.floor(seconds / 60);
    let sec: string | number = seconds - min * 60;

    if (min < 10) min = "0" + min;
    if (sec < 10) sec = "0" + sec;
    return min + ":" + sec;
  }

  ngOnDestroy(): void {
    this.cleanUp$.next();
    this.cleanUp$.complete();
    document.title = this.originalTitle;
  }

  okClicked(): void {
    this.dialogRef.close(true);
  }
}
