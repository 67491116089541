import { ProfileCountRequestFactory } from "./profile-count-request.factory";
import { CampaignCountRequest, Universe } from "../models";
import { Campaign } from "../models/campaign.model";

export class CampaignCountRequestFactory {
  public static create(campaign: Campaign, universes: Universe[]): CampaignCountRequest {
    const campaignCountRequest: CampaignCountRequest = {
      campaign: {
        isAddressable: campaign.isAddressable,
        capFixed: campaign.capFixed,
        capPercentage: campaign.capPercentage,
        profiles: campaign.profiles.map(profile => ProfileCountRequestFactory.create(profile, campaign)),
        status: campaign.status,
        cappingLevel: campaign.cappingLevel,
        controlLevel: campaign.controlLevel,
        controlPercentage: campaign.controlPercentage,
        isGross: campaign.isGross
      },
      countOptions: {
        universes,
        isGross: campaign.isGross
      }
    };

    return campaignCountRequest;
  }
}
