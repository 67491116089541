import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, NgForm, FormArray } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import forEach from "lodash.foreach";

export interface FormControlErrorMetaData {
  name: string;
  errorMessage: string;
}

export type FormControlType = "input" | "checkbox" | "select" | "select-autocomplete" | "radio" | "hidden" | "textarea";

export type InputType = "text" | "number" | "password" | "email";

export interface ControlOption {
  label: string;
  value: any;
  disabled?: boolean;
}

export interface EnhancedFormControl extends FormControl {
  isRequired?: boolean;
  isLoading?: boolean;
  label: string;
  name: string;
  placeholder?: string;
  type?: FormControlType;
  options?: ControlOption[];
  showEmptyOption?: boolean;
  inputType?: InputType;
  errorsMetaData?: FormControlErrorMetaData[];
  hint?: string;
  suffix?: string;
  initialValue?: any;
  multiple?: boolean;
  multiSelectDisplayMaxLen?: number;
  onFocus?: () => any;
  onBlur?: () => any;
}

export const enhancedFormControlFactory: (
  formControl: FormControl,
  data: Partial<EnhancedFormControl>
) => EnhancedFormControl = (formControl, data) => {
  const enhancedFormControl: EnhancedFormControl = formControl as EnhancedFormControl;
  forEach(data, (value: any, key: string) => {
    enhancedFormControl[key] = value;
  });
  return enhancedFormControl;
};

export class EnhancedFormGroup extends FormGroup {
  controls: {
    [key: string]: EnhancedFormControl | FormArray;
  };

  constructor(controls?: { [key: string]: EnhancedFormControl | FormArray }) {
    super(controls || {});
  }
}

export class EnhancedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: "radr-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"]
})
export class FormComponent {
  @Input() class: string = "radr-form";
  @Input() formGroup: EnhancedFormGroup = new EnhancedFormGroup();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  public errorStateMatcher: EnhancedErrorStateMatcher = new EnhancedErrorStateMatcher();

  get formControls(): EnhancedFormControl[] {
    return [].concat(...Object.values(this.formGroup.controls)); // if nested arrays are present, flatten into single array
  }

  public buildSelectAutocompleteOptions(options?: ControlOption[]): any {
    return (options || []).map(({ value }) => value);
  }

  public onSubmit(): void {
    if (!this.formGroup.pending && this.formGroup.valid) {
      this.formSubmit.emit();
    }
  }
}
