import { Component, ContentChild, Inject, Input, TemplateRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Router, NavigationEnd } from "@angular/router";
import { filter, take } from "rxjs/operators";

interface RadrModalOptions {
  title?: string;
  subtitle?: string;
}

@Component({
  selector: "radr-modal",
  templateUrl: "./radr-modal.component.html",
  styleUrls: ["./radr-modal.component.scss"]
})
export class RadrModalComponent {
  @ContentChild(TemplateRef, { static: true }) modalContent: any;
  @Input() toolbarColor: string = "primary";
  @Input() warningIcon: boolean = false;
  @Input() closeDisabled: boolean = false;
  public title: string;
  public subtitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: RadrModalOptions,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.handleNavEndEvent(this.router);
  }

  handleNavEndEvent(router: Router): void {
    router.events
      .pipe(
        filter(e => e instanceof NavigationEnd && e.urlAfterRedirects.includes("login")),
        take(1)
      )
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }
}
