<div *ngIf="getHeaderTemplate() as template">
  <div [ngClass]="getClassNames('header')">
    <ng-container *ngTemplateOutlet="template; context: getHeaderContext()"></ng-container>
  </div>
</div>

<div [ngClass]="getClassNames('switchRow')" #switchRow>
  <ng-template #defaultArrowIcon>
    <i [ngClass]="getClassNames('arrowIcon')"></i>
  </ng-template>

  <a
    *ngIf="allowCollapse"
    (click)="toggleCollapse()"
    [ngClass]="getClassNames('arrowIconButton', data.collapsed ? 'collapsed' : null)"
  >
    <ng-container *ngIf="getArrowIconTemplate() as template; else defaultArrowIcon">
      <ng-container *ngTemplateOutlet="template; context: getArrowIconContext()"></ng-container>
    </ng-container>
  </a>

  <ng-container *ngIf="getButtonGroupTemplate() as template; else defaultButtonGroup">
    <div [ngClass]="getClassNames('buttonGroup', 'rightAlign')">
      <ng-container *ngTemplateOutlet="template; context: getButtonGroupContext()"></ng-container>
    </div>
  </ng-container>

  <ng-template #defaultButtonGroup>
    <div [ngClass]="getClassNames('buttonGroup', 'rightAlign')">
      <button type="button" (click)="addRule()" [ngClass]="getClassNames('button')" [disabled]="disabled">
        <i [ngClass]="getClassNames('addIcon')"></i> Rule
      </button>
      <button
        type="button"
        (click)="addRuleSet()"
        [ngClass]="getClassNames('button')"
        *ngIf="allowRuleset"
        [disabled]="disabled"
      >
        <i [ngClass]="getClassNames('addIcon')"></i> Ruleset
      </button>
      <ng-container *ngIf="!!parentValue && allowRuleset">
        <button
          type="button"
          (click)="removeRuleSet()"
          [ngClass]="getClassNames('button', 'removeButton')"
          [disabled]="disabled"
        >
          <i [ngClass]="getClassNames('removeIcon')"></i>
        </button>
      </ng-container>
    </div>
  </ng-template>

  <ng-container *ngIf="getSwitchGroupTemplate() as template; else defaultSwitchGroup">
    <ng-container *ngTemplateOutlet="template; context: getSwitchGroupContext()"></ng-container>
  </ng-container>

  <ng-template #defaultSwitchGroup>
    <div [ngClass]="getClassNames('switchGroup', 'transition')" *ngIf="data">
      <div [ngClass]="getClassNames('switchControl')">
        <input
          type="radio"
          [ngClass]="getClassNames('switchRadio')"
          [(ngModel)]="data.condition"
          [disabled]="disabled"
          value="and"
          #andOption
        />
        <label (click)="changeCondition(andOption.value)" [ngClass]="getClassNames('switchLabel')">AND</label>
      </div>
      <div [ngClass]="getClassNames('switchControl')">
        <input
          type="radio"
          [ngClass]="getClassNames('switchRadio')"
          [(ngModel)]="data.condition"
          [disabled]="disabled"
          value="or"
          #orOption
        />
        <label (click)="changeCondition(orOption.value)" [ngClass]="getClassNames('switchLabel')">OR</label>
      </div>
    </div>
  </ng-template>
</div>

<div
  #treeContainer
  (transitionend)="transitionEnd($event)"
  [ngClass]="getClassNames('treeContainer', data.collapsed ? 'collapsed' : null)"
>
  <ul
    *ngIf="data && data.rules"
    [ngClass]="getClassNames('tree')"
    [id]="'drop-list-' + dropListGuid"
    cdkDropList
    [cdkDropListConnectedTo]="connectedDropLists"
    [cdkDropListDisabled]="disabled"
    [cdkDropListEnterPredicate]="canDropPredicate()"
    (cdkDropListDropped)="onDragDrop($event)"
  >
    <ng-container *ngFor="let rule of data.rules; let i = index">
      <ng-container
        *ngIf="{
          ruleset: !!rule.rules,
          invalid: !config.allowEmptyRulesets && rule.rules && rule.rules.length === 0,
          rule: rule | setAudienceBuilderConfig: config : fields : operatorMap : updateSegmentIds | async
        } as local"
      >
        <!-- disabled below for not moving groups -->
        <li
          #ruleListItem
          [ngClass]="getQueryItemClassName(local)"
          class="q-attribute"
          cdkDropList
          [cdkDropListConnectedTo]="connectedDropLists"
          [cdkDropListDisabled]="disabled"
          cdkDrag
          [cdkDragData]="rule"
          [cdkDragDisabled]="disabled || local.ruleset"
          (cdkDragMoved)="onDragMove($event)"
          (cdkDragReleased)="onDragRelease($event)"
        >
          <ng-container *ngIf="rule?.loading; else qualLoaded">
            <mat-progress-spinner
              id="counts-processing-indicator"
              mode="indeterminate"
              diameter="17"
            ></mat-progress-spinner>
            <span class="audience-loading">Audience is loading, please wait</span>
          </ng-container>
          <!-- bad styling as placeholder for groups, will fix in defect ticket -->
          <ng-template #qualLoaded>
            <div class="drag-audience-preview" *cdkDragPreview [id]="dropListGuid + '-preview'">
              <span>{{ getDragPreivewName(rule) }}</span>
              <i class="material-icons"> drag_indicator </i>
            </div>
            <div class="drop-audience-placeholder" *cdkDragPlaceholder></div>
            <ng-container *ngIf="!local.ruleset && local.rule as rule">
              <div *ngIf="entities?.length > 0" class="q-inline-block-display">
                <ng-container *ngIf="getEntityTemplate() as template; else defaultEntity">
                  <ng-container *ngTemplateOutlet="template; context: getEntityContext(rule)"></ng-container>
                </ng-container>
              </div>

              <ng-template #defaultEntity>
                <div [ngClass]="getClassNames('entityControlSize')">
                  <select
                    [ngClass]="getClassNames('entityControl')"
                    [(ngModel)]="rule.entity"
                    (ngModelChange)="changeEntity($event, rule, i, data)"
                    [disabled]="disabled"
                  >
                    <option *ngFor="let entity of entities" [ngValue]="entity.value">
                      {{ entity.name }}
                    </option>
                  </select>
                </div>
              </ng-template>

              <ng-container *ngIf="getFieldTemplate() as template; else defaultField">
                <ng-container *ngTemplateOutlet="template; context: getFieldContext(rule)"></ng-container>
              </ng-container>

              <ng-template #defaultField>
                <div [ngClass]="getClassNames('fieldControlSize')">
                  <select
                    [ngClass]="getClassNames('fieldControl')"
                    [(ngModel)]="rule.field"
                    (ngModelChange)="changeField($event, rule)"
                    [disabled]="disabled || true"
                    [class.tune-in-audience-name]="rule.audienceType === 2"
                  >
                    <option *ngFor="let field of getFields(rule.entity)" [ngValue]="field.value">
                      {{ field.name }}
                    </option>
                  </select>
                </div>
              </ng-template>

              <ng-container *ngIf="getOperatorTemplate(rule.audienceType) as template; else defaultOperator">
                <ng-container *ngTemplateOutlet="template; context: getOperatorContext(rule)"></ng-container>
              </ng-container>

              <ng-template #defaultOperator>
                <div [ngClass]="getClassNames('operatorControlSize')" *ngIf="rule.audienceType !== 2">
                  <select
                    [ngClass]="getClassNames('operatorControl')"
                    [(ngModel)]="rule.operator"
                    (ngModelChange)="changeOperator(rule)"
                    [disabled]="disabled"
                  >
                    <option *ngFor="let operator of getOperators(rule.field)" [ngValue]="operator">
                      {{ operator }}
                    </option>
                  </select>
                </div>
              </ng-template>

              <ng-container *ngIf="findTemplateForRule(rule) as template; else defaultInput">
                <ng-container *ngTemplateOutlet="template; context: getInputContext(rule)"></ng-container>
              </ng-container>
              <div *ngIf="getEpsilonHintText(rule)" style="color: red">Epsilon Audiences use YYYYMM format</div>
              <ng-template #defaultInput>
                <div
                  [ngClass]="getClassNames('inputControlSize')"
                  [ngSwitch]="getInputType(rule.field, rule.operator, rule.tuneInType)"
                >
                  <input
                    [ngClass]="getClassNames('inputControl')"
                    [(ngModel)]="rule.value"
                    (ngModelChange)="changeInput(rule)"
                    [disabled]="disabled"
                    *ngSwitchCase="'string'"
                    type="text"
                  />
                  <input
                    [ngClass]="getClassNames('inputControl')"
                    [(ngModel)]="rule.value"
                    (ngModelChange)="changeInput(rule)"
                    [disabled]="disabled"
                    *ngSwitchCase="'number'"
                    type="number"
                  />
                  <input
                    [ngClass]="getClassNames('inputControl')"
                    [(ngModel)]="rule.value"
                    (ngModelChange)="changeInput(rule)"
                    [disabled]="disabled"
                    *ngSwitchCase="'date'"
                    type="date"
                  />
                  <input
                    [ngClass]="getClassNames('inputControl')"
                    [(ngModel)]="rule.value"
                    (ngModelChange)="changeInput(rule)"
                    [disabled]="disabled"
                    *ngSwitchCase="'time'"
                    type="time"
                  />
                  <select
                    [ngClass]="getClassNames('inputControl')"
                    [(ngModel)]="rule.value"
                    (ngModelChange)="changeInput(rule)"
                    [disabled]="disabled"
                    *ngSwitchCase="'category'"
                  >
                    <option *ngFor="let opt of getOptions(rule.field)" [ngValue]="opt.value">
                      {{ opt.name }}
                    </option>
                  </select>
                  <ng-container *ngSwitchCase="'multiselect'">
                    <select
                      [ngClass]="getClassNames('inputControl')"
                      [(ngModel)]="rule.value"
                      (ngModelChange)="changeInput(rule)"
                      [disabled]="disabled"
                      multiple
                    >
                      <option *ngFor="let opt of getOptions(rule.field)" [ngValue]="opt.value">
                        {{ opt.name }}
                      </option>
                    </select>
                  </ng-container>
                  <input
                    [ngClass]="getClassNames('inputControl')"
                    [(ngModel)]="rule.value"
                    (ngModelChange)="changeInput(rule)"
                    [disabled]="disabled"
                    *ngSwitchCase="'boolean'"
                    type="checkbox"
                  />
                  <ng-container *ngSwitchCase="'tune-in-program'">
                    <radr-select-autocomplete
                      [(value)]="rule.value"
                      tuneInType="Program"
                      (selectionChange)="changeTuneInInput(rule, { program: $event })"
                      [loadOptionsOnOpen]="false"
                      [disabled]="disabled"
                      [searchBackend]="true"
                      searchEndpoint="qualifiers/tune_in_programs?searchFilter="
                      optionsCache="tuneInPrograms"
                      class="tune-in-autocomplete"
                      placeholder="Select Program(s)"
                      data-test="tuneInProgram"
                      formFieldAppearance="outline"
                    ></radr-select-autocomplete>
                    <mat-form-field appearance="outline" class="tune-in-air-date">
                      <mat-label>Original Air Date Start</mat-label>
                      <input
                        matInput
                        [matDatepicker]="startDate"
                        [max]="getMaxOriginalAirStart(rule)"
                        (dateChange)="changeTuneInInput(rule, { originalAirDateStart: $event.value })"
                        #refStart="ngModel"
                        [(ngModel)]="rule.originalAirDateStart"
                        name="originalAirDateStart"
                      />
                      <mat-datepicker #startDate (closed)="datePickerOnClosed(refStart)"></mat-datepicker>
                      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                      <mat-error id="radr-form-error-date-format" *ngIf="refStart.touched && refStart.invalid">
                        {{ getOriginalAirDateErrors(rule, refStart) }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="tune-in-air-date">
                      <mat-label>Original Air Date End</mat-label>
                      <input
                        matInput
                        [matDatepicker]="endDate"
                        [min]="getMinOriginalAirEnd(rule)"
                        (dateChange)="changeTuneInInput(rule, { originalAirDateEnd: $event.value })"
                        #refEnd="ngModel"
                        [(ngModel)]="rule.originalAirDateEnd"
                        name="originalAirDateEnd"
                      />
                      <mat-datepicker #endDate (closed)="datePickerOnClosed(refEnd)"></mat-datepicker>
                      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                      <mat-error id="radr-form-error-date-format" *ngIf="refEnd.touched && refEnd.invalid">
                        {{ getOriginalAirDateErrors(rule, refEnd) }}
                      </mat-error>
                    </mat-form-field>
                    <radr-select-autocomplete
                      [options]="tuneInViewershipOptions"
                      [(value)]="rule.tercile"
                      (selectionChange)="changeTuneInInput(rule, { viewershipLevels: $event })"
                      [disabled]="disabled"
                      class="tune-in-autocomplete"
                      data-test="tuneInViewershipLevel"
                      placeholder="Select Viewership Level"
                      formFieldAppearance="outline"
                    ></radr-select-autocomplete>
                  </ng-container>
                  <ng-container *ngSwitchCase="'tune-in-station'">
                    <radr-select-autocomplete
                      [(value)]="rule.value"
                      tuneInType="Station"
                      (selectionChange)="changeTuneInInput(rule, { station: $event })"
                      [loadOptionsOnOpen]="false"
                      [disabled]="disabled"
                      [searchBackend]="true"
                      searchEndpoint="qualifiers/tune_in_stations?searchFilter="
                      optionsCache="tuneInStations"
                      class="tune-in-autocomplete"
                      placeholder="Select Station(s)"
                      data-cy="tuneInStation"
                      formFieldAppearance="outline"
                    ></radr-select-autocomplete>
                    <radr-select-autocomplete
                      [options]="tuneInViewershipOptions"
                      [(value)]="rule.tercile"
                      (selectionChange)="changeTuneInInput(rule, { viewershipLevels: $event })"
                      [disabled]="disabled"
                      class="tune-in-autocomplete"
                      data-test="tuneInViewershipLevel"
                      placeholder="Select Viewership Level"
                      formFieldAppearance="outline"
                    ></radr-select-autocomplete>
                  </ng-container>
                  <ng-container *ngSwitchCase="'tune-in-genre'">
                    <radr-select-autocomplete
                      [(value)]="rule.value"
                      (selectionChange)="changeTuneInInput(rule, { genre: $event })"
                      [loadOptionsOnOpen]="false"
                      tuneInType="Genre"
                      [disabled]="disabled"
                      [searchBackend]="true"
                      searchEndpoint="qualifiers/tune_in_genres?searchFilter="
                      optionsCache="tuneInGenres"
                      class="tune-in-autocomplete"
                      placeholder="Select Genre(s)"
                      data-test="tuneInGenre"
                      formFieldAppearance="outline"
                    ></radr-select-autocomplete>
                    <radr-select-autocomplete
                      [options]="tuneInViewershipOptions"
                      [(value)]="rule.tercile"
                      (selectionChange)="changeTuneInInput(rule, { viewershipLevels: $event })"
                      [disabled]="disabled"
                      class="tune-in-autocomplete"
                      data-test="tuneInViewershipLevel"
                      placeholder="Select Viewership Level"
                      formFieldAppearance="outline"
                    ></radr-select-autocomplete>
                  </ng-container>
                  <ng-container *ngSwitchCase="'tune-in-scheduling'">
                    <mat-form-field class="tune-in-schedule-input" appearance="outline">
                      <input
                        matInput
                        [placeholder]="getTuneInSchedulePlaceholder(rule.value)"
                        readonly
                        (click)="addNewSchedule(rule)"
                      />
                    </mat-form-field>
                    <radr-select-autocomplete
                      [options]="tuneInViewershipOptions"
                      [(value)]="rule.tercile"
                      (selectionChange)="changeTuneInInput(rule, { viewershipLevels: $event })"
                      [disabled]="disabled"
                      class="tune-in-autocomplete"
                      data-test="tuneInViewershipLevel"
                      placeholder="Select Viewership Level"
                      formFieldAppearance="outline"
                    ></radr-select-autocomplete>
                  </ng-container>
                </div>
              </ng-template>
              <ng-container *ngIf="getRemoveButtonTemplate() as template; else defaultRemoveButton">
                <div [ngClass]="getClassNames('buttonGroup', 'rightAlign')">
                  <ng-container *ngTemplateOutlet="template; context: getRemoveButtonContext(rule)"></ng-container>
                </div>
              </ng-container>

              <ng-template #defaultRemoveButton>
                <div [ngClass]="getClassNames('removeButtonSize', 'rightAlign')">
                  <button
                    mat-icon-button
                    type="button"
                    [ngClass]="getClassNames('button', 'removeButton')"
                    (click)="removeRule(rule, data)"
                    [disabled]="disabled"
                  >
                    <i [ngClass]="getClassNames('removeIcon')"></i>
                  </button>
                </div>
              </ng-template>
            </ng-container>
            <radr-audience-query-builder
              *ngIf="local.ruleset"
              [data]="rule"
              [disabled]="disabled"
              [parentTouchedCallback]="parentTouchedCallback || onTouchedCallback"
              [parentChangeCallback]="parentChangeCallback || onChangeCallback"
              [parentInputTemplates]="parentInputTemplates || inputTemplates"
              [parentOperatorTemplate]="parentOperatorTemplate || operatorTemplate"
              [parentFieldTemplate]="parentFieldTemplate || fieldTemplate"
              [parentEntityTemplate]="parentEntityTemplate || entityTemplate"
              [parentSwitchGroupTemplate]="parentSwitchGroupTemplate || switchGroupTemplate"
              [parentButtonGroupTemplate]="parentButtonGroupTemplate || buttonGroupTemplate"
              [parentRemoveButtonTemplate]="parentRemoveButtonTemplate || removeButtonTemplate"
              [parentEmptyWarningTemplate]="parentEmptyWarningTemplate || emptyWarningTemplate"
              [parentArrowIconTemplate]="parentArrowIconTemplate || arrowIconTemplate"
              [parentValue]="data"
              [classNames]="classNames"
              [config]="config"
              [allowRuleset]="allowRuleset"
              [allowCollapse]="allowCollapse"
              [emptyMessage]="emptyMessage"
              [operatorMap]="operatorMap"
              (updateSegmentIds)="updateSegmentIds.emit($event)"
              [audience]="audience"
            >
            </radr-audience-query-builder>

            <ng-container *ngIf="getEmptyWarningTemplate() as template">
              <ng-container *ngIf="local.invalid">
                <ng-container *ngTemplateOutlet="template; context: getEmptyWarningContext()"></ng-container>
              </ng-container>
            </ng-container>
            <div cdkDragHandle *ngIf="!local.ruleset && !disabled" class="drag-audience-icon">
              <i class="material-icons"> drag_indicator </i>
            </div>
          </ng-template>
          <!-- <ng-template #defaultEmptyWarning>
            <p [ngClass]="getClassNames('emptyWarning')" *ngIf="local.invalid">
              {{emptyMessage}}
            </p>
          </ng-template> -->
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
