import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { TableConfigModel } from "@shared/models";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ProfilesService } from "@shared/services/profiles.service";
import { RoleAccessService } from "@shared/services/role-access.service";
import { CampaignViewModel } from "radr-shared";
import { Audience, AudienceStatusEnum, AudienceValueCounts } from "radr-shared";
import { AudiencesService } from "@shared/services/audience.service";

@Component({
  selector: "radr-audience-details-modal",
  templateUrl: "./audience-details-modal.component.html",
  styleUrls: ["./audience-details-modal.component.scss"]
})
export class AudienceDetailsModalComponent implements OnInit {
  public isLoading: boolean = false;
  public audience: Audience;
  public audienceValueCounts: AudienceValueCounts[];
  public audienceDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public audienceDataSourceTableConfig: TableConfigModel;
  public campaign: CampaignViewModel;
  public audienceSelection: FormControl = new FormControl();
  public availableProfiles: any[] = [];
  public audienceStatusEnum: typeof AudienceStatusEnum = AudienceStatusEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AudienceDetailsModalComponent>,
    private audiencesService: AudiencesService,
    private campaignBuilderService: CampaignBuilderService,
    private profilesService: ProfilesService,
    private roleAccessService: RoleAccessService
  ) {}

  ngOnInit(): void {
    this.setTableConfig();
    this.getAudienceDetailsAndValueCounts(this.data.audience?.id);
    this.loadAvailableProfiles();
  }

  canAddAudience(): boolean {
    const hasValue: boolean = this.audienceSelection.value && this.audienceSelection.value.length !== 0;
    if (this.roleAccessService.isAdmin()) {
      return this.campaign.canEdit && hasValue;
    } else {
      return this.campaign.canEdit && hasValue && this.roleAccessService.isEditor(this.campaign);
    }
  }

  setTableConfig(): void {
    this.audienceDataSourceTableConfig = {
      headerTopBorder: true,
      isSortable: true,
      displayColumns: ["value", "count"],
      columns: [
        {
          name: "value",
          title: "audience Details",
          field: "value",
          width: "50%"
        },
        {
          name: "count",
          title: "Counts",
          field: "count",
          width: "50%"
        }
      ]
    };
  }

  // TODO: Counts are going to be handled in a new story
  //
  getAudienceDetailsAndValueCounts(id: number): void {
    this.isLoading = true;
    this.audiencesService.getAudience(id).subscribe(audience => {
      this.audience = audience;
    });
    //   this.audiencesService.getAudienceValueCounts(id).subscribe(audienceValueCounts => {
    //     if (audienceValueCounts.length) {
    //       this.audienceValueCounts = audienceValueCounts;
    //       this.setDataSource();
    //     }
    //     this.isLoading = false;
    //   });
    this.isLoading = false;
  }

  // setDataSource(): void {
  //   this.audienceDataSource.data = this.audienceValueCounts;
  // }

  loadAvailableProfiles(): void {
    this.campaign = this.campaignBuilderService.getSelectedItem();
    this.availableProfiles = this.campaign.profiles.slice();
    this.availableProfiles.push({ name: "New Profile" });
    if (this.data.audience.audienceType === 2) {
      this.availableProfiles = this.availableProfiles.filter(profile => {
        return profile.profileType === 2;
      });
    }
  }

  buildSelectAutocompleteOptions(): string[] {
    return this.availableProfiles.map((profile, i) => profile.name || `Unnamed Profile ${i + 1}`);
  }

  addAudienceToProfiles(selectedProfiles: string[]): void {
    // TODO: Audiences can't be dragged into profiles yet

    if (selectedProfiles.includes("New Profile")) {
      this.campaignBuilderService.addNewProfile(null, null, true);
    }

    const profilesToAlter: any[] = selectedProfiles.map(selection => {
      if (selection === "New Profile") {
        return this.campaign.profiles[this.campaign.profiles.length - 1];
      }

      return this.campaign.profiles.find((profile, i) => {
        return profile.name === selection || selection === `Unnamed Profile ${i + 1}`;
      });
    });

    if (this.audience.dataSource?.canoeCampaignName && this.audience.dataSource?.canoeCampaignId) {
      this.campaignBuilderService.setCanoeCampaignControlValues(
        this.audience.dataSource?.canoeCampaignName,
        this.audience.dataSource?.canoeCampaignId
      );
    }

    setTimeout(() => this.pushNewAudienceToProfiles(profilesToAlter), 1);
    this.close();
  }

  pushNewAudienceToProfiles(profilesToAlter: string[]): void {
    this.profilesService.addAudienceToProfile.next({ audience: this.audience, profiles: profilesToAlter });
  }

  close(): void {
    this.dialogRef.close();
  }
}
