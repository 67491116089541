import { AsyncValidatorFn, AbstractControl, Validators, ValidatorFn } from "@angular/forms";
import get from "lodash.get";
import { Observable, timer } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { FormControlErrorMetaData } from "./form.component";

export function forbiddenNameValidatorFactory(
  isUniqueFn: (name: string, initialValue?: any) => Observable<boolean>
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
    return timer(500).pipe(
      switchMap(() =>
        isUniqueFn(control.value, get(control, "initialValue")).pipe(
          map(isUnique => (isUnique ? null : { forbiddenName: { value: control.value } })),
          catchError(() => null)
        )
      )
    );
  };
}

/**
 * Generate a list of validators: one enforcing required, one enforcing a max char length
 */
export const generateRequiredMaxLengthValidators: (
  maxLength: number,
  options?: { isRequired?: boolean }
) => ValidatorFn[] = (maxLength, options = {}) => {
  const validators: ValidatorFn[] = [Validators.maxLength(maxLength)];
  const { isRequired = true } = options;
  if (isRequired) {
    validators.unshift(Validators.required);
  }
  return validators;
};

const alphaNumericValidatorErrName: string = "alphanumeric";

/**
 * Custom validator for enforcing alphanumeric-only inputs
 */
export const alphaNumericValidator: ValidatorFn = (control: AbstractControl) => {
  const value: any = get(control, "value");
  if (typeof value === "string" && value && !(value as string).match(/^[a-z0-9]+$/gi)) {
    return { [alphaNumericValidatorErrName]: { value } };
  }
  return null;
};

// const listAlphaNumericValidatorErrName: string = "alphanumeric";

/**
 * Custom validator for enforcing correct list with alphanumeric-only inputs
 */
export const listAlphaNumericValidator: ValidatorFn = (control: AbstractControl) => {
  const value: any = get(control, "value");
  if (typeof value === "string" && value && value.includes(",")) {
    const list = value.split(",").map((tag: string) => tag.trim());
    const invalidValue = list.find((item: string) => !(item as string).match(/^[a-z0-9]+$/gi));
    if (invalidValue) {
      return { [alphaNumericValidatorErrName]: { value } };
    }
  }
  return null;
};

const startsWithAlphaNumericValidationErrName: string = "startsWithAlphaNumeric";

/**
 * Ensure the input starts with an alphanumeric character
 */
export const startsWithAlphaNumericValidator: ValidatorFn = (control: AbstractControl) => {
  const value: any = control.value;
  if (typeof value === "string" && value && !(value as string).match(/^[a-z0-9]/i)) {
    return { [startsWithAlphaNumericValidationErrName]: { value } };
  }
  return null;
};

const forbiddenSpacesValidatorErrName: string = "forbiddenSpaces";
/**
 * Custom validator for enforcing no spaces
 */
export const forbiddenSpacesValidator: ValidatorFn = (control: AbstractControl) => {
  const value: any = get(control, "value");
  if (typeof value === "string" && (value as string).match(/\s/g)) {
    return { [forbiddenSpacesValidatorErrName]: { value } };
  }
  return null;
};

const multipleEmailsValidatorErrName: string = "multipleEmails";
/**
 * Custom validator for multiple email addresses separated by commas
 */
export const multipleEmailsValidator: ValidatorFn = (control: AbstractControl) => {
  const value: any = get(control, "value");
  if (typeof value === "string" && value) {
    const emails: string[] = (value as string).split(",");
    const invalidEmail: string = emails.find(email => !email.match(/^\s*[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}\s*$/i));
    if (invalidEmail) {
      return { [multipleEmailsValidatorErrName]: { value } };
    }
  }
  return null;
};

/**
 * Custom validator for limiting the number of options in a select
 */
export function optionsOverflowValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const options: any = get(control, "options", []);
    const value: any = get(control, "value");
    if (options.length > 100 && !value) {
      return { overflow: true };
    }
    return null;
  };
}

export const generateRequiredErrorMetaData: () => FormControlErrorMetaData = () => ({
  name: "required",
  errorMessage: "You must specify a value."
});

export const generateRequiredMaxLengthErrorsMetaData: (maxLength: number) => FormControlErrorMetaData[] = maxLength => [
  generateRequiredErrorMetaData(),
  {
    name: "maxlength",
    errorMessage: `Value must be ${maxLength} characters or fewer.`
  }
];

export const generateMinValueErrorMetaData: (minValue: number) => FormControlErrorMetaData = minValue => ({
  name: "min",
  errorMessage: `Value must be greater than ${minValue - 1}`
});

/**
 * Custom error meta data for use in conjunction with forbiddenSpacesValidator
 */
export const forbiddenSpacesErrorMetaData: FormControlErrorMetaData = {
  name: forbiddenSpacesValidatorErrName,
  errorMessage: "Value cannot contain spaces"
};

export const alphanumericErrorMetaData: FormControlErrorMetaData = {
  name: alphaNumericValidatorErrName,
  errorMessage: "Value can only contain numbers and letters"
};
