/**
 * This file is meant to provide more granularity and
 */

export abstract class ClientError extends Error {
  private fingerprintPrefix: string = "client";
  protected fingerprintId: string;
  public functionName: string;
  public get fingerprint(): string {
    return this.fingerprintPrefix + "-" + this.fingerprintId;
  }

  constructor(message: string, functionName?: string) {
    super(message);
    this.functionName = functionName;
  }
}

export function errorFunctionNameFactory(originalError: ClientError, newFunctionName: string): ClientError {
  if (originalError instanceof SessionTimeoutError) {
    return new SessionTimeoutError(originalError.message, newFunctionName);
  } else if (originalError instanceof ProgrammerError) {
    return new ProgrammerError(originalError.message, newFunctionName);
  } else if (originalError instanceof GatewayTimeoutError) {
    return new GatewayTimeoutError(originalError.message, newFunctionName);
  } else if (originalError instanceof UnknownError) {
    return new UnknownError(originalError.message, newFunctionName);
  } else if (originalError instanceof ProfilesOutOfOrderError) {
    return new ProfilesOutOfOrderError(originalError.message, newFunctionName);
  } else if (originalError instanceof ProfileNotFoundError) {
    return new ProfileNotFoundError(originalError.message, newFunctionName);
  } else if (originalError instanceof CampaignNotFoundError) {
    return new CampaignNotFoundError(originalError.message, newFunctionName);
  } else if (originalError instanceof S3PresignedUrlError) {
    return new S3PresignedUrlError(originalError.message, newFunctionName);
  }
}

/**
 * Use this if the SSO session has timed out.
 * NOTE: This error will not be sent to Sentry due to noise.
 */
export class SessionTimeoutError extends ClientError {
  protected fingerprintId: string = "session-timeout";
}

/**
 * Use this if there is a specific way to use a class, service, or component
 * and a misuse of the interface by a programmer would result in an error.
 */
export class ProgrammerError extends ClientError {
  protected fingerprintId: string = "programmer-error";
}

/**
 * Use this for gateway timeouts since sometimes they may be out of
 * our control.
 */
export class GatewayTimeoutError extends ClientError {
  protected fingerprintId: string = "gateway-timeout-error";
}

/**
 * Use this if you don't know what would cause a failure here,
 * but you want to be sure any error is handled gracefully.
 */
export class UnknownError extends ClientError {
  protected fingerprintId: string = "unknown-error";
}
/**
 * Use this if tune-in report query doesn't have any matching
 * results
 */
export class EmptyTuneInQuery extends ClientError {
  protected fingerprintId: string = "empty-tune-in-query";
}

/**
 * Use if we detect that profiles are out of priority order in the builder.
 */
export class ProfilesOutOfOrderError extends ClientError {
  protected fingerprintId: string = "profiles-out-of-order-error";
}

/**
 * Use this if you are expecting to manipulate or show a profile that
 * doesn't exist.
 */
export class ProfileNotFoundError extends ClientError {
  protected fingerprintId: string = "profile-not-found-error";
}

/**
 * Use this if you are expecting to manipulate or show an audience that
 * doesn't exist.
 */
export class AudienceNotFoundError extends ClientError {
  protected fingerprintId: string = "audience-not-found-error";
}

/**
 * Use this if you are expecting to manipulate or show a campaign that
 * doesn't exist.
 */
export class CampaignNotFoundError extends ClientError {
  protected fingerprintId: string = "campaign-not-found-error";
}

/**
 * Use this if there was an error getting an s3 presigned url from
 * the backend.
 */
export class S3PresignedUrlError extends ClientError {
  protected fingerprintId: string = "s3-presigned-url-error";
}
