<div class="toggle-group-container">
  <mat-button-toggle-group [(value)]="qualifierCampaignToggle">
    <mat-button-toggle
      id="campaign-builder-left-menu-qualifiers-toggle-btn"
      class="toggle-group-button"
      data-qa="qualifiers-toggle-button"
      [value]="qualifierCampaignToggleValues.Qualifiers"
      aria-label="select qualifiers"
    >
      <div class="toggle-button-content">QUALIFIERS</div>
    </mat-button-toggle>
    <mat-button-toggle
      id="campaign-builder-left-menu-profiles-toggle-btn"
      class="toggle-group-button"
      data-qa="profiles-toggle-button"
      [value]="qualifierCampaignToggleValues.Profiles"
      aria-label="select profiles"
    >
      <div class="toggle-button-content">PROFILES</div>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<mat-form-field [ngSwitch]="qualifierCampaignToggle" appearance="fill" class="search-container">
  <mat-label *ngSwitchCase="qualifierCampaignToggleValues.Qualifiers"> Search Qualifiers </mat-label>
  <mat-label *ngSwitchCase="qualifierCampaignToggleValues.Profiles"> Search Profiles </mat-label>
  <input
    id="campaign-builder-left-menu-search-input"
    data-qa="campaign-builder-left-menu-search-input"
    matInput
    (input)="search($event)"
  />
  <mat-icon matPrefix>search</mat-icon>
</mat-form-field>
<mat-divider></mat-divider>
<radr-campaign-builder-list
  [class.spinner]="isLoading"
  [class.overlay]="isLoading"
  [type]="qualifierCampaignToggle"
  [items]="getFilteredListItems() | async"
  [filterText]="filter$ | async"
></radr-campaign-builder-list>
