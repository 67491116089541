import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarContentComponent } from "@shared/components/snackbar-content/snackbar-content.component";
import { SnackBarStatus } from "@shared/utils/notify";

@Injectable({
  providedIn: "root"
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public open(
    message: string,
    action: string,
    duration: number = 10000,
    snackBarType: SnackBarStatus = SnackBarStatus.Unknown
  ): void {
    this.snackBar.openFromComponent(SnackbarContentComponent, {
      data: {
        dismiss: () => this.snackBar.dismiss(),
        message,
        action: action ?? undefined
      },
      duration,
      panelClass: snackBarType ? `mat-snack-bar-container--${snackBarType}` : undefined
    });
  }
}
