import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/components/shared.module";
import { QueryBuilderModule } from "@shared/components/angular-query-builder";
import { AudienceQueryBuilderModule } from "@shared/components/audience-query-builder";
import { CampaignBuilderLeftMenuComponent } from "./components/campaign-builder-left-menu/campaign-builder-left-menu.component";
import { CampaignBuilderListComponent } from "./components/campaign-builder-list/campaign-builder-list.component";
import { DataProviderListComponent } from "./components/data-provider-list/data-provider-list.component";
import { ExecutionPlatformControlGroupComponent } from "./components/execution-platform-control-group/execution-platform-control-group.component";
import { ProfileDefinitionComponent } from "./components/profile-definition/profile-definition.component";
import { BatchValuesSelectorModalComponent } from "./components/batch-values-selector-modal/batch-values-selector-modal.component";
import { AddNewButtonComponent } from "./components/add-new-button/add-new-button.component";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CampaignQueryBuilderComponent } from "./components/campaign-query-builder/campaign-query-builder.component";
import { QualifierCountComponent } from "./components/qualifier-count/qualifier-count.component";
import { ProfileCountComponent } from "./components/profile-count/profile-count.component";
import { UnsavedChangesModalComponent } from "./components/unsaved-changes-modal/unsaved-changes-modal.component";
import { ExecutionPlatformToggleComponent } from "./components/execution-platform-toggle/execution-platform-toggle.component";
import { EditCampaignPopupComponent } from "./components/edit-campaign-popup/edit-campaign-popup.component";
import { EditProfilePopupComponent } from "./components/edit-profile-popup/edit-profile-popup.component";
import { EditControlGroupSizePopupComponent } from "./components/edit-control-group-size-popup/edit-control-group-size-popup.component";
import { ExecutionPlatformGroupComponent } from "./components/export-settings/execution-platform-group/execution-platform-group.component";
import { ExecutionPlatformGroupHeaderComponent } from "./components/export-settings/execution-platform-group/execution-platform-group-header/execution-platform-group-header.component";
import { ExecutionPlatformGroupSteppedInputComponent } from "./components/export-settings/execution-platform-group/execution-platform-group-stepped-input/execution-platform-group-stepped-input.component";
import { ExportSettingsComponent } from "./components/export-settings/export-settings.component";
import { SpectrumReachDataManagementComponent } from "./components/export-settings/spectrum-reach-data-management/spectrum-reach-data-management.component";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatTreeModule } from "@angular/material/tree";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TreeModule } from "@circlon/angular-tree-component";
import { EditCampaignCappingPopupComponent } from "./components/edit-campaign-capping-popup/edit-campaign-capping-popup.component";
import { ProfilePreviewModalComponent } from "./components/profile-preview-modal/profile-preview-modal.component";
import { QualifierDetailsModalComponent } from "./components/qualifier-details-modal/qualifier-details-modal.component";
import { AddTestMacsModalComponent } from "./components/add-test-macs-modal/add-test-macs-modal.component";
import { AudienceDefinitionComponent } from "./components/audience-definition/audience-definition.component";
import { EditAudiencePopupComponent } from "./components/edit-audience-popup/edit-audience-popup.component";
import { AudienceCountComponent } from "./components/audience-count/audience-count.component";
import { CreateAudienceModalComponent } from "./components/create-audience-modal/create-audience-modal.component";
import { TotalCountHeaderComponent } from "./components/total-count-header/total-count-header.component";
import { DragDropListItemComponent } from "./components/drag-drop-list-item/drag-drop-list-item.component";
import { AudienceBuilderLeftMenuComponent } from "./components/audience-builder-left-menu/audience-builder-left-menu.component";
import { AudienceBuilderListComponent } from "./components/audience-builder-list/audience-builder-list.component";
import { AudienceListComponent } from "./components/audience-list/audience-list.component";
import { ProfileAudienceDefinitionComponent } from "./components/profile-audience-definition/profile-audience-definition.component";
import { AudienceSingleCountComponent } from "./components/audience-single-count/audience-single-count.component";
import { AudienceCountHeaderComponent } from "./components/audience-count-header/audience-count-header.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    QueryBuilderModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatDividerModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTreeModule,
    TreeModule,
    AudienceQueryBuilderModule,
    MatAutocompleteModule
  ],
  declarations: [
    AddTestMacsModalComponent,
    ExecutionPlatformControlGroupComponent,
    CampaignBuilderLeftMenuComponent,
    CampaignBuilderListComponent,
    DataProviderListComponent,
    ProfileDefinitionComponent,
    QualifierCountComponent,
    ProfileCountComponent,
    BatchValuesSelectorModalComponent,
    AddNewButtonComponent,
    CampaignQueryBuilderComponent,
    UnsavedChangesModalComponent,
    ExecutionPlatformToggleComponent,
    EditCampaignPopupComponent,
    EditProfilePopupComponent,
    EditControlGroupSizePopupComponent,
    EditCampaignCappingPopupComponent,
    ExecutionPlatformGroupComponent,
    ExecutionPlatformGroupHeaderComponent,
    ExecutionPlatformGroupSteppedInputComponent,
    ExportSettingsComponent,
    SpectrumReachDataManagementComponent,
    ProfilePreviewModalComponent,
    QualifierDetailsModalComponent,
    AudienceDefinitionComponent,
    EditAudiencePopupComponent,
    AudienceCountComponent,
    AudienceSingleCountComponent,
    CreateAudienceModalComponent,
    TotalCountHeaderComponent,
    AudienceCountHeaderComponent,
    DragDropListItemComponent,
    AudienceBuilderLeftMenuComponent,
    AudienceBuilderListComponent,
    AudienceListComponent,
    ProfileAudienceDefinitionComponent
  ],
  exports: [
    AddTestMacsModalComponent,
    ExecutionPlatformControlGroupComponent,
    CampaignBuilderLeftMenuComponent,
    CampaignBuilderListComponent,
    DataProviderListComponent,
    ProfileDefinitionComponent,
    QualifierCountComponent,
    ProfileCountComponent,
    BatchValuesSelectorModalComponent,
    AddNewButtonComponent,
    CampaignQueryBuilderComponent,
    UnsavedChangesModalComponent,
    ExecutionPlatformToggleComponent,
    EditCampaignPopupComponent,
    EditProfilePopupComponent,
    EditControlGroupSizePopupComponent,
    EditCampaignCappingPopupComponent,
    ExecutionPlatformGroupComponent,
    ExecutionPlatformGroupHeaderComponent,
    ExecutionPlatformGroupSteppedInputComponent,
    ExportSettingsComponent,
    SpectrumReachDataManagementComponent,
    CommonModule,
    SharedModule,
    QueryBuilderModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatTreeModule,
    CreateAudienceModalComponent,
    TotalCountHeaderComponent,
    AudienceCountHeaderComponent,
    DragDropListItemComponent,
    AudienceBuilderLeftMenuComponent,
    AudienceBuilderListComponent,
    AudienceListComponent,
    ProfileAudienceDefinitionComponent,
    AudienceQueryBuilderModule
  ]
})
export class BuilderModule {}
