<div
  class="text-overflow"
  (mouseover)="isTextOverflowing($event)"
  #tooltip="matTooltip"
  [matTooltip]="displayText"
  [matTooltipDisabled]="!showTooltip"
  matTooltipPosition="right"
  [matTooltipClass]="['radr-text-overflow-tooltip']"
>
  <span>
    {{ displayText }}
  </span>
</div>
