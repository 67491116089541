import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { S3PresignedUrlError } from "@shared/models";
import { KeycloakEvent, KeycloakService } from "keycloak-angular";
import Keycloak from "keycloak-js";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public redirectUrl: string;
  public keycloakEvents$: Observable<KeycloakEvent> = this.keycloak.keycloakEvents$.asObservable();
  private readonly route: string = `${environment.api_domain}/auth`;

  constructor(
    private httpClient: HttpClient,
    private keycloak: KeycloakService,
    private loadingService: LoadingService
  ) {}

  public isAuthenticated(): boolean {
    try {
      const isAuth: boolean = !this.keycloak.isTokenExpired();
      if (isAuth) {
        const kcInstance: any = this.keycloak.getKeycloakInstance();
        const token: string = kcInstance.token;
        const refreshToken: string = kcInstance.refreshToken;
        if (token) {
          localStorage.setItem("token", token);
        }
        if (refreshToken) {
          localStorage.setItem("refreshToken", refreshToken);
        }
      }
      // The following tryRefresh call is likely not necessary
      // as the Keycloak module checks for token expiration as
      // part of the built-in interceptor on every request.
      // When it attempts to add the BEARER token, it also
      // checks if we need a refresh.
      // In the event that we want more control, we can
      // disable the built-in incerceptor functionality,
      // but it is super nice to not have to worry about it.
      this.tryRefresh();
      return isAuth;
    } catch (err) {
      this.logout();
      return false;
    }
  }

  public tryRefresh(): Promise<boolean> {
    return this.keycloak.updateToken(60);
  }

  public getRemainingExpirySeconds(): number {
    const keycloakInstance: any = this.keycloak.getKeycloakInstance();
    const refreshTokenStr: string = keycloakInstance.refreshToken;
    const refreshToken: Keycloak.KeycloakTokenParsed = keycloakInstance.refreshTokenParsed;
    if (refreshTokenStr) {
      localStorage.setItem("refreshToken", refreshTokenStr);
    }
    if (refreshToken) {
      return refreshToken.exp - Math.round(Date.now() / 1000) + keycloakInstance.timeSkew;
    } else {
      return 3600; // We don't want to show the modal if we don't have a token
    }
  }

  public getS3PresignedPutUrl(fileName: string, contentType): Observable<any> {
    return this.httpClient
      .get<any>(
        `${this.route}/presigned-s3-put-url?fileName=${encodeURIComponent(fileName)}&contentType=${contentType}`
      )
      .pipe(
        tap(resp => {
          if (!resp?.url || !resp?.s3Key) {
            throw new S3PresignedUrlError(
              "Could not retrieve presigned s3 upload url.",
              "AuthService.getS3PresignedPutUrl"
            );
          }
        })
      );
  }

  public getS3PresignedGetUrl(fileName: string, s3Bucket: string): Observable<any> {
    const params: any = {};
    if (fileName) {
      params.fileName = encodeURIComponent(fileName);
    }

    if (s3Bucket) {
      params.s3Bucket = encodeURIComponent(s3Bucket);
    }

    return this.httpClient
      .get<any>(`${this.route}/presigned-s3-get-url`, {
        params
      })
      .pipe(
        tap(resp => {
          if (!resp?.url) {
            throw new S3PresignedUrlError(
              "Could not retrieve presigned s3 download url.",
              "AuthService.getS3PresignedGetUrl"
            );
          }
        })
      );
  }

  public logout(): Promise<void> {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.keycloak.clearToken();
    localStorage.setItem("loggedOut", "yes");
    return this.keycloak.logout();
  }
}
