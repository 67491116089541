<radr-modal toolbarColor="warn" warningIcon="true">
  <ng-container body>
    <div mat-dialog-content class="campaign-not-activated-modal-content">
      <p class="campaign-not-activated-modal-paragraph">
        By reingesting the subscriber list, the latest subscriber and device files will<br />
        be processed.
      </p>
    </div>
  </ng-container>

  <ng-container actions>
    <button mat-button id="cancel-button" (click)="cancelClicked()">CANCEL</button>
    <button color="warn" mat-flat-button id="ok-button" (click)="okClicked()">OK</button>
  </ng-container>
</radr-modal>
