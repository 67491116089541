<div style="height: calc(100vh - 330px)">
  <tree-root #treeView [nodes]="tree" [options]="options">
    <ng-template #loadingTemplate></ng-template>
    <ng-template #treeNodeTemplate let-node let-index="index">
      <span *ngIf="node.data.children; else itemTemplate">
        {{ node.data.name }}
      </span>
      <ng-template #itemTemplate>
        <radr-drag-drop-list-item
          [item]="node.data"
          [type]="type"
          [disabled]="disabled"
          [autoRefreshStatus]="true"
        ></radr-drag-drop-list-item>
      </ng-template>
    </ng-template>
  </tree-root>
</div>
