import { Component, Input } from "@angular/core";

@Component({
  selector: "radr-text-overflow",
  templateUrl: "./text-overflow.component.html",
  styleUrls: ["./text-overflow.component.scss"]
})
export class TextOverflowComponent {
  @Input() displayText: string;
  public showTooltip: boolean = false;

  constructor() {}

  isTextOverflowing($event: MouseEvent): void {
    const e: HTMLElement = $event.target as HTMLElement;
    const normalizeEle: HTMLElement = e?.classList?.contains("text-overflow") ? e : e.parentElement;
    if (normalizeEle.offsetWidth < normalizeEle.scrollWidth) {
      this.showTooltip = true;
    } else {
      this.showTooltip = false;
    }
  }
}
