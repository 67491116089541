<div class="query-builder-counts-wrapper" [class.loading]="campaignBuilderService.isCampaignCountsLoading">
  <div class="query-builder-counts">
    <div class="query-builder-total-count">
      <div class="universe-count-row" *ngFor="let universe of selectedUniverses; let i = index">
        <span *ngIf="mode === modes.ProfileBuilder && i === 0" class="universe-name"
          >Status: {{ getProfileStatus(profile.status) }}</span
        >
        <span class="universe-name">{{ universe }}</span>
        <span class="query-builder-count-label">Target:</span>
        <mat-progress-spinner
          id="{{ loadingSpinnerId }}"
          *ngIf="
            (campaignBuilderService.getProfileCount(profile.priority, true, universe) === undefined &&
              qualifierService.isRuleSetPartiallyComplete(profile.definition, profile.priority === 0) &&
              !countError &&
              campaign.isLoadingCounts) ||
              campaign.isProcessingRootProfileIncluded;
            else countDisplay
          "
          mode="indeterminate"
          diameter="15"
        ></mat-progress-spinner>
        <ng-template #countDisplay>
          <ng-container *ngIf="countError; else countValue">
            <mat-icon
              class="count-error-warning-icon"
              matTooltip="There was an error retrieving counts"
              matTooltipPosition="above"
            >
              error
            </mat-icon>
          </ng-container>
        </ng-template>

        <ng-template #countValue>
          <span id="{{ countValueId }}" class="query-builder-total-count-number">{{
            incompleteCount
              ? "-"
              : (campaignBuilderService.getProfileCount(profile.priority, true, universe) | number: "" : "en-US") || "-"
          }}</span>
        </ng-template>

        <ng-container class="query-builder-control-count" *ngIf="mode === modes.CampaignBuilder">
          <span class="query-builder-count-label query-builder-control-count-label">Control:</span>
          <mat-progress-spinner
            id="{{ controlLoadingSpinnerId }}"
            *ngIf="
              campaignBuilderService.getProfileCount(profile.priority, false, universe) === undefined &&
                qualifierService.isRuleSetPartiallyComplete(profile.definition) &&
                !countError &&
                campaign.isLoadingCounts;
              else controlCountDisplay
            "
            mode="indeterminate"
            diameter="15"
          ></mat-progress-spinner>
          <ng-template #controlCountDisplay>
            <ng-container *ngIf="countError; else controlCountValue">
              <mat-icon
                class="count-error-warning-icon"
                matTooltip="There was an error retrieving counts"
                matTooltipPosition="above"
              >
                error
              </mat-icon>
            </ng-container>
          </ng-template>

          <ng-template #controlCountValue>
            <span id="{{ controlCountValueId }}" class="query-builder-control-count-number">{{
              incompleteCount
                ? "-"
                : (campaignBuilderService.getProfileCount(profile.priority, false, universe) | number: "" : "en-US") ||
                  "-"
            }}</span>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <radr-contextual-message-icon
      *ngIf="incompleteCount && !campaignBuilderService.isCampaignCountsLoading && profile.priority > 0"
      id="{{ countValueId }}-incomplete-count"
      type="warning"
      size="small"
      verticalAlign="top"
      message="Counts could not be calculated because this profile contains incomplete qualifier(s)."
    ></radr-contextual-message-icon>
    <radr-contextual-message-icon
      *ngIf="campaign?.isActive && campaign?.isCappingActive"
      type="info"
      size="small"
      verticalAlign="top"
      message="Active campaigns reflect the capped counts for profiles and campaign when the capping status is active."
    ></radr-contextual-message-icon>
    <radr-contextual-message-icon
      *ngIf="!campaign?.isActive && campaign?.isCappingActive"
      type="info"
      verticalAlign="top"
      message="Inactive campaigns reflect estimated capped counts for profiles and campaigns."
    ></radr-contextual-message-icon>
  </div>
</div>
