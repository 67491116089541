<div cdkDropList [cdkDropListConnectedTo]="dropList" [cdkDropListDisabled]="disabled">
  <li
    [ngClass]="{ 'list-item': true, draggable: isDraggable(item) }"
    cdkDrag
    [cdkDragDisabled]="!isDraggable(item)"
    [cdkDragData]="item"
  >
    <div *cdkDragPlaceholder class="drop-profile-placeholder"></div>

    <span class="list-item-name clickable" (click)="itemClicked()">
      <radr-text-overflow [displayText]="item.name"></radr-text-overflow>
    </span>
    <span *ngIf="isDraggable(item)" cdkDragHandle class="drag-indicator" [attr.disabled]="disabled">
      <i *ngIf="!disabled" class="material-icons"> drag_indicator </i>
    </span>
    <span *ngIf="!isDraggable(item)">
      {{ item?.status?.description }}
    </span>
  </li>
</div>
