<radr-modal class="batch-values-selector-modal">
  <ng-container body>
    <div [class.spinner]="loading" *ngIf="!previouslyValidated">
      <div class="batch-values-modal-body" mat-dialog-content>
        <div class="batch-values-modal-input-wrapper">
          <mat-form-field appearance="fill">
            <input
              class="batch-values-modal-input"
              matInput
              [(ngModel)]="values"
              (change)="valueChanged()"
              placeholder="Enter values (items must be separated by a comma)"
              [disabled]="!!file"
            />
          </mat-form-field>
          <button mat-icon-button *ngIf="values" (click)="clearAll()" class="batch-values-clear-input-button">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="batch-values-modal-file-upload-row">
          <radr-file-upload
            (fileLoaded)="fileLoaded($event)"
            (fileLoading)="fileLoading($event)"
            acceptedFileTypes=".csv, .txt, .zip, .pgp, .tsv, .dat, .gz"
            [isDataSourceUpload]="true"
            uploadText="Select a file from your computer"
          ></radr-file-upload>
          <button
            id="batch-values-modal-ok"
            color="primary"
            mat-flat-button
            (click)="validate()"
            [disabled]="validateButtonDisabled"
          >
            VALIDATE
          </button>
        </div>
      </div>
      <div class="batch-values-modal-divider"></div>
    </div>
    <mat-expansion-panel
      class="batch-values-expansion batch-values-selector-matched-values-panel"
      *ngIf="totalValidatedResults"
      displayMode="flat"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>check_circle</mat-icon>
          <span class="batch-values-selector-bold">{{ matchedValues.length }}</span>
          <span>of</span>
          <span class="batch-values-selector-bold">{{ totalValidatedResults }}</span>
          values validated
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="batch-values-value-list">{{ matchedValues.join(", ") }}</p>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="batch-values-expansion batch-values-selector-not-matched-values-panel"
      *ngIf="totalValidatedResults"
      displayMode="flat"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>warning</mat-icon>
          <span class="batch-values-selector-bold">{{ notMatchedValues.length }}</span>
          <span>of</span>
          <span class="batch-values-selector-bold">{{ totalValidatedResults }}</span>
          values not found
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="batch-values-value-list">{{ notMatchedValues.join(", ") }}</p>
    </mat-expansion-panel>
  </ng-container>
  <ng-container actions>
    <div class="batch-values-left-aligned-action-buttons">
      <button
        *ngIf="totalValidatedResults"
        mat-flat-button
        class="batch-values-download-validation-file-button"
        (click)="downloadValidationReport()"
      >
        Download Validation Report
        <mat-icon>cloud_download</mat-icon>
      </button>
    </div>
    <div class="batch-values-right-aligned-action-buttons">
      <button class="batch-values-modal-cancel" mat-flat-button (click)="cancelClicked()" *ngIf="!previouslyValidated">
        CANCEL
      </button>
      <button
        id="batch-values-modal-ok"
        color="primary"
        mat-flat-button
        (click)="okClicked()"
        [disabled]="!validationComplete"
        *ngIf="!previouslyValidated"
      >
        APPLY VALUES
      </button>
      <button
        id="batch-values-modal-ok"
        color="primary"
        mat-flat-button
        (click)="cancelClicked()"
        *ngIf="previouslyValidated"
      >
        OK
      </button>
    </div>
  </ng-container>
</radr-modal>
