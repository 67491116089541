import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { timer, EMPTY, Observable, Subscription } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { QueryBuilderService } from "@shared/services/query-builder.service";
import { CampaignBuilderLeftMenuTypes } from "../campaign-builder-left-menu/campaign-builder-left-menu.component";
import { Qualifier, QualifierStatusEnum, FileStatus } from "radr-shared";
import { ProfilePreviewModalComponent } from "../profile-preview-modal/profile-preview-modal.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { QualifierDetailsModalComponent } from "../qualifier-details-modal/qualifier-details-modal.component";
import { AudienceDetailsModalComponent } from "@shared/components/audience-details-modal/audience-details-modal.component";

@Component({
  selector: "radr-drag-drop-list-item",
  templateUrl: "drag-drop-list-item.component.html",
  styleUrls: ["drag-drop-list-item.component.scss"]
})
export class DragDropListItemComponent implements OnChanges, OnDestroy {
  @Input() item: any;
  @Input() type: CampaignBuilderLeftMenuTypes;
  @Input() disabled: boolean;
  @Input() autoRefreshStatus: boolean;
  autoRefresher: Subscription;

  get dropList(): null | string | string[] {
    switch (this.type) {
      case CampaignBuilderLeftMenuTypes.Qualifiers:
        return this.queryBuilderService.qualifierDropZoneIds;
      case CampaignBuilderLeftMenuTypes.Profiles:
        return this.queryBuilderService.profileDropZoneIds.concat(["campaign-query-builder-profile-list"]);
      case CampaignBuilderLeftMenuTypes.Audiences:
        return this.queryBuilderService.audienceDropZoneIds;
      default:
        return null;
    }
  }

  public types: any = CampaignBuilderLeftMenuTypes;

  constructor(
    private queryBuilderService: QueryBuilderService,
    private qualifiersService: QualifiersService,
    private dialog: MatDialog
  ) {}

  public ngOnDestroy(): void {
    if (this.autoRefresher) {
      this.autoRefresher.unsubscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.autoRefreshStatus?.currentValue && this.type === CampaignBuilderLeftMenuTypes.Qualifiers) {
      if (
        this.item?.status?.id !== QualifierStatusEnum.Processed &&
        this.item?.status?.id !== QualifierStatusEnum.Error &&
        this.item.id
      ) {
        this.autoRefresher = timer(1, 60000)
          .pipe(
            switchMap(() => this.qualifiersService.getQualifierStatus(this.item.id)),
            catchError(err => EMPTY)
          )
          .subscribe((qualifierStatus: FileStatus) => {
            this.item.status = qualifierStatus;

            if (
              this.item?.status?.id === QualifierStatusEnum.Processed &&
              this.item?.status?.id === QualifierStatusEnum.Error
            ) {
              this.autoRefresher.unsubscribe();
            }
          });
      } else if (this.autoRefresher) {
        this.autoRefresher.unsubscribe();
      }
    }
  }

  public isDraggable(item: any): boolean {
    if (this.type === CampaignBuilderLeftMenuTypes.Qualifiers) {
      const qualifier: Qualifier = item as Qualifier;
      return qualifier?.status?.id === QualifierStatusEnum.Processed;
    } else {
      return true;
    }
  }

  public itemClicked(): void {
    if (this.type === CampaignBuilderLeftMenuTypes.Profiles) {
      const dialogRef: MatDialogRef<ProfilePreviewModalComponent> = this.dialog.open(ProfilePreviewModalComponent, {
        data: {
          title: "Profile Details",
          profileId: this.item.id
        }
      });
    } else if (this.type === CampaignBuilderLeftMenuTypes.Qualifiers) {
      const dialogRef: MatDialogRef<QualifierDetailsModalComponent> = this.dialog.open(QualifierDetailsModalComponent, {
        data: {
          title: "Qualifier Details",
          qualifier: this.item
        }
      });
    } else if (this.type === CampaignBuilderLeftMenuTypes.Audiences) {
      const dialogRef: MatDialogRef<AudienceDetailsModalComponent> = this.dialog.open(AudienceDetailsModalComponent, {
        data: {
          title: "Audience Details",
          audience: this.item
        }
      });
    }
  }
}
