<div class="snack-container">
  <div>
    <span [innerHtml]="data.message"></span>
  </div>
  <div>
    <button
      mat-button
      class="snack-action-button mat-simple-snackbar-action"
      *ngIf="data.action"
      (click)="data.dismiss()"
    >
      {{ data.action }}
    </button>
  </div>
</div>
