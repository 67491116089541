import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { mergeMap, take } from "rxjs/operators";
import { BatchValueMetadata, ValidatedBatchResult } from "radr-shared";
import MasterDetailService from "../lib/master-detail.service";

@Injectable({
  providedIn: "root"
})
export class BatchValuesService extends MasterDetailService<ValidatedBatchResult> {
  public validatedBatchValues$: Observable<ValidatedBatchResult> = this.selectedItemSource$.asObservable();

  constructor() {
    super("batch-values", false);
  }

  getBatchValueMetadata(id: number): Observable<BatchValueMetadata> {
    return this.getOne<BatchValueMetadata>(`${this.route}/${id}`);
  }

  createBatchValues(batchValueMetadata: BatchValueMetadata, values?: string[]): Observable<number> {
    return this.post(`${this.route}`, { batchValueMetadata, values });
  }

  validateBatchValues(batchId: number): Observable<ValidatedBatchResult> {
    return this.getOne<ValidatedBatchResult>(`${this.route}/${batchId}/validate`);
  }
}
