import { Profile } from "./profile.model";
import { ResearchPlatform } from "./research-platform.model";
import { AudienceCappingType, CappingLevel, CappingType, ControlLevel } from "../enums";
import { ExecutionPlatformGroup } from "./execution-platform-group.model";
import { CampaignStatus } from "./campaign-status.model";
import { Universe } from "./universe.model";

/**
 * Represents the model for a Campaign in RADR
 *
 * @interface Campaign
 */
export class Campaign {
  /**
   * Identifier for a campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  id: number;

  /**
   * The name of the campaign
   * @type {string}
   * @memberof Campaign
   */
  name: string;

  /**
   * The description of the campaign
   * @type {string}
   * @memberof Campaign
   */
  description: string;

  /**
   * The requester's email for the campaign
   * @type {string}
   * @memberof Campaign
   */
  requesterEmail: string;

  /**
   * The household count of the campaign
   * @type {number}
   * @memberof Campaign
   */
  householdCount: number;

  /**
   * The device count of the campaign
   * @type {number}
   * @memberof Campaign
   */
  deviceCount: number;

  /**
   * The created date of the campaign
   * @type {Date}
   * @memberof Campaign
   */
  createdDateTime: Date;

  /**
   * The last modified date of the campaign
   * @type {Date}
   * @memberof Campaign
   */
  lastModifiedDateTime: Date;

  /**
   * The date the campaign was first activated
   * @type {Date | null}
   * @memberof Campaign
   */
  firstActivatedDateTime: Date | null;

  /**
   * User that created the campaign.
   * @type {string}
   * @memberof Campaign
   */
  createdBy: string;

  /**
   * User that altered the campaign.
   * @type {string}
   * @memberof Campaign
   */
  lastModifiedBy: string;

  /**
   * The profiles for this campaign
   *
   * @type {Profile[]}
   * @memberof Campaign
   */
  profiles: Profile[];

  /**
   * The execution platforms for this campaign
   *
   * @type {ExecutionPlatforms[]}
   * @memberof Campaign
   */
  researchPlatforms: ResearchPlatform[];

  executionPlatformGroups: ExecutionPlatformGroup[];

  /**
   * Flag to mark that the campaign is gross only
   *
   * @type {boolean}
   * @memberof Campaign
   */
  isGross: boolean;

  /**
   * Contains the group name
   * for this campaign.
   *
   * @type {string}
   * @memberof Campaign
   */
  groupName: string;

  /**
   * Flag to mark that the campaign is addressable.
   * for this campaign.
   *
   * @type {boolean}
   * @memberof Campaign
   */
  isAddressable: boolean;

  /**
   * The level at which test and control groups
   * are applied.
   * Valid values include
   * * Campaign (1)
   * * Profile (2)
   *
   * If something is wrong, it can be set to 0.
   *
   * @type {ControlLevel}
   * @memberof Campaign
   */
  controlLevel: ControlLevel;

  /**
   * The control group size for a test and control
   * campaign, represented as a true percentage, not
   * a decimal representation.
   * Ex. 10 = 10%
   *
   * @type {number}
   * @memberof Campaign
   */
  controlPercentage: number;

  /**
   * The level at which count capping is applied.
   * Valid values include
   * * Campaign (1)
   * * Profile (2)
   *
   * If something is wrong, it can be set to 0.
   *
   * @type {CappingLevel | AudienceCappingType | CappingType}
   * @memberof Campaign
   */
  cappingLevel: CappingLevel | AudienceCappingType | CappingType;

  /**
   * The capped subscriber count for the campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  capFixed: number;

  /**
   * The capped subscriber count for the campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  capPercentage: number;

  /**
   * Contains the Canoe Video campaign name
   * for this campaign.
   *
   * @type {string}
   * @memberof Campaign
   */
  canoeVideoCampaignName: string;

  /**
   * Contains the Canoe Video campaign ID
   * for this campaign.
   *
   * @type {string}
   * @memberof Campaign
   */
  canoeVideoCampaignId: string;

  /**
   * Contains the Canoe ATV campaign name
   * for this campaign.
   *
   * @type {string}
   * @memberof Campaign
   */
  canoeATVCampaignName: string;

  /**
   * Contains the Canoe ATV campaign ID
   * for this campaign.
   *
   * @type {string}
   * @memberof Campaign
   */
  canoeATVCampaignId: string;

  /**
   * Contains the universe for this campaign.
   *
   * @type {Universe}
   * @memberof Campaign
   */
  universes: Universe[];

  /**
   * The current status of the campaign
   *
   * @type {CampaignStatus}
   * @memberof Campaign
   */
  status?: CampaignStatus;

  /**
   * The start date of the campaign
   *
   * @type {Date}
   * @memberof Campaign
   */
  startDate?: Date;

  /**
   * The end date of the campaign
   *
   * @type {Date}
   * @memberof Campaign
   */
  endDate?: Date;

  /**
   * Whether or not the counts should auto caculate
   *
   * @type {boolean}
   * @memberof Campaign
   */
  autoCalculateCounts: boolean;

  /**
   * Flag to mark that the campaign is audience finder restricted
   *
   * @type {boolean}
   * @memberof Campaign
   */
  isRestrictedAudienceFinder: boolean;

  /**
   * Flag to mark that the campaign is audience finder atv restricted
   *
   * @type {boolean}
   * @memberof Campaign
   */
  isRestrictedAudienceFinderAtv: boolean;

  /**
   * Flag to mark that the campaign is archived
   *
   * @type {boolean}
   * @memberof Campaign
   */
  archived: boolean;

  /**
   * id that represents an addressable type for the campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  addressableTypeId: number;

  /**
   * business line that represents an custom addressable for campaign(local or national/enterprise)
   *
   * @type {string}
   * @memberof Campaign
   */
  businessLine: string;
}
