import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  NgModel,
  FormControl
} from "@angular/forms";
import { QueryOperatorDirective } from "./query-operator.directive";
import { QueryFieldDirective } from "./query-field.directive";
import { QueryEntityDirective } from "./query-entity.directive";
import { QuerySwitchGroupDirective } from "./query-switch-group.directive";
import { QueryButtonGroupDirective } from "./query-button-group.directive";
import { QueryInputDirective } from "./query-input.directive";
import { QueryRemoveButtonDirective } from "./query-remove-button.directive";
import { QueryEmptyWarningDirective } from "./query-empty-warning.directive";
import { QueryArrowIconDirective } from "./query-arrow-icon.directive";
import { QueryHeaderDirective } from "./query-header.directive";
import {
  ButtonGroupContext,
  Entity,
  Field,
  SwitchGroupContext,
  EntityContext,
  FieldContext,
  InputContext,
  LocalRuleMeta,
  OperatorContext,
  Option,
  QueryBuilderClassNames,
  QueryBuilderConfig,
  RemoveButtonContext,
  ArrowIconContext,
  Rule,
  RuleSet,
  EmptyWarningContext
} from "./query-builder.interfaces";
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  Renderer2
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDragMove,
  Point,
  CdkDrag,
  CdkDropList,
  CdkDragRelease
} from "@angular/cdk/drag-drop";
import { QueryBuilderService } from "@shared/services/query-builder.service";
import { Guid } from "@shared/lib/guid.service";
import {
  RootProfileCloneOptions,
  RootProfileModalComponent
} from "src/app/modules/builder/components/root-profile-modal/root-profile-modal.component";
import { AudienceViewModel, ProfileViewModel } from "radr-shared";
import { ProgrammerError } from "@shared/models";
import { ProfilesService } from "@shared/services/profiles.service";
import { Subscription } from "rxjs";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { DialogModalComponent } from "@shared/components/dialog-modal/dialog-modal.component";
import { TuneinScheduleModalComponent } from "@shared/components/tunein-schedule-modal/tunein-schedule-modal.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TuneInScheduleModel } from "@shared/models/tune-in-schedule.model";
import { ApplicationSettingsService } from "@shared/services/application-settings.service";

export const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => QueryBuilderComponent),
  multi: true
};

export const VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => QueryBuilderComponent),
  multi: true
};

interface Schedule {
  station: string[];
  program: string[];
  fromDate: any;
  toDate: any;
  fromTime: any;
  toTime: any;
  toTimeSuffix: any;
  fromTimeSuffix: any;
  timezone: any;
  utcToDateTime: any;
  utcFromDateTime: any;
}

interface TuneInOptions {
  station?: string[];
  program?: string[];
  genre?: string;
  schedule?: Schedule[];
  viewershipLevels?: string[];
  originalAirDateStart?: any;
  originalAirDateEnd?: any;
}

interface AddRuleOptions {
  id: string;
  index?: number;
  name?: string;
  isControl?: boolean;
  segmentId?: string;
  segmentName?: string;
  qualifierTypeId?: number;
  tuneInType?: string;
  tercile?: string[];
  originalAirDateStart?: string;
  originalAirDateEnd?: string;
}

@Component({
  selector: "radr-query-builder",
  templateUrl: "./query-builder.component.html",
  styleUrls: ["./query-builder.component.scss"],
  providers: [CONTROL_VALUE_ACCESSOR, VALIDATOR]
})
export class QueryBuilderComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  public dropListGuid: string = Guid.newGuid();
  public audienceEnabled: boolean;

  public fields: Field[];
  public filterFields: Field[];
  public entities: Entity[];
  public defaultClassNames: QueryBuilderClassNames = {
    arrowIconButton: "q-arrow-icon-button",
    arrowIcon: "q-icon q-arrow-icon",
    removeIcon: "q-icon q-remove-icon",
    addIcon: "q-icon q-add-icon",
    button: "q-button",
    buttonGroup: "q-button-group",
    removeButton: "q-remove-button",
    switchGroup: "q-switch-group",
    switchLabel: "q-switch-label",
    switchRadio: "q-switch-radio",
    rightAlign: "q-right-align",
    transition: "q-transition",
    collapsed: "q-collapsed",
    treeContainer: "q-tree-container",
    tree: "q-tree",
    row: "q-row",
    connector: "q-connector",
    rule: "q-rule",
    ruleSet: "q-ruleset",
    invalidRuleSet: "q-invalid-ruleset",
    emptyWarning: "q-empty-warning",
    fieldControl: "q-field-control",
    fieldControlSize: "q-control-size",
    entityControl: "q-entity-control",
    entityControlSize: "q-control-size",
    operatorControl: "q-operator-control",
    operatorControlSize: "q-control-size",
    inputControl: "q-input-control",
    inputControlSize: "q-control-size",
    header: "q-header",
    isNew: "q-border-highlight"
  };
  public defaultOperatorMap: { [key: string]: string[] } = {
    string: ["=", "!=", "contains", "like"],
    number: ["=", "!=", ">", ">=", "<", "<="],
    time: ["=", "!=", ">", ">=", "<", "<="],
    date: ["=", "!=", ">", ">=", "<", "<="],
    category: ["=", "!=", "in", "not in", "batch"],
    boolean: ["="]
  };
  @Input() disabled: boolean;
  @Input() data: RuleSet = { condition: "and", rules: [] };

  // For ControlValueAccessor interface
  public onChangeCallback: () => void;
  public onTouchedCallback: () => any;

  @Input() allowRuleset: boolean = true;
  @Input() allowCollapse: boolean = false;
  @Input() profile: ProfileViewModel;
  @Input() audience: AudienceViewModel;
  @Input() emptyMessage: string = "A ruleset cannot be empty. Please add a rule or remove it altogether.";
  @Input() classNames: QueryBuilderClassNames;
  @Input() operatorMap: { [key: string]: string[] };
  @Input() parentValue: RuleSet;
  @Input() config: QueryBuilderConfig = { fields: {} };
  @Input() parentArrowIconTemplate: QueryArrowIconDirective;
  @Input() parentInputTemplates: QueryList<QueryInputDirective>;
  @Input() parentOperatorTemplate: QueryOperatorDirective;
  @Input() parentFieldTemplate: QueryFieldDirective;
  @Input() parentEntityTemplate: QueryEntityDirective;
  @Input() parentSwitchGroupTemplate: QuerySwitchGroupDirective;
  @Input() parentButtonGroupTemplate: QueryButtonGroupDirective;
  @Input() parentRemoveButtonTemplate: QueryRemoveButtonDirective;
  @Input() parentEmptyWarningTemplate: QueryEmptyWarningDirective;
  @Input() parentChangeCallback: () => void;
  @Input() parentTouchedCallback: () => void;
  @Input() persistValueOnFieldChange: boolean = false;
  @Input() isPreview: boolean = false;

  @ViewChild("treeContainer", { static: true }) treeContainer: ElementRef;
  @ViewChild("switchRow", { static: true }) switchRow: ElementRef;
  @ViewChildren("ruleListItem") ruleElements: QueryList<ElementRef>;

  @ContentChild(QueryButtonGroupDirective)
  buttonGroupTemplate: QueryButtonGroupDirective;
  @ContentChild(QuerySwitchGroupDirective)
  switchGroupTemplate: QuerySwitchGroupDirective;
  @ContentChild(QueryFieldDirective)
  fieldTemplate: QueryFieldDirective;
  @ContentChild(QueryEntityDirective)
  entityTemplate: QueryEntityDirective;
  @ContentChild(QueryOperatorDirective)
  operatorTemplate: QueryOperatorDirective;
  @ContentChild(QueryRemoveButtonDirective)
  removeButtonTemplate: QueryRemoveButtonDirective;
  @ContentChild(QueryEmptyWarningDirective)
  emptyWarningTemplate: QueryEmptyWarningDirective;
  @ContentChildren(QueryInputDirective) inputTemplates: QueryList<QueryInputDirective>;
  @ContentChild(QueryArrowIconDirective)
  arrowIconTemplate: QueryArrowIconDirective;
  @ContentChild(QueryHeaderDirective) headerTemplate: QueryHeaderDirective;

  @Output() updateSegmentIds: EventEmitter<any> = new EventEmitter<any>();

  private defaultTemplateTypes: string[] = ["string", "number", "time", "date", "category", "boolean", "multiselect"];
  private defaultPersistValueTypes: string[] = ["string", "number", "time", "date", "boolean"];
  private defaultEmptyList: any[] = [];
  private operatorsCache: { [key: string]: string[] };
  private inputContextCache: Map<Rule, InputContext> = new Map<Rule, InputContext>();
  private operatorContextCache: Map<Rule, OperatorContext> = new Map<Rule, OperatorContext>();
  private fieldContextCache: Map<Rule, FieldContext> = new Map<Rule, FieldContext>();
  private entityContextCache: Map<Rule, EntityContext> = new Map<Rule, EntityContext>();
  private removeButtonContextCache: Map<Rule, RemoveButtonContext> = new Map<Rule, RemoveButtonContext>();
  private buttonGroupContext: ButtonGroupContext;
  private tuneInScheduleDialogRef: MatDialogRef<TuneinScheduleModalComponent>;
  private addQualifierFromModalListener: Subscription;
  public tempTuneInScheduleObject: TuneInScheduleModel[] = [];
  public tuneInScheduleObject: TuneInScheduleModel[] = [];

  tuneInViewershipOptions: string[] = ["Light", "Medium", "Heavy"];
  datePickerOnClosed(formControl: NgModel): void {
    formControl.control.markAsTouched();
  }

  public get connectedDropLists(): any[] {
    return this.queryBuilderService.qualifierDropZoneIds;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private queryBuilderService: QueryBuilderService,
    private dialog: MatDialog,
    private profilesService: ProfilesService,
    private campaignBuilderService: CampaignBuilderService,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    private applicationSettingsService: ApplicationSettingsService
  ) {}

  // ----------OnInit Implementation----------

  ngOnInit(): void {
    this.queryBuilderService.qualifierAddedToList.next(this.dropListGuid);
    this.queryBuilderService.profileAddedToList.next({
      guid: this.dropListGuid,
      ruleSet: this.profile?.definition || this.data
    });
    this.setupAddQualifierFromModalListener();

    this.audienceEnabled = this.applicationSettingsService.audienceEnabledFlag;
  }

  ngOnDestroy(): void {
    if (this.addQualifierFromModalListener) {
      this.addQualifierFromModalListener.unsubscribe();
    }
  }

  // ----------OnChanges Implementation----------

  ngOnChanges(changes: SimpleChanges): void {
    const config: QueryBuilderConfig = this.config;
    const type: string = typeof config;

    if (type === "object") {
      this.fields = Object.keys(config.fields).map(value => {
        const field: Field = config.fields[value];
        field.value = field.value || value;
        return field;
      });
      if (config.entities) {
        this.entities = Object.keys(config.entities).map(value => {
          const entity: Entity = config.entities[value];
          entity.value = entity.value || value;
          return entity;
        });
      } else {
        this.entities = null;
      }

      this.operatorsCache = {};
    } else {
      throw new ProgrammerError(
        `Expected 'config' must be a valid object, got ${type} instead.`,
        "QueryBuilderComponent.ngOnChanges"
      );
    }
  }

  // ----------Validator Implementation----------

  validate(control: AbstractControl): ValidationErrors | null {
    const errors: { [key: string]: any } = {};
    const ruleErrorStore: any[] = [];
    let hasErrors: boolean = false;

    if (!this.config.allowEmptyRulesets && this.checkEmptyRuleInRuleset(this.data)) {
      errors.empty = "Empty rulesets are not allowed.";
      hasErrors = true;
    }

    this.validateRulesInRuleset(this.data, ruleErrorStore);

    if (ruleErrorStore.length) {
      errors.rules = ruleErrorStore;
      hasErrors = true;
    }
    return hasErrors ? errors : null;
  }

  // ----------ControlValueAccessor Implementation----------

  @Input()
  get value(): RuleSet {
    return this.data;
  }
  set value(value: RuleSet) {
    // When component is initialized without a formControl, null is passed to value
    this.data = value || { condition: "and", rules: [] };
    this.handleDataChange();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = () => fn(this.data);
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = () => fn(this.data);
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  // ----------END----------

  getDisabledState = () => {
    return this.disabled;
  };

  findTemplateForRule(rule: Rule): TemplateRef<any> {
    if (
      rule.tuneInType === "Program" ||
      rule.tuneInType === "Station" ||
      rule.tuneInType === "Genre" ||
      rule.tuneInType === "Scheduling"
    ) {
      return null;
    }

    const type: string = this.getInputType(rule?.field, rule?.operator, rule?.tuneInType);
    if (type) {
      const queryInput: QueryInputDirective = this.findQueryInput(type);
      if (queryInput) {
        return queryInput.template;
      } else {
        if (this.defaultTemplateTypes.indexOf(type) === -1) {
          console.warn(`Could not find template for field with type: ${type}`);
        }
        return null;
      }
    }
  }

  findQueryInput(type: string): QueryInputDirective {
    const templates: QueryList<QueryInputDirective> = this.parentInputTemplates || this.inputTemplates;
    return templates.find(item => item.queryInputType === type);
  }

  getOperators(field: string): string[] {
    if (this.operatorsCache[field]) {
      return this.operatorsCache[field];
    }
    let operators: any[] = this.defaultEmptyList;
    const fieldObject: Field = this.config.fields[field];

    if (this.config.getOperators) {
      return this.config.getOperators(field, fieldObject);
    }

    const type: string = fieldObject.type;

    if (fieldObject && fieldObject.operators) {
      operators = fieldObject.operators;
    } else if (type) {
      operators =
        (this.operatorMap && this.operatorMap[type]) || this.defaultOperatorMap[type] || this.defaultEmptyList;
      if (operators.length === 0) {
        console.warn(
          `No operators found for field '${field}' with type ${fieldObject.type}. ` +
            `Please define an 'operators' property on the field or use the 'operatorMap' binding to fix this.`
        );
      }
      if (fieldObject.nullable) {
        operators = operators.concat(["is null", "is not null"]);
      }
    } else {
      console.warn(`No 'type' property found on field: '${field}'`);
    }

    this.operatorsCache[field] = operators;
    return operators;
  }

  getFields(entity: string): Field[] {
    if (this.entities && entity) {
      return this.fields.filter(field => {
        return field && field.entity === entity;
      });
    } else {
      return this.fields;
    }
  }

  getInputType(field: string, operator: string, tuneInType?: string): string {
    if (this.config.getInputType) {
      return this.config.getInputType(field, operator, tuneInType);
    }

    if (!this.config.fields[field]) {
      throw new ProgrammerError(
        `No configuration for field '${field}' could be found! Please add it to config.fields.`,
        "QueryBuilderComponent.getInputType"
      );
    }
    if (tuneInType === "Program") {
      return "tune-in-program";
    }

    if (tuneInType === "Station") {
      return "tune-in-station";
    }

    if (tuneInType === "Genre") {
      return "tune-in-genre";
    }

    if (tuneInType === "Scheduling") {
      return "tune-in-scheduling";
    }
    const type: string = this.config.fields[field].type;
    switch (operator) {
      case "is null":
      case "is not null":
        return null; // No displayed component
      case "in":
      case "not in":
        return type === "category" || type === "boolean" ? "multiselect" : type;
      default:
        return type;
    }
  }

  getOptions(field: string, context?: any): Option[] {
    if (this.config.getOptions) {
      return this.config.getOptions(field, context);
    }
    return this.config.fields[field].options || this.defaultEmptyList;
  }

  getClassNames(...args: any[]): string {
    const clsLookup: any = this.classNames ? this.classNames : this.defaultClassNames;
    const classNames: any = args.map(id => clsLookup[id] || this.defaultClassNames[id]).filter(c => !!c);
    return classNames.length ? classNames.join(" ") : null;
  }

  getDefaultField(entity: Entity): Field {
    if (!entity) {
      return null;
    } else if (entity.defaultField !== undefined) {
      return this.getDefaultValue(entity.defaultField);
    } else {
      const entityFields: Field[] = this.fields.filter(field => {
        return field && field.entity === entity.value;
      });
      if (entityFields && entityFields.length) {
        return entityFields[0];
      } else {
        console.warn(
          `No fields found for entity '${entity.name}'. ` +
            `A 'defaultOperator' is also not specified on the field config. Operator value will default to null.`
        );
        return null;
      }
    }
  }

  getDefaultOperator(field: Field): string {
    if (field && field.defaultOperator !== undefined) {
      return this.getDefaultValue(field.defaultOperator);
    } else {
      const operators: string[] = this.getOperators(field.value);
      if (operators && operators.length) {
        return operators[0];
      } else {
        console.warn(
          `No operators found for field '${field.value}'. ` +
            `A 'defaultOperator' is also not specified on the field config. Operator value will default to null.`
        );
        return null;
      }
    }
  }

  private isValidRule(rule: Rule): boolean {
    return rule.field && rule.operator && (rule.value || rule.value === false);
  }

  private isValidTuneInRule(rule: Rule): boolean {
    return !!rule.field && !!rule.value && !!rule.tercile && !!rule.viewershipLevels;
  }

  getTuneInSchedulePlaceholder(value: any): string {
    if (!value || value.length === 0) {
      return "Add Schedule";
    } else return "View/Edit Schedule";
  }

  addNewSchedule(rule: Rule): void {
    const tuneInSchedules: any[] = rule?.value ? rule.value : [new TuneInScheduleModel()];
    this.tuneInScheduleDialogRef = this.dialog.open(TuneinScheduleModalComponent, {
      data: {
        title: "Add Tune-In Schedule",
        subtitle: "All times displayed in Eastern Time Zone",
        schedules: [...tuneInSchedules]
      }
    });
    this.tuneInScheduleDialogRef.disableClose = true;
    this.tuneInScheduleDialogRef.afterClosed().subscribe(results => {
      if (results.action === "submit") {
        rule.value = results.data;
      }
    });
  }

  addRule(parent?: RuleSet, options: AddRuleOptions = { index: undefined, id: undefined }, field?: Field): void {
    if (this.disabled) {
      return;
    }

    parent = parent || this.data;
    if (this.config.addRule) {
      this.config.addRule(parent);
    } else {
      if (!field) {
        field = !options.id ? this.fields[0] : ({} as Field);
      }
      const rulesetToAdd: any = {
        field: options.id || field.value,
        operator: field.operator,
        operators: field.operators,
        value: field.value,
        entity: field.entity,
        count: null,
        isControl: options?.isControl ?? false,
        qualifierTypeId: options?.qualifierTypeId,
        tuneInType: options?.tuneInType,
        qualifierName: options?.name,
        tercile: options?.tercile,
        originalAirDateStart: options?.originalAirDateStart,
        originalAirDateEnd: options?.originalAirDateEnd
      };

      if (options.index >= 0) {
        const firstArrayChunk: (RuleSet | Rule)[] = parent.rules.slice(0, options.index);
        firstArrayChunk.push(rulesetToAdd);
        const secondArrayChunk: (RuleSet | Rule)[] = parent.rules.slice(options.index);

        const newRulesArray: (RuleSet | Rule)[] = firstArrayChunk.concat(secondArrayChunk);
        parent.rules = newRulesArray;
      } else {
        parent.rules = parent.rules.concat([rulesetToAdd]);
      }
    }

    this.handleTouched();
    this.handleDataChange(true);
  }

  removeRule(rule: Rule, parent?: RuleSet): void {
    if (this.disabled) {
      return;
    }

    parent = parent || this.data;
    if (this.config.removeRule) {
      this.config.removeRule(rule, parent);
    } else {
      this.removeRuleRecursively(parent, rule);
    }
    this.inputContextCache.delete(rule);
    this.operatorContextCache.delete(rule);
    this.fieldContextCache.delete(rule);
    this.entityContextCache.delete(rule);
    this.removeButtonContextCache.delete(rule);

    this.handleTouched(true);
    this.handleDataChange(true);

    this.campaignBuilderService.loadCounts();
  }

  removeRuleRecursively(rule, ruleToRemove) {
    if (rule.rules) {
      rule.rules = rule.rules.filter(rule => this.removeRuleRecursively(rule, ruleToRemove));
    }
    return rule !== ruleToRemove;
  }

  addRuleSet(parent?: RuleSet, options?: RuleSet): void {
    if (this.disabled) {
      return;
    }

    parent = parent || this.data;
    if (this.config.addRuleSet) {
      this.config.addRuleSet(parent);
    } else if (options) {
      parent.rules = parent.rules.concat(options);
    } else {
      parent.rules = parent.rules.concat([{ condition: "and", rules: [] }]);
    }

    this.handleTouched(true);
    this.handleDataChange(true);
  }

  removeRuleSet(ruleset?: RuleSet, parent?: RuleSet): void {
    if (this.disabled) {
      return;
    }

    ruleset = ruleset || this.data;
    parent = parent || this.parentValue;
    if (this.config.removeRuleSet) {
      this.config.removeRuleSet(ruleset, parent);
    } else {
      parent.rules = parent.rules.filter(r => r !== ruleset);
    }

    this.handleTouched(true);
    this.handleDataChange(true);

    this.campaignBuilderService.loadCounts();
  }

  transitionEnd(e: Event): void {
    this.treeContainer.nativeElement.style.maxHeight = null;
  }

  toggleCollapse(): void {
    this.computedTreeContainerHeight();
    setTimeout(() => {
      this.data.collapsed = !this.data.collapsed;
    }, 100);
  }

  computedTreeContainerHeight(): void {
    const nativeElement: HTMLElement = this.treeContainer.nativeElement;
    const nativeSwitchRowElement: HTMLElement = this.switchRow.nativeElement;
    if (nativeElement && nativeElement.firstElementChild) {
      nativeElement.style.maxHeight = nativeElement.firstElementChild.clientHeight + 8 + "px";
    }

    if (nativeSwitchRowElement?.classList?.contains("hidden")) {
      nativeSwitchRowElement.classList.remove("hidden");
      setTimeout(() => {
        nativeSwitchRowElement.classList.remove("visuallyhidden");
      }, 20);
    } else {
      nativeSwitchRowElement.classList.add("visuallyhidden");
      nativeSwitchRowElement.addEventListener(
        "transitionend",
        () => {
          nativeSwitchRowElement.classList.add("hidden");
        },
        {
          capture: false,
          once: true,
          passive: false
        }
      );
    }
  }

  changeCondition(value: string): void {
    if (this.disabled) {
      return;
    }

    this.data.condition = value;
    this.handleTouched(true);
    this.handleDataChange();

    this.campaignBuilderService.loadCounts();
  }

  changeOperator(rule: any): void {
    if (this.disabled) {
      return;
    }

    const inputType: string = this.getInputType(rule.field, rule.operator, rule.tuneInType);
    if ((inputType === "multiselect" && typeof rule.value !== "object") || inputType === "batch") {
      rule.value = null;
    } else if (inputType === "string" && typeof rule.value !== "string") {
      rule.value = null;
    }

    this.handleTouched(true, rule);
    this.handleDataChange();

    if (this.isValidRule(rule)) {
      this.campaignBuilderService.loadCounts();
    }
  }

  coerceValueForOperator(operator: string, value: any, rule: Rule): any {
    const inputType: string = this.getInputType(rule.field, operator, rule.tuneInType);
    if (inputType === "multiselect" && !Array.isArray(value)) {
      return [value];
    }
    return value;
  }

  changeInput(rule: any, multiSelectValue?: string[]): void {
    if (this.disabled) {
      return;
    }

    let value: any = rule.value;
    const inputType: string = this.getInputType(rule.field, rule.operator, rule.tuneInType);
    if (
      [
        "multiselect",
        "activeProfile",
        "tune-in-program",
        "tune-in-station",
        "tune-in-genre",
        "tune-in-scheduling"
      ].includes(inputType) &&
      multiSelectValue &&
      multiSelectValue.length >= 0
    ) {
      value = multiSelectValue;
    }

    if (this.config.coerceValueForOperator) {
      rule.value = this.config.coerceValueForOperator(rule.operator, value, rule);
      this.changeDetectorRef.detectChanges();
    } else {
      rule.value = this.coerceValueForOperator(rule.operator, value, rule);
      this.changeDetectorRef.detectChanges();
    }

    this.handleTouched(true, rule);
    this.handleDataChange();

    if (this.isValidRule(rule)) {
      this.campaignBuilderService.loadCounts();
    }
  }

  changeTuneInInput(rule: any, options: TuneInOptions): void {
    if (this.disabled) {
      return;
    }
    if (options.program) {
      rule.value = options.program;
    }
    if (options.station) {
      rule.value = options.station;
    }
    if (options.genre) {
      rule.value = options.genre;
    }
    if (options.schedule) {
      rule.value = options.schedule;
    }
    if (options.viewershipLevels) {
      rule.tercile = options.viewershipLevels;
    }
    if (options.originalAirDateStart) {
      rule.originalAirDateStart = options.originalAirDateStart;
    }
    if (options.originalAirDateEnd) {
      rule.originalAirDateEnd = options.originalAirDateEnd;
    }
    this.handleTouched(true, rule);
    this.handleDataChange();

    if (this.isValidTuneInRule(rule)) {
      this.campaignBuilderService.loadCounts();
    }
  }

  getMaxOriginalAirStart(rule: Rule): string {
    return rule.originalAirDateEnd;
  }
  getMinOriginalAirEnd(rule: Rule): string {
    return rule.originalAirDateStart;
  }
  getOriginalAirDateErrors(rule: any, ref: NgModel): string {
    if (rule.originalAirDateStart && rule.originalAirDateEnd && rule.originalAirDateStart > rule.originalAirDateEnd) {
      if (ref.name === "originalAirDateStart") {
        return "Start date must be before end date";
      } else {
        return "End date must be after start date";
      }
    } else {
      return "Invalid Date. Must be MM/DD/YYYY";
    }
  }

  changeField(fieldValue: string, rule: Rule): void {
    if (this.disabled) {
      return;
    }

    const inputContext: InputContext = this.inputContextCache.get(rule);
    const currentField: any = inputContext && inputContext.field;

    const nextField: Field = this.config.fields[fieldValue];

    const nextValue: any = this.calculateFieldChangeValue(currentField, nextField, rule.value);

    if (nextValue !== undefined) {
      rule.value = nextValue;
    } else {
      delete rule.value;
    }

    rule.operator = this.getDefaultOperator(nextField);

    // Create new context objects so templates will automatically update
    this.inputContextCache.delete(rule);
    this.operatorContextCache.delete(rule);
    this.fieldContextCache.delete(rule);
    this.entityContextCache.delete(rule);
    this.getInputContext(rule);
    this.getFieldContext(rule);
    this.getOperatorContext(rule);
    this.getEntityContext(rule);

    this.handleTouched(true);
    this.handleDataChange();

    if (this.isValidRule(rule)) {
      this.campaignBuilderService.loadCounts();
    }
  }

  private calculateFieldChangeValue(currentField: Field, nextField: Field, currentValue: any): any {
    if (this.config.calculateFieldChangeValue != null) {
      return this.config.calculateFieldChangeValue(currentField, nextField, currentValue);
    }

    const canKeepValue = () => {
      if (currentField == null || nextField == null) {
        return false;
      }
      return currentField.type === nextField.type && this.defaultPersistValueTypes.includes(currentField.type);
    };

    if (this.persistValueOnFieldChange && canKeepValue()) {
      return currentValue;
    }

    if (nextField && nextField.defaultValue !== undefined) {
      return this.getDefaultValue(nextField.defaultValue);
    }

    return undefined;
  }

  changeEntity(entityValue: string, rule: Rule, index: number, data: RuleSet): void {
    if (this.disabled) {
      return;
    }

    const entity: Entity = this.entities.find(e => e.value === entityValue);
    const defaultField: Field = this.getDefaultField(entity);
    data.rules[index] = {
      ...rule,
      field: defaultField.value
    };
    if (defaultField) {
      this.changeField(defaultField.value, {
        ...rule,
        field: defaultField.value
      });
    } else {
      this.handleTouched(true);
      this.handleDataChange();
    }
  }

  getDefaultValue(defaultValue: any): any {
    switch (typeof defaultValue) {
      case "function":
        return defaultValue();
      default:
        return defaultValue;
    }
  }

  getOperatorTemplate(qualifierTypeId?: number): TemplateRef<any> {
    if (qualifierTypeId === 2) {
      return null;
    }
    const t: QueryOperatorDirective = this.parentOperatorTemplate || this.operatorTemplate;
    return t ? t.template : null;
  }

  getFieldTemplate(): TemplateRef<any> {
    const t: QueryFieldDirective = this.parentFieldTemplate || this.fieldTemplate;
    return t ? t.template : null;
  }

  getEntityTemplate(): TemplateRef<any> {
    const t: QueryEntityDirective = this.parentEntityTemplate || this.entityTemplate;
    return t ? t.template : null;
  }

  getArrowIconTemplate(): TemplateRef<any> {
    const t: QueryArrowIconDirective = this.parentArrowIconTemplate || this.arrowIconTemplate;
    return t ? t.template : null;
  }

  getHeaderTemplate(): TemplateRef<any> {
    const t: QueryHeaderDirective = this.headerTemplate;
    return t ? t.template : null;
  }

  getButtonGroupTemplate(): TemplateRef<any> {
    const t: QueryButtonGroupDirective = this.parentButtonGroupTemplate || this.buttonGroupTemplate;
    return t ? t.template : null;
  }

  getSwitchGroupTemplate(): TemplateRef<any> {
    const t: QuerySwitchGroupDirective = this.parentSwitchGroupTemplate || this.switchGroupTemplate;
    return t ? t.template : null;
  }

  getRemoveButtonTemplate(): TemplateRef<any> {
    const t: QueryRemoveButtonDirective = this.parentRemoveButtonTemplate || this.removeButtonTemplate;
    return t ? t.template : null;
  }

  getEmptyWarningTemplate(): TemplateRef<any> {
    const t: QueryEmptyWarningDirective = this.parentEmptyWarningTemplate || this.emptyWarningTemplate;
    return t ? t.template : null;
  }

  getQueryItemClassName(local: LocalRuleMeta, isNew?: boolean): string {
    let cls: string = this.getClassNames("row", "connector", "transition");
    cls += " " + this.getClassNames(local.ruleset ? "ruleSet" : "rule");
    if (local.invalid) {
      cls += " " + this.getClassNames("invalidRuleSet");
    }
    if (local?.rule?.field?.indexOf(`p`) >= 0) {
      cls += " " + this.getClassNames("profileQualifierRow");
    }
    if (isNew) {
      cls += " " + this.getClassNames("isNew");
    }
    return cls;
  }

  getButtonGroupContext(): ButtonGroupContext {
    if (!this.buttonGroupContext) {
      this.buttonGroupContext = {
        addRule: this.addRule.bind(this),
        addRuleSet: this.allowRuleset && this.addRuleSet.bind(this),
        removeRuleSet: this.allowRuleset && this.parentValue && this.removeRuleSet.bind(this),
        getDisabledState: this.getDisabledState,
        $implicit: this.data
      };
    }
    return this.buttonGroupContext;
  }

  getRemoveButtonContext(rule: Rule): RemoveButtonContext {
    if (!this.removeButtonContextCache.has(rule)) {
      this.removeButtonContextCache.set(rule, {
        removeRule: this.removeRule.bind(this),
        handleTouched: this.handleTouched.bind(this),
        getDisabledState: this.getDisabledState,
        $implicit: rule
      });
    }
    return this.removeButtonContextCache.get(rule);
  }

  getFieldContext(rule: Rule): FieldContext {
    if (!this.fieldContextCache.has(rule)) {
      this.fieldContextCache.set(rule, {
        onChange: this.changeField.bind(this),
        getFields: this.getFields.bind(this),
        getDisabledState: this.getDisabledState,
        fields: this.fields,
        $implicit: rule
      });
    }
    return this.fieldContextCache.get(rule);
  }

  getEntityContext(rule: Rule): EntityContext {
    if (!this.entityContextCache.has(rule)) {
      this.entityContextCache.set(rule, {
        onChange: this.changeEntity.bind(this),
        getDisabledState: this.getDisabledState,
        entities: this.entities,
        $implicit: rule
      });
    }
    return this.entityContextCache.get(rule);
  }

  getSwitchGroupContext(): SwitchGroupContext {
    return {
      onChange: this.changeCondition.bind(this),
      getDisabledState: this.getDisabledState,
      $implicit: this.data
    };
  }

  getArrowIconContext(): ArrowIconContext {
    return {
      getDisabledState: this.getDisabledState,
      $implicit: this.data
    };
  }

  getHeaderContext(): any {
    return {
      $implicit: this.data
    };
  }

  getEmptyWarningContext(): EmptyWarningContext {
    return {
      getDisabledState: this.getDisabledState,
      message: this.emptyMessage,
      $implicit: this.data
    };
  }

  getOperatorContext(rule: Rule): OperatorContext {
    if (!this.operatorContextCache.has(rule)) {
      this.operatorContextCache.set(rule, {
        onChange: this.changeOperator.bind(this),
        getDisabledState: this.getDisabledState,
        operators: this.getOperators(rule.field),
        $implicit: rule
      });
    }
    return this.operatorContextCache.get(rule);
  }

  public bustInputContextCache(): void {
    this.inputContextCache.clear();
  }

  getInputContext(rule: Rule): InputContext {
    if (!this.inputContextCache.has(rule)) {
      this.inputContextCache.set(rule, {
        onChange: this.changeInput.bind(this),
        getDisabledState: this.getDisabledState,
        options: this.getOptions(rule.field, this),
        field: this.config.fields[rule.field],
        $implicit: rule
      });
    }
    return this.inputContextCache.get(rule);
  }

  getDragPreivewName(rule: Rule): string {
    return `Move ${this.fields.find(field => field?.value === rule.field)?.name || "qualifier"}`;
  }

  public setupAddQualifierFromModalListener(): void {
    this.addQualifierFromModalListener = this.profilesService.addQualifierToProfile.subscribe(data => {
      data.profiles.map(profile => {
        if (this.profile) {
          if (profile.priority === this.profile.priority) {
            this.addRule(
              null,
              { id: data.qualifier.id.toString(), qualifierTypeId: data.qualifier.qualifierTypeId },
              { name: data.qualifier.name, type: null }
            );
            setTimeout(() => {
              this.renderer.addClass(this.ruleElements.last.nativeElement, "q-border-highlight");
            }, 1);
            setTimeout(() => {
              this.renderer.removeClass(this.ruleElements.last.nativeElement, "q-border-highlight");
            }, 6500);
          }
        }
      });
    });
  }

  public onDragDrop(event: CdkDragDrop<any>): void {
    const eventData: any = event.item.data;
    if (eventData) {
      if (eventData.field || eventData.rules) {
        if (event.container.id === event.previousContainer.id) {
          // re-order qualifiers on drag and drop in same profile group
          moveItemInArray(this.data.rules, event.previousIndex, event.currentIndex);
          moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
        } else {
          // add qualifier to destination profile group and remove it from original profile group
          this.addRule(
            null,
            {
              id: eventData.field,
              index: event.currentIndex,
              isControl: event.item?.data?.isControl,
              name: event.item?.data?.name,
              segmentId: eventData?.segmentId?.toString() ?? undefined,
              segmentName: eventData?.segmentName ?? undefined,
              qualifierTypeId: eventData?.qualifierTypeId,
              tuneInType: eventData?.tuneInType,
              tercile: eventData?.tercile,
              originalAirDateStart: eventData?.originalAirDateStart,
              originalAirDateEnd: eventData?.originalAirDateEnd
            },
            eventData
          );
          this.removeRule(eventData, this.queryBuilderService.dropZoneToRuleSetMap.get(event.previousContainer.id));
          this.ngOnChanges(null);
        }
      } else {
        // should refactor this, not great to manually call ngOnChanges
        this.ngOnChanges(null);

        if (eventData.type === "Profile") {
          const dialogRef: MatDialogRef<RootProfileModalComponent> = this.dialog.open(RootProfileModalComponent, {
            data: {
              title: "Profile Actions",
              sourceProfile: eventData,
              destinationProfile: this.profile
            },
            disableClose: true
          });

          dialogRef
            .afterClosed()
            .subscribe((args: { cloneOption: RootProfileCloneOptions; profile: ProfileViewModel }) => {
              if (args?.cloneOption === RootProfileCloneOptions.Clone) {
                args?.profile.definition.rules.forEach((rule, index, rules) => {
                  if (rule.rules) {
                    this.addRuleSet(null, {
                      condition: rule.condition,
                      rules: rule.rules
                    });
                  } else {
                    this.addRule(
                      null,
                      {
                        id: rule.field,
                        index: event.currentIndex,
                        name: event.item?.data?.name,
                        segmentId: eventData?.segmentId?.toString() ?? undefined,
                        segmentName: eventData?.segmentName ?? undefined,
                        qualifierTypeId: eventData.qualifierTypeId,
                        tuneInType: eventData.tuneInType,
                        tercile: eventData?.tercile,
                        originalAirDateStart: eventData?.originalAirDateStart,
                        originalAirDateEnd: eventData?.originalAirDateEnd
                      },
                      {
                        name: rule.name,
                        id: rule.field,
                        value: rule.value,
                        type: rule.type,
                        segmentId: rule.segmentId,
                        segmentName: rule.segmentName,
                        operators: rule.operators,
                        operator: rule.operator
                      }
                    );
                  }
                });
              } else if (args?.cloneOption === RootProfileCloneOptions.Root) {
                this.addRule(null, {
                  id: args.profile.profileQualifierId,
                  index: event.currentIndex,
                  qualifierTypeId: eventData.qualifierTypeId,
                  tuneInType: eventData.tuneInType,
                  tercile: eventData?.tercile,
                  originalAirDateStart: eventData?.originalAirDateStart,
                  originalAirDateEnd: eventData?.originalAirDateEnd
                });
              }
            });
        } else {
          if (eventData.qualifierTypeId === 2 && this.profile.profileType !== 2) {
            const dialogRef: MatDialogRef<DialogModalComponent> = this.dialog.open(DialogModalComponent, {
              data: {
                title: "Warning",
                content: "This profile is not marked Tune-In. Cannot add Tune-In qualifier to non-Tune-In profile.",
                submitButtonText: "Ok",
                dialogType: "Warning"
              }
            });
          } else {
            this.addRule(null, {
              id: eventData.id.toString(),
              index: event.currentIndex,
              name: eventData?.name,
              segmentId: eventData?.segmentId?.toString() ?? undefined,
              segmentName: eventData?.segmentName ?? undefined,
              qualifierTypeId: eventData.qualifierTypeId,
              tuneInType: eventData.tuneInType,
              tercile: eventData?.tercile,
              originalAirDateStart: eventData?.originalAirDateStart,
              originalAirDateEnd: eventData?.originalAirDateEnd
            });
            if (eventData?.canoeCampaignName && eventData?.canoeCampaignId) {
              this.campaignBuilderService.setCanoeCampaignControlValues(
                eventData?.canoeCampaignName,
                eventData?.canoeCampaignId
              );
            }
            this.handleTouched(true);
          }
        }
      }
    }
  }

  // fire counts for profile being dragged from
  public onDragRelease(event: CdkDragRelease<any>): void {
    this.handleDataChange(true);
    this.handleTouched(true);
  }

  public onDragMove(event: CdkDragMove<any>): void {
    const nodeMovePreview: any = new ElementRef<HTMLElement>(document.getElementById(`${this.dropListGuid}-preview`));
    const xPos: number = event.pointerPosition.x - 800;
    const yPos: number = event.pointerPosition.y - 40;
    if (nodeMovePreview?.nativeElement) {
      nodeMovePreview.nativeElement.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
    }
  }

  private checkEmptyRuleInRuleset(ruleset: RuleSet): boolean {
    if (!ruleset || !ruleset.rules || ruleset.rules.length === 0) {
      return true;
    } else {
      return ruleset.rules.some((item: RuleSet) => {
        if (item.rules) {
          return this.checkEmptyRuleInRuleset(item);
        } else {
          return false;
        }
      });
    }
  }

  private validateRulesInRuleset(ruleset: RuleSet, errorStore: any[]): void {
    if (ruleset && ruleset.rules && ruleset.rules.length > 0) {
      ruleset.rules.forEach(item => {
        if ((item as RuleSet).rules) {
          return this.validateRulesInRuleset(item as RuleSet, errorStore);
        } else if ((item as Rule).field) {
          const field: Field = this.config.fields[(item as Rule).field];
          if (field && field.validator && field.validator.apply) {
            const error: any = field.validator(item as Rule, ruleset);
            if (error != null) {
              errorStore.push(error);
            }
          }
        }
      });
    }
  }

  private handleDataChange(updateSegmentIds: boolean = false): void {
    this.changeDetectorRef.markForCheck();
    if (this.onChangeCallback) {
      this.onChangeCallback();
    }
    if (this.parentChangeCallback) {
      this.parentChangeCallback();
    }
    if (updateSegmentIds) {
      this.updateSegmentIds.emit();
    }
  }

  private handleTouched(dataChangeEvent: boolean = false, rule?: RuleSet): void {
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
    if (this.parentTouchedCallback) {
      this.parentTouchedCallback();
    }

    if (dataChangeEvent) {
      this.profile?.setAsDirty();
    }
  }

  public canDropPredicate(): (drag: CdkDrag, drop: CdkDropList) => boolean {
    return (drag: CdkDrag, drop: CdkDropList): boolean => {
      const fromBounds: DOMRect = drag.dropContainer.element.nativeElement.getBoundingClientRect();
      const toBounds: DOMRect = drop.element.nativeElement.getBoundingClientRect();

      if (!this.intersect(fromBounds, toBounds)) {
        return true;
      }

      // Access a private cdkDragDrop variable to find the pointer position
      // tslint:disable-next-line: no-string-literal
      const pointerPosition: Point = drag._dragRef["_pointerPositionAtLastDirectionChange"];

      // Handle pointer position in nested drop zones
      if (this.insideOf(fromBounds, toBounds)) {
        return !this.pointInsideOf(pointerPosition, fromBounds);
      }
      if (this.insideOf(toBounds, fromBounds) && this.pointInsideOf(pointerPosition, toBounds)) {
        return true;
      }
      return false;
    };
  }

  private intersect(r1: DOMRect | ClientRect, r2: DOMRect | ClientRect): boolean {
    return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);
  }

  private insideOf(innerRect: DOMRect | ClientRect, outerRect: DOMRect | ClientRect): boolean {
    return (
      innerRect.left >= outerRect.left &&
      innerRect.right <= outerRect.right &&
      innerRect.top >= outerRect.top &&
      innerRect.bottom <= outerRect.bottom &&
      !(
        innerRect.left === outerRect.left &&
        innerRect.right === outerRect.right &&
        innerRect.top === outerRect.top &&
        innerRect.bottom === outerRect.bottom
      )
    );
  }

  private pointInsideOf(position: Point, rect: DOMRect | ClientRect): boolean {
    return position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom;
  }

  getEpsilonHintText(rule: any): boolean {
    return rule.qualifierName?.includes("Date (Epsilon)");
  }
}
