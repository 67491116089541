import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { catchError, mergeMap, take } from "rxjs/operators";
import { WorkBook, WorkSheet, writeFile as xlsxWriteFile, utils as xlsxUtils } from "xlsx/dist/xlsx.core.min";
import { BatchValueMetadata, ProfileViewModel, ValidatedBatchResult } from "radr-shared";
import { Guid } from "@shared/lib/guid.service";
import { BatchValuesService } from "@shared/services/batch-values.service";
import { S3Service } from "@shared/services/s3.service";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { UnknownError } from "@shared/models";

export interface BatchValuesSelectorModalOptions {
  title: string;
  qualifierId: number;
  profile: ProfileViewModel;
  previouslyValidated: boolean;
  validatedBatchResult: ValidatedBatchResult;
}
@Component({
  selector: "radr-batch-values-selector-modal",
  templateUrl: "./batch-values-selector-modal.component.html",
  styleUrls: ["./batch-values-selector-modal.component.scss"]
})
export class BatchValuesSelectorModalComponent {
  public values: string = "";
  public file: File;
  public batchId: number;
  public loading: boolean = false;
  public fileIngestInProcess: boolean = false;
  public validationComplete: boolean = false;
  public previouslyValidated: boolean = false;
  public validatedBatchResult: ValidatedBatchResult;

  public get validateButtonDisabled(): boolean {
    return (!this.values && !this.file) || this.fileIngestInProcess || this.loading;
  }

  public get totalValidatedResults(): number {
    return this.matchedValues.length + this.notMatchedValues.length;
  }

  public get matchedValues(): string[] {
    return this.validatedBatchResult?.matchedValues || [];
  }

  public get notMatchedValues(): string[] {
    return this.validatedBatchResult?.notMatchedValues || [];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BatchValuesSelectorModalOptions,
    public dialogRef: MatDialogRef<BatchValuesSelectorModalComponent>,
    private s3Service: S3Service,
    private batchValuesService: BatchValuesService,
    private campaignBuilderService: CampaignBuilderService
  ) {
    if (this.data?.previouslyValidated) {
      this.previouslyValidated = this.data.previouslyValidated;
    }
    if (this.data?.validatedBatchResult) {
      this.validatedBatchResult = this.data.validatedBatchResult;
    }
  }

  clearAll(): void {
    this.values = null;
    this.validatedBatchResult = null;
    this.batchId = null;
    this.validationComplete = false;
  }

  okClicked(): void {
    this.dialogRef.close({ validatedBatchResult: this.validatedBatchResult, batchId: this.batchId });
  }

  cancelClicked(): void {
    this.dialogRef.close();
  }

  valueChanged(): void {
    this.validationComplete = false;
    this.batchId = null;
    this.fileIngestInProcess = false;
  }

  async validate(): Promise<void> {
    this.loading = true;
    this.validatedBatchResult = null;
    if (!this.batchId && !this.file && !!this.values) {
      this.batchId = await this.cleanAndIngestValues();
    }

    this.batchValuesService
      .validateBatchValues(this.batchId)
      .pipe(take(1))
      .subscribe(
        result => {
          this.validatedBatchResult = {
            matchedValues: result.matchedValues,
            notMatchedValues: result.notMatchedValues
          };
          this.data.profile.validatedBatchValues = this.data.profile.validatedBatchValues || {};
          this.data.profile.validatedBatchValues[this.batchId] = this.validatedBatchResult;
          this.validationComplete = true;
        },
        () => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  fileLoaded(file: File): void {
    this.file = file;
    if (file) {
      this.values = null;
      this.uploadAndIngestFile(this.file);
    } else {
      this.fileIngestInProcess = false;
      this.clearAll();
    }

    this.validatedBatchResult = null;
  }

  fileLoading(isLoading: boolean): void {
    this.loading = isLoading || this.fileIngestInProcess;
  }

  createFileDownloadArray(): any[] {
    const maxArrayLength: number = Math.max(this.matchedValues.length, this.notMatchedValues.length);
    const combinedArray: any[] = [["Valid Values", "Not Found Values"]];
    for (let i: number = 0; i < maxArrayLength; i++) {
      combinedArray.push([this.matchedValues[i], this.notMatchedValues[i]]);
    }

    return combinedArray;
  }

  downloadValidationReport(): void {
    const ws: WorkSheet = xlsxUtils.aoa_to_sheet(this.createFileDownloadArray());
    const wb: WorkBook = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(wb, ws, "Sheet1");

    const currentDateTimeForFileName: string = new Date()
      .toLocaleString()
      .replace(/[^0-9]/gi, "")
      .toLowerCase();
    xlsxWriteFile(wb, `batch_value_validation_${currentDateTimeForFileName}.csv`, { bookType: "csv" });
  }

  private uploadAndIngestFile(file: File): void {
    const stopSpinners: () => void = () => {
      this.fileIngestInProcess = false;
      this.loading = false;
    };
    this.fileIngestInProcess = true;
    this.s3Service
      .uploadFileToS3PresignedUrl(file, {
        getFileName: () => {
          const guid: string = Guid.newGuid();
          return `batch-values/${guid}.batch`;
        }
      })
      .pipe(
        take(1),
        mergeMap(([_s3PutResp, s3Key]) => {
          const metadata: BatchValueMetadata = {
            qualifierId: this.data.qualifierId,
            fileName: file.name,
            s3Key
          };
          return this.batchValuesService.createBatchValues(metadata);
        }),
        catchError(err => {
          stopSpinners();
          throw new UnknownError(err, "BatchValuesSelectorModalComponent.uploadAndIngestFile");
        })
      )
      .subscribe(
        batchId => {
          this.batchId = batchId;
        },
        err => {
          stopSpinners();
          throw new UnknownError(err, "BatchValuesSelectorModalComponent.uploadAndIngestFile");
        },
        stopSpinners
      );
  }

  private async cleanAndIngestValues(): Promise<number> {
    // NOTE: This logic splits on all commas, so commas in quotes will also be split
    const values: string[] = this.values
      ?.split(",")
      ?.map(value => value.trim())
      ?.filter(value => value);
    this.values = values?.join(",");

    const batchValueMetadata: BatchValueMetadata = {
      qualifierId: this.data.qualifierId,
      fileName: undefined,
      s3Key: undefined
    };

    return this.batchValuesService.createBatchValues(batchValueMetadata, values).toPromise();
  }
}
