import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CampaignBuilderModes } from "@shared/enums/campaign-builder-mode.enum";
import { ProfilesService } from "@shared/services/profiles.service";
import { ProfileViewModel } from "radr-shared";

@Component({
  selector: "radr-profile-preview-modal",
  templateUrl: "./profile-preview-modal.component.html",
  styleUrls: ["./profile-preview-modal.component.scss"]
})
export class ProfilePreviewModalComponent {
  public profile: ProfileViewModel;
  public modes: typeof CampaignBuilderModes = CampaignBuilderModes;
  public isLoading: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProfilePreviewModalComponent>,
    private profilesService: ProfilesService
  ) {
    if (data.profile) {
      this.profile = data.profile;
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.profilesService.getProfileBuilderViewModel(data.profileId).subscribe(profile => {
        this.profile = profile;
        this.isLoading = false;
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
