<radr-modal [toolbarColor]="accentColor" [warningIcon]="isWarning">
  <ng-container body>
    <div mat-dialog-content>
      <p class="mat-dialog-content-text">{{ data?.content }}</p>
    </div>
  </ng-container>

  <ng-container actions>
    <button id="dialog-modal-cancel-btn" mat-button data-qa="radr-dialog-modal-cancel-button" matDialogClose>
      {{ data?.cancelButtonText }}
    </button>
    <button
      id="dialog-modal-ok-btn"
      mat-button
      data-qa="radr-dialog-modal-ok-button"
      mat-flat-button
      (click)="okClicked()"
      [color]="accentColor"
    >
      {{ data.submitButtonText }}
    </button>
  </ng-container>
</radr-modal>
