import { Injectable } from "@angular/core";
import { KeycloakEvent, KeycloakService } from "keycloak-angular";
import { ViewModel } from "radr-shared/src/view-models/view-model.viewmodel";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export enum RolesEnum {
  Editor = "radr_editor",
  Admin = "radr_admin",
  ReadOnly = "radr_readonly",
  DevTest = "radr-dev-test",
  LimitedAccess = "radr_limitedaccess"
}

@Injectable({
  providedIn: "root"
})
export class RoleAccessService {
  public redirectUrl: string;
  public keycloakEvents$: Observable<KeycloakEvent> = this.keycloak.keycloakEvents$.asObservable();
  private readonly route: string = `${environment.api_domain}/auth`;

  constructor(private keycloak: KeycloakService) {}

  canEditEntity(entity: ViewModel): boolean {
    return entity?.canEdit && (this.isEditor(entity) || this.isLimitedAccess());
  }

  isEditor(entity: ViewModel): boolean {
    const userRoles: string[] = this.keycloak.getUserRoles();
    return (
      userRoles.indexOf(RolesEnum.Admin) > -1 ||
      (userRoles.indexOf(RolesEnum.Editor) > -1 && entity?.createdBy === this.keycloak.getUsername()) ||
      (userRoles.indexOf(RolesEnum.LimitedAccess) > -1 && entity?.createdBy === this.keycloak.getUsername())
    );
  }

  isReadOnly(): boolean {
    return this.keycloak.getUserRoles().indexOf(RolesEnum.ReadOnly) > -1;
  }

  isAdmin(): boolean {
    return this.keycloak.getUserRoles().indexOf(RolesEnum.Admin) > -1;
  }

  isLimitedAccess(): boolean {
    return this.keycloak.getUserRoles().indexOf(RolesEnum.LimitedAccess) > -1;
  }

  isDevOrTest(): boolean {
    return this.keycloak.getUserRoles().indexOf(RolesEnum.DevTest) > -1;
  }
}
