<mat-card class="radr-export-settings-card">
  <radr-component-loader
    id="loading-execution-platform-group-{{ groupNumber }}"
    *ngIf="loadingGroupRules"
  ></radr-component-loader>
  <radr-execution-platform-group-header
    [groupNumber]="groupNumber"
    [formGroup]="executionPlatformGroupForm"
    [groupRules]="groupRules"
    [isGross]="isGross"
  ></radr-execution-platform-group-header>
  <div class="execution-platform-group-inputs">
    <radr-execution-platform-group-stepped-input
      id="requester-dropdown"
      [groupNumber]="groupNumber"
      [stepNumber]="1"
      [inputCategory]="groupInputTypes.Requester"
      [formGroup]="executionPlatformGroupForm"
      controlName="requesterGroupId"
      [options$]="requesters$"
    ></radr-execution-platform-group-stepped-input>
    <radr-execution-platform-group-stepped-input
      id="product-dropdown"
      [groupNumber]="groupNumber"
      [stepNumber]="2"
      [inputCategory]="groupInputTypes.Product"
      [formGroup]="executionPlatformGroupForm"
      controlName="productGroupId"
      [options$]="products$"
    ></radr-execution-platform-group-stepped-input>
    <div>
      <span *ngIf="universeLabel" class="universe-label">Your Universe is: {{ universeLabel }}</span>
      <radr-execution-platform-group-stepped-input
        id="execution-platform-dropdown"
        [universeLabel]="!universeLabel"
        [groupNumber]="groupNumber"
        [stepNumber]="3"
        [inputCategory]="groupInputTypes.ExecutionPlatform"
        [formGroup]="executionPlatformGroupForm"
        controlName="executionPlatformId"
        [options$]="executionPlatforms$"
      ></radr-execution-platform-group-stepped-input>
    </div>
  </div>
  <div *ngIf="isFullAvail || isFreewheelFullAvail" class="execution-platform-sub-form-container">
    <div class="execution-platform-sub-form-wrapper" [formGroup]="groupNameForm">
      <mat-form-field floatLabel="always" class="mat-form-field">
        <mat-label
          >Group Name
          <span class="radr-sub-label"
            >(required for {{ isFullAvail ? "Full Avail campaigns" : "Full Avail Freewheel Campaigns" }})</span
          ></mat-label
        >
        <input
          matInput
          formControlName="groupName"
          placeholder="Enter Group Name"
          (focus)="setGroupNameButtonVisibility(true)"
        />
        <mat-error
          *ngFor="let errorMetaData of groupNameControl?.errorsMetaData || []"
          id="radr-form__input-{{ groupNameControl?.name }}-{{ errorMetaData.name }}-error"
        >
          <span *ngIf="groupNameControl?.invalid && (groupNameControl?.errors)[errorMetaData.name]">
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
        <mat-hint *ngIf="groupNameControl?.hint">{{ groupNameControl?.hint }}</mat-hint>
      </mat-form-field>
      <div [hidden]="!groupNameButtonsVisible">
        <button
          mat-icon-button
          class="edit-popup-submit"
          [disabled]="groupNameForm.pending || groupNameForm.invalid"
          (click)="submitGroupName()"
        >
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="edit-popup-cancel" (click)="cancelGroupName()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isCanoeVideo" class="execution-platform-sub-form-container">
    <div class="execution-platform-sub-form-wrapper" [formGroup]="canoeVideoDetailsForm">
      <mat-form-field floatLabel="always" class="mat-form-field">
        <mat-label>Canoe Video Campaign Name *</mat-label>
        <input
          matInput
          formControlName="canoeVideoCampaignName"
          placeholder="Enter Canoe Video Campaign Name"
          (focus)="setCanoeVideoDetailsButtonVisibility(true)"
          id="canoe-video-campaign-name-input"
        />
        <mat-error
          *ngFor="let errorMetaData of canoeVideoCampaignNameControl?.errorsMetaData || []"
          id="radr-form__input-{{ canoeVideoCampaignNameControl?.name }}-{{ errorMetaData.name }}-error"
        >
          <span
            *ngIf="
              canoeVideoCampaignNameControl?.invalid && (canoeVideoCampaignNameControl?.errors)[errorMetaData.name]
            "
          >
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
      </mat-form-field>
      <div class="execution-platform-sub-form-spacer"></div>
      <mat-form-field floatLabel="always" class="mat-form-field">
        <mat-label>Canoe Video Campaign ID *</mat-label>
        <input
          matInput
          formControlName="canoeVideoCampaignId"
          placeholder="Enter Canoe Video Campaign ID"
          (focus)="setCanoeVideoDetailsButtonVisibility(true)"
          id="canoe-video-campaign-id-input"
        />
        <mat-error
          *ngFor="let errorMetaData of canoeVideoCampaignIdControl?.errorsMetaData || []"
          id="radr-form__input-{{ canoeVideoCampaignIdControl?.name }}-{{ errorMetaData.name }}-error"
        >
          <span
            *ngIf="canoeVideoCampaignIdControl?.invalid && (canoeVideoCampaignIdControl?.errors)[errorMetaData.name]"
          >
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
      </mat-form-field>
      <div [hidden]="!canoeVideoDetailsButtonsVisible">
        <button
          mat-icon-button
          class="edit-popup-submit"
          [disabled]="canoeVideoDetailsForm.pending || canoeVideoDetailsForm.invalid"
          (click)="submitCanoeVideoDetailsForm()"
        >
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="edit-popup-cancel" (click)="cancelCanoeVideoDetailsForm()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isCanoeATV" class="execution-platform-sub-form-container">
    <div class="execution-platform-sub-form-wrapper" [formGroup]="canoeATVDetailsForm">
      <mat-form-field floatLabel="always" class="mat-form-field">
        <mat-label>Canoe ATV Campaign Name *</mat-label>
        <input
          matInput
          formControlName="canoeATVCampaignName"
          placeholder="Enter Canoe ATV Campaign Name"
          (focus)="setCanoeATVDetailsButtonVisibility(true)"
          id="canoe-atv-campaign-name-input"
        />
        <mat-error
          *ngFor="let errorMetaData of canoeATVCampaignNameControl?.errorsMetaData || []"
          id="radr-form__input-{{ canoeATVCampaignNameControl?.name }}-{{ errorMetaData.name }}-error"
        >
          <span
            *ngIf="canoeATVCampaignNameControl?.invalid && (canoeATVCampaignNameControl?.errors)[errorMetaData.name]"
          >
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
      </mat-form-field>
      <div class="execution-platform-sub-form-spacer"></div>
      <mat-form-field floatLabel="always" class="mat-form-field">
        <mat-label>Canoe ATV Campaign ID *</mat-label>
        <input
          matInput
          formControlName="canoeATVCampaignId"
          placeholder="Enter Canoe ATV Campaign ID"
          (focus)="setCanoeATVDetailsButtonVisibility(true)"
          id="canoe-atv-campaign-id-input"
        />
        <mat-error
          *ngFor="let errorMetaData of canoeATVCampaignIdControl?.errorsMetaData || []"
          id="radr-form__input-{{ canoeATVCampaignIdControl?.name }}-{{ errorMetaData.name }}-error"
        >
          <span *ngIf="canoeATVCampaignIdControl?.invalid && (canoeATVCampaignIdControl?.errors)[errorMetaData.name]">
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
      </mat-form-field>
      <div [hidden]="!canoeATVDetailsButtonsVisible">
        <button
          mat-icon-button
          class="edit-popup-submit"
          [disabled]="canoeATVDetailsForm.pending || canoeATVDetailsForm.invalid"
          (click)="submitCanoeATVDetailsForm()"
        >
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="edit-popup-cancel" (click)="cancelCanoeATVDetailsForm()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-card-footer class="execution-platform-group-footer">
    <button
      *ngIf="showAddNewButton"
      id="add-new-execution-platform-group"
      data-test="add-new-execution-platform-group"
      mat-button
      (click)="addNewExecutionPlatformGroup()"
      [disabled]="disableAddExecutionPlatform()"
    >
      <mat-icon>add</mat-icon> Add Execution Platform
    </button>
  </mat-card-footer>
</mat-card>
