<div class="query-builder-counts-wrapper" [class.loading]="campaignBuilderService.isCampaignCountsLoading">
  <span class="query-builder-counts-title">
    <radr-contextual-message-icon
      *ngIf="isRuleIncomplete"
      id="{{ countValueId }}-incomplete-count"
      type="warning"
      size="small"
      message="Counts could not be calculated because this qualifier is incomplete."
    ></radr-contextual-message-icon>
    HOUSEHOLD COUNT
  </span>
  <div class="query-builder-counts">
    <div class="query-builder-total-count">
      <mat-progress-spinner
        id="{{ loadingSpinnerId }}"
        *ngIf="
          campaignBuilderService.isQualifierCountsLoading && !hasCachedQualifierCount && !qualifierCountError;
          else countDisplay
        "
        mode="indeterminate"
        diameter="15"
      ></mat-progress-spinner>
      <ng-template #countDisplay>
        <ng-container *ngIf="qualifierCountError && !hasCachedQualifierCount && !isRuleIncomplete; else countValue">
          <mat-icon
            class="count-error-warning-icon"
            matTooltip="There was an error retrieving counts"
            matTooltipPosition="above"
          >
            error
          </mat-icon>
        </ng-container>
      </ng-template>
      <ng-template #countValue>
        <span *ngIf="!isRuleIncomplete" id="{{ countValueId }}" class="query-builder-total-count-number">{{
          campaignBuilderService.getQualifierCount(qualifierHash) | number: "" : "en-US"
        }}</span>
      </ng-template>
    </div>
  </div>
</div>
