<mat-icon
  [ngClass]="{
    'contextual-message-icon': true,
    unknown: type == UNKNOWN,
    info: type == INFO,
    warning: type == WARNING,
    error: type == ERROR,
    small: size == SMALL,
    medium: size == MEDIUM,
    large: size == LARGE,
    top: verticalAlign == TOP,
    middle: verticalAlign == MIDDLE,
    bottom: verticalAlign == BOTTOM
  }"
  [matTooltip]="message"
  [matTooltipDisabled]="!message || disabled"
  >error</mat-icon
>
