import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import notify, { SnackBarStatus } from "@shared/utils/notify";
import { forkJoin, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: "root"
})
export class S3Service {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingService,
    private snackBar: MatSnackBar
  ) {}

  public uploadFileToS3PresignedUrl(
    file: File,
    options: { prefix?: string; getFileName?: (file: File) => string }
  ): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ "Content-Type": file.type });
    const fileName: string = options.getFileName ? options.getFileName(file) : file.name;
    const fileNameWithPrefix: string = options.prefix ? `${options.prefix}/${fileName}` : fileName;
    const cleanFileNameWithPrefix: string = fileNameWithPrefix.replace(/[!~*'()"&]/g, "");

    return this.authService.getS3PresignedPutUrl(cleanFileNameWithPrefix, file.type).pipe(
      mergeMap(({ url, s3Key }) => {
        return forkJoin([this.httpClient.put(url, file, { headers }), of(s3Key)]);
      })
    );
  }

  public downloadS3File(fileName: string, s3Bucket: string): void {
    this.loadingService.setLoading(true);
    this.authService.getS3PresignedGetUrl(fileName, s3Bucket).subscribe(
      ({ url }) => {
        window.open(url, "_blank");
        this.loadingService.setLoading(false);
      },
      err => {
        notify(this.snackBar, "There was an error downloading the file", { snackBarType: SnackBarStatus.Error });
        this.loadingService.setLoading(false);
      }
    );
  }
}
