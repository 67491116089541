import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AppInjector } from "@shared/lib/app-injector.service";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatListModule } from "@angular/material/list";
import { CommonModule, DatePipe } from "@angular/common";
import { MatBadgeModule } from "@angular/material/badge";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { IconRegistryModule } from "@shared/components/icon-registry.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material/core";
import * as Sentry from "@sentry/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initializeKeycloak } from "../keycloak-init.factory";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActionButtonModule } from "@shared/components/action-button/action-button.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { LoadingOverlayModule } from "@shared/components/loading-overlay/loading-overlay.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "@shared/components/shared.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { BuilderModule } from "./modules/builder/builder.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule,
    ActionButtonModule,
    LoadingOverlayModule,
    IconRegistryModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    BuilderModule,
    SharedModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    DatePipe
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
