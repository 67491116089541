<mat-form-field class="select-autocomplete" floatLabel="always" [appearance]="formFieldAppearance">
  <mat-label [hidden]="!label">{{ label }}</mat-label>
  <input #fileInput type="file" id="file" hidden="true" (change)="parseFileAndUpdateInputs($event)" />
  <mat-select
    class="select-autocomplete__select"
    panelClass="select-autocomplete__panel"
    #selectAutocomplete
    [multiple]="multiple"
    [placeholder]="getPlaceholderText()"
    [formControl]="control"
    [required]="required"
    (openedChange)="openedChange($event)"
  >
    <mat-select-trigger class="select-autocomplete__trigger sa-trigger" [title]="triggerTitleText">
      <div class="sa-trigger__content">
        <span class="sa-trigger__text">{{ triggerText }}</span>
        <span class="sa-trigger__text-additional">{{ remainingTriggerText }}</span>
      </div>
    </mat-select-trigger>
    <input
      class="select-autocomplete__filter-input"
      matInput
      [attr.disabled]="disabled || isLoadingOptions ? true : null"
      [formControl]="filterInputFormControl"
      data-test="filterInputFormControl"
      placeholder="Search (comma delimited)..."
      (keydown)="handleInputKey($event)"
    />
    <cdk-virtual-scroll-viewport
      [itemSize]="36"
      [minBufferPx]="36 * 30"
      [maxBufferPx]="36 * 100"
      class="select-autocomplete__options-list"
      [class.loading-options]="isLoadingOptions"
    >
      <mat-option
        class="select-autocomplete__option"
        *ngIf="filteredOptions.length == 0 && !isLoadingOptions"
        value="None"
        disabled
      >
        No options found
      </mat-option>
      <mat-option
        #matOption
        class="select-autocomplete__option"
        *cdkVirtualFor="let option of filteredOptions; templateCacheSize: 100"
        [value]="option"
        (onSelectionChange)="optionClicked($event)"
        [disabled]="disabled || isLoadingOptions || optionsDisabled"
        (click)="shiftClicked($event, matOption)"
      >
        {{ option }}
      </mat-option>
    </cdk-virtual-scroll-viewport>
    <div *ngIf="showActions && !disabled" class="select-autocomplete__actions sa-actions">
      <div class="sa-actions__left">
        <button
          class="sa-actions__clear"
          mat-button
          color="primary"
          [disabled]="!isClearEnabled()"
          (click)="handleClearClick()"
        >
          Clear
        </button>
        <span class="sa-actions__divider">|</span>
        <span [matTooltip]="matToolTipText" [matTooltipDisabled]="!isSelectAllDisabled()" *ngIf="!selectAllTuneIn">
          <button
            class="sa-actions__select-all"
            mat-button
            color="primary"
            [disabled]="isSelectAllDisabled()"
            (click)="handleSelectAllClick()"
          >
            Select All
          </button>
        </span>
        <mat-checkbox
          *ngIf="selectAllTuneIn"
          [formControl]="selectAllTuneInFormControl"
          data-test="select-all-tune-in-checkbox"
          >Select All</mat-checkbox
        >
        <span class="sa-actions__divider">|</span>
        <button class="sa-actions__clear" *ngIf="tuneInType" mat-button color="primary" (click)="openFilePicker()">
          Upload File
        </button>
      </div>
      <div class="sa-actions__right">
        <button class="sa-actions__apply" mat-button color="primary" (click)="handleApplyClick()">Apply</button>
      </div>
    </div>
  </mat-select>
  <span id="radr-select-autocomplete-{{ control['name'] }}-spinner" *ngIf="isLoading" class="spinner"></span>
</mat-form-field>
<mat-error *ngIf="control.touched && control.errors" radrFormError [control]="control"></mat-error>
