import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { RadrNotification } from "radr-shared";
import BaseService from "@shared/lib/base.service";
import { HttpParams } from "@angular/common/http";
import { NotificationTypeEnum } from "radr-shared/src/enums";

@Injectable({
  providedIn: "root"
})
export class RadrNotificationsService extends BaseService {
  private readonly route: string = "notifications";
  public errorCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor() {
    super();
  }

  getNotifications(
    showErrorsOnly: boolean = false,
    notificationType: NotificationTypeEnum
  ): Observable<RadrNotification[]> {
    let params: HttpParams = new HttpParams();
    params = params.set("showErrorsOnly", `${showErrorsOnly}`);
    params = params.set("notificationType", `${notificationType}`);
    return this.getMany<RadrNotification>(this.route, { params });
  }

  getErrorCount(): Observable<number> {
    return this.getOne<number>(`${this.route}/errors/count`).pipe(
      tap(count => {
        if (count) {
          this.errorCount$.next(count);
        }
      })
    );
  }
}
