<div class="audience-definition">
  <radr-component-loader id="loading-audience-options" *ngIf="audienceOptionsLoading"></radr-component-loader>
  <mat-form-field appearance="fill" class="audience-form-field">
    <mat-label>Target Audience</mat-label>
    <input type="text" matInput [formControl]="targetAudience" [matAutocomplete]="targetSelect" />
    <mat-autocomplete
      #targetSelect="matAutocomplete"
      [displayWith]="getOptionText"
      (optionSelected)="targetAudienceSelected($event.option.value)"
      (opened)="targetAudienceClear()"
    >
      <mat-option *ngFor="let option of filteredTargetOptions$ | async" [value]="option">
        {{ option.audienceName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="fill" class="audience-form-field" *ngIf="!isControlActive()">
    <mat-label>Control Audience</mat-label>
    <input type="text" matInput [formControl]="controlAudience" [matAutocomplete]="controlSelect" />
    <mat-autocomplete
      #controlSelect="matAutocomplete"
      [displayWith]="getOptionText"
      (optionSelected)="controlAudienceSelected($event.option.value)"
      (opened)="controlAudienceClear()"
    >
      <mat-option *ngFor="let option of filteredControlOptions$ | async" [value]="option">
        {{ option.audienceName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
