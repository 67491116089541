import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Universe } from "radr-shared";
import MasterDetailService from "@shared/lib/master-detail.service";

@Injectable({
  providedIn: "root"
})
export class UniversesService extends MasterDetailService<Universe> {
  public readonly universes$: Observable<Universe[]> = this.masterListSource$.asObservable();
  public readonly universes: BehaviorSubject<Universe[]> = this.masterListSource$;
  constructor() {
    super("universes");
  }

  public findUniverseById(universeId: number): Universe {
    return this.universes.getValue().find(universe => universe.id === universeId);
  }
}
