import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injector } from "@angular/core";
import get from "lodash.get";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { SnackbarService } from "@shared/services/snackbar.service";
import { AppInjector } from "./app-injector.service";
import { AuthService } from "@shared/services/auth.service";
import { SnackBarStatus } from "@shared/utils/notify";
import { LoadingService } from "@shared/services/loading.service";
import { EmptyTuneInQuery, GatewayTimeoutError, SessionTimeoutError, UnknownError } from "@shared/models";

export default abstract class BaseService {
  private baseUrl: string = environment.api_domain;
  protected httpClient: HttpClient;
  protected snackbarService: SnackbarService;
  protected loadingService: LoadingService;
  private authService: AuthService;

  constructor(protected viewModelClass?: any) {
    const injector: Injector = AppInjector.getInjector();
    this.httpClient = injector && injector.get(HttpClient);
    this.snackbarService = injector && injector.get(SnackbarService);
    this.loadingService = injector && injector.get(LoadingService);
    this.authService = injector && injector.get(AuthService);
  }

  private createInstanceOfViewModel<T>(object: any): T {
    if (this.viewModelClass) {
      return new this.viewModelClass(object);
    }

    return object;
  }

  protected getMany<T>(
    route: string,
    options?: { [key: string]: any; params: HttpParams },
    isViewModel: boolean = true
  ): Observable<T[]> {
    return this.httpClient.get<T[]>(`${this.baseUrl}/${route}`, options).pipe(
      map(result => {
        if (!isViewModel) {
          return result;
        }
        return result.map(item => this.createInstanceOfViewModel<T>(item));
      }),
      catchError(this.handleError)
    );
  }

  protected getOne<T>(
    route: string,
    options?: { [key: string]: any; params: HttpParams },
    isViewModel: boolean = true
  ): Observable<T> {
    return this.httpClient.get<T>(`${this.baseUrl}/${route}`, options).pipe(
      map(result => {
        if (!isViewModel) {
          return result;
        }
        return this.createInstanceOfViewModel<T>(result);
      }),
      catchError(this.handleError)
    );
  }

  protected post<T>(route: string, body: any, options?: object): Observable<T> {
    return this.httpClient.post<T>(`${this.baseUrl}/${route}`, body, options).pipe(catchError(this.handleError));
  }

  protected patch<T>(route: string, body: any, options?: object): Observable<T> {
    return this.httpClient.patch<T>(`${this.baseUrl}/${route}`, body, options).pipe(catchError(this.handleError));
  }

  protected put<T>(route: string, body: any, options?: object): Observable<T> {
    return this.httpClient.put<T>(`${this.baseUrl}/${route}`, body, options).pipe(catchError(this.handleError));
  }

  protected delete<T>(route: string): Observable<T> {
    return this.httpClient.delete<T>(`${this.baseUrl}/${route}`).pipe(catchError(this.handleError));
  }

  private handleError = (error: HttpErrorResponse) => {
    // This should already be handled by the library's built-in interceptor.
    if (error.status === 400) {
      if (JSON.stringify(error.error).includes("No results found for the given selection")) {
        return throwError(new EmptyTuneInQuery(error.error));
      }
    }
    if (error.status === 401 || error.status === 403) {
      this.authService?.tryRefresh().then(success => {
        if (!success) {
          this.snackbarService?.open(
            "Your session has expired! Please login again to continue.",
            "OK",
            10000,
            SnackBarStatus.Error
          );
          this.authService?.logout();
        }
      });
      return throwError(new SessionTimeoutError("Session Expired; please login again"));
    }

    if (this.snackbarService) {
      this.snackbarService.open(
        `<h3 class="network-error">An unexpected error has occured in the RADR application.</h3></br>
         URL: "${error.url}"</br>
         Status Code: "${error.status} - ${error.statusText}"</br>
         Message: "${error.error?.message || error.message}"`,
        "Close",
        50000,
        SnackBarStatus.Error
      );
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    if (error.status === 504) {
      return throwError(new GatewayTimeoutError(`A gateway timeout has occurred on ${error.url}`));
    }
    // return an observable with a user-facing error message
    return throwError(
      new UnknownError(
        `${error.status ? error.status + " - " : ""}${
          error.error?.message || error.message || "Something bad happened; please try again later."
        }`,
        error.url || null
      )
    );
  };
}
