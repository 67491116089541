export class TuneInScheduleModel {
  station: string[];
  program: string[];
  fromDate: string;
  toDate: string;
  fromTime: string;
  toTime: string;
  toTimeSuffix: string;
  fromTimeSuffix: string;
  timezone?: string;
  utcToDateTime?: string;
  utcFromDateTime?: string;
  constructor() {}
}
