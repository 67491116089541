import { Component, Input } from "@angular/core";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { QualifiersService } from "@shared/services/qualifiers.service";
import * as _ from "lodash";
@Component({
  selector: "radr-qualifier-count",
  templateUrl: "./qualifier-count.component.html",
  styleUrls: ["./qualifier-count.component.scss"]
})
export class QualifierCountComponent {
  debouncedLoadCounts: () => void = _.debounce(() => {
    this.campaignBuilderService.loadQualifierCounts([
      { field: this.field, operator: this.operator, value: this.value, isControl: this.isControl }
    ]);
  }, 800);

  get cachedQualifierCount(): number {
    return this.campaignBuilderService.getQualifierCount(this.qualifierHash);
  }

  get hasCachedQualifierCount(): boolean {
    return this.campaignBuilderService.hasQualifierCount(this.qualifierHash);
  }
  get qualifierCountError(): boolean {
    return this.campaignBuilderService.qualifierCountErrors.has(this.qualifierHash);
  }

  @Input() qualifierName: string;
  private _field: string;
  @Input()
  set field(val: string) {
    this._field = val;
    this.setQualifierHash();
  }
  get field(): string {
    return this._field;
  }

  private _operator: string;
  @Input()
  set operator(val: string) {
    this._operator = val;
    this.setQualifierHash();
  }
  get operator(): string {
    return this._operator;
  }

  private _value: any;
  @Input()
  set value(val: any) {
    this._value = val;
    this.setQualifierHash();
  }
  get value(): any {
    return this._value;
  }

  private _isControl: boolean;
  @Input()
  set isControl(val: boolean) {
    this._isControl = val;
    this.setQualifierHash();
  }
  get isControl(): boolean {
    return this._isControl;
  }

  private _tercile: any;
  @Input()
  set tercile(val: any) {
    this._tercile = val;
    this.setQualifierHash();
  }
  get tercile(): any {
    return this._tercile;
  }

  private _originalAirDateStart: string;
  @Input()
  set originalAirDateStart(val: string) {
    this._originalAirDateStart = val;
    this.setQualifierHash();
  }
  get originalAirDateStart(): string {
    return this._originalAirDateStart;
  }

  private _originalAirDateEnd: string;
  @Input()
  set originalAirDateEnd(val: string) {
    this._originalAirDateEnd = val;
    this.setQualifierHash();
  }
  get originalAirDateEnd(): string {
    return this._originalAirDateEnd;
  }

  private _viewershipLevels: any;
  @Input()
  set viewershipLevels(val: any) {
    this._viewershipLevels = val;
    this.setQualifierHash();
  }
  get viewershipLevel(): any {
    return this._viewershipLevels;
  }

  get loadingSpinnerId(): string {
    let spinnerId: string = this.getUniqueIdPrefix() + "household-";
    spinnerId += "-spinner";
    return spinnerId;
  }

  get countValueId(): string {
    let countId: string = this.getUniqueIdPrefix() + "household-";
    countId += "-count";
    return countId;
  }

  public qualifierHash: string;

  get isRuleIncomplete(): boolean {
    if (["Tune-In Program", "Tune-In Station", "Tune-In Scheduling", "Tune-In Genre"].includes(this.qualifierName)) {
      return !(!!this.field && !!this.value?.length && !!this.tercile?.length);
    } else {
      return !(
        this.field &&
        this.operator &&
        !([undefined, null].includes(this.value) || (Array.isArray(this.value) && this.value.length === 0))
      );
    }
  }

  constructor(
    public campaignBuilderService: CampaignBuilderService,
    public qualifiersService: QualifiersService
  ) {}

  private getUniqueIdPrefix(): string {
    let qualifierString: string = "";
    if (this.field) {
      qualifierString = `qualifier-${this.field}-`;
    }
    return qualifierString;
  }

  private operatorAndValueTypesMatch(operator: string, value: any): boolean {
    switch (typeof value) {
      case "string":
      case "number":
        return ["=", "!=", ">", "<", ">=", "<="].includes(operator);
      case "object":
        if (value instanceof Array) {
          return ["in", "not in", "includes", "excludes"].includes(operator);
        } else {
          return ["batch"].includes(operator);
        }
      case "boolean":
        return ["exists", "is"].includes(operator);
      case "undefined":
        return false;
      default:
        return true;
    }
  }

  private setQualifierHash(): void {
    this.qualifierHash = this.qualifiersService.createQualifierHash(
      this.field,
      this.value,
      this.operator,
      this.isControl
    );

    if (
      this.field &&
      this.operator &&
      this.value !== undefined &&
      !this.cachedQualifierCount &&
      this.operatorAndValueTypesMatch(this.operator, this.value)
    ) {
      if (["string", "number"].includes(typeof this.value)) {
        this.debouncedLoadCounts();
      } else {
        this.campaignBuilderService.loadQualifierCounts([
          { field: this.field, operator: this.operator, value: this.value, isControl: this.isControl }
        ]);
      }
    }
  }
}
