import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import {
  BatchValuesSelectorModalComponent,
  BatchValuesSelectorModalOptions
} from "../../../builder/components/batch-values-selector-modal/batch-values-selector-modal.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BatchValuesService } from "@shared/services/batch-values.service";
import { ProfileViewModel, ValidatedBatchResult, AudienceViewModel } from "radr-shared";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";

@Component({
  selector: "radr-batch-values-selector",
  templateUrl: "./batch-values-selector.component.html",
  styleUrls: ["./batch-values-selector.component.scss"],
  providers: []
})
export class BatchValuesSelectorComponent implements OnChanges {
  @Input() batchId: number;
  @Input() qualifierId: number;
  @Input() profile: ProfileViewModel;
  @Input() audience: AudienceViewModel;
  @Output() batchIdChange: EventEmitter<number> = new EventEmitter<number>();

  public validatedBatchResult: ValidatedBatchResult;
  public loading: boolean = false;

  public get numberAppliedLabel(): string {
    const numberApplied: number = this.validatedBatchResult?.matchedValues.length || 0;

    if (this.loading) {
      return "";
    }

    return `${numberApplied} Applied `;
  }

  constructor(
    public dialog: MatDialog,
    public batchValuesService: BatchValuesService,
    private campaignBuilderService: CampaignBuilderService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile && (changes.batchId || changes.qualifierId) && this.batchId && this.qualifierId) {
      this.validatedBatchResult = this.profile.validatedBatchValues[this.batchId];
    }
  }

  openBatchSelector(): void {
    const dialogRef: MatDialogRef<BatchValuesSelectorModalComponent> = this.dialog.open(
      BatchValuesSelectorModalComponent,
      {
        data: {
          title: "Batch Value Selector",
          qualifierId: +this.qualifierId,
          previouslyValidated: !!this.validatedBatchResult,
          validatedBatchResult: this.validatedBatchResult,
          profile: this.profile
        } as BatchValuesSelectorModalOptions
      }
    );

    dialogRef.afterClosed().subscribe(val => {
      if (val?.validatedBatchResult) {
        this.validatedBatchResult = val.validatedBatchResult;
      }

      if (val?.batchId) {
        this.batchIdChange.emit(val.batchId);
      }
    });
  }
}
