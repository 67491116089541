import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, combineLatest, Subject } from "rxjs";
import MasterDetailService from "../lib/master-detail.service";
import { Audience, Qualifier } from "radr-shared/src/models";
import { AudienceTreeViewModel, AudienceViewModel } from "radr-shared/src/view-models";
import { Rule, RuleSet } from "@shared/components/audience-query-builder";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AudiencesService extends MasterDetailService<Audience> {
  constructor(private qualifierService: QualifiersService) {
    super("audience", false);
  }

  public audienceList$: Observable<Audience[]> = this.masterListSource$.asObservable();
  public audienceTreeView: BehaviorSubject<AudienceTreeViewModel[]> = new BehaviorSubject<AudienceTreeViewModel[]>([]);
  public audienceTreeView$: Observable<AudienceTreeViewModel[]> = this.audienceTreeView.asObservable();
  public addAudienceToAudience: Subject<{ audience: Audience; audiences: Audience[] }> = new Subject();
  public selectedAudienceIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  public audienceOptions$: Observable<Audience[]> = combineLatest([this.audienceList$, this.selectedAudienceIds]).pipe(
    map(([audiences, selectedIds]) => {
      return (
        audiences
          ?.filter(
            audience => audience.audienceName && !selectedIds?.includes(audience?.id) && audience?.dataSourceId == null
          )
          // filter out audiences that are already selected or are from a datasource
          ?.sort((a, b) =>
            a.audienceName.localeCompare(b.audienceName, undefined, {
              numeric: true,
              sensitivity: "base"
            })
          )
      );
    })
  );

  getAudiences(): Observable<Audience[]> {
    return this.updateMasterList();
  }

  getAudience(id: number): Observable<Audience> {
    return this.getOne(`${this.route}/${id}`);
  }

  getAudienceTreeView(): Observable<AudienceTreeViewModel[]> {
    return this.getMany(`${this.route}/tree-view`);
  }

  getDistinctAudienceValues(id: number): Observable<any> {
    return this.getMany(`${this.route}/${id}/distinctValues`);
  }

  deleteAudience(audienceId: number): Observable<any> {
    return this.delete(`${this.route}/${audienceId}`);
  }

  getAudiencesByQualifierId(
    qualifierId: string,
    includeDefaultAudience: boolean = false
  ): Observable<AudienceViewModel[]> {
    return this.getMany<AudienceViewModel>(
      `${this.route}?qualifierId=${qualifierId}&includeDefaultAudience=${includeDefaultAudience}`
    );
  }

  patchAudience(audienceId: number, audience: Partial<AudienceViewModel>): Observable<any> {
    // TODO: Make sure route actually works
    return this.patch(`${this.route}/${audienceId}`, { ...audience, id: audienceId });
  }

  createAudienceFromDatasource(audience: Audience): Observable<Audience> {
    return this.post(`${this.route}/datasource`, audience);
  }

  ingestAllAudiences(): Observable<any> {
    return this.post(`${this.route}/ingest`, {});
  }

  createAudienceFromBuilder(audience: Audience): Observable<Audience> {
    return this.post(`${this.route}/builder`, audience);
  }

  updateAudience(audienceId: number, audience: Partial<Audience>): Observable<Audience> {
    // TODO: Make sure route actually works
    return this.patch(`${this.route}/${audienceId}`, audience);
  }

  refreshAudience(id: number): Observable<any> {
    // TODO: Make sure route actually works
    return this.post(`${this.route}/${id}/refresh`, { id });
  }

  isAudienceNameUnique(name: string, unsavedNames: string[] = null): Observable<boolean> {
    if (!name) {
      return of(true);
    }

    // check incoming names from component that haven't been saved yet
    name = name.trim();
    if (unsavedNames && unsavedNames.length) {
      const matches: string[] = unsavedNames.filter(elem => (elem || "").toLowerCase() === name.toLowerCase());

      if (matches.length > 0) {
        return of(false);
      }
    }

    // now run to the server to check names in the db
    const params: HttpParams = new HttpParams().set("name", encodeURIComponent(name));
    return super.getOne<boolean>(`${this.route}/is_audience_name_unique`, { params });
  }

  getSegmentIdNameMapFromRuleSet(rSet: RuleSet, segmentIdToNameMap?: Map<string, string>): Map<string, string> {
    segmentIdToNameMap = segmentIdToNameMap ?? new Map<string, string>();
    if (rSet.rules.length > 0) {
      rSet.rules.forEach((r: RuleSet | Rule) => {
        if (this.isRuleSet(r)) {
          this.getSegmentIdNameMapFromRuleSet(r, segmentIdToNameMap);
        } else {
          if (r?.segmentId && !segmentIdToNameMap.has(r.segmentId)) {
            segmentIdToNameMap.set(r.segmentId, r.segmentName);
          }
        }
      });
    }
    return segmentIdToNameMap;
  }

  isRuleSet(r: Rule | RuleSet): r is RuleSet {
    return (r as RuleSet).rules !== undefined;
  }

  clearSelectedAudiences(removedIds: number[]): void {
    this.selectedAudienceIds.next(
      this.selectedAudienceIds.getValue().filter(audienceId => !removedIds.includes(audienceId))
    );
  }

  updateSelectedAudiences(newAudienceId: number, oldAudienceId?: number): void {
    this.selectedAudienceIds.next(
      [...this.selectedAudienceIds.getValue(), newAudienceId].filter(
        audienceId => !oldAudienceId || audienceId !== oldAudienceId
      )
    );
  }
}
