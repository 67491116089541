import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Observable, BehaviorSubject, of, combineLatest, Subject } from "rxjs";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import cloneDeep from "lodash.clonedeep";
import { TreeViewModel } from "radr-shared";
import { DataProvidersService } from "@shared/services/data-providers.service";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { RoleAccessService } from "@shared/services/role-access.service";

export enum CampaignBuilderLeftMenuTypes {
  Qualifiers = "qualifiers",
  Profiles = "profiles",
  Audiences = "audiences"
}

@Component({
  selector: "radr-campaign-builder-left-menu",
  templateUrl: "./campaign-builder-left-menu.component.html",
  styleUrls: ["./campaign-builder-left-menu.component.scss"]
})
export class CampaignBuilderLeftMenuComponent implements OnInit, OnDestroy {
  public qualifierCampaignToggle: CampaignBuilderLeftMenuTypes = CampaignBuilderLeftMenuTypes.Qualifiers;
  public qualifierCampaignToggleValues: any = CampaignBuilderLeftMenuTypes;
  public filter$: BehaviorSubject<string> = new BehaviorSubject("");
  public filterTextChanged: Subject<string> = new Subject<string>();
  public campaignsTree$: Observable<TreeViewModel[]>;
  public filteredCampaignTree$: Observable<TreeViewModel[]>;
  public dataProviderTree$: Observable<TreeViewModel[]>;
  public filteredAudienceTree$: Observable<TreeViewModel[]>;
  public filteredDataProviderTree$: Observable<TreeViewModel[]>;
  private cleanUp$: Subject<void> = new Subject<void>();

  get isLoading(): boolean {
    return this.qualifierCampaignToggle === CampaignBuilderLeftMenuTypes.Qualifiers
      ? this.dataProvidersService.isLoadingTreeView
      : this.campaignBuilderService.isLoadingTreeView;
  }

  constructor(
    private campaignBuilderService: CampaignBuilderService,
    private dataProvidersService: DataProvidersService,
    public roleAccessService: RoleAccessService
  ) {}

  ngOnInit(): void {
    this.campaignsTree$ = this.campaignBuilderService.campaignsTreeView;
    this.filteredCampaignTree$ = this.filterTreeView(this.filter$, this.campaignsTree$);

    this.dataProviderTree$ = this.dataProvidersService.dataProvidersTreeView;
    this.filteredDataProviderTree$ = this.filterTreeView(this.filter$, this.dataProviderTree$);

    this.filterTextChanged.pipe(debounceTime(500), takeUntil(this.cleanUp$)).subscribe(filter => {
      this.filter$.next(filter);
    });
  }

  ngOnDestroy(): void {
    this.cleanUp$.next();
    this.cleanUp$.complete();
  }

  public getFilteredListItems(): Observable<any[]> {
    switch (this.qualifierCampaignToggle) {
      case CampaignBuilderLeftMenuTypes.Profiles:
        return this.filteredCampaignTree$;
      case CampaignBuilderLeftMenuTypes.Qualifiers:
        return this.filteredDataProviderTree$;
      case CampaignBuilderLeftMenuTypes.Audiences:
        return this.filteredAudienceTree$;
      default:
        return of([]);
    }
  }

  public filterTreeView(
    filter$: Observable<string>,
    treeView$: Observable<TreeViewModel[]>
  ): Observable<TreeViewModel[]> {
    return combineLatest([filter$, treeView$]).pipe(
      map(([filterText, treeView]) => {
        return cloneDeep(treeView).filter(node => this.isVisible(node, filterText));
      })
    );
  }

  public isVisible(node: TreeViewModel, filterText: string): boolean {
    if (filterText) {
      if (node.filters?.some(filter => filter?.toLowerCase().includes(filterText?.toLowerCase()))) {
        node.isExpanded = true;
        return true;
      } else {
        if (node.children) {
          const matchingChildren: TreeViewModel[] = node.children.filter(childNode =>
            this.isVisible(childNode, filterText)
          );
          if (matchingChildren?.length > 0) {
            node.children = matchingChildren;
            node.isExpanded = true;
          }
          return matchingChildren?.length > 0;
        }
      }
    } else {
      node.isExpanded = false;
      return true;
    }
  }

  search(inputEvent: any): void {
    this.filterTextChanged.next(inputEvent.target.value);
  }
}
