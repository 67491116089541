<div class="tune-in-schedule-item" *ngIf="scheduleForm">
  <form [formGroup]="scheduleForm" class="radr-tune-in-schedule-item-form">
    <radr-select-autocomplete
      [control]="stationsControl"
      [value]="stationsControl.value"
      [loadOptionsOnOpen]="false"
      [searchBackend]="true"
      [fileUploaded]="fileUploaded"
      tuneInType="Station"
      searchEndpoint="qualifiers/tune_in_stations?searchFilter="
      class="tune-in-autocomplete"
      placeholder="Select Station(s)"
      data-test="tuneInStation"
      formFieldAppearance="outline"
    ></radr-select-autocomplete>
    <radr-select-autocomplete
      [control]="programsControl"
      [value]="programsControl.value"
      [loadOptionsOnOpen]="false"
      [searchBackend]="true"
      [fileUploaded]="fileUploaded"
      tuneInType="Program"
      searchEndpoint="qualifiers/tune_in_programs?searchFilter="
      class="tune-in-autocomplete"
      placeholder="Select Program(s)"
      data-test="tuneInProgram"
      formFieldAppearance="outline"
    ></radr-select-autocomplete>
    <mat-form-field appearance="outline" class="schedule-from-date">
      <mat-label>From Date:</mat-label>
      <input
        matInput
        [matDatepicker]="fromDate"
        [max]="getMaxFromDate()"
        formControlName="fromDate"
        name="fromDate"
        [ngStyle]="{ display: fromDateControl.value ? 'none' : 'block' }"
      />
      <mat-datepicker #fromDate (closed)="datePickerOnClosed('fromDate')"></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
      <div *ngIf="fromDateControl.value">{{ fromDateControl.value | date: "MM/dd/yyyy" }}</div>
    </mat-form-field>

    <mat-form-field appearance="outline" class="schedule-to-date">
      <mat-label>To Date:</mat-label>
      <input
        matInput
        [matDatepicker]="toDate"
        [min]="getMinToDate()"
        formControlName="toDate"
        name="toDate"
        [ngStyle]="{ display: toDateControl.value ? 'none' : 'block' }"
      />
      <mat-datepicker #toDate (closed)="datePickerOnClosed('toDate')"></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
      <div *ngIf="toDateControl.value">{{ toDateControl.value | date: "MM/dd/yyyy" }}</div>
    </mat-form-field>

    <mat-form-field appearance="outline" class="schedule-from-time">
      <mat-label>From Time</mat-label>
      <input
        name="fromTime"
        matInput
        formControlName="fromTime"
        mask="h0:m0"
        [dropSpecialCharacters]="false"
        [leadZeroDateTime]="true"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" data-test="daytimeFromSuffix" class="daytime-select">
      <mat-label>AM/PM</mat-label>
      <mat-select matNativeControl formControlName="fromTimeSuffix">
        <mat-option value="AM">AM</mat-option>
        <mat-option value="PM">PM</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="schedule-to-time">
      <mat-label>To Time</mat-label>
      <input
        name="toTime"
        matInput
        formControlName="toTime"
        mask="h0:m0"
        [dropSpecialCharacters]="false"
        [leadZeroDateTime]="true"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" data-test="daytimeToSuffix" class="daytime-select">
      <mat-label>AM/PM</mat-label>
      <mat-select matNativeControl formControlName="toTimeSuffix">
        <mat-option value="AM">AM</mat-option>
        <mat-option value="PM">PM</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
