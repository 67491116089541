import { Campaign, ProfileCountRequest } from "../models";
import { Profile } from "../models/profile.model";

export class ProfileCountRequestFactory {
  public static create(profile: Profile, campaign: Campaign): ProfileCountRequest {
    const profileCountRequest: ProfileCountRequest = {
      id: profile.id,
      priority: profile.priority,
      profileType: profile.profileType,
      definition: profile.definition,
      capFixed: profile.capFixed,
      capPercentage: profile.capPercentage,
      controlPercentage: profile.controlPercentage,
      targetAudienceId: profile.targetAudienceId,
      controlAudienceId: profile.controlAudienceId
    };

    return profileCountRequest;
  }
}
