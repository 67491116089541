import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/campaigns",
    pathMatch: "full"
  },
  {
    path: "versions",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/versions/versions.module").then(m => m.VersionsModule)
  },
  {
    path: "admin",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/admin/admin.module").then(m => m.AdminModule)
  },
  {
    path: "logout",
    loadChildren: () => import("./modules/logout/logout.module").then(m => m.LogoutModule)
  },
  {
    path: "campaigns",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/campaigns-summary/campaigns-summary.module").then(m => m.CampaignsSummaryModule)
  },
  {
    path: "campaign-builder",
    redirectTo: "campaigns/builder"
  },
  {
    path: "campaigns/builder",
    loadChildren: () => import("./modules/builder/campaign-builder.module").then(m => m.CampaignBuilderModule)
  },
  {
    path: "profiles",
    loadChildren: () => import("./modules/profiles-summary/profiles-summary.module").then(m => m.ProfilesSummaryModule)
  },
  {
    path: "profiles/builder",
    loadChildren: () => import("./modules/builder/profile-builder.module").then(m => m.ProfileBuilderModule)
  },
  {
    path: "data_sources",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/data-sources/data-sources.module").then(m => m.DataSourcesModule)
  },
  {
    path: "notifications",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/notifications/notifications.module").then(m => m.NotificationsModule)
  },
  {
    path: "export-audit",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/export-audit/export-audit.module").then(m => m.ExportAuditModule)
  },
  // If someone tries to go to a non-existent path, let's just go to campaign summary
  {
    path: "**",
    redirectTo: "/campaigns"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
