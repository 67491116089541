import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { CampaignViewModel, ProfileViewModel } from "radr-shared";
import { RoleAccessService } from "@shared/services/role-access.service";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";

interface AddTestMacsModalOptions {
  profile: ProfileViewModel;
}

@Component({
  selector: "radr-add-test-macs-modal",
  templateUrl: "./add-test-macs-modal.component.html",
  styleUrls: ["./add-test-macs-modal.component.scss"]
})
export class AddTestMacsModalComponent implements OnInit, OnDestroy {
  public acceptButtonLoading: boolean = false;
  public formGroup: FormGroup;
  private campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
  private cleanup: Subject<void> = new Subject<void>();

  isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddTestMacsModalOptions,
    private campaignBuilderService: CampaignBuilderService,
    private roleAccessService: RoleAccessService,
    private dialogRef: MatDialogRef<AddTestMacsModalComponent>
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  canEdit(): boolean {
    return this.roleAccessService.canEditEntity(this.campaign);
  }

  submit(): void {
    this.campaignBuilderService
      .getSelectedItem()
      .profiles.find(profile => profile.priority === this.data.profile.priority).testMacAddresses =
      this.formGroup.get("testMacAddresses").value;
    this.dialogRef.close();
  }

  buildForm(): void {
    this.formGroup = new FormGroup({
      testMacAddresses: new FormControl(this.data.profile.testMacAddresses, {
        updateOn: "change",
        validators: Validators.required
      })
    });
  }

  ngOnDestroy(): void {
    this.cleanup.next();
    this.cleanup.complete();
  }
}
