import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { TreeViewModel } from "radr-shared";
import MasterDetailService from "@shared/lib/master-detail.service";
import { RoleAccessService } from "./role-access.service";

@Injectable({
  providedIn: "root"
})
export class DataProvidersService extends MasterDetailService<TreeViewModel> {
  public dataProvidersTreeView: BehaviorSubject<TreeViewModel[]> = new BehaviorSubject<TreeViewModel[]>([]);
  public dataProvidersTreeView$: Observable<TreeViewModel[]> = this.dataProvidersTreeView.asObservable();
  public isLoadingTreeView: boolean = false;

  constructor(private roleAccessService: RoleAccessService) {
    super("data-providers", false);
  }

  getDataProvidersTreeView(): Observable<TreeViewModel[]> {
    const limitedAccessParam: string = this.roleAccessService.isLimitedAccess() ? "?limitedAccess=true" : "";
    this.isLoadingTreeView = true;
    return super.getMany(`${this.route}/tree-view${limitedAccessParam}`).pipe(
      tap((tree: TreeViewModel[]) => {
        this.isLoadingTreeView = false;
        this.dataProvidersTreeView.next(tree);
      })
    );
  }

  doesDataProviderRequireSegmentId(dataProviderName: string): boolean {
    return dataProviderName?.toLowerCase().includes("canoe");
  }
}
