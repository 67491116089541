import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { SimplifiCategory, SimplifiSubcategory, SimplifiAttribute } from "radr-shared";
import BaseService from "@shared/lib/base.service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SimplifiService extends BaseService {
  private readonly route: string = "simplifi";
  private categories: SimplifiCategory[];
  private subcategories: SimplifiSubcategory[];
  private attributes: SimplifiAttribute[];

  constructor() {
    super();
  }

  getSimplifiOptions(): Observable<{
    categories: SimplifiCategory[];
    subcategories: SimplifiSubcategory[];
    attributes: SimplifiAttribute[];
  }> {
    if (this.categories?.length && this.subcategories?.length) {
      return of({
        categories: this.categories,
        subcategories: this.subcategories,
        attributes: this.attributes
      });
    } else {
      return this.getOne<{
        categories: SimplifiCategory[];
        subcategories: SimplifiSubcategory[];
        attributes: SimplifiAttribute[];
      }>(this.route).pipe(
        tap(results => {
          this.categories = results.categories;
          this.subcategories = results.subcategories;
          this.attributes = results.attributes;
        })
      );
    }
  }
}
