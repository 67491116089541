import { DataSource, Qualifier } from "../models";
import { QualifierViewModel } from "./qualifier.viewmodel";
import { ViewModel } from "./view-model.viewmodel";

/**
 * Represents a data source view model in RADR
 *
 * @export
 * @class DataSourceSummaryViewModel
 */
export class DataSourceViewModel extends DataSource implements ViewModel {
  constructor(dataSource: any = {}) {
    super();
    Object.assign(this, dataSource);

    this.transfromQualifiersToViewModel();
  }

  createdBy: string;
  qualifiers: QualifierViewModel[];

  get canEdit(): boolean {
    return true;
  }

  private transfromQualifiersToViewModel(): void {
    this.qualifiers = this.qualifiers?.map((qualifier: Qualifier) => {
      return new QualifierViewModel(qualifier);
    });
  }
}
