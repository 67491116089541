<form (ngSubmit)="onSubmit()" [ngClass]="'radr-form ' + class">
  <div mat-dialog-content [ngClass]="'radr-form-content ' + class + '-content'">
    <ng-container *ngFor="let control of formControls">
      <mat-form-field
        *ngIf="!control.type || control.type === 'input'"
        floatLabel="always"
        class="mat-form-field radr-form__input-{{ control?.name }}"
      >
        <mat-label>{{ control.label }}</mat-label>
        <input
          matInput
          id="radr-form__input-{{ control?.name }}"
          [attr.data-qa]="class + '-' + control.name + '-input'"
          [placeholder]="control.placeholder"
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [required]="control.isRequired"
          [type]="control.inputType || 'text'"
          (focus)="control.onFocus ? control.onFocus() : null"
          (blur)="control.onBlur ? control.onBlur() : null"
          (keydown.enter)="onSubmit()"
        />
        <span id="radr-form__input-{{ control?.name }}-spinner" *ngIf="control?.isLoading" class="spinner"></span>
        <span *ngIf="control?.suffix" id="radr-form__input-{{ control?.name }}-suffix" matSuffix>{{
          control?.suffix
        }}</span>
        <mat-error
          *ngFor="let errorMetaData of control.errorsMetaData || []"
          id="radr-form__input-{{ control?.name }}-{{ errorMetaData.name }}-error"
          [attr.data-qa]="class + '-' + control.name + '-' + errorMetaData.name + '-error'"
        >
          <span *ngIf="control.invalid && control.errors[errorMetaData.name]">
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
        <mat-hint *ngIf="control.hint" align="left">{{ control.hint }}</mat-hint>
      </mat-form-field>
      <mat-form-field
        *ngIf="control.type === 'textarea'"
        floatLabel="always"
        class="mat-form-field radr-form__textarea-{{ control?.name }}"
      >
        <mat-label>{{ control.label }}</mat-label>
        <textarea
          matInput
          id="radr-form__textarea-{{ control?.name }}"
          [cdkTextareaAutosize]="true"
          [attr.data-qa]="class + '-' + control.name + '-textbox'"
          [placeholder]="control.placeholder"
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [required]="control.isRequired"
          [type]="control.inputType || 'text'"
          (focus)="control.onFocus ? control.onFocus() : null"
          (blur)="control.onBlur ? control.onBlur() : null"
          (keydown.enter)="onSubmit()"
        ></textarea>
        <span id="radr-form__textarea-{{ control?.name }}-spinner" *ngIf="control?.isLoading" class="spinner"></span>
        <mat-error
          *ngFor="let errorMetaData of control.errorsMetaData || []"
          id="radr-form__textarea-{{ control?.name }}-{{ errorMetaData.name }}-error"
          [attr.data-qa]="class + '-' + control.name + '-' + errorMetaData.name + '-error'"
        >
          <span *ngIf="control.invalid && control.errors[errorMetaData.name]">
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
        <mat-hint *ngIf="control.hint" align="left">{{ control.hint }}</mat-hint>
      </mat-form-field>
      <div
        *ngIf="control.type === 'checkbox'"
        class="radr-form__mat-form-field radr-form__checkbox-{{ control?.name }}"
      >
        <mat-checkbox
          id="radr-form__checkbox-{{ control?.name }}"
          [attr.data-qa]="class + '-' + control.name + '-checkbox'"
          [formControl]="control"
          [required]="control.isRequired"
        >
          {{ control.label }}
        </mat-checkbox>
        <mat-error
          *ngFor="let errorMetaData of control.errorsMetaData || []"
          id="radr-form__checkbox-{{ control?.name }}-{{ errorMetaData.name }}-error"
          [attr.data-qa]="class + '-' + control.name + '-' + errorMetaData.name + '-error'"
        >
          <span *ngIf="control.invalid && control.errors[errorMetaData.name]">
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
        <mat-hint *ngIf="control.hint" align="left">{{ control.hint }}</mat-hint>
      </div>
      <mat-form-field
        *ngIf="control.type === 'select'"
        floatLabel="always"
        class="mat-form-field radr-form__select-{{ control?.name }}"
      >
        <mat-label>{{ control.label }}</mat-label>
        <mat-select
          id="radr-form__select-{{ control?.name }}"
          aria-label="Select an option"
          [attr.data-qa]="class + '-' + control.name + '-select'"
          [formControl]="control"
          [required]="control.isRequired"
          [placeholder]="control.placeholder"
        >
          <mat-option
            *ngIf="control.showEmptyOption"
            id="radr-form__select-{{ control?.name }}-select-option-empty"
            class="radr-select-empty-option"
            [attr.data-qa]="class + '-' + control.name + '-select-option-empty'"
            [value]="null"
          >
            {{ control.placeholder }}
          </mat-option>
          <mat-option
            *ngFor="let option of control.options"
            id="radr-form__select-{{ control?.name }}-select-option-{{ option.value }}"
            [attr.data-qa]="class + '-' + control.name + '-select-option-' + option.value"
            [value]="option.value"
            [disabled]="option.disabled"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
        <span id="radr-form__select-{{ control?.name }}-spinner" *ngIf="control?.isLoading" class="spinner"></span>
        <mat-error
          *ngFor="let errorMetaData of control.errorsMetaData || []"
          id="radr-form__select-{{ control?.name }}-{{ errorMetaData.name }}-error"
          [attr.data-qa]="class + '-' + control.name + '-' + errorMetaData.name + '-error'"
        >
          <span *ngIf="control.invalid && control.errors[errorMetaData.name]">
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
        <mat-hint *ngIf="control.hint" align="left">{{ control.hint }}</mat-hint>
      </mat-form-field>
      <radr-select-autocomplete
        *ngIf="control.type === 'select-autocomplete'"
        id="radr-form__autocomplete-{{ control?.name }}"
        class="radr-form__autocomplete-{{ control?.name }}"
        [attr.data-qa]="class + '-' + control.name + '-select-autocomplete'"
        [label]="control.label"
        [options]="buildSelectAutocompleteOptions(control.options)"
        [required]="control.isRequired"
        [showActions]="false"
        [formControl]="control"
        [multiple]="control?.multiple || false"
        [multiSelectDisplayMaxLen]="control?.multiSelectDisplayMaxLen"
        [isLoading]="control?.isLoading"
      ></radr-select-autocomplete>
      <div *ngIf="control.type === 'radio'" class="radr-form__mat-form-field radr-form__radio-{{ control?.name }}">
        <mat-label class="radr-form__radio-label"
          >{{ control.label }}<span *ngIf="control.isRequired"> *</span></mat-label
        >
        <mat-radio-group
          aria-label="Select an option"
          id="radr-form__radio-{{ control?.name }}"
          [attr.data-qa]="class + '-' + control.name + '-radio'"
          [formControl]="control"
          [required]="control.isRequired"
        >
          <mat-radio-button
            *ngFor="let option of control.options"
            id="radr-form__radio-{{ control?.name }}-radio-option-{{ option.value }}"
            [attr.data-qa]="class + '-' + control.name + '-radio-option-' + option.value"
            [value]="option.value"
            >{{ option.label }}</mat-radio-button
          >
        </mat-radio-group>
        <mat-error
          *ngFor="let errorMetaData of control.errorsMetaData || []"
          id="radr-form__radio-{{ control?.name }}-{{ errorMetaData.name }}-error"
          [attr.data-qa]="class + '-' + control.name + '-' + errorMetaData.name + '-error'"
        >
          <span *ngIf="control.invalid && control.errors[errorMetaData.name]">
            {{ errorMetaData.errorMessage }}
          </span>
        </mat-error>
        <mat-hint *ngIf="control.hint" align="left">{{ control.hint }}</mat-hint>
      </div>
      <mat-form-field
        *ngIf="control.type === 'hidden'"
        [hidden]="true"
        style="display: none"
        class="mat-form-field radr-form__hidden-{{ control?.name }}"
      >
        <input
          matInput
          id="radr-form__hidden-{{ control?.name }}"
          [attr.data-qa]="class + '-' + control.name + '-input'"
          [formControl]="control"
        />
      </mat-form-field>
    </ng-container>
  </div>
</form>
