import { NgModule } from "@angular/core";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@NgModule({
  imports: [MatIconModule]
})
export class IconRegistryModule {
  icons: any = {
    add: "assets/icons/ic_add_black_24px.svg",
    addCircleOutline: "assets/icons/ic_add_circle_outline_24px.svg",
    clear: "assets/icons/ic_clear.svg",
    close: "assets/icons/baseline-close-24px.svg",
    delete: "assets/icons/ic_delete_black_24px.svg",
    edit: "assets/icons/ic_mode_edit_black_24px.svg",
    menu: "assets/icons/hamburger-menu-24px.svg",
    removeCircleOutline: "assets/icons/ic_remove_circle_outline_24px.svg",
    search: "assets/icons/ic_search_black_24px.svg",
    warning: "assets/icons/ic_warning_red_24px.svg"
  };

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    Object.keys(this.icons).forEach(key => {
      iconRegistry.addSvgIcon(key, sanitizer.bypassSecurityTrustResourceUrl(this.icons[key]));
    });
  }
}
