import { KeycloakService } from "keycloak-angular";

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        const isAuth: boolean = await keycloak.init({
          config: {
            // TODO: Put these in SSM or SecretsManager
            // in case they become environment specific.
            url: "https://keycloak.spectrumtoolbox.com" + "/auth",
            realm: "spectrumtoolbox",
            clientId: "reach-radr-backend"
          },
          initOptions: {
            // Ideally we would use a silent SSO check
            // with an iframe here, but the version of
            // keycloak server we are using does not
            // support this.
            checkLoginIframe: false,
            // TODO: Remove next line before prod release.
            // It provides more context for understanding
            // what the library is doing for us.
            enableLogging: true,
            token: localStorage.getItem("token") || "",
            refreshToken: localStorage.getItem("refreshToken") || ""
          },
          loadUserProfileAtStartUp: true,
          bearerExcludedUrls: [
            `radr-file-import-[a-z]{2,}\.s3`,
            `radr-audience-measurement-export-[a-z]{2,}\.s3`,
            `radr-execution-platform-export-[a-z]{2,}\.s3`
          ]
        });
        if (!isAuth || localStorage.getItem("loggedOut")) {
          localStorage.removeItem("loggedOut");
          await keycloak.login({ prompt: "login" });
        }
        resolve();
      } catch (error) {
        if (keycloak.getKeycloakInstance()) {
          localStorage.removeItem("loggedOut");
          await keycloak.login({ prompt: "login" });
          return resolve();
        }
        reject(error);
      }
    });
  };
}
