import { Component, Output, Input, ElementRef, EventEmitter, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { WorkBook, read as xlsxRead, write as xlsxWrite, utils as xlsxUtils } from "xlsx/dist/xlsx.core.min";

@Component({
  selector: "radr-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"]
})
export class FileUploadComponent {
  @ViewChild("fileInput", { static: false }) fileUploadInput: ElementRef;

  @Input() acceptedFileTypes: string = ".xlsx, .xls, .csv";
  @Input() uploadText: string = "Upload CSV or Excel file";
  @Input() isDataSourceUpload: boolean = false;
  @Input() showFileErrors: boolean = true;
  @Input() importedFile: string;
  @Output() fileLoaded: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  public file: File;
  public errorMessage: string;

  constructor(public dialogRef: MatDialogRef<FileUploadComponent>) {}

  uploadFile(): void {
    this.fileUploadInput.nativeElement.click();
  }

  async filePicked(files: FileList): Promise<void> {
    this.fileLoading.emit(true);

    if (files && files[0]) {
      this.file = files[0];
    }
    if (this.isDataSourceUpload) {
      this.fileLoaded.emit(this.file);
    } else {
      let parsedWorkbook: WorkBook;
      try {
        const file: ArrayBuffer = await this.file.arrayBuffer();
        parsedWorkbook = xlsxRead(file, {
          type: "array",
          raw: true,
          cellNF: true,
          cellDates: true,
          cellFormula: true,
          cellHTML: true,
          cellStyles: true,
          cellText: true
        });
        this.errorMessage = null;
      } catch (error) {
        if (this.showFileErrors) {
          this.errorMessage = "File Error: could not be read";
        }
      }

      if (!this.errorMessage) {
        if (parsedWorkbook && !this.file.name.endsWith("csv")) {
          this.file = await this.convertFileToCsv(parsedWorkbook, this.file);
        }
        this.fileLoaded.emit(this.file);
      }
    }

    this.fileLoading.emit(false);
  }

  convertFileToCsv(wb: WorkBook, file: File): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const contentsAsCsv: string = xlsxUtils.sheet_to_csv(wb.Sheets[wb.SheetNames[0]], { FS: "," });
      const buffer: ArrayBuffer = new ArrayBuffer(contentsAsCsv.length);
      const bufView: Uint8Array = new Uint8Array(buffer);
      for (let i: number = 0; i < contentsAsCsv.length; ++i) {
        bufView[i] = contentsAsCsv.charCodeAt(i);
      }
      const fileOut: File = new File([buffer], file.name, { lastModified: file.lastModified, type: "text/csv" });
      resolve(fileOut);
    });
  }

  clearFile(): void {
    this.file = null;
    this.errorMessage = null;
    this.fileUploadInput.nativeElement.value = null;
    this.fileLoaded.emit(null);
  }
}
