<button
  id="{{ id }}-action-btn"
  mat-raised-button
  color="primary"
  class="radr-btn"
  (click)="buttonClicked()"
  type="button"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>
