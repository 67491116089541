import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { AudiencesService } from "@shared/services/audience.service";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ProfileViewModel } from "radr-shared";
import { Audience } from "radr-shared/src/models";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";

@Component({
  selector: "radr-profile-audience-definition",
  templateUrl: "./profile-audience-definition.component.html",
  styleUrls: ["./profile-audience-definition.component.scss"]
})
export class ProfileAudienceDefinitionComponent implements OnInit, OnDestroy {
  constructor(
    private audienceService: AudiencesService,
    public campaignBuilderService: CampaignBuilderService
  ) {}
  private cleanUp: Subject<void> = new Subject<void>();

  @Input() audienceForm: FormGroup;

  @Input()
  set profile(val: ProfileViewModel) {
    this.profileValue = val;
  }
  get profile(): ProfileViewModel {
    return this.profileValue;
  }

  private profileValue: ProfileViewModel;
  audienceOptionsLoading: boolean = true;
  targetAudience: AbstractControl;
  controlAudience: AbstractControl;
  previousTargetValueId: number;
  previousControlValueId: number;

  get audienceOptions$(): Observable<Audience[]> {
    return this.audienceService.audienceOptions$;
  }
  filteredTargetOptions$: Observable<Audience[]>;
  filteredControlOptions$: Observable<Audience[]>;
  previousControlValue: boolean;

  ngOnInit(): void {
    this.targetAudience = this.audienceForm.get("target");
    this.controlAudience = this.audienceForm.get("control");
    this.targetAudience.setValue(this.profile.targetAudience);
    this.controlAudience.setValue(this.profile.controlAudience);
    this.previousTargetValueId = this.targetAudience?.value?.id;
    this.previousControlValueId = this.controlAudience?.value?.id;
    this.previousControlValue = this.campaignBuilderService.getSelectedItem().isControlActive;

    this.filteredTargetOptions$ = combineLatest([
      this.audienceOptions$,
      this.targetAudience.valueChanges.pipe(startWith(""))
    ]).pipe(
      map(([audienceOptions, value]) => {
        return this._audienceOptionsFilter(audienceOptions, value);
      }),
      takeUntil(this.cleanUp)
    );
    this.filteredControlOptions$ = combineLatest([
      this.audienceOptions$,
      this.controlAudience.valueChanges.pipe(startWith(""))
    ]).pipe(
      map(([audienceOptions, value]) => {
        return this._audienceOptionsFilter(audienceOptions, value);
      }),
      takeUntil(this.cleanUp)
    );

    this.audienceOptions$.subscribe(options => {
      if (!options.length) {
        this.audienceService
          .getAudiences()
          .pipe()
          .subscribe(() => {
            this.audienceOptionsLoading = false;
          });
      } else {
        this.audienceOptionsLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.audienceService.clearSelectedAudiences([this.previousTargetValueId, this.previousControlValueId]);
    this.cleanUp.next();
    this.cleanUp.complete();
  }

  targetAudienceSelected(audienceSelected: Audience): void {
    this.profile.targetAudienceId = audienceSelected.id;
    this.audienceService.updateSelectedAudiences(audienceSelected.id, this.previousTargetValueId);
    this.previousTargetValueId = audienceSelected.id;
  }

  targetAudienceClear(): void {
    this.profile.targetAudienceId = null;
    this.audienceService.clearSelectedAudiences([this.previousTargetValueId]);
    this.targetAudience.setValue("");
  }

  controlAudienceSelected(audienceSelected: Audience): void {
    this.profile.controlAudienceId = audienceSelected.id;
    this.audienceService.updateSelectedAudiences(audienceSelected.id, this.previousControlValueId);
    this.previousControlValueId = audienceSelected.id;
  }

  controlAudienceClear(): void {
    this.profile.controlAudienceId = null;
    this.audienceService.clearSelectedAudiences([this.previousControlValueId]);
    this.controlAudience.setValue("");
  }

  public getOptionText(option: Audience): string {
    return option && option.audienceName ? option.audienceName : "";
  }

  private _audienceOptionsFilter(audienceOptions: Audience[], value: string | Audience): Audience[] {
    const filterValue: string = typeof value === "object" ? value.audienceName : value;
    return audienceOptions?.filter(option => !filterValue || option.audienceName.toLowerCase().includes(filterValue));
  }

  isControlActive(): boolean {
    this.didControlChange(this.campaignBuilderService.getSelectedItem().isControlActive);
    return this.campaignBuilderService.getSelectedItem().isControlActive;
  }

  didControlChange(controlValue: boolean): void {
    if (this.previousControlValue !== controlValue) {
      this.previousControlValue = controlValue;
      if (this.previousControlValueId) {
        this.controlAudienceClear();
      }
    }
  }
}
