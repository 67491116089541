import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { FileStatus, Qualifier } from "radr-shared";
import MasterDetailService from "../lib/master-detail.service";
import { HttpParams } from "@angular/common/http";
import { Rule, RuleSet } from "@shared/components/angular-query-builder";
import { QualifierValueCounts } from "radr-shared/src/models";
import md5 from "blueimp-md5";

@Injectable({
  providedIn: "root"
})
export class QualifiersService extends MasterDetailService<Qualifier> {
  public qualifierList$: Observable<Qualifier[]> = this.masterListSource$.asObservable();

  constructor() {
    super("qualifiers", false);
  }

  getDistinctQualifierValues(id: number): Observable<string[]> {
    return this.getMany(`${this.route}/${id}/distinctValues`);
  }

  getDistinctQualifierValuesEndpoint(id: number | string): string {
    return `${this.route}/${id}/distinctValues?limit=10000`;
  }

  refreshQualifier(id: number): Observable<any> {
    return this.post(`${this.route}/${id}/refresh`, { id });
  }

  getQualifiers(): Observable<Qualifier[]> {
    return this.updateMasterList();
  }

  getQualifiersByTypeId(qualifierTypeId: number): Observable<Qualifier[]> {
    return this.getMany(`${this.route}?qualifierTypeId=${qualifierTypeId}`);
  }

  createQualifier(qualifier: Qualifier): Observable<Qualifier> {
    return this.post(`${this.route}`, qualifier);
  }

  getQualifierStatus(id: number): Observable<FileStatus> {
    return this.getOne(`${this.route}/${id}/status`);
  }

  getQualifierValueCounts(id: number): Observable<QualifierValueCounts[]> {
    return this.getMany(`${this.route}/${id}/counts`);
  }

  getQualifier(id: number): Observable<Qualifier> {
    return this.getOne(`${this.route}/${id}`);
  }

  updateQualifier(qualifierId: number, qualifier: Partial<Qualifier>): Observable<Qualifier> {
    return this.patch(`${this.route}/${qualifierId}`, qualifier);
  }

  deleteQualifier(qualifierId: number): Observable<void> {
    return this.delete(`${this.route}/${qualifierId}`);
  }

  verifyTuneInValues(tuneInValues: string[], type: string): Observable<unknown> {
    return this.post(`${this.route}/verify-tune-in-values?type=${type}`, tuneInValues);
  }

  getTuneInPrograms(searchFilter: string = ""): Observable<string[]> {
    return this.getMany(`${this.route}/tune_in_programs?searchFilter=${searchFilter}`);
  }

  getTuneInStations(searchFilter: string = ""): Observable<string[]> {
    return this.getMany(`${this.route}/tune_in_stations?searchFilter=${searchFilter}`);
  }

  getTuneInGenres(searchFilter: string = ""): Observable<string[]> {
    return this.getMany(`${this.route}/tune_in_genres?searchFilter=${searchFilter}`);
  }

  isQualifierNameUnique(name: string, unsavedNames: string[] = null): Observable<boolean> {
    if (!name) {
      return of(true);
    }

    // check incoming names from component that haven't been saved yet
    name = name.trim();
    if (unsavedNames && unsavedNames.length) {
      const matches: string[] = unsavedNames.filter(elem => (elem || "").toLowerCase() === name.toLowerCase());

      if (matches.length > 0) {
        return of(false);
      }
    }

    // now run to the server to check names in the db
    const params: HttpParams = new HttpParams().set("name", encodeURIComponent(name));

    return super.getOne<boolean>(`${this.route}/is_qualifier_name_unique`, { params });
  }

  getSegmentIdNameMapFromRuleSet(rSet: RuleSet, segmentIdToNameMap?: Map<string, string>): Map<string, string> {
    segmentIdToNameMap = segmentIdToNameMap ?? new Map<string, string>();
    if (rSet.rules.length > 0) {
      rSet.rules.forEach((r: RuleSet | Rule) => {
        if (this.isRuleSet(r)) {
          this.getSegmentIdNameMapFromRuleSet(r, segmentIdToNameMap);
        } else {
          if (r?.segmentId && !segmentIdToNameMap.has(r.segmentId)) {
            segmentIdToNameMap.set(r.segmentId, r.segmentName);
          }
        }
      });
    }
    return segmentIdToNameMap;
  }

  getUniqueSegmentIdsFromRuleSet(rSet: RuleSet): string[] {
    const segIdNameMap: Map<string, string> = this.getSegmentIdNameMapFromRuleSet(rSet);
    return [...segIdNameMap.keys()];
  }

  isRuleComplete(r: Rule): boolean {
    if (["Tune-In Program", "Tune-In Station", "Tune-In Scheduling", "Tune-In Genre"].includes(r.qualifierName)) {
      return !!r.field && !!r.value?.length && !!r.tercile?.length;
    } else {
      return (
        r.operator &&
        (Array.isArray(r.value) ? r.value.length > 0 : true) &&
        (typeof r.value === "string" ? r.value.length > 0 : true) &&
        r.value != null &&
        typeof r.value !== "undefined"
      );
    }
  }

  isRuleSetComplete(rSet: RuleSet, defaultProfile: boolean = false): boolean {
    if (rSet.rules.length === 0) {
      return defaultProfile;
    }
    return rSet.rules.every((r: RuleSet | Rule) => {
      if (this.isRuleSet(r)) {
        return this.isRuleSetComplete(r);
      } else {
        return this.isRuleComplete(r);
      }
    });
  }

  isRuleSetPartiallyComplete(rSet: RuleSet, defaultProfile: boolean = false): boolean {
    if (rSet.rules.length === 0) {
      return defaultProfile;
    }
    return rSet.rules.some((r: RuleSet | Rule) => {
      if (this.isRuleSet(r)) {
        return this.isRuleSetComplete(r);
      } else {
        return this.isRuleComplete(r);
      }
    });
  }

  isTuneInRuleDateInvalid(r: Rule): boolean {
    return (
      (!r.originalAirDateStart && !r.originalAirDateEnd) ||
      (!r.originalAirDateEnd && r.originalAirDateStart && this.isDate(r.originalAirDateStart)) ||
      (!r.originalAirDateStart && r.originalAirDateEnd && this.isDate(r.originalAirDateEnd)) ||
      (r.originalAirDateStart &&
        r.originalAirDateEnd &&
        this.isDate(r.originalAirDateStart) &&
        this.isDate(r.originalAirDateEnd) &&
        r.originalAirDateEnd >= r.originalAirDateStart)
    );
  }

  isDate(date: string): boolean {
    return new Date(date).toString() !== "Invalid Date";
  }

  isTuneInRuleSetDateValid(rSet: RuleSet): boolean {
    return rSet.rules.some((r: RuleSet | Rule) => {
      if (this.isRuleSet(r)) {
        return this.isTuneInRuleSetDateValid(r);
      } else {
        return !this.isTuneInRuleDateInvalid(r);
      }
    });
  }

  isRuleSet(r: Rule | RuleSet): r is RuleSet {
    return (r as RuleSet).rules !== undefined;
  }

  createQualifierHash(field: string, value: string, operator: string, isControl: boolean): string {
    const valueString: string = Array.isArray(value) ? value.map(item => `${item}`).join(",") : `${value}`;
    return md5(`field:${field},value:${valueString},operator:${operator},isControl:${isControl}`.toLowerCase());
  }
}
