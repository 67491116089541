import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { TreeViewModel } from "radr-shared";
import { CampaignBuilderLeftMenuTypes } from "../campaign-builder-left-menu/campaign-builder-left-menu.component";
import { TreeOptions } from "@circlon/angular-tree-component/lib/models/tree-options.model";
import { TreeComponent } from "@circlon/angular-tree-component";

@Component({
  selector: "radr-data-provider-list",
  templateUrl: "data-provider-list.component.html",
  styleUrls: ["data-provider-list.component.scss"]
})
export class DataProviderListComponent implements OnChanges, AfterViewInit {
  @ViewChild("treeView") treeView: TreeComponent;
  public CampaignBuilderLeftMenuTypes: any = CampaignBuilderLeftMenuTypes;
  options: Partial<TreeOptions> = {
    useVirtualScroll: true,
    nodeHeight: () => 60
  };

  public initialized: boolean = false;
  @Input() disabled: boolean;
  @Input() type: CampaignBuilderLeftMenuTypes;
  @Input() tree: TreeViewModel[];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      if (changes.tree) {
        setTimeout(this.expandTree.bind(this), 0);
      }
    }
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    this.expandTree();
  }

  expandTree(): void {
    this.treeView.treeModel.collapseAll();
    this.tree.forEach((node, i) => {
      if (node.isExpanded) {
        setTimeout(() => {
          this.treeView.treeModel.roots[i]?.expand();
        }, 0);
      }
      if (node.children) {
        node.children.forEach((childNode, childIndex) => {
          if (childNode.isExpanded) {
            setTimeout(() => {
              this.treeView.treeModel.roots[i].children[childIndex].expand();
            }, 0);
          }
        });
      }
    });
  }
}
