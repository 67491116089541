import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import BaseService from "../lib/base.service";

import { SubscriberSummaryViewModel } from "radr-shared";
import { ApplicationSettingsService } from "./application-settings.service";

export enum CountLoadingStatus {
  Unknown = "UNKNOWN",
  Pending = "PENDING",
  Complete = "COMPLETE"
}

@Injectable({
  providedIn: "root"
})
export class SubscriberService extends BaseService {
  protected readonly route: string = "subscriber";

  public subscriberCount: ReplaySubject<number> = new ReplaySubject<number>(1);
  public subscriberSummary: ReplaySubject<SubscriberSummaryViewModel> = new ReplaySubject<SubscriberSummaryViewModel>(
    1
  );

  constructor() {
    super();
  }

  getSubscriberCount(): void {
    super.getOne<number>(`${this.route}/count`).subscribe(count => {
      this.subscriberCount.next(count);
    });
  }

  getSubscriberSummary(): void {
    super
      .getOne<SubscriberSummaryViewModel>(`${this.route}/summary`)
      .subscribe((summary: SubscriberSummaryViewModel) => {
        this.subscriberSummary.next({
          ...summary,
          devicesLastUpdated: new Date(summary.devicesLastUpdated),
          subscribersLastUpdated: new Date(summary.subscribersLastUpdated)
        });
      });
  }

  overrideSubscriberDeviceImport(): void {
    super.post<any>(`${this.route}/overrideSubscriberDeviceImport`, null).subscribe();
  }
}
