/**
 * Represents the model for a Qualifer in RADR
 *
 * @interface Qualifier
 */
import { FileStatus } from "./file-status.model";
import { AudienceCappingType, CappingType } from "../enums";
import { DataSource } from "./data-source.model";
import { Audience } from "./audience.model";

export class Qualifier {
  /**
   * Identifier for a qualifier
   *
   * @type {number}
   * @memberof Qualifier
   */
  id: number;
  /**
   * The name of the qualifier
   *
   * @type {string}
   * @memberof Qualifier
   */
  name: string;
  /**
   * The description of the qualifier
   *
   * @type {string}
   * @memberof Qualifier
   */
  description: string;
  /**
   * The original name of the column in the raw file   *
   *
   * @type {string}
   * @memberof Qualifier
   */
  columnName: string;

  /**
   * The data type of the qualifier
   *
   * @type {string}
   * @memberof Qualifier
   */
  dataType: string;
  /**
   * The qualifier value/test value.
   *
   * @type {string[] | null}
   * @memberof Qualifier
   */
  qualifierValue: string[] | null;
  /**
   * Parent file / data source status.
   *
   * @type {FileStatus}
   * @memberof Qualifier
   */
  status: FileStatus;
  /**
   * Parent data source id.
   *
   * @type {number}
   * @memberof Qualifier
   */
  dataSourceId: number;
  /**
   * Parent data source.
   *
   * @type {DataSource}
   * @memberof Qualifier
   */
  dataSource?: DataSource;
  /**
   * The user who created the qualifier
   *
   * @type {string}
   * @memberof Qualifier
   */
  createdBy: string;
  /**
   * The date that the qualifier was created
   *
   * @type {string}
   * @memberof Qualifier
   */
  createdDateTime: string;
  /**
   * The user who last modified the qualifier
   *
   * @type {string}
   * @memberof Qualifier
   */
  lastModifiedBy: string;
  /**
   * The date that the qualifier was last modified
   *
   * @type {string}
   * @memberof Qualifier
   */
  lastModifiedDateTime: string;
  /**
   * Represents segment name value
   * @type {string}
   * @memberof Qualifier
   */
  segmentId: string;
  /**
   * Represents segment id value
   * @type {string}
   * @memberof Qualifier
   */
  segmentName: string;
  /**
   * The capping limit for subscribers in this qualifier
   * @type {number}
   * @memberof Qualifier
   */
  qualifierCap: number;
  /**
   * the capping percentage for subscribers in this qualifier
   * @type {number}
   * @memberof Qualifier
   */
  qualifierPercentage: number;
  /**
   * Represents which type of cap to implement
   * @type {CappingType | AudienceCappingType}
   * @memberof Qualifier
   */
  qualifierCappingType: CappingType | AudienceCappingType;
  /**
   * The total number of active subscribers in this qualifier
   * @type {number}
   * @memberof Qualifier
   */
  matchedCount: number;
  /**
   * Segment Definition from external system
   * @type {string}
   * @memberof Qualifier
   */
  segmentDefinition: string;
  /**
   * Category to help group qualifiers
   * @type {string}
   * @memberof Qualifier
   */
  category: string;
  /**
   * The total number of rows in the qualifier's original data source. Only for HHID types
   * @type {number}
   * @memberof Qualifier
   */
  unmatchedCount: number;
  /**
   * The query associated with the qualifier if the type is tune-in
   * @type {string}
   * @memberof Qualifier
   */
  query: string;
  /**
   * The type of qualifier (standard = 1, tune-in = 2, query = 3)
   * @type {number}
   * @memberof Qualifier
   */
  qualifierTypeId: number;
  /**
   * The type of tune in qualifier
   * @type {string}
   * @memberof Qualifier
   */
  tuneInType: string;
  /**
   * Boolean value representing if qualifier is allowed for limited access role
   * @type {boolean}
   * @memberof Qualifier
   */
  enabledForLimitedAccessRole: boolean;
  /**
   * Sibling audience id.
   *
   * @type {number}
   * @memberof Qualifier
   */
  audienceId?: number;
  /**
   * sibling audience.
   *
   * @type {Audience}
   * @memberof Qualifier
   */
  audience?: Audience;
  /**
   * control percentage of subscribers.
   *
   * @type {number | null}
   * @memberof Qualifier
   */
  controlPercentage?: number | null;
}
