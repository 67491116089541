import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SwUpdate, UpdateAvailableEvent } from "@angular/service-worker";
import { DialogModalComponent } from "@shared/components/dialog-modal/dialog-modal.component";
import closeDialogAndNotify from "@shared/utils/closeDialogAndNotify";
import { SnackBarStatus } from "@shared/utils/notify";
import { KeycloakService } from "keycloak-angular";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PromptAppUpdateServiceService {
  constructor(
    updates: SwUpdate,
    matDialog: MatDialog,
    snackbar: MatSnackBar,
    private keycloak: KeycloakService
  ) {
    updates.available.subscribe((event: UpdateAvailableEvent) => {
      const currentVersion: string = this.getVersionInfo(event.current.appData);
      const newVersion: string = this.getVersionInfo(event.available.appData);
      const dialogRef: MatDialogRef<DialogModalComponent> = matDialog.open(DialogModalComponent, {
        data: {
          title: "New Version Available",
          content: `A new version of RADR is available!

Current Version: ${currentVersion}
Available Version: ${newVersion}

To use this new version, please click 'Update' below to refresh your browser.

If you are in the middle of something, you may click 'Cancel' and save your work.
Once your work is saved, please refresh the application.

Unexpected behavior could occur when using an old version of the application.`,
          submitButtonText: "Update",
          cancelButtonText: "Cancel"
        }
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((update: boolean) => {
          if (update) {
            keycloak
              .updateToken(60)
              .then(() => {})
              .catch(() => {
                alert("Failed to refresh token");
              });
            updates.activateUpdate().then(() => document.location.reload());
          } else {
            closeDialogAndNotify(
              matDialog,
              snackbar,
              "Please refresh your browser after saving your changes!",
              SnackBarStatus.Error
            );
          }
        });
    });
  }

  private getVersionInfo(appData: any): string {
    if (appData?.release && appData?.release !== "CODEBUILD_GIT_TAG") {
      return appData.release;
    }
    return appData?.commit;
  }
}
