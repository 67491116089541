export interface TriggerData {
  text: string;
  remaining: number;
}

/**
 * Return a text string for display within the select trigeer
 * If there are more items to display then the max length will allow, return a remaining count
 * Note: Will always use the first value as the text even if the length of that value exceeds max length
 */
export const getTriggerData: (selectedValue?: any[], triggerTextMaxLen?: number) => TriggerData = (
  selectedValue,
  triggerTextMaxLen = 10
) => {
  const data: TriggerData = {
    text: "",
    remaining: 0
  };

  if (selectedValue) {
    if (Array.isArray(selectedValue) && selectedValue.length) {
      const [first, ...values] = selectedValue;
      data.text = first.toString();
      while (values.length) {
        const remainingItemsCount: number = values.length;
        const newText: string = `${data.text}, ${values.shift()}`;
        if (newText.length > triggerTextMaxLen) {
          data.remaining = remainingItemsCount;
          return data;
        }
        data.text = newText;
      }
    } else {
      data.text = selectedValue.toString();
    }
  }

  return data;
};
