/**
 * Represents the model for an Audience in RADR and Hubble
 *
 * @interface Audience
 */
import { AudienceLabel } from "./audience-label.model";
import { AudienceSublabel } from "./audience-sub-label.model";
import { SimplifiCategory } from "./simplifi-category.model";
import { SimplifiSubcategory } from "./simplifi-subcategory.model";
import { DataSource } from "./data-source.model";
import { Qualifier } from "./qualifier.model";
import { AudienceCappingType } from "../enums";
export class Audience {
  id: number;
  audienceName: string = "";
  uiDisplayName: string;
  definition: any = { condition: "and", rules: [] };
  labelId?: number;
  label?: AudienceLabel;
  sublabelId?: number;
  sublabel?: AudienceSublabel;
  dataType: string;
  columnName: string;
  /**
   * User that created the profile
   * @type {string}
   * @memberof Profile
   */
  createdBy: string;

  /**
   * The priority of the audience, relative to the other audiences in the audience
   * @type {number}
   * @memberof Audience
   */
  priority: number;

  /**
   * The export key of the profile
   * @type {string}
   * @memberof Profile
   */
  exportKey: string;

  /**
   * Segment id
   * @type {string}
   * @memberof Profile
   */
  segmentId: string;

  /**
   * The capped subscriber count for the campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  capFixed: number;

  /**
   * The capped subscriber count for the campaign
   *
   * @type {number}
   * @memberof Campaign
   */
  capPercentage: number;

  /**
   * Control percentage of profile
   * @type {number}
   * @memberof Profile
   */
  controlPercentage: number;

  /**
   * The name of the profile
   * @type {string}
   * @memberof Profile
   */
  name: string;

  /**
   * The simplifi category id of the profile
   * @type {SimplifiCategory}
   * @memberof Profile
   */
  simplifiCategory: SimplifiCategory;

  /**
   * The simplifi subcategory id of the profile
   * @type {SimplifiSubcategory}
   * @memberof Profile
   */
  simplifiSubcategory: SimplifiSubcategory;

  /**
   * The description of the profile
   * @type {string}
   * @memberof Profile
   */
  description: string;

  /**
   * Boolean if the profile is a data catalog profile or not
   * @type {boolean}
   * @memberof Profile
   */
  inDataCatalog: boolean;

  /**
   * Segment name
   * @type {string}
   * @memberof Profile
   */
  segmentName: string;

  /**
   * Identifier for the campaign id of the profile
   *
   * @type {number}
   * @memberof Profile
   */
  campaignId: number;
  createdDateTime: Date | string;
  dataSourceId: number;
  dataSource: DataSource;
  audienceCappingType: AudienceCappingType;

  /**
   * The type of audience (standard = 1, tune-in = 2, query = 3)
   * @type {number}
   * @memberof Audience
   */
  audienceType: number;
  qualifierId?: number;
  /**
   * sibling qualifier.
   *
   * @type {Qualifier}
   * @memberof Audience
   */
  qualifier?: Qualifier;
}
