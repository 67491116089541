import { Injectable } from "@angular/core";
import { Observable, timer } from "rxjs";
import BaseService from "@shared/lib/base.service";
import { switchMap, takeWhile } from "rxjs/operators";
import notify, { SnackBarStatus } from "@shared/utils/notify";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root"
})
export class TasksService extends BaseService {
  constructor(private snackBar: MatSnackBar) {
    super();
  }

  public pollForTaskCompletion(
    taskId: string,
    successMessage: string,
    failureMessage: string,
    pollingFrequencyMs: number = 10000,
    maxRetries: number = 360
  ): void {
    let stopPolling: boolean = false;
    let currentRetryNumber: number = 0;
    timer(0, pollingFrequencyMs)
      .pipe(
        takeWhile(() => !stopPolling),
        switchMap(() => this.getTaskStatus(taskId))
      )
      .subscribe(status => {
        currentRetryNumber++;
        if (currentRetryNumber >= maxRetries) {
          stopPolling = true;
        }

        if (status.state !== "PENDING") {
          if (status.state === "SUCCESS") {
            notify(this.snackBar, successMessage, { snackBarType: SnackBarStatus.Success });
          } else if (status.state === "FAILURE") {
            notify(this.snackBar, failureMessage, { snackBarType: SnackBarStatus.Error });
          }
          stopPolling = true;
          localStorage.removeItem(`task_id_${taskId}`);
        }
      });
  }

  public getTaskStatus(taskId: string): Observable<any> {
    return this.getOne(`tasks/${taskId}`);
  }
}
