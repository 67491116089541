import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogModalComponent } from "@shared/components/dialog-modal/dialog-modal.component";
import { EnhancedFormGroup } from "@shared/components/form/form.component";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ExecutionPlatformGroupsService } from "@shared/services/execution-platform-groups.service";
import { ExecutionPlatformsService } from "@shared/services/execution-platforms.service";
import {
  ExecutionPlatform,
  ExecutionPlatformGroupRule,
  CampaignViewModel,
  Universe,
  ExecutionPlatformGroup
} from "radr-shared";
import { Subject } from "rxjs";
import { distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import uniq from "lodash.uniqby";

@Component({
  selector: "radr-execution-platform-group-header",
  templateUrl: "./execution-platform-group-header.component.html",
  styleUrls: ["./execution-platform-group-header.component.scss"]
})
export class ExecutionPlatformGroupHeaderComponent implements OnChanges, OnDestroy, OnInit {
  @Input() groupNumber: number;
  @Input() formGroup: EnhancedFormGroup | FormGroup;
  @Input() isGross: boolean;
  @Input() groupRules: ExecutionPlatformGroupRule[];

  private get requesterGroupId(): AbstractControl {
    return this.formGroup?.get("requesterGroupId");
  }

  private get productGroupId(): AbstractControl {
    return this.formGroup?.get("productGroupId");
  }

  private get executionPlatformId(): AbstractControl {
    return this.formGroup?.get("executionPlatformId");
  }

  public get campaign(): CampaignViewModel {
    return this.campaignBuilderService.getSelectedItem();
  }

  public get universe(): Universe {
    return this.campaignBuilderService.getUniverseForExecutionPlatformGroup(this.groupNumber);
  }

  public grossOrDedupe: "Gross" | "Dedupe" = "Gross";
  public destination: string = "N/A";
  public networkId: string;
  public deviceCount: string = "N/A";
  public householdCount: string = "N/A";

  public executionPlatform: ExecutionPlatform;

  private cleanUp: Subject<void> = new Subject<void>();

  constructor(
    public campaignBuilderService: CampaignBuilderService,
    private executionPlatformsService: ExecutionPlatformsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.executionPlatformId?.valueChanges
      ?.pipe(takeUntil(this.cleanUp), distinctUntilChanged())
      .subscribe(executionPlatformId => {
        this.handleExecutionPlatformChange(executionPlatformId);
      });
    this.productGroupId?.valueChanges
      ?.pipe(takeUntil(this.cleanUp), distinctUntilChanged())
      .subscribe(executionPlatformId => {
        this.handleProductGroupChange(executionPlatformId);
      });
    this.campaignBuilderService.focusedCampaignChanged$
      .pipe(
        takeUntil(this.cleanUp),
        filter(c => !c.id)
      )
      .subscribe(() => {
        this.clearGroup();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.groupRules?.currentValue?.length >= 0) {
      this.handleExecutionPlatformChange(this.executionPlatformId?.value);
    }
  }

  ngOnDestroy(): void {
    this.cleanUp.next();
    this.cleanUp.complete();
  }

  public clearGroup(): void {
    this.requesterGroupId.reset();
    this.productGroupId.reset();
    this.executionPlatformId.reset();

    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    if (campaign && campaign.executionPlatformGroups[this.groupNumber]) {
      if (campaign.executionPlatformGroups[this.groupNumber].executionPlatformId === 6) {
        campaign.canoeVideoCampaignId = null;
        campaign.canoeVideoCampaignName = null;
      }
      if (campaign.executionPlatformGroups[this.groupNumber].executionPlatformId === 7) {
        campaign.canoeATVCampaignId = null;
        campaign.canoeATVCampaignName = null;
      }
      campaign.executionPlatformGroups[this.groupNumber].requesterGroupId = null;
      campaign.executionPlatformGroups[this.groupNumber].productGroupId = null;
      campaign.executionPlatformGroups[this.groupNumber].executionPlatformId = null;
      campaign.executionPlatformGroups[this.groupNumber].universeId = null;
      campaign.executionPlatformGroups[this.groupNumber].universe = null;
      campaign.onPropertyChange$.next("executionPlatformGroups");
    }
  }

  public removeOrClearExecutionPlatformGroup(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    const epGroups: ExecutionPlatformGroup[] = campaign.executionPlatformGroups;
    if (epGroups.length > 1) {
      campaign.executionPlatformGroups.splice(this.groupNumber, 1);
      campaign.onPropertyChange$.next("executionPlatformGroups");
    } else {
      this.clearGroup();
    }
  }

  private handleProductGroupChange(productGroupId: number): void {
    const foundRule: ExecutionPlatformGroupRule = this.groupRules.find((rule: ExecutionPlatformGroupRule) => {
      return (
        rule?.requesterGroup?.id === this.requesterGroupId.value &&
        rule?.productGroup?.id === productGroupId &&
        rule?.executionPlatform?.id === this.executionPlatformId.value
      );
    });
    this.executionPlatform = foundRule?.executionPlatform;
    this.destination = this.executionPlatform?.destination?.name ?? "N/A";
    this.getNetworkId(this.executionPlatformId.value);
    const universe: Universe = foundRule?.universe;
    if (!!universe && !this.campaignBuilderService.hasCountsForUniverse(universe)) {
      this.campaignBuilderService.loadCounts({ universes: [universe], keepCache: true });
    }
  }

  private handleExecutionPlatformChange(executionPlatformId: number): void {
    const foundRule: ExecutionPlatformGroupRule = this.groupRules.find((rule: ExecutionPlatformGroupRule) => {
      return (
        rule?.requesterGroup?.id === this.requesterGroupId.value &&
        rule?.productGroup?.id === this.productGroupId.value &&
        rule?.executionPlatform?.id === executionPlatformId
      );
    });
    this.executionPlatform = foundRule?.executionPlatform;
    this.destination = this.executionPlatform?.destination?.name ?? "N/A";
    this.getNetworkId(executionPlatformId);
    const universes: Universe[] = uniq(
      [
        foundRule?.universe,
        ...this.campaignBuilderService
          .getSelectedUniverses()
          .filter(universe => !this.campaignBuilderService.hasCountsForUniverse(universe))
      ],
      "id"
    );
    if (!!foundRule?.universe && !this.campaignBuilderService.hasCountsForUniverse(foundRule?.universe)) {
      this.campaignBuilderService.loadCounts({ universes, keepCache: true, force: true });
    }
  }

  private getNetworkId(executionPlatformId: number): void {
    if (executionPlatformId) {
      this.executionPlatformsService
        .getNetworkId(executionPlatformId)
        .pipe(take(1))
        .subscribe(networkId => {
          this.networkId = networkId;
        });
    }
  }

  requestRemoveExecutionPlatform(): void {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();

    if (
      this.oppositeResearchPlatormToggledOn(campaign) &&
      !this.campaignBuilderService.doesCampaignOnlyHaveDefaultExecutionPlatformGroup(campaign)
    ) {
      const researchPlatform: string =
        campaign.researchPlatforms.find(rp => rp.id === this.universe.id)?.id === 2
          ? "Audience Finder"
          : ("Audience Finder - ATV" ?? "");
      const dialogRef: MatDialogRef<DialogModalComponent> = this.dialog.open(DialogModalComponent, {
        data: {
          title: "Remove Research Platform",
          content: `Are you sure you want to remove this execution platform? Doing so will also remove the ${researchPlatform} research platform from the campaign as well.`,
          submitButtonText: "OK",
          cancelButtonText: "Cancel",
          dialogType: "Warning"
        }
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(removeRequested => {
          if (removeRequested) {
            campaign.removeResearchPlatform(this.universe.id);
            campaign.toggleOffRestrictedFields(this.universe);
            this.removeOrClearExecutionPlatformGroup();
          }
        });
    } else {
      this.removeOrClearExecutionPlatformGroup();
    }
  }

  oppositeResearchPlatormToggledOn(campaign: CampaignViewModel): boolean {
    return (
      (campaign.executionPlatformGroups.filter(ep => ep.universeId === 2).length === 1 &&
        this.universe?.id === 2 &&
        !!campaign.universes.find(u => u.id !== 2) &&
        campaign.isAudienceFinderToggledOn) ||
      (campaign.executionPlatformGroups.filter(ep => ep.universeId === 3).length === 1 &&
        this.universe?.id === 3 &&
        !!campaign.universes.find(u => u.id !== 3) &&
        campaign.isAudienceFinderATVToggledOn)
    );
  }
}
