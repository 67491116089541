import { Component, Input } from "@angular/core";
import { QueryBuilderService } from "@shared/services/query-builder.service";
import { CampaignBuilderLeftMenuTypes } from "../campaign-builder-left-menu/campaign-builder-left-menu.component";
import { CampaignViewModel } from "radr-shared";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { RoleAccessService } from "@shared/services/role-access.service";

@Component({
  selector: "radr-campaign-builder-list",
  templateUrl: "./campaign-builder-list.component.html",
  styleUrls: ["./campaign-builder-list.component.scss"]
})
export class CampaignBuilderListComponent {
  @Input() items: any[];
  @Input() type: CampaignBuilderLeftMenuTypes;
  @Input() filterText: string;
  public types: any = CampaignBuilderLeftMenuTypes;

  get campaign(): CampaignViewModel {
    return this.campaignBuilderService.getSelectedItem();
  }

  get isQualifierList(): boolean {
    return CampaignBuilderLeftMenuTypes.Qualifiers === this.type;
  }

  get dropList(): null | string | string[] {
    switch (this.type) {
      case CampaignBuilderLeftMenuTypes.Qualifiers:
        return this.queryBuilderService.qualifierDropZoneIds;
      case CampaignBuilderLeftMenuTypes.Profiles:
        return this.queryBuilderService.profileDropZoneIds;
      default:
        return null;
    }
  }

  constructor(
    private queryBuilderService: QueryBuilderService,
    private campaignBuilderService: CampaignBuilderService,
    public roleAccessService: RoleAccessService
  ) {}
}
