import { HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  concat,
  interval,
  Observable,
  of,
  Subject,
  Subscription,
  throwError
} from "rxjs";
import { catchError, exhaustMap, filter, map, switchMap, take, takeLast, takeWhile, tap } from "rxjs/operators";
import { KeycloakService } from "keycloak-angular";
import {
  AudienceViewModel,
  Audience,
  CappingLevel,
  TreeViewModel,
  Universe,
  AudienceCountRequestFactory
} from "radr-shared";
import { Location } from "@angular/common";
import MasterDetailService from "../lib/master-detail.service";
import clonedeep from "lodash.clonedeep";
import { AudiencesService } from "./audience.service";
import { ApplicationSettingsService } from "./application-settings.service";
import { defaultControlPercentage } from "@shared/consts";
import { ExecutionPlatformsService } from "./execution-platforms.service";
import { DialogModalComponent } from "@shared/components/dialog-modal/dialog-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { SnackBarStatus } from "@shared/utils/notify";
import { AudienceNotFoundError, errorFunctionNameFactory } from "@shared/models";
import { saveAs } from "file-saver";
import { UniversesService } from "./universes.service";
import { TasksService } from "./tasks.service";
import { CeleryTaskStatesEnum } from "radr-shared/src/enums";
import { Rule } from "@shared/components/audience-query-builder";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleAccessService } from "./role-access.service";
import { QualifiersService } from "./qualifiers.service";

@Injectable({
  providedIn: "root"
})
export class AudienceBuilderService extends MasterDetailService<AudienceViewModel> implements OnDestroy {
  // get isAudienceCountsLoading(): boolean {
  //   return this.audienceCountsTasksProcessing.length > 0;
  // }
  public get isAudienceCountsLoading(): boolean {
    return this._isAudienceCountsLoading;
  }

  get firstPriority(): number {
    const audience: AudienceViewModel = this.getSelectedItem();
    if (audience && audience?.audiences?.length > 0) {
      return audience.audiences.find(p => p.priority === 0) ? 0 : 1;
    }
    return 1;
  }

  get includesTuneInAudience(): boolean {
    return !!this.getSelectedItem().audiences.find(p => p.audienceType === 2);
  }

  get audienceCountsEnabled(): boolean {
    return this.getSelectedItem().autoCalculateCounts;
  }
  set audienceCountsEnabled(value: boolean) {
    const audience: AudienceViewModel = this.getSelectedItem();
    if (value && !this.audienceCountsEnabled) {
      audience.autoCalculateCounts = value;
      // this.loadCounts({ keepCache: false });
    } else {
      audience.autoCalculateCounts = value;
    }
  }

  constructor(
    public dialog: MatDialog,
    private tasksService: TasksService,
    private roleAccessService: RoleAccessService,
    private qualifierService: QualifiersService
  ) {
    super("audiences", false, AudienceViewModel);
  }
  public audienceList$: Observable<Audience[]> = this.masterListSource$.asObservable();
  public audiencesTreeView: BehaviorSubject<TreeViewModel[]> = new BehaviorSubject<TreeViewModel[]>([]);
  public newAudienceAddedToAudience: Subject<{
    audience: AudienceViewModel;
    options: { highlight: boolean };
  }> = new Subject();
  public isLoadingTreeView: boolean = false;
  public counts: any = {};
  public audienceCountsTasksProcessing: string[] = [];
  public audienceCounts: any = {};
  private audiencesPendingCounts: Partial<Rule>[] = [];
  private _isAudienceCountsLoading: boolean = false;
  public focusedAudienceChanged$: Subject<AudienceViewModel> = new Subject<AudienceViewModel>();
  public countError: boolean = false;
  public audienceCountErrors: Set<string> = new Set();
  private audienceStatusPoller: Subscription;
  private audienceCountsPoller: Subscription;
  public pendingAudienceCountRequest: Subscription;
  public selectedAudienceIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  public static b64toBlob(b64Data: string, contentType: string = "", sliceSize: number = 512): Blob {
    const byteCharacters: string = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice: string = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers: any[] = new Array(slice.length);
      for (let i: number = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray: Uint8Array = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob: Blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  ngOnDestroy(): void {
    if (this.audienceStatusPoller) {
      this.audienceStatusPoller.unsubscribe();
    }
    if (this.audienceCountsPoller) {
      this.audienceCountsPoller.unsubscribe();
    }
    if (this.pendingAudienceCountRequest) {
      this.pendingAudienceCountRequest.unsubscribe();
    }
  }

  selectItem(item: AudienceViewModel): void {
    const oldId: number = this.getSelectedItem()?.id;
    this.selectedItemSource$.next(item);
    this.counts = {};
    if (item.id !== oldId && !!oldId) {
      this.focusedAudienceChanged$.next(item);
    }
  }

  getAudiences(): Observable<AudienceViewModel[]> {
    return this.updateMasterList();
  }

  getAudience(audienceId: number): Observable<AudienceViewModel> {
    return this.getOne<AudienceViewModel>(`${this.route}/${audienceId}`);
  }

  getAudienceBuilderViewModel(audienceId: number): Observable<AudienceViewModel> {
    return this.getOne<AudienceViewModel>(`${this.route}/builder-viewmodel/${audienceId}`);
  }

  getAudienceStatus(audienceId: number): Observable<Audience> {
    return this.getOne<Audience>(`${this.route}/${audienceId}/status`, undefined, false);
  }

  getUpdatedRootAudience(audienceId: number): Observable<Audience> {
    return this.getOne<Audience>(`audiences/${audienceId}`);
  }

  getAudienceCounts(audienceHash: string): number {
    return this.audienceCounts[`A_${audienceHash.toUpperCase()}_COUNT`];
  }

  getAudiencesTreeView(): Observable<TreeViewModel[]> {
    this.isLoadingTreeView = true;
    const inDataCatalog: string = this.roleAccessService.isLimitedAccess() ? `?inDataCatalog=true` : "";
    return super.getMany(`${this.route}/tree-view${inDataCatalog}`, undefined, false).pipe(
      tap((tree: TreeViewModel[]) => {
        this.isLoadingTreeView = false;
        this.audiencesTreeView.next(tree);
      })
    );
  }

  private getUniverseCounts(universeName: string): any {
    return this.counts[universeName] || {};
  }

  public getTotalHouseholdCount(universe?: Universe): number {
    return this.getUniverseCounts(universe?.name).CAMPAIGN_COUNT;
  }

  public hasCountsForUniverse(universe?: Universe): boolean {
    return Object.keys(this.getUniverseCounts(universe?.name)).length > 0;
  }

  public loadAudienceCounts(audienceRules: Rule[]): void {
    // if (this.includesTuneInAudience) {
    //   this._isAudienceCountsLoading = false;
    //   this.counts = {};
    //   return;
    // }
    this._isAudienceCountsLoading = true;
    if (this.pendingAudienceCountRequest) {
      this.pendingAudienceCountRequest.unsubscribe();
    }
    this.audiencesPendingCounts = this.audiencesPendingCounts.concat(audienceRules);
    audienceRules.forEach(rule => {
      const hash: string = this.qualifierService.createQualifierHash(
        rule.field,
        rule.value,
        rule.operator,
        rule.isControl
      );
      this.audienceCountErrors.delete(hash);
    });
    this.pendingAudienceCountRequest = super
      .post<any>(`subscriber/count/audience`, AudienceCountRequestFactory.create(this.audiencesPendingCounts))
      .pipe(
        catchError(err => {
          this.audienceCountsTasksProcessing = [];
          audienceRules.forEach(rule => {
            const hash: string = this.qualifierService.createQualifierHash(
              rule.field,
              rule.value,
              rule.operator,
              rule.isControl
            );
            this.audienceCountErrors.add(hash);
          });
          this._isAudienceCountsLoading = false;
          return throwError(errorFunctionNameFactory(err, "SubscriberService.getAudienceCounts"));
        })
      )
      .subscribe((countsTaskId: string) => {
        this.audienceCountsTasksProcessing.push(countsTaskId);
        this.audienceCountsPoller = this.taskPoll({ taskId: countsTaskId }).subscribe(finishedTask => {
          if (finishedTask.state === CeleryTaskStatesEnum.FAILURE) {
            this.countError = true;
          }
          this.counts = Object.assign(this.counts || {}, finishedTask.result);
          this.audienceCounts = this.getUniverseCounts("All Subscribers");
          this.audienceCountsTasksProcessing = this.audienceCountsTasksProcessing.filter(id => id !== finishedTask.id);
          this._isAudienceCountsLoading = false;
        });
      });
  }

  taskPoll(options: { taskId: string; isAudienceTask?: boolean; pollingFrequencyMs?: number }): Observable<any> {
    const pollingInterval$: BehaviorSubject<number> = new BehaviorSubject(500);
    let stopPolling: boolean = false;
    const poll: Observable<any> = pollingInterval$.pipe(switchMap(value => interval(value))).pipe(
      takeWhile(() => !stopPolling),
      exhaustMap(() => this.tasksService.getTaskStatus(options.taskId)),
      filter(finishedTask => ["SUCCESS", "FAILURE"].includes(finishedTask.state)),
      tap(() => {
        stopPolling = true;
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
        clearTimeout(thirdTimeout);
      })
    );

    const firstTimeout: NodeJS.Timeout = setTimeout(() => pollingInterval$.next(1000), 2000);
    const secondTimeout: NodeJS.Timeout = setTimeout(() => pollingInterval$.next(2000), 12000);
    const thirdTimeout: NodeJS.Timeout = setTimeout(() => pollingInterval$.next(5000), 42000);

    return poll;
  }

  // addNewAudience(audience?: AudienceViewModel, priority?: number, highlightInBuilder?: boolean): AudienceViewModel {
  //   const audience: AudienceViewModel = this.getSelectedItem();
  //   let newAudience: AudienceViewModel = new AudienceViewModel();
  //
  //   if (audience) {
  //     newAudience = clonedeep(audience);
  //
  //     if (priority || priority === 0) {
  //       audience.audiences.forEach(p => {
  //         if (p.priority >= priority && priority !== 0) {
  //           p.priority += 1;
  //         }
  //       });
  //       newAudience.priority = priority;
  //     } else {
  //       newAudience.priority = audience.audiences.length + this.firstPriority;
  //     }
  //   } else {
  //     newAudience.definition = { condition: "and", rules: [] };
  //     newAudience.priority = audience.audiences.length + this.firstPriority;
  //   }
  //
  //   newAudience.audienceId = audience.id;
  //   newAudience.id = null;
  //   newAudience.name = null;
  //   newAudience.description = null;
  //   newAudience.exportKey = null;
  //   newAudience.capFixed = null;
  //   newAudience.capPercentage = null;
  //   newAudience.status = audience.status.id;
  //   newAudience.createdBy = this.keycloak.getUsername();
  //   newAudience.audienceType = audience ? audience.audienceType : 1;
  //   newAudience.exportKey = audience.groupName
  //     ? audience.groupName + String(newAudience.priority).padStart(2, "0")
  //     : null;
  //
  //   if (this.isAudienceFullAvail() && audience.groupName) {
  //     newAudience.exportKey = this.audiencesService.getAudienceExportKeyByGroupNameAndPriority(
  //       audience.groupName,
  //       newAudience.priority
  //     );
  //   }
  //
  //   if (audience.isControlActive) {
  //     if (audience.isControlLevelAudience) {
  //       newAudience.controlPercentage = this.getSelectedItem().controlPercentage || defaultControlPercentage;
  //     } else {
  //       newAudience.controlPercentage = defaultControlPercentage;
  //     }
  //   } else {
  //     newAudience.controlPercentage = null;
  //   }
  //
  //   audience.audiences.push(newAudience);
  //   audience.audiences.sort((a, b) => a.priority - b.priority);
  //   this.newAudienceAddedToAudience.next({ audience: newAudience, options: { highlight: true } });
  //   this.onSelectedItemPropertyChanged();
  //
  //   return newAudience;
  // }
}
