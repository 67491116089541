// IMPORTANT Any updates to this file should also be made to:
// profile-engine/engine/lib/typedefs/audience_types.py

export enum AudienceDataTypes {
  HouseholdId = "hhid",
  Values = "values",
  Number = "number",
  String = "string",
  Date = "date",
  Boolean = "boolean",
  Canoe = "canoe"
}
