import { Injectable } from "@angular/core";
import { RuleSet, Rule } from "@shared/components/angular-query-builder";
import { Subject, Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class QueryBuilderService {
  get tuneInPrograms(): string[] {
    return this._tuneInPrograms;
  }
  set tuneInPrograms(value: string[]) {
    this._tuneInPrograms = value;
  }
  get tuneInStations(): string[] {
    return this._tuneInStations;
  }
  set tuneInStations(value: string[]) {
    this._tuneInStations = value;
  }
  get tuneInGenres(): string[] {
    return this._tuneInGenres;
  }
  set tuneInGenres(value: string[]) {
    this._tuneInGenres = value;
  }

  public qualifierAddedToList: Subject<string> = new Subject<string>();
  public profileAddedToList: Subject<{ guid: string; ruleSet: RuleSet | Rule }> = new Subject<{
    guid: string;
    ruleSet: RuleSet | Rule;
  }>();
  private qualifierAddedToListSubscription: Subscription = this.qualifierAddedToList.subscribe(guid => {
    this.qualifierDropZoneIds.unshift(`drop-list-${guid}`);
  });
  private profileAddedToListSubscription: Subscription = this.profileAddedToList.subscribe(guidRuleSetMap => {
    this.profileDropZoneIds.unshift(`drop-list-${guidRuleSetMap.guid}`);
    this.dropZoneToRuleSetMap.set(`drop-list-${guidRuleSetMap.guid}`, guidRuleSetMap.ruleSet);
  });
  public dropZoneToRuleSetMap: any = new Map();
  public qualifierDropZoneIds: string[] = [];
  public profileDropZoneIds: string[] = [];

  public _tuneInPrograms: string[] = [];
  public _tuneInStations: string[] = [];
  public _tuneInGenres: string[] = [];

  // AUDIENCE FUNCTIONALITY BELOW THIS LINE
  //
  public audienceAddedToList: Subject<string> = new Subject<string>();
  public audienceDropZoneIds: string[] = [];
  private audienceAddedToListSubscription: Subscription = this.audienceAddedToList.subscribe(guid => {
    this.audienceDropZoneIds.unshift(`drop-list-${guid}`);
  });

  public getCachedOptions(cache: string): any[] {
    switch (cache) {
      case "tuneInPrograms":
        return this.tuneInPrograms;
      case "tuneInStations":
        return this.tuneInStations;
      case "tuneInGenres":
        return this.tuneInGenres;
      default:
        break;
    }
  }

  public setCachedOptions(cache: string, options: any[]): void {
    switch (cache) {
      case "tuneInPrograms":
        this.tuneInPrograms = options;
        break;
      case "tuneInStations":
        this.tuneInStations = options;
        break;
      case "tuneInGenres":
        this.tuneInGenres = options;
        break;
      default:
        break;
    }
  }
}
