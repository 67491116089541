<radr-modal toolbarColor="primary">
  <ng-container body>
    <div class="profile-list-body">
      <p>This {{ data?.type || "qualifier" }} cannot be deleted because it's being used in the following profiles:</p>
      <mat-list class="profile-nav-list">
        <a mat-list-item (click)="goToProfile(profile)" *ngFor="let profile of data.profiles"> {{ profile.name }} </a>
      </mat-list>
    </div>
  </ng-container>

  <ng-container actions>
    <button mat-flat-button color="primary" (click)="closeModal()">OK</button>
  </ng-container>
</radr-modal>
