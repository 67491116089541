import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";

@Component({
  selector: "radr-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"]
})
export class ActionButtonComponent implements OnInit {
  @Input() id: string = Math.random().toString();
  @Input() disabled: boolean = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  buttonClicked(): void {
    this.clicked.emit();
  }
}
