import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApplicationSetting, AppVersion } from "radr-shared";
import BaseService from "../lib/base.service";

@Injectable({
  providedIn: "root"
})
export class ApplicationSettingsService extends BaseService {
  protected readonly route: string = "application-settings";

  subscriberMinimum: number = 0;
  audienceEnabledFlag: boolean = false;

  constructor() {
    super();
  }

  public getApplicationSetting(key: string): Observable<ApplicationSetting> {
    return this.getOne(`${this.route}?key=${key}`);
  }

  public getApplicationSettings(keys: string[]): Observable<ApplicationSetting[]> {
    return this.getMany(`${this.route}?key=${keys.join("|")}`);
  }

  public loadSubscriberMinimum(): Observable<number> {
    return this.getApplicationSetting("minimumSubscribers").pipe(
      map((setting: ApplicationSetting) => {
        if (setting) {
          this.subscriberMinimum = parseInt(setting.value, 10);
        } else {
          console.warn(`No mimimumSubscriber setting found! Defaulting to ${this.subscriberMinimum}`);
        }
        return this.subscriberMinimum;
      })
    );
  }

  public getApplicationVersions(): Observable<AppVersion[]> {
    return this.getMany(`${this.route}/versions`);
  }

  public getAudienceEnabledFlag(): Observable<boolean> {
    return this.getApplicationSetting("audience_enabled").pipe(
      map((setting: ApplicationSetting) => {
        if (setting) {
          this.audienceEnabledFlag = setting.value === "true";
        } else {
          console.warn(`Error retrieving Audience Enabled Flag`);
        }
        return this.audienceEnabledFlag;
      })
    );
  }
}
