import { Component, Input, OnInit } from "@angular/core";
import { SubscriberService } from "@shared/services/subscriber.service";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { CampaignStatusEnum, CampaignViewModel, ProfileViewModel, Universe } from "radr-shared";
import { CampaignBuilderModes } from "@shared/enums/campaign-builder-mode.enum";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { RoleAccessService } from "@shared/services/role-access.service";

@Component({
  selector: "radr-profile-count",
  templateUrl: "./profile-count.component.html",
  styleUrls: ["./profile-count.component.scss"]
})
export class ProfileCountComponent implements OnInit {
  public modes: typeof CampaignBuilderModes = CampaignBuilderModes;
  @Input() mode: string = CampaignBuilderModes.CampaignBuilder;

  public incompleteCount: boolean = false;

  public onChangeSubscription: any;

  @Input() profile: ProfileViewModel;

  get campaign(): CampaignViewModel {
    return this.campaignBuilderService.getSelectedItem();
  }

  get shouldShowGross(): boolean {
    return this.campaignBuilderService.getSelectedItem().isGross;
  }

  get selectedUniverses(): string[] {
    return Array.from(this.campaign.selectedUniverses).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
  }

  get countTitle(): string {
    let countTitle: string = "HOUSEHOLD COUNT";
    if (this.profile) {
      if (this.shouldShowGross) {
        countTitle = `GROSS ${countTitle}`;
      } else {
        countTitle = `DEDUPE ${countTitle}`;
      }
    }
    return countTitle;
  }

  get countType(): string {
    return this.shouldShowGross ? "gross" : "dedupe";
  }

  get countError(): boolean {
    return this.campaignBuilderService.countError;
  }

  get loadingSpinnerId(): string {
    return `${this.getUniqueIdPrefix()}household-${this.countType}-spinner`;
  }

  get countValueId(): string {
    return `${this.getUniqueIdPrefix()}household-${this.countType}-count`;
  }

  get controlLoadingSpinnerId(): string {
    return this.getUniqueIdPrefix() + "household-control-spinner";
  }

  get controlCountValueId(): string {
    return this.getUniqueIdPrefix() + "household-control-count";
  }

  constructor(
    public campaignBuilderService: CampaignBuilderService,
    public qualifierService: QualifiersService,
    public roleAccessService: RoleAccessService
  ) {}

  ngOnInit(): void {
    if (this.profile?.id) {
      this.campaignBuilderService.profileStatusPoll(this.profile);
    } else {
      this.profile.status = CampaignStatusEnum.Inactive;
    }
  }

  getProfileStatus(status: number): string {
    switch (status) {
      case CampaignStatusEnum.Inactive:
        return "Inactive";
      case CampaignStatusEnum.Processing:
        return "Processing";
      case CampaignStatusEnum.Error:
        return "Error";
      case CampaignStatusEnum.Active:
        return "Active";
      case CampaignStatusEnum.PendingProcessing:
        return "Pending Processing";
      default:
        return "Inactive";
    }
  }

  private getUniqueIdPrefix(): string {
    return this.profile ? `profile-${this.profile.priority}-` : "";
  }
}
