<radr-modal toolbarColor="primary" [class.spinner]="isLoading">
  <ng-container body>
    <div class="profile-preview-body" *ngIf="profile">
      <div class="profile-preview-header">
        <div class="profile-data">
          <radr-text-overflow
            class="profile-header-field"
            [displayText]="profile.name"
            *ngIf="profile.name"
          ></radr-text-overflow>
          <span class="divider" *ngIf="profile.description"> | </span>
          <radr-text-overflow
            class="profile-header-field"
            [displayText]="profile.description"
            *ngIf="profile.description"
          ></radr-text-overflow>
          <span class="divider" *ngIf="profile.exportKey"> | </span>
          <radr-text-overflow
            class="profile-header-field"
            [displayText]="profile.exportKey"
            *ngIf="profile.exportKey"
          ></radr-text-overflow>
        </div>
        <div class="profile-counts">
          <span>GROSS HOUSEHOLD COUNT </span>
          <span>Target: </span>
          <span class="profile-header-field">{{ profile.testCount | number: "" : "en-US" }}</span>
          <span> Control: </span>
          <span class="profile-header-field">{{ profile.controlCount | number: "" : "en-US" }}</span>
        </div>
      </div>
      <div class="profile-create-date">
        <span class="profile-header-field">Date Created: </span>
        <span>{{ profile.createdDateTime | date: "shortDate" }}</span>
        <span class="profile-header-field"> Date Updated: </span>
        <span>{{ profile.lastModifiedDateTime | date: "shortDate" }}</span>
      </div>
      <radr-profile-definition
        [(definition)]="profile.definition"
        [profile]="profile"
        [mode]="modes.ProfileBuilder"
        [hideHeader]="true"
        [hideCounts]="true"
        [disabled]="true"
        [canDrag]="false"
        [isPreview]="true"
      ></radr-profile-definition>
    </div>
  </ng-container>
  <ng-container actions>
    <button id="batch-values-modal-ok" color="primary" mat-flat-button (click)="close()">Close</button>
  </ng-container>
</radr-modal>
