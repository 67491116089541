import { Component, Input, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CampaignBuilderModes } from "@shared/enums/campaign-builder-mode.enum";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ProfilesService } from "@shared/services/profiles.service";
import { CampaignViewModel, ProfileViewModel } from "radr-shared";
import { forkJoin, Observable, of } from "rxjs";

export enum RootProfileCloneOptions {
  Cancel,
  Clone,
  Root
}
@Component({
  selector: "radr-root-profile-modal",
  templateUrl: "./root-profile-modal.component.html",
  styleUrls: ["./root-profile-modal.component.scss"]
})
export class RootProfileModalComponent implements OnInit {
  @Input() class: string = "radr-root-profile-modal";

  public enableQualifierOption: boolean = false;
  public rootProfileCloneOption: any = RootProfileCloneOptions;
  public isLoading: boolean = false;
  public sourceProfile: ProfileViewModel;

  constructor(
    public dialogRef: MatDialogRef<RootProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profileService: ProfilesService,
    private campaignBuilderService: CampaignBuilderService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.profileService.getProfileBuilderViewModel(this.data.sourceProfile.id).subscribe(profile => {
      this.sourceProfile = profile;
      let destinationProfileCheck: Observable<ProfileViewModel[]> = of([]);
      if (this.data.destinationProfile?.profileQualifierId) {
        destinationProfileCheck = this.profileService.getProfilesByQualifierId(
          this.data.destinationProfile.profileQualifierId
        );
      }

      let getSourceCampaign: Observable<CampaignViewModel> = of(new CampaignViewModel());
      if (this.sourceProfile?.campaignId) {
        getSourceCampaign = this.campaignBuilderService.getCampaign(this.sourceProfile.campaignId);
      }

      forkJoin([getSourceCampaign, destinationProfileCheck]).subscribe(
        ([sourceCampaign, destinationProfileChildren]) => {
          this.enableQualifierOption =
            this.data.mode !== CampaignBuilderModes.ProfileBuilder &&
            (sourceCampaign?.isActive || this.sourceProfile?.isRoot) &&
            !this.sourceProfile?.containsRootProfiles &&
            destinationProfileChildren?.length === 0 &&
            !this.data.destinationProfile?.isRoot;
        }
      );

      this.isLoading = false;
    });
  }
}
